import React, { useEffect, useRef, useState } from "react";

import {
  AL_BASE_URL,
  REQUEST_TYPES,
  weekdays,
} from "views/Attendance/constants";
// components
import TimePicker from "components/TimePicker/NewTimePicker";
import RequestDatePicker from "./RequestDatePicker";
import RequestType from "./RequestType";
import ClickOutsideListner from "components/ClickOutsideListner";
import DateForm from "./DateForm";
// context
import { useGlobalContext } from "contexts/GlobalContext";
import useWindowDimensions from "hooks/useWindowDimensions";
// utils
import { addDays, areIntervalsOverlapping, format, isBefore, subDays, isAfter, isEqual, isWithinInterval } from "utils/date";
import { convertTimeZone, convertHhmmToUserTimezone, convertHhmmToUtc, getDateFromHhmm, getMyTimeZoneFromLocal, getMyTimezoneOffset2, getUTCTime, isBlankHTML, removeHTMLTags } from "utils/Common";
// services
import { alertService } from "services/alertService";
import APIService from "services/apiService";
// styles
import "./ShiftRequestForm.scss";

const nextDayMsg = 'Please confirm the end time is set for the next day';
const dstAdjustMsg = 'Your input has been updated as per Daylight Saving Time.';
const adhocShiftMessage = "Please work for at-least 4 hrs";
// const standardShiftDstAdjustMsg = 'Working on DST time zone, end date of {{end_date}} applied to schedule. Please apply for new schedule once this is approved.';

const defaultisWFH = 1;
const defaultStartTime = "09:30";
const defaultEndTime = "18:30";
function getSpecialShiftStateEmptyObj(initial) {
  return {
    start_date: "",
    end_date: "",
    timings: [
      {
        start_time: initial ? "" : defaultStartTime,
        end_time: initial ? "" : defaultEndTime,
      },
    ],
  };
}

function getSpecialShiftStateDefault() {
  return [getSpecialShiftStateEmptyObj()];
}

const getAdhocShiftStateObj = () => {
  return {
    start_date: "",
    end_date: "",
  };
};

const getAdhocShiftStateDefault = () => {
  return [getAdhocShiftStateObj()];
};

const getStandardShiftStateDefault = () => {
  let defaultSchedule = [1, 2, 3, 4, 5, 6, 0].map((n) => getDefaultStandardDataObj(n));

  return {
    start_date: "",
    schedule: defaultSchedule,
    dst_schedule: structuredClone(defaultSchedule),
  }
}

function add9HoursToTime(inputTime) {
  // Split the input time into hours and minutes
  const [hours, minutes] = inputTime.split(":").map(Number);

  // Add 9 hours to the input time
  const newHours = (hours + 9) % 24; // Ensure it wraps around to the next day if needed

  // Format the new time
  const newTime = `${String(newHours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;

  return newTime;
}

function getDefaultStandardDataObj(weekday) {
  return {
    weekday,
    start_time: defaultStartTime,
    end_time: defaultEndTime,
    isChecked: false,
  };
}

export default function ShiftRequestForm({
  shiftOptions,
  selectedLeaveType,
  selectedShiftType,
  setSelectedShiftType,
  selectedFormType,
  setSelectedFormType,
  isEditMode,
  data,
  currentSchedule,
  onClose,
  isFormChangesSavedFnRef,
  onSubmit,
  onResubmit,
  loggedInUser,
  setDiscardChangesAlert,
}) {
  const shiftRequestFormRef = useRef();
  const { observesDST, myTimeZone } = useGlobalContext();
  const tzOffset = myTimeZone.utc_offset;
  const dstTZOffset = myTimeZone.dst_utc_offset ?? tzOffset;
  // const myTimeZone = getMyTimeZoneFromLocal();

  const isStandardShiftType = selectedShiftType?.id === REQUEST_TYPES.STANDARD;
  const isSpecialShiftType = selectedShiftType?.id === REQUEST_TYPES.SPECIAL;
  const isAdhocShiftType = selectedShiftType?.id === REQUEST_TYPES.ADHOC;

  // Form Common States
  const [comment, setComment] = useState(() =>
    isEditMode && data.comment ? removeHTMLTags(data.comment) : ""
  );
  const [showDSTForm, setShowDSTForm] = useState(false);

  const [isActivityTracked, setIsActivityTracked] = useState(() => {
    return isEditMode
      ? data?.is_activity_tracked ?? 1
      : data?.type === REQUEST_TYPES.SPECIAL
        ? 1
        : currentSchedule.schedule[0]?.is_activity_tracked ?? 1;
  });
  const [isWFH, setIsWFH] = useState(() => {
    return isEditMode
      ? data.is_wfh
      : data?.type === REQUEST_TYPES.SPECIAL
        ? 1
        : currentSchedule.schedule[0]?.is_wfh ?? 1;
  });

  // Special Shift State and Functions
  const [specialShifts, setSpecialShifts] = useState(() =>
    isEditMode && data.type === REQUEST_TYPES.SPECIAL
      ? structuredClone([...data.specialShifts, getSpecialShiftStateEmptyObj(true)])
      : getSpecialShiftStateDefault()
  );

  const getSpecialShiftData = () => {
    let data = [...specialShifts];
    if (specialShifts.length > 1) {
      // Remove the last special shift card if it's empty.
      // This is to prevent to remove the last special shift card (if empty) when the user is trying to submit the form
      const lastSpecialShift = data[data.length - 1];
      if (
        lastSpecialShift.start_date === "" &&
        lastSpecialShift.end_date === "" &&
        lastSpecialShift.timings.length === 1
      ) {
        const { start_time, end_time } = lastSpecialShift.timings[0];
        if (start_time === "" && end_time === "") data.pop();
      }
    }

    return data;
  };

  const [adhocShiftSlots, setAdhocShiftSlots] = useState(() =>
    isEditMode && data.type === REQUEST_TYPES.ADHOC
      ? structuredClone([...data.adhocShifts, getAdhocShiftStateDefault()])
      : getAdhocShiftStateDefault()
  );

  const getAdhocShiftsData = () => {
    let data = [...adhocShiftSlots];
    if (data.length > 1) {
      // Remove the last slot if it's empty.
      // This is to prevent to remove the last slot (if empty) when the user is trying to submit the form
      const lastLeaveDay = adhocShiftSlots[adhocShiftSlots.length - 1];
      if (lastLeaveDay.start_date === "" && lastLeaveDay.end_date === "") {
        data.pop();
      }
    }

    return data;
  };

  const [focusedSlot, setFocusedSlot] = useState(isEditMode ? null : 0);
  const toggleFocus = (index, subIndex) => {
    if (index !== focusedSlot) setFocusedSlot(index);
    if (index !== null && isSpecialShiftType && !specialShifts[index]?.timings?.[0]?.start_time) {
      setSpecialShifts((prev) => {
        prev[index].timings[0].start_time = defaultStartTime;
        prev[index].timings[0].end_time = defaultEndTime;
        return [...prev];
      });
    }
  };

  function removeSpecialShiftRequest(index) {
    if (isSpecialShiftDSTAdjusted) {
      setErrorMessage("");
      setIsSpecialShiftDSTAdjusted(false);
    }

    if (specialShifts.length <= 1) {
      setSpecialShifts(getSpecialShiftStateDefault());
    } else if (index === specialShifts.length - 1) {
      specialShifts[index] = getSpecialShiftStateEmptyObj(true);
      setSpecialShifts([...specialShifts]);
    } else {
      specialShifts.splice(index, 1);
      setSpecialShifts([...specialShifts]);
    }
    if (focusedSlot) toggleFocus(null);
  }

  function removeAdhocShiftSlot(index) {
    if (adhocShiftSlots.length <= 1) {
      setAdhocShiftSlots(getAdhocShiftStateDefault());
    } else if (index === adhocShiftSlots.length - 1) {
      adhocShiftSlots[index] = getAdhocShiftStateDefault();
      setAdhocShiftSlots([...adhocShiftSlots]);
    } else {
      adhocShiftSlots.splice(index, 1);
      setAdhocShiftSlots([...adhocShiftSlots]);
    }
  }

  function addMoreShiftClockTimings(index) {
    specialShifts[index].timings.push(
      getSpecialShiftStateEmptyObj().timings[0]
    );
    setSpecialShifts([...specialShifts]);
  }

  function removeShiftClockTimings(index, timingIndex) {
    if (specialShifts[index].timings.length > 1) {
      specialShifts[index].timings.splice(timingIndex, 1);
      setSpecialShifts([...specialShifts]);
    }
  }

  // contains date picker refs of each special shift day card => [[startRef, endRef],...]
  const datePickerRefs = useRef([]);
  function handleSpecialShiftDateChange(date, field, index) {
    if (isSpecialShiftDSTAdjusted) {
      setIsSpecialShiftDSTAdjusted(false);
      errorMessage === nextDayMsg && setErrorMessage("");
    }

    if (field === "start_date") {
      const endDate = specialShifts[index].end_date;

      if (!endDate) {
        const datepickerRef = datePickerRefs.current[index][1];
        datepickerRef?.open?.();
      } else if (isBefore(endDate, date)) {
        specialShifts[index].end_date = date;
      }
    } else if (field === "end_date") {
      const startDate = specialShifts[index].start_date;

      if (!startDate) {
        const datepickerRef = datePickerRefs.current[index][0];
        datepickerRef?.open?.();
      } else if (isBefore(date, startDate)) {
        specialShifts[index].start_date = date;
      }
    }

    specialShifts[index][field] = date;

    // Add a new special shift day card if the last special shift day card is filled
    if (
      index === specialShifts.length - 1 &&
      specialShifts[index].start_date &&
      specialShifts[index].end_date
    ) {
      setSpecialShifts([...specialShifts, getSpecialShiftStateEmptyObj(true)]);
      return;
    }

    setSpecialShifts([...specialShifts]);
  }

  function handleDateChange(date, field, index) {
    if (field === "start_date") {
      const endDate = adhocShiftSlots[index].end_date;

      if (!endDate) {
        const datepickerRef = datePickerRefs.current[index][1];
        datepickerRef?.open?.();
      } else if (isBefore(endDate, date)) {
        adhocShiftSlots[index].end_date = date;
      }
    } else if (field === "end_date") {
      const startDate = adhocShiftSlots[index].start_date;

      if (!startDate) {
        const datepickerRef = datePickerRefs.current[index][0];
        datepickerRef?.open?.();
      } else if (isBefore(date, startDate)) {
        adhocShiftSlots[index].start_date = date;
      }
    }

    adhocShiftSlots[index][field] = date;

    // Add a new slot if the last slot is filled
    if (
      index === adhocShiftSlots.length - 1 &&
      adhocShiftSlots[index].start_date &&
      adhocShiftSlots[index].end_date
    ) {
      setAdhocShiftSlots([...adhocShiftSlots, getAdhocShiftStateObj()]);
      return;
    }

    setAdhocShiftSlots([...adhocShiftSlots]);
  }

  useEffect(() => {
    if (shiftRequestFormRef.current) {
      shiftRequestFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [specialShifts.length]);

  function handleSpecialShiftClockTimingsChange(
    value,
    field,
    index,
    timingIndex
  ) {
    specialShifts[index].timings[timingIndex][field] = value;
    if (field === "end_time") {
      // if isEndTimeModified is true, 9hrs will not be added to end time while modifying start time
      specialShifts[index].timings[timingIndex].isEndTimeModified = true;
    } else {
      // if isEndTimeModified is false, add 9hrs to end time from start time value
      const isEndTimeModified =
        specialShifts[index].timings[timingIndex]?.isEndTimeModified;
      if (!isEndTimeModified) {
        specialShifts[index].timings[timingIndex].end_time =
          add9HoursToTime(value);
      }
    }
    setSpecialShifts([...specialShifts]);
  }

  // Standard Shift State and Functions
  const [standardShift, setStandardShift] = useState(() => {

    return isEditMode && data.type === REQUEST_TYPES.STANDARD ? {
      ...data.standardShift,
      created_at: format(convertTimeZone(new Date(data.created_at)), "YYYY-MM-DD"),
      // incoming data does not have isChecked property and also some day may be missing if not selected
      schedule: [1, 2, 3, 4, 5, 6, 0].reduce((acc, cur) => {
        const index = data.standardShift.schedule.findIndex(
          (s) => s.weekday === cur
        );
        if (index === -1) {
          acc.push(getDefaultStandardDataObj(cur));
        } else {
          acc.push({
            ...data.standardShift.schedule[index],
            isChecked: true,
          });
        }
        return acc;
      }, []),
      dst_schedule: [1, 2, 3, 4, 5, 6, 0].reduce((acc, cur) => {
        const index = data.standardShift?.dst_schedule?.findIndex(
          (s) => s.weekday === cur
        );
        if (index === -1) {
          acc.push(getDefaultStandardDataObj(cur));
        } else {
          acc.push({
            ...data.standardShift.dst_schedule[index],
            isChecked: true,
          });
        }
        return acc;
      }, []),
    } : getStandardShiftStateDefault();
  });

  useEffect(() => {
    if (!isEditMode && selectedShiftType?.id === REQUEST_TYPES.STANDARD) {
      let schedule = standardShift.schedule.map((sch) => {
        const curSchDay = currentSchedule.schedule.find(
          (s) => s.weekday === sch.weekday
        );

        if (curSchDay) {
          const { start_time, end_time } = curSchDay;
          return {
            ...sch,
            start_time,
            end_time,
            isChecked: true,
          };
        }

        return sch;
      });
      let dst_schedule = standardShift.dst_schedule.map((sch) => {
        const curSchDay = currentSchedule.dst_schedule.find(
          (s) => s.weekday === sch.weekday
        );

        if (curSchDay) {
          const { start_time, end_time } = curSchDay;
          return {
            ...sch,
            start_time,
            end_time,
            isChecked: true,
          };
        }

        return sch;
      });

      setStandardShift({
        start_date: "",
        schedule: structuredClone(schedule),
        dst_schedule: structuredClone(dst_schedule),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule, selectedShiftType]);

  function handleScheduleChange(isChecked, index, isDst) {
    standardShift[isDst ? 'dst_schedule' : 'schedule'][index].isChecked = isChecked;
    setStandardShift({ ...standardShift });
  }

  function handleStandardShiftClockTimingsChange(value, field, index, isDSTChange) {
    const data = structuredClone(standardShift);
    const key = isDSTChange ? 'dst_schedule' : 'schedule';
    data[key][index][field] = value;
    if (field === "end_time") {
      // if isEndTimeModified is true, 9hrs will not be added to end time while modifying start time
      data[key][index].isEndTimeModified = true;
    } else {
      // if isEndTimeModified is false, add 9hrs to end time from start time value
      const isEndTimeModified = data[key][index]?.isEndTimeModified;
      if (!isEndTimeModified) {
        data[key][index].end_time = add9HoursToTime(value);
      }
    }

    // if isDSTChange is false, then update the value for dst_schedule as well if it is not modified (maintain a variable to check if dst entry is modified)
    if (!isDSTChange) {
      const isDSTScheduleModified = data.dst_schedule[index]?.isDSTScheduleModified;
      if (!isDSTScheduleModified) {
        data.dst_schedule[index][field] = value;
        if (field === "end_time") {
          data.dst_schedule[index].isEndTimeModified = true;
        } else {
          data.dst_schedule[index].end_time = add9HoursToTime(value);
        }
      }
    } else {
      data[key][index].isDSTScheduleModified = true;
    }

    setStandardShift(data);
  }

  // Submit Functions
  const [dataSubmitInProcess, setDataSubmitInProcess] = useState(false);

  const [errorMessage, setErrorMessage] = useState(isAdhocShiftType ? adhocShiftMessage : "");

  function submitRequest() {
    const shiftTypeMap = {
      [REQUEST_TYPES.STANDARD]: submitStandardShiftRequest,
      [REQUEST_TYPES.SPECIAL]: submitSpecialShiftRequest,
      [REQUEST_TYPES.ADHOC]: submitAdhocShiftRequest,
    };

    shiftTypeMap[selectedShiftType?.id]?.();
  }


  const scheduleDifference = (schedule, curSchedule) => {
    return schedule.some((sch) => {
      const curSch = curSchedule.find((s) => s.weekday === sch.weekday);
      if (!curSch && sch.isChecked) return true;
      if (curSch && !sch.isChecked) return true;
      if (curSch && sch.isChecked) {
        return (
          sch.start_time !== curSch.start_time ||
          sch.end_time !== curSch.end_time
        );
      }
      return false;
    });
  }

  function isScheduleHasChanges(currentSchedule) {
    const isScheduleModified = scheduleDifference(standardShift.schedule, currentSchedule.schedule);
    const isDstScheduleModified = scheduleDifference(standardShift.dst_schedule, currentSchedule.dst_schedule);
    const changedActiveTrackingStatus = isEditMode
      ? isActivityTracked !== data?.is_activity_tracked
      : isActivityTracked !== currentSchedule.schedule[0]?.is_activity_tracked;
    const changedWFHStatus = isEditMode
      ? isWFH !== data?.is_wfh
      : isWFH !== currentSchedule.schedule[0]?.is_wfh;

    return isScheduleModified || isDstScheduleModified || changedWFHStatus || changedActiveTrackingStatus;
  }

  async function submitStandardShiftRequest() {
    if (!standardShift.start_date) {
      setErrorMessage("Please choose a start date");
      return;
    }

    const createdDate = isEditMode
      ? standardShift.created_at
      : format(convertTimeZone(), "YYYY-MM-DD");
    const effectiveDate = standardShift.start_date;
    if (effectiveDate <= createdDate) {
      setErrorMessage(
        isEditMode
          ? "Start date cannot fall on the day of creation or prior."
          : "Schedule the start date for after today"
      );
      return;
    }

    const switchedOnCount = standardShift.schedule.reduce((acc, cur) => {
      if (!cur.isChecked) return acc;
      acc += 1;
      return acc;
    }, 0);

    if (switchedOnCount < 5) {
      setErrorMessage("A minimum of five working days should be switched on");
      return;
    }

    if (!isScheduleHasChanges(currentSchedule)) {
      setErrorMessage(
        "Cannot submit without making any changes to the current schedule"
      );
      return;
    }

    if (isBlankHTML(comment)) {
      setErrorMessage("Give a reason, please");
      return;
    }

    if (errorMessage !== nextDayMsg && standardShift.schedule.some(sch => sch.isChecked && sch.start_time > sch.end_time)) {
      setErrorMessage(nextDayMsg);
      return;
    }

    const formatSchedule = (data, offset, isDST = false) => {
      return data.reduce((acc, sch) => {
        const isChecked = isDST ? standardShift.schedule.find(s => s.weekday === sch.weekday).isChecked : sch.isChecked;
        if (isChecked) {
          const startTime = sch.start_time.replace(":", "");
          const endTime = sch.end_time.replace(":", "");
          const [start_time] = convertHhmmToUtc(startTime, offset);
          const [end_time, end_time_offset] = convertHhmmToUtc(endTime, offset, startTime > endTime ? 1 : 0);

          acc.push({
            weekday: sch.weekday,
            start_time,
            start_time_offset: 0,
            end_time,
            end_time_offset,
            is_activity_tracked: isActivityTracked,
            is_wfh: isWFH,
          });
        }

        return acc;
      }, [])
    }
    let apiPayload = {
      start_date: standardShift.start_date,
      comment,
      schedule: formatSchedule(standardShift.schedule, tzOffset),
    };

    if (observesDST) {
      apiPayload.dst_schedule = formatSchedule(standardShift.dst_schedule, dstTZOffset, true);
    }

    try {
      setDataSubmitInProcess(true);
      let url = AL_BASE_URL + `/request/schedule`;
      if (isEditMode) url += `/${data.id}`;
      const response = await APIService.apiRequest(
        url,
        apiPayload,
        false,
        isEditMode ? "PUT" : "POST"
      );
      setDataSubmitInProcess(false);
      if (response.status === 1) {
        alertService.success(response.msg);
        const date = convertTimeZone();

        let standardShiftRequest = {
          approval_timestamp: null,
          data: apiPayload.schedule.map(event => {
            const { weekday, start_time, start_time_offset, end_time, end_time_offset } = event;
            const [startTime, startTimeOffset] = convertHhmmToUserTimezone(start_time, tzOffset, start_time_offset);
            const [endTime, endTimeOffset] = convertHhmmToUserTimezone(end_time, tzOffset, end_time_offset);

            return {
              effective_start_date: apiPayload.start_date,
              effective_end_date: null,
              start_time: {
                time: startTime,
                time_offset: startTimeOffset
              },
              end_time: {
                time: endTime,
                time_offset: endTimeOffset
              },
              weekday,
              is_activity_tracked: isActivityTracked,
              is_wfh: isWFH,
            };
          }),
          decline_timestamp: null,
          is_approved: 0,
          is_rejected: 0,
          manager_first_name: null,
          manager_img_url: null,
          manager_last_name: null,
          manager_middle_name: null,
          notes_count: 1,
          pending_timestamp: null,
          reason: isEditMode ? data.comment : comment,
          request_type: REQUEST_TYPES.STANDARD,
          resource_request_id: isEditMode ? data.id : response.request_id,
          reason_comment_id: isEditMode ? data.reason_comment_id : response.reason_comment_id,
          timezone_id: myTimeZone.id,
          unread_timestamp: isEditMode ? data.unread_timestamp : "0",
          updated_at: date,
          // utc_offset: myTimeZone.utc_offset,
          ...loggedInUser,
        };

        let dst_data = null;
        if (observesDST) {
          dst_data = apiPayload.dst_schedule.map(event => {
            const { weekday, start_time, start_time_offset, end_time, end_time_offset } = event;
            const [startTime, startTimeOffset] = convertHhmmToUserTimezone(start_time, dstTZOffset, start_time_offset);
            const [endTime, endTimeOffset] = convertHhmmToUserTimezone(end_time, dstTZOffset, end_time_offset);

            return {
              effective_start_date: apiPayload.start_date,
              effective_end_date: null,
              start_time: {
                time: startTime,
                time_offset: startTimeOffset
              },
              end_time: {
                time: endTime,
                time_offset: endTimeOffset
              },
              weekday,
              is_activity_tracked: isActivityTracked,
            };
          })
        } else {
          dst_data = standardShiftRequest.data;
        }
        standardShiftRequest.dst_data = structuredClone(dst_data);

        if (!isEditMode) {
          standardShiftRequest.created_at = date;
          onSubmit(standardShiftRequest);
        } else {
          onResubmit(standardShiftRequest);
          // Edit comment in case it modified
          if (data.comment !== comment) {
            handleCommentChange(standardShiftRequest);
          }
        }

        onClose();
      } else {
        setErrorMessage(response.msg);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setDataSubmitInProcess(false);
    }
  }

  function getDateFromTimeStr(timeStr) {
    const [hours, minutes] = timeStr.split(":");
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
    return date;
  }

  function isSpecialShiftDatesIsOverlapping(data) {
    const isDatesOverlapping = data.some((specialShift, index) => {
      const startDate = new Date(specialShift.start_date);
      const endDate = new Date(specialShift.end_date);

      return data.some((sShift, i) => {
        if (i === index) {
          return false;
        }

        const sDate = new Date(sShift.start_date);
        const eDate = new Date(sShift.end_date);

        return areIntervalsOverlapping(
          { start: startDate, end: endDate },
          { start: sDate, end: eDate },
          false
        );
      });
    });

    return isDatesOverlapping;
  }

  function isSpecialShiftTimingsOverlapping(data) {
    const isTimingsOverlapping = data.some((specialShift) => {
      const timings = specialShift.timings;

      return timings.some((timing, index) => {
        const startDate = getDateFromTimeStr(timing.start_time);
        const endDate = getDateFromTimeStr(timing.end_time);

        return timings.some((timing, i) => {
          if (i === index) {
            return false;
          }

          const sDate = getDateFromTimeStr(timing.start_time);
          const eDate = getDateFromTimeStr(timing.end_time);

          return areIntervalsOverlapping(
            { start: startDate, end: endDate },
            { start: sDate, end: eDate },
            false
          );
        });
      });
    });

    return isTimingsOverlapping;
  }

  // function isValidSpecialShift(specialShifts) { // special shift should not exceed 24 hours
  //   return specialShifts.findIndex(ss => {
  //     const firstSlotStartTime = ss.timings[0].start_time.replace(":", "");
  //     const lastSlotStartTime = ss.timings[ss.timings.length - 1].start_time.replace(":", "");
  //     const lastSlotEndTime = ss.timings[ss.timings.length - 1].end_time.replace(":", "");
  //     return lastSlotStartTime < firstSlotStartTime || lastSlotEndTime < firstSlotStartTime
  //   })
  // }

  const isSpecialShiftNeedDstAdjust = (dstStartDate, dstEndDate) => {
    const formatDate = (date) => format(date, "YYYY-MM-DD");
    const dst_start_date = new Date(dstStartDate).setHours(0, 0, 0, 0);
    const dst_end_date = new Date(dstEndDate).setHours(0, 0, 0, 0);
    let needDstAdjust = false;
    let result = [];

    for (const cur of specialShifts) {
      if (!cur.start_date) continue;
      const start_date = new Date(getUTCTime(cur.start_date)).setHours(0, 0, 0, 0);
      const end_date = new Date(getUTCTime(cur.end_date)).setHours(0, 0, 0, 0);
      const timings = cur.timings;

      const isStartDateWithinInterval = isWithinInterval(start_date, { start: dst_start_date, end: dst_end_date });
      const isEndDateWithinInterval = isWithinInterval(end_date, { start: dst_start_date, end: dst_end_date });
      const isWrappingDstDates = isBefore(start_date, dst_start_date) && isAfter(end_date, dst_end_date);

      if (isStartDateWithinInterval || isEndDateWithinInterval || isWrappingDstDates) {
        if (isEqual(start_date, dst_start_date) && isEqual(end_date, dst_start_date)) {
          result.push(cur)
        } else if (isEqual(start_date, dst_end_date) && isEqual(end_date, dst_end_date)) {
          result.push(cur)
        } else if (isWrappingDstDates) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(subDays(dst_start_date, 1)),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(dst_start_date),
              end_date: formatDate(dst_start_date),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(addDays(dst_start_date, 1)),
              end_date: formatDate(subDays(dst_end_date, 1)),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(dst_end_date),
              end_date: formatDate(dst_end_date),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(addDays(dst_end_date, 1)),
              end_date: formatDate(end_date),
              timings: structuredClone(timings),
            }
          );
        } else if (isAfter(start_date, dst_start_date) && isEqual(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(subDays(dst_end_date, 1)),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(end_date),
              end_date: formatDate(end_date),
              timings: structuredClone(timings),
            }
          );
        } else if (isBefore(start_date, dst_start_date) && isEqual(end_date, dst_start_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(subDays(dst_start_date, 1)),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(dst_start_date),
              end_date: formatDate(dst_start_date),
              timings: structuredClone(timings),
              is_dst: true
            }
          );
        } else if (isEqual(start_date, dst_start_date) && isBefore(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(dst_start_date),
              end_date: formatDate(dst_start_date),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(addDays(dst_start_date, 1)),
              end_date: formatDate(end_date),
              timings,
              is_dst: true
            }
          );
        } else if (isBefore(start_date, dst_start_date) && isBefore(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(subDays(dst_start_date, 1)),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(dst_start_date),
              end_date: formatDate(dst_start_date),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(addDays(dst_start_date, 1)),
              end_date: formatDate(end_date),
              timings: structuredClone(timings),
              is_dst: true
            }
          );
        } else if (isEqual(start_date, dst_end_date) && isAfter(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(start_date),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(addDays(start_date, 1)),
              end_date: formatDate(end_date),
              timings: structuredClone(timings),
            }
          );
        } else if (isAfter(start_date, dst_start_date) && isAfter(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(subDays(dst_end_date, 1)),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(dst_end_date),
              end_date: formatDate(dst_end_date),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(addDays(dst_end_date, 1)),
              end_date: formatDate(end_date),
              timings: structuredClone(timings),
            }
          );
        } else if (isBefore(start_date, dst_start_date) && isEqual(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(start_date),
              end_date: formatDate(subDays(dst_start_date, 1)),
              timings: structuredClone(timings),
            },
            {
              start_date: formatDate(dst_start_date),
              end_date: formatDate(dst_start_date),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(addDays(dst_start_date, 1)),
              end_date: formatDate(subDays(dst_end_date, 1)),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(dst_end_date),
              end_date: formatDate(dst_end_date),
              timings: structuredClone(timings),
            }
          );
        } else if (isEqual(start_date, dst_start_date) && isEqual(end_date, dst_end_date)) {
          needDstAdjust = true;
          result.push(
            {
              start_date: formatDate(dst_start_date),
              end_date: formatDate(dst_start_date),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(addDays(dst_start_date, 1)),
              end_date: formatDate(subDays(dst_end_date, 1)),
              timings: structuredClone(timings),
              is_dst: true
            },
            {
              start_date: formatDate(dst_end_date),
              end_date: formatDate(dst_end_date),
              timings: structuredClone(timings),
            }
          );
        } else {
          result.push(cur);
        }
      } else {
        result.push(cur);
      }
    }

    return [needDstAdjust, result]
  };

  const [isSpecialShiftDSTAdjusted, setIsSpecialShiftDSTAdjusted] = useState(false); // flag to check if the special shift timings are adjusted
  async function submitSpecialShiftRequest() {
    // Check if start date or end date is not blank
    const specialShifts = getSpecialShiftData();
    if (
      specialShifts.some(
        (specialShift) => !specialShift.start_date || !specialShift.end_date
      )
    ) {
      setErrorMessage("Please choose a start date and end date for each shift");
      return;
    }

    // const createdDate = format(isEditMode ? data.created_at  : convertTimeZone(), "YYYY-MM-DD");
    // const isAnyPastShifts = specialShifts.some(
    //   (specialShift) => specialShift.start_date < createdDate
    // );

    // if (isAnyPastShifts) {
    //   setErrorMessage("The start date cannot be a day in the past");
    //   return;
    // }

    // Check if the date is not overlapping
    if (isSpecialShiftDatesIsOverlapping(specialShifts)) {
      setErrorMessage("Date overlap found");
      return;
    }

    // Check if timings on each date are not overlapping
    if (isSpecialShiftTimingsOverlapping(specialShifts)) {
      setErrorMessage("Timings overlap found");
      return;
    }

    // TODO: Check if the shift duration is not more than 24 hours
    // const invalidSpecialShiftIndex = isValidSpecialShift(specialShifts);
    // if (invalidSpecialShiftIndex !== -1) {
    //   const specialShift = specialShifts[invalidSpecialShiftIndex];
    //   const startDate = format(new Date(specialShift.start_date), "ddd, MMM DD, YYYY");
    //   const endDate = format(new Date(specialShift.end_date), "ddd, MMM DD, YYYY");
    //   const range = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    //   setErrorMessage(`Shift duration exceeds 24 hours (${range})`);
    //   return;
    // }

    // Check if comment is blank
    if (isBlankHTML(comment)) {
      setErrorMessage("Give a reason, please");
      return;
    }

    const tz = getMyTimeZoneFromLocal();
    if (tz.dst_start_date && tz.dst_end_date && !isSpecialShiftDSTAdjusted) {
      const dstStartDate = new Date(tz.dst_start_date); // both start & end date is in UTC
      const dstEndDate = new Date(tz.dst_end_date);
      const [needDstAdjust, adjustedSpecialShifts] = isSpecialShiftNeedDstAdjust(dstStartDate, dstEndDate);
      if (needDstAdjust) {
        setErrorMessage(dstAdjustMsg)
        setSpecialShifts(adjustedSpecialShifts)
        setIsSpecialShiftDSTAdjusted(true);
        return;
      }
    }

    if (errorMessage !== nextDayMsg && specialShifts.some(ss => ss.timings.some(t => t.start_time > t.end_time))) {
      setErrorMessage(nextDayMsg);
      return;
    }

    const apiPayload = {
      comment,
      data: specialShifts
        .reduce((acc, specialShift) => {
          let isExtendedToNextDay = false;
          specialShift.timings.forEach((timing, index) => {
            const startTime = timing.start_time.replace(":", "");
            const endTime = timing.end_time.replace(":", "");
            const isStartTimeGreaterThanEndTime = startTime > endTime;
            let startTzOffset = getMyTimezoneOffset2(getDateFromHhmm(startTime, specialShift.start_date));
            // let endTzOffset = getMyTimezoneOffset2(getDateFromHhmm(endTime, specialShift.end_date));
            const [start_time, start_time_offset] = convertHhmmToUtc(startTime, startTzOffset, isExtendedToNextDay ? 1 : 0);
            const [end_time, end_time_offset] = convertHhmmToUtc(
              endTime,
              startTzOffset,
              isExtendedToNextDay || isStartTimeGreaterThanEndTime ? 1 : 0
            );

            if (index > 0 && specialShift.timings.length > 0 && !isExtendedToNextDay) {
              const prevSpecialShift = specialShift.timings[index - 1];
              const prevSlotTimingEndTime = prevSpecialShift.end_time.replace(":", "");
              if (prevSlotTimingEndTime > startTime) {
                isExtendedToNextDay = true;
              }
            }

            if (!isExtendedToNextDay && isStartTimeGreaterThanEndTime) {
              isExtendedToNextDay = true;
            }

            acc.push({
              start_date: specialShift.start_date,
              end_date: specialShift.end_date,
              is_full_day: 0,
              start_time,
              start_time_offset,
              end_time,
              end_time_offset,
              is_activity_tracked: isActivityTracked,
              is_wfh: isWFH,
            });
          });

          return acc;
        }, [])
        .sort((a, b) => new Date(a.end_date) - new Date(b.end_date)), // Sort the special shift days by end date in ascending order
    };

    try {
      setDataSubmitInProcess(true);
      let url = AL_BASE_URL + `/request/special_shift`;
      if (isEditMode) url += `/${data.id}`;
      const response = await APIService.apiRequest(
        url,
        apiPayload,
        false,
        isEditMode ? "PUT" : "POST"
      );
      setDataSubmitInProcess(false);
      if (response.status === 1) {
        alertService.success(response.msg);
        const date = convertTimeZone();

        const specialShiftRequest = {
          data: apiPayload.data.map(event => {
            const { start_date, end_date, is_full_day, start_time, start_time_offset, end_time, end_time_offset } = event;
            const startTzOffset = getMyTimezoneOffset2(getDateFromHhmm(start_time, start_date));
            // const endTzOffset = getMyTimezoneOffset2(getDateFromHhmm(end_time, end_date));
            const [startTime, startTimeOffset] = convertHhmmToUserTimezone(start_time, startTzOffset, start_time_offset);
            const [endTime, endTimeOffset] = convertHhmmToUserTimezone(end_time, startTzOffset, end_time_offset);
            return {
              start_date,
              end_date,
              is_full_day,
              start_time: {
                time: startTime,
                time_offset: startTimeOffset
              },
              end_time: {
                time: endTime,
                time_offset: endTimeOffset
              },
              is_activity_tracked: isActivityTracked,
              is_wfh: isWFH,
            };
          }),
          decline_timestamp: null,
          is_approved: 0,
          is_rejected: 0,
          manager_first_name: null,
          manager_img_url: null,
          manager_last_name: null,
          manager_middle_name: null,
          notes_count: 1,
          pending_timestamp: null,
          request_type: REQUEST_TYPES.SPECIAL,
          resource_request_id: isEditMode ? data.id : response.request_id,
          reason: isEditMode ? data.comment : comment,
          reason_comment_id: isEditMode
            ? data.reason_comment_id
            : response.reason_comment_id,
          // utc_offset: myTimeZone.utc_offset,
          unread_timestamp: isEditMode ? data.unread_timestamp : "0",
          updated_at: date,
          ...loggedInUser,
        };

        if (!isEditMode) {
          specialShiftRequest.created_at = `${format(getUTCTime(), 'ddd, DD MMM YYYY HH:mm:ss')} GMT`;

          onSubmit(specialShiftRequest);
        } else {
          onResubmit(specialShiftRequest);
          // Edit comment in case it modified
          if (data.comment !== comment) {
            handleCommentChange(specialShiftRequest);
          }
        }

        onClose();
      } else {
        setErrorMessage(response.msg);
      }
    } catch (error) {
      setDataSubmitInProcess(false);
      setErrorMessage(error.message);
    }
  }

  async function submitAdhocShiftRequest() {
    setErrorMessage("");

    const adhocShiftSlots = getAdhocShiftsData();
    const areAllSlotsFilled = adhocShiftSlots.every((day) => {
      return day.start_date !== "" && day.end_date !== "";
    });

    if (!areAllSlotsFilled) {
      setErrorMessage("Please fill all empty days input");
      return;
    }

    if (!comment.trim()) {
      setErrorMessage("Give a reason, please");
      return;
    }

    const areSlotsOverlapping = adhocShiftSlots.some((day, index) => {
      if (!day.start_date || !day.end_date) return false;
      const startDate = new Date(day.start_date);
      const endDate = new Date(day.end_date);

      return adhocShiftSlots.some((day, i) => {
        if (i === index || !day.start_date || !day.end_date) {
          return false;
        }

        const sDate = new Date(day.start_date);
        const eDate = new Date(day.end_date);

        return areIntervalsOverlapping(
          { start: startDate, end: endDate },
          { start: sDate, end: eDate }
        );
      });
    });

    if (areSlotsOverlapping) {
      setErrorMessage("Days are overlapping");
      return;
    }

    setDataSubmitInProcess(true);

    try {
      const apiPayload = {
        comment,
        data: adhocShiftSlots
          .filter(d => d.start_date && d.end_date).map((day) => {
            return {
              start_date: day.start_date,
              end_date: day.end_date,
              is_activity_tracked: isActivityTracked,
              is_wfh: isWFH,
            };
          })
          .sort((a, b) => new Date(a.end_date) - new Date(b.end_date)), // Sort the days by end date in ascending order
      };

      let url = AL_BASE_URL + "/request/adhoc_shift";
      if (isEditMode) url += `/${data.id}`;
      const response = await APIService.apiRequest(
        url,
        apiPayload,
        false,
        isEditMode ? "PUT" : "POST"
      );

      setDataSubmitInProcess(false);
      if (response.status === 1) {
        alertService.success(response.msg);
        const date = convertTimeZone();

        const request = {
          approval_timestamp: null,
          data: [...apiPayload.data],
          decline_timestamp: null,
          is_approved: 0,
          is_rejected: 0,
          manager_first_name: null,
          manager_img_url: null,
          manager_last_name: null,
          manager_middle_name: null,
          notes_count: 1,
          pending_timestamp: null,
          request_type: REQUEST_TYPES.ADHOC,
          resource_request_id: isEditMode ? data.id : response.request_id,
          reason: isEditMode ? data.comment : comment,
          reason_comment_id: isEditMode
            ? data.reason_comment_id
            : response.reason_comment_id,
          unread_timestamp: isEditMode ? data.unread_timestamp : "0",
          updated_at: date,
          // utc_offset: myTimeZone.utc_offset,
          ...loggedInUser,
        };

        if (!isEditMode) {
          request.created_at = date;
          onSubmit(request);
        } else {
          onResubmit(request);
          // Edit comment in case it modified
          if (data.comment !== comment) {
            let editCommentPayload = {
              note: comment,
            };

            const editCommentResponse = await APIService.apiRequest(
              AL_BASE_URL + `/notes/${data.reason_comment_id}`,
              editCommentPayload,
              false,
              "PUT"
            );

            if (editCommentResponse.status === 1) {
              const modifiedLeaveRequest = {
                ...request,
                reason: editCommentPayload.note,
              };
              onResubmit(modifiedLeaveRequest);
              // alertService.success(editCommentResponse.msg);
            } else {
              alertService.error(
                editCommentResponse.msg || editCommentResponse.message
              );
            }
          }
        }

        onClose();
      } else {
        setErrorMessage(response.msg);
      }
    } catch (error) {
      console.log(error);
      setDataSubmitInProcess(false);
    }
  }

  async function handleCommentChange(shiftRequestDetails) {
    let editCommentPayload = {
      note: comment,
    };

    const editCommentResponse = await APIService.apiRequest(
      AL_BASE_URL + `/notes/${data.reason_comment_id}`,
      editCommentPayload,
      false,
      "PUT"
    );

    if (editCommentResponse.status === 1) {
      const modifiedShiftRequestDetails = {
        ...shiftRequestDetails,
        reason: editCommentPayload.note,
      };
      onResubmit(modifiedShiftRequestDetails);
      // alertService.success(editCommentResponse.msg);
    } else {
      alertService.error(
        editCommentResponse.msg || editCommentResponse.message
      );
    }
  }

  function isFormChangesSaved() {
    // Comment is common for both standard and special shift
    if (isEditMode) {
      if (comment !== data.comment) {
        return false;
      }
    } else {
      if (comment !== "" || !isBlankHTML(comment)) {
        return false;
      }
    }

    if (isStandardShiftType) {
      return isStandardScheduleChangesSaved();
    } else if (isSpecialShiftType) {
      return isSpecialShiftChangesSaved();
    } else if (isAdhocShiftType) {
      return isAdhocShiftChangesSaved();
    }
  }

  function isSpecialShiftChangesSaved() {
    const specialShifts = getSpecialShiftData();
    if (isEditMode) {
      if (
        JSON.stringify(specialShifts) !== JSON.stringify(data.specialShifts) ||
        isWFH !== data.specialShifts?.[0]?.is_wfh ||
        isActivityTracked !== data?.is_activity_tracked
      ) {
        return false;
      }

      return true;
    } else {
      if (
        specialShifts.length > 1 ||
        specialShifts[0].start_date !== "" ||
        specialShifts[0].end_date !== "" ||
        specialShifts[0].timings.length > 1 ||
        specialShifts[0].timings[0].start_time !== defaultStartTime ||
        specialShifts[0].timings[0].end_time !== defaultEndTime ||
        isWFH !== defaultisWFH
      ) {
        return false;
      }

      return true;
    }
  }

  function isStandardScheduleChangesSaved() {
    if (isEditMode) {
      if (
        standardShift.start_date !== data.standardShift.start_date ||
        isWFH !== data.standardShift.schedule?.[0]?.is_wfh ||
        isActivityTracked !== data?.is_activity_tracked
      ) {
        return false;
      }

      if (data?.standardShift?.schedule?.length > 0) {
        return !isScheduleHasChanges(data.standardShift);
      }

      return true;
    } else {
      if (isScheduleHasChanges(currentSchedule)) {
        return false;
      }

      if (isWFH !== defaultisWFH) {
        return false;
      }

      return true;
    }
  }

  function isAdhocShiftChangesSaved() {
    const adhocShiftDays = getAdhocShiftsData();
    if (!isEditMode) {
      if (
        // selectedLeaveType.id !== selectedLeaveType.id ||
        comment !== "" ||
        !isBlankHTML(comment) ||
        adhocShiftDays.length > 1 ||
        adhocShiftDays[0].start_date !== "" ||
        adhocShiftDays[0].end_date !== ""
      ) {
        return false;
      }

      return true;
    } else {
      if (adhocShiftDays.length !== data.leave_days.length) {
        return false;
      }

      const areAdhocShiftDaysSame = adhocShiftDays.every((day, index) => {
        return (
          day.start_date === data.leave_days[index].start_date &&
          day.end_date === data.leave_days[index].end_date
        );
      });

      if (!areAdhocShiftDaysSame) {
        return false;
      }

      return true;
    }
  }

  useEffect(() => {
    isFormChangesSavedFnRef.current = isFormChangesSaved;
    return () => {
      isFormChangesSavedFnRef.current = null;
    }
  }, [isFormChangesSaved]);

  const isDesktopScreen = useWindowDimensions().width > 640;

  return (
    <div className="shift-request-form" ref={shiftRequestFormRef}>
      <div className="form-header">
        <div className="form-options">
          <RequestType
            selectedLeaveType={selectedLeaveType}
            selectedShiftType={selectedShiftType}
            selectedFormType={selectedFormType}
            options={shiftOptions}
            onFormChange={(formType) => {
              if (typeof isFormChangesSavedFnRef.current === 'function') {
                if (isFormChangesSavedFnRef.current() === false) {
                  setDiscardChangesAlert({
                    show: false,
                    yesHandler: () => {
                      setDiscardChangesAlert(null);
                      setSelectedFormType(formType);
                    },
                  });
                  return;
                }
              }
              setSelectedFormType(formType);
            }}
            onRequestTypeChange={(type) => {
              if (typeof isFormChangesSavedFnRef.current === 'function') {
                if (isFormChangesSavedFnRef.current() === false) {
                  setDiscardChangesAlert({
                    show: false,
                    yesHandler: () => {
                      if (selectedShiftType.id === REQUEST_TYPES.ADHOC) {
                        setAdhocShiftSlots(getAdhocShiftStateDefault());
                      }

                      if (selectedShiftType.id === REQUEST_TYPES.SPECIAL) {
                        setSpecialShifts(getSpecialShiftStateDefault());
                      }

                      if (type.id === REQUEST_TYPES.ADHOC) {
                        setErrorMessage(adhocShiftMessage);
                      } else if (errorMessage === adhocShiftMessage) {
                        setErrorMessage("");
                      }

                      setFocusedSlot(0);
                      setIsActivityTracked(() => currentSchedule.schedule[0]?.is_activity_tracked ?? 1);
                      setIsWFH(() => currentSchedule.schedule[0]?.is_wfh ?? 1);
                      setComment("");
                      setDiscardChangesAlert(null);
                      setSelectedShiftType(type);
                    },
                  });
                  return;
                }
              }

              if (type.id === REQUEST_TYPES.ADHOC) {
                setErrorMessage(adhocShiftMessage);
              } else if (errorMessage === adhocShiftMessage) {
                setErrorMessage("");
              }

              setFocusedSlot(0);
              setSelectedShiftType(type);
            }}
            disabled={dataSubmitInProcess || isEditMode}
          />
        </div>
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="form-extra-options">
        <div className="location-wrapper">
          <span className="label">Location</span>
          <div className="toggle-wrapper">
            <button className={`toggle-option ${isWFH ? 'active' : ''}`} onClick={() => setIsWFH(1)}>
              <span className="location-type remote"></span>
              <span>Remote</span>
            </button>
            <button className={`toggle-option ${!isWFH ? 'active' : ''}`} onClick={() => setIsWFH(0)}>
              <span className="location-type office"></span>
              <span>Office</span>
            </button>
          </div>
        </div>

        <div className="location-wrapper">
          <span className="label">Work</span>
          <div className="toggle-wrapper">
            <button className={`toggle-option ${isActivityTracked ? 'active' : ''}`} onClick={() => setIsActivityTracked(1)}>
              <span className="work-type online"></span>
              <span>Online work</span>
            </button>
            <button className={`toggle-option ${!isActivityTracked ? 'active' : ''}`} onClick={() => setIsActivityTracked(0)}>
              <span className="work-type offline"></span>
              <span>Offline work</span>
            </button>
          </div>
        </div>
      </div>

      <div className="form-body">
        {isStandardShiftType && (
          <div className="standard-shift-request-form">
            <div className={`standard-shift-request-details ${observesDST ? 'column' : ''} ${standardShift.start_date ? 'has-date' : ''}`}>
              <div className="standard-shift-start">
                <div className="standard-shift-start-date-picker">
                  <RequestDatePicker
                    placeholder="Effective Date"
                    date={standardShift.start_date}
                    onChange={(date) => {
                      setStandardShift({ ...standardShift, start_date: date })
                    }}
                    disabled={dataSubmitInProcess}
                  />
                </div>
                {observesDST && !isDesktopScreen && <button
                  className="dst_toggle_button"
                  onClick={() => setShowDSTForm(prev => !prev)}
                >
                  {showDSTForm ? 'Daylight Saving' : 'Standard'}
                </button>}
              </div>
              <div
                style={{
                  height: `${!standardShift.start_date ? 0 : 300 + (observesDST ? 23 : 0)}px`,
                  overflow: `${!standardShift.start_date ? 'hidden' : 'visible'}`,
                  transition: 'height 300ms',
                }}
              >
                {observesDST && isDesktopScreen && <div className="standard-shift-schedule standard-shift-schedule-header">
                  <div className="checkbox-wrapper"><div className="option checkbox"></div></div>
                  <div className="label">Standard Time</div>
                  <div className="label">Daylight Saving Time</div>
                </div>}
                {standardShift.schedule.map((sch, index) => {
                  const dstSch = standardShift.dst_schedule[index];
                  return (
                    <div className="standard-shift-schedule" key={index}>
                      <div className="checkbox-wrapper">
                        <div className="option checkbox">
                          <input
                            type="checkbox"
                            id={`${weekdays[sch.weekday]}`}
                            onChange={(e) =>
                              handleScheduleChange(e.target.checked, index)
                            }
                            checked={sch.isChecked}
                            disabled={dataSubmitInProcess}
                          />
                          <label htmlFor={`${weekdays[sch.weekday]}`}>
                            {weekdays[sch.weekday]}
                          </label>
                        </div>
                      </div>
                      {(isDesktopScreen ? true : !showDSTForm) && <ScheduleItem
                        sch={sch}
                        index={index}
                        weekdays={weekdays}
                        handleScheduleChange={handleScheduleChange}
                        handleStandardShiftClockTimingsChange={handleStandardShiftClockTimingsChange}
                        dataSubmitInProcess={dataSubmitInProcess}
                        isChecked={sch.isChecked}
                      />}
                      {(observesDST && (isDesktopScreen ? true : showDSTForm)) && <ScheduleItem
                        sch={dstSch}
                        index={index}
                        weekdays={weekdays}
                        handleScheduleChange={handleScheduleChange}
                        handleStandardShiftClockTimingsChange={handleStandardShiftClockTimingsChange}
                        dataSubmitInProcess={dataSubmitInProcess}
                        isChecked={sch.isChecked}
                        isDst={true}
                      />}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {isSpecialShiftType && (
          <>
            <div className="special-shift-request-cards">
              {specialShifts.map((day, index, arr) => {
                // let dayCount =
                //   day.start_date && day.end_date
                //     ? differenceInDays(day.end_date, day.start_date) + 1
                //     : 0;

                const isLast = index === arr.length - 1;

                const isFocused = focusedSlot === index;
                const isDateFilled = day.start_date && day.end_date;
                const isTimingsFilled = day.timings.some(timing => timing.start_time || timing.end_time);
                const showDeleteBtn = isFocused || isDateFilled || isTimingsFilled;

                return (
                  <div className="special-shift-form-card" key={index}>
                    {isSpecialShiftDSTAdjusted && !day.addMore && (
                      <span className="title">
                        {day.is_dst ? 'Daylight Saving Time' : 'Standard Time'}
                      </span>
                    )}
                    <div className={`request-card ${isLast ? "add-card" : ""}`}>
                      {showDeleteBtn && (
                        <button
                          className="request-card-remove-btn"
                          onClick={() => removeSpecialShiftRequest(index)}
                          disabled={dataSubmitInProcess}
                        >
                          <span className="icon icon-minus"></span>
                        </button>
                      )}

                      {/* Start and End inputs */}
                      <ClickOutsideListner onOutsideClick={(e) => {
                        const classNames = [
                          'request-date-picker-btn focused placeholder',
                          'request-date-picker-btn focused',
                          'hrs-input focused',
                          'mins-input focused',
                          'am-pm-input focused',
                          'new-timepicker-wrapper',
                          // 'list-option ',
                          // 'list-option active',
                          // 'number-list-wrapper',
                          // 'number-list-container',
                          // 'time-block',
                          // 'header',
                        ]
                        const parentOffsetClassName = ["number-list-wrapper", "time-block"]
                        if (!classNames.includes(e.target.className) && !parentOffsetClassName.includes(e.target.offsetParent?.className)) {
                          toggleFocus(null);
                        }
                      }}>
                        <div className={`special-shift-details-wrapper ${isFocused ? ' focused' : ''}`} onClick={() => toggleFocus(index)}>
                          {/* <div className="special-shift-request-form-header">
                          <span>Start</span>
                          <span>End</span>
                        </div> */}
                          <div className="special-shift-date-wrapper">
                            <div className={`special-shift-request-form-range  ${isFocused ? ' focused' : ''}`}>
                              <div className="start hyphen-in-after">
                                <RequestDatePicker
                                  ref={(el) => {
                                    if (!datePickerRefs.current[index]) {
                                      datePickerRefs.current[index] = [el];
                                    } else {
                                      datePickerRefs.current[index][0] = el;
                                    }
                                  }}
                                  date={day.start_date}
                                  placeholder="Start Date"
                                  onChange={(date) =>
                                    handleSpecialShiftDateChange(
                                      date,
                                      "start_date",
                                      index
                                    )
                                  }
                                  isFocused={isFocused}
                                  disabled={dataSubmitInProcess}
                                />
                              </div>
                              {/* {!isFocused && <div className="hyphen"></div>} */}
                              <div className="end">
                                <RequestDatePicker
                                  ref={(el) =>
                                    (datePickerRefs.current[index][1] = el)
                                  }
                                  date={day.end_date}
                                  placeholder="End Date"
                                  onChange={(date) =>
                                    handleSpecialShiftDateChange(
                                      date,
                                      "end_date",
                                      index
                                    )
                                  }
                                  isFocused={isFocused}
                                  disabled={dataSubmitInProcess}
                                />
                              </div>
                            </div>
                            {/* <span className="range-count">
                            {dayCount > 0
                              ? isDesktopScreen
                                ? `${dayCount} ${dayCount > 1 ? "days" : "day"}`
                                : `${dayCount}D`
                              : ""}
                          </span> */}
                          </div>
                          {/* Clock-in and Clock-out inputs */}
                          <div>
                            {/* <div className="special-shift-request-clock-header">
                          <span>Clock-In</span>
                          <span>Clock-Out</span>
                        </div> */}
                            {day.timings.map((timing, timingIndex) => {
                              return (
                                <div
                                  key={timingIndex}
                                  className="special-shift-request-form-day"
                                  style={{
                                    marginTop: timingIndex === 0 ? "0px" : "15px",
                                  }}
                                >
                                  <div className={`special-shift-request-form-clock-range ${isFocused ? ' focused' : ''}`}>
                                    <div className="start hyphen-in-after">
                                      <TimePicker
                                        id={`start_time_${index}_${timingIndex}`}
                                        value={timing.start_time}
                                        placeholder="Clock-In"
                                        readOnly={!isFocused}
                                        onChange={(time) =>
                                          handleSpecialShiftClockTimingsChange(
                                            time.value,
                                            "start_time",
                                            index,
                                            timingIndex
                                          )
                                        }
                                        disabled={dataSubmitInProcess}
                                      />
                                    </div>
                                    {/* {!isFocused && <div className="hyphen"></div>} */}
                                    <div className="end">
                                      <TimePicker
                                        id={`end_time_${index}_${timingIndex}`}
                                        value={timing.end_time}
                                        placeholder="Clock-Out"
                                        readOnly={!isFocused}
                                        onChange={(time) =>
                                          handleSpecialShiftClockTimingsChange(
                                            time.value,
                                            "end_time",
                                            index,
                                            timingIndex
                                          )
                                        }
                                        disabled={dataSubmitInProcess}
                                      />
                                    </div>
                                  </div>
                                  {(isFocused || isDateFilled) && <div className="special-shift-card-timings-actions">
                                    <button
                                      style={{
                                        // marginTop: "5px",
                                        opacity:
                                          day.timings.length === 1 ? "0.5" : "1",
                                      }}
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                        removeShiftClockTimings(index, timingIndex)
                                      }}
                                      disabled={dataSubmitInProcess}
                                    >
                                      <span className="icon icon-minus"></span>
                                    </button>
                                    {timingIndex === day.timings.length - 1 && (
                                      <button
                                        className="add-more-clock-btn mobile"
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                          addMoreShiftClockTimings(index)
                                        }}
                                        disabled={dataSubmitInProcess}
                                      >
                                        <span className="icon icon-plus"></span>
                                      </button>
                                    )}
                                  </div>}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </ClickOutsideListner>
                    </div>
                  </div>
                );
              }
              )}
            </div>
          </>
        )}

        {isAdhocShiftType ? (
          <DateForm
            data={adhocShiftSlots}
            disabled={dataSubmitInProcess}
            isSlotFocused={focusedSlot}
            removeSlot={removeAdhocShiftSlot}
            toggleFocus={toggleFocus}
            handleDateChange={handleDateChange}
            datePickerRefs={datePickerRefs}
          />
        ) : null}
      </div>

      {/* Comment Form */}
      <div className="form-comment">
        <textarea
          className="form-comment-area"
          placeholder="Reason"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={250}
        />
        <span className='textLength'>{`${comment?.length} / 250`}</span>
      </div>

      <div className="form-actions">
        <div className="form-action-btns">
          <button
            className='btn-block'
            onClick={() => submitRequest()}
            disabled={dataSubmitInProcess}
          >
            {errorMessage === nextDayMsg ||
              errorMessage === dstAdjustMsg
              ? "Confirm"
              : "Submit"}
          </button>
          <button className='btn-block' onClick={() => onClose()} disabled={dataSubmitInProcess}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

const ScheduleItem = ({
  sch,
  index,
  weekdays,
  handleStandardShiftClockTimingsChange,
  dataSubmitInProcess,
  isChecked,
  isDst = false // Default to false if not provided
}) => {
  const suffix = isDst ? '_dst' : ''; // Suffix for IDs

  return (
    <div
      className="standard-shift-request-form-clock-range"
      style={{
        display: isChecked ? '' : 'none',
      }}
    >
      <TimePicker
        id={`${weekdays[sch.weekday]}_start_time${suffix}`}
        value={sch.start_time}
        onChange={(time) =>
          handleStandardShiftClockTimingsChange(
            time.value,
            `start_time`,
            index,
            isDst,
          )
        }
        disabled={dataSubmitInProcess}
      />
      <TimePicker
        id={`${weekdays[sch.weekday]}_end_time${suffix}`}
        value={sch.end_time}
        onChange={(time) =>
          handleStandardShiftClockTimingsChange(
            time.value,
            `end_time`,
            index,
            isDst,
          )
        }
        disabled={dataSubmitInProcess}
      />
    </div>
  );
};