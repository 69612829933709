import { useAttendanceFilters } from '../context'

import AttendanceTable from './AttendanceTable'
import CompanyAnalytics from './Analytics/CompanyAnalytics'
import { TABS } from './HomeTabs'
import { useState } from 'react'

const HomeTabContent = ({ loggedInUser, showLineLoader, setShowLineLoader, showAnalyticsFor, toggleAnalytics }) => {
    const { activeHomeTab } = useAttendanceFilters()
    const [officeFloor, setOfficeFloor] = useState(null);
    return (
        <div className='home-tab-content'>
            {activeHomeTab.includes(TABS.ALL_HANDS) ? <AttendanceTable
                loggedInUser={loggedInUser}
                showLineLoader={showLineLoader}
                setShowLineLoader={setShowLineLoader}
                showAnalyticsFor={showAnalyticsFor}
                toggleAnalytics={toggleAnalytics}
                officeFloor={officeFloor}
                setOfficeFloor={setOfficeFloor}
            /> : null}
            {activeHomeTab.includes(TABS.ANALYTICS) ? <CompanyAnalytics /> : null}
        </div>
    )
}

export default HomeTabContent