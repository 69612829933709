import { useCallback, useEffect, useRef, useState } from 'react';

import { alertService } from 'services/alertService';
import APIService from 'services/apiService';

import Avatar from 'components/Avatar';
import Comments from '../Comments';
import LeaveDetailsCard from './LeaveDetailCard';
import PreviewModal from 'components/PreviewModal/PreviewModal';
import SpecialShiftDetailCard from './SpecialShiftDetailCard';
import StandardShiftDetailCard from '../StandardShiftDetailCard';
import { MouseTooltip } from 'components/ToolTip';

import { useGlobalContext } from 'contexts/GlobalContext';
import useUserData from 'hooks/useUserData';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { AL_BASE_URL, REQUEST_TYPES } from "views/Attendance/constants";
import { unreadEmitter, unreadEventsEnum } from "views/Attendance/context";

import { format, formatTimeAgo } from 'utils/date';
import { convertTimeZone, getUTCDate, removeHTMLTags } from 'utils/Common';
import { compareSchedules, isNotConfidential } from 'views/Attendance/utils';

import './RequestCard.scss';
import clsx from 'utils/clsx';

const RequestCard = ({
    request,
    loggedInUser,
    onRevokeRequest,
    onStatusChange,
    onRequestUpdate,
    isUnread,
    addRequestToUnread,
    markRequestAsRead,
    onEditClick,
    showCheckbox,
    checked,
    canMarkRead,
    onChange,
    updateCardInfo,
    showComments,
    toggleComments,
}) => {
    const { user, isManager } = useUserData();
    const { width } = useWindowDimensions();
    const isDesktopVersion = width > 640;

    const { img_url, first_name, middle_name, last_name, manager_img_url, manager_first_name, manager_manager_middle_name, manager_last_name } = request;
    const requestType = request?.request_type;
    const isLeaveRequest = requestType === REQUEST_TYPES.LEAVE;
    const isSpecialRequest = requestType === REQUEST_TYPES.SPECIAL;
    const isStandardRequest = requestType === REQUEST_TYPES.STANDARD;
    const isAdhocRequest = requestType === REQUEST_TYPES.ADHOC;

    const isBereavementLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Bereavement";
    const isMedicalLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Medical";
    const isMaternityLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Maternity";
    const isPaternityLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Paternity";

    const isWFH = (isSpecialRequest || isStandardRequest || isAdhocRequest) && request?.data?.[0]?.is_wfh === 1;
    const isActivityTracked = (isSpecialRequest || isStandardRequest || isAdhocRequest) && request.data[0]?.is_activity_tracked === 1;

    const isApproved = request?.is_approved;
    const isRejected = request?.is_rejected;
    const isPending = !isApproved && !isRejected;
    const status = isApproved ? 'approved' : isRejected ? 'declined' : 'pending';

    const isMyRequest = loggedInUser?.resource_id === request.resource_id;

    const requestTimestamps = {
        created: request.created_at,
        approved: request.approval_timestamp,
        declined: request.decline_timestamp,
    }

    const userName = `${first_name} ${middle_name ? `${middle_name} ${last_name}` : last_name}`
    const managerName = `${manager_first_name} ${manager_manager_middle_name ? `${manager_manager_middle_name} ${manager_last_name}` : manager_last_name}`

    const { doesObservesDST, observesDST: doesUserTimeZoneObserveDST, timeZones } = useGlobalContext();
    const timeZone = timeZones.find((tz) => tz.id === request.timezone_id);
    const doesTimeZoneObserveDST = doesObservesDST(timeZone);

    const isSameSchedule = request.request_type === "schedule" ? compareSchedules(request.data, request.dst_data) : false;
    let showDSTData = false;
    if (doesUserTimeZoneObserveDST) {
        showDSTData = !isSameSchedule;
    } else {
        showDSTData = doesTimeZoneObserveDST && !isSameSchedule;
    }

    // const isActivityNotTracked = (isSpecialRequest && request.data?.[0]?.is_activity_tracked === 0) || (isStandardRequest && request.data?.[0]?.is_activity_tracked === 0)

    const requestLabel = isLeaveRequest
        ? `${isManager || isMyRequest ? `${request.data[0]?.leave_type} ` : ''}Leave`
        : isSpecialRequest ? "Special Shift"
            : isStandardRequest ? "Standard Shift"
                : isAdhocRequest ? "Adhoc Shift"
                    : "";

    const requestCard = {
        [REQUEST_TYPES.LEAVE]: <LeaveDetailsCard data={request.data} />,
        [REQUEST_TYPES.SPECIAL]: <SpecialShiftDetailCard data={request.data} />,
        [REQUEST_TYPES.STANDARD]: <StandardShiftDetailCard data={request.data} dst_data={showDSTData ? request.dst_data : null} />,
        [REQUEST_TYPES.ADHOC]: <LeaveDetailsCard data={request.data} />,
    }

    const addCommentRef = useRef();

    const [dataSubmitInProcess, setDataSubmitInProcess] = useState(false);
    const [previewFileData, setPreviewFileData] = useState(null);
    const [comments, setComments] = useState(null);

    const [reason, setReason] = useState('');
    const [statusChangeReasonInput, setStatusChangeReasonInput] = useState(null);

    const updateCommentCount = useCallback((count) => {
        const requestClone = structuredClone(request);
        requestClone.notes_count = count;
        onRequestUpdate(requestClone);
    }, [onRequestUpdate, request]);


    useEffect(() => {
        const unsub_add_comment = unreadEmitter.subscribe(
            unreadEventsEnum.comment_added,
            ({ request_id, status, data, isTeamRequestEvent }) => {
                if (!isTeamRequestEvent && +request_id === +request.resource_request_id) {
                    updateCommentCount(request.notes_count + 1);
                    const parsedData = JSON.parse(data)[0];
                    addRequestToUnread(request_id);
                    if (showComments) {
                        addCommentRef.current({ ...parsedData, created_at: convertTimeZone(new Date(parsedData.created_at + ' GMT')) });
                        markRequestAsRead(request_id);
                    } else {
                        updateCardInfo(request_id, { unread_timestamp: parsedData.created_at + ' GMT' });
                    }
                }
            }
        );

        // const unsub_edit_comment = unreadEmitter.subscribe(
        //   unreadEventsEnum.comment_edited,
        //   ({ request_id, status }) => {
        //   }
        // );
        // const unsub_delete_comment = unreadEmitter.subscribe(
        //   unreadEventsEnum.comment_deleted,
        //   ({ request_id, status }) => {
        //   }
        // );
        return () => {
            unsub_add_comment();
            // unsub_edit_comment();
            // unsub_delete_comment();
        }
    }, [addRequestToUnread, request.notes_count, request.resource_request_id, updateCommentCount, showComments]);

    const approveRequest = async (e) => {
        e.stopPropagation();
        if (statusChangeReasonInput) closeReasonInput();
        setDataSubmitInProcess(true);
        try {
            const apiUrl = AL_BASE_URL + `/approve_request/${request.resource_request_id}`;
            const response = await APIService.apiRequest(apiUrl, null, false, "PUT");

            if (response.status === 1) {
                const requestClone = structuredClone(request);
                requestClone.is_approved = 1;
                requestClone.is_rejected = 0;
                requestClone.approval_timestamp = convertTimeZone();
                onStatusChange({ ...requestClone, ...getManagerInfo() });

                setComments(prev => {
                    const newComment = {
                        "child_notes_count": 0,
                        "created_at": format(getUTCDate(), "ddd, DD MMM YYYY HH:mm:ss") + ' GMT',
                        "created_by": user.resource.first_name.id,
                        "created_by_name": {
                            "first_name": user.resource.first_name,
                            "img_url": user.resource.first_name.img_url,
                            "last_name": user.resource.last_name,
                            "middle_name": ""
                        },
                        "id": 0,
                        "note": "Approved",
                        "parent_note_id": null,
                        "resource_request_id": request.resource_request_id,
                        "type": "approval"
                    }
                    return prev ? [...prev, newComment] : [newComment];
                })
            } else {
                alertService.error(response.msg);
            }
        } catch (error) {
            alertService.error(error.message);
        } finally {
            setDataSubmitInProcess(false);
        }
    }

    const declineRequest = async () => {
        if (!reason.trim()) return;
        setDataSubmitInProcess(true);
        try {
            const apiPayload = { comment: reason }
            const apiUrl = AL_BASE_URL + `/reject_request/${request.resource_request_id}`;
            const response = await APIService.apiRequest(apiUrl, apiPayload, false, "PUT");

            if (response.status === 1) {
                const requestClone = { ...request };
                requestClone.is_approved = 0;
                requestClone.is_rejected = 1;
                requestClone.decline_timestamp = convertTimeZone();
                requestClone.decline_reason = reason;
                onStatusChange({ ...requestClone, ...getManagerInfo() });
                closeReasonInput();
                setComments(prev => {
                    const newComment = {
                        "child_notes_count": 0,
                        "created_at": format(getUTCDate(), "ddd, DD MMM YYYY HH:mm:ss") + ' GMT',
                        "created_by": user.resource.first_name.id,
                        "created_by_name": {
                            "first_name": user.resource.first_name,
                            "img_url": user.resource.first_name.img_url,
                            "last_name": user.resource.last_name,
                            "middle_name": ""
                        },
                        "id": 0,
                        "note": reason,
                        "parent_note_id": null,
                        "resource_request_id": request.resource_request_id,
                        "type": "decline_reason"
                    }
                    return prev ? [...prev, newComment] : [newComment];
                })
            } else {
                alertService.error(response.msg);
            }
        } catch (error) {
            alertService.error(error.message);
        } finally {
            setDataSubmitInProcess(false);
        }
    }

    const handleInputChange = (e) => {
        setReason(e.target.value);
    }

    const openDeclineReasonInput = (e) => {
        e.stopPropagation();
        setStatusChangeReasonInput('decline');
    }

    const closeReasonInput = (e) => {
        e?.stopPropagation?.();
        setStatusChangeReasonInput(null);
        setReason('');
    }

    const handleSubmit = (e) => {
        e.stopPropagation()
        declineRequest();
    }

    const getManagerInfo = () => {
        return {
            manager_first_name: loggedInUser.first_name,
            manager_last_name: loggedInUser.last_name,
            manager_middle_name: loggedInUser.middle_name,
            manager_img_url: loggedInUser.img_url,
        }
    }

    const hasFooter = statusChangeReasonInput || showComments || (!isPending && !showComments && !statusChangeReasonInput)

    const isOverdue = isLeaveRequest && request.data.some((day) => day.is_overdue === 1);
    const isShortNotice = isLeaveRequest && request.data.some((day) => day.is_short_notice === 1);

    const isClickable = isManager ? true : isMyRequest;

    return (
        <div
            className={clsx('request-card-wrapper', {
                'no-footer': !hasFooter,
                'fade-out': !isPending,
                'can-select': showCheckbox,
                'disabled': !canMarkRead,
                'is-clickable': isClickable
            })}
            id={`request_${request.resource_request_id}`}
            onClick={() => {
                if (!isMyRequest && !isManager) return;
                toggleComments(request.resource_request_id);
                if (statusChangeReasonInput) {
                    closeReasonInput();
                }
                if (!showComments) {
                    setTimeout(() => {
                        document.querySelector(`#request_${request.resource_request_id}`).scrollIntoView({ block: 'nearest' });
                    }, 0);
                }
            }}
        >
            {showCheckbox && (
                <span
                    className={clsx('select-box', {
                        'selected': checked,
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!canMarkRead) return;
                        onChange({ isChecked: checked, requestId: request.resource_request_id });
                    }}
                ></span>
            )}
            <div className='avatar-wrapper'>
                <Avatar
                    imgSrc={img_url}
                    firstName={first_name}
                    lastName={last_name}
                    height={isDesktopVersion ? 30 : 36}
                    width={isDesktopVersion ? 30 : 36}
                    fontSize={12}
                />
            </div>
            <div className='request-header'>
                <div className="font-bold author-name">{userName}</div>
                <MouseTooltip content={format(
                    requestTimestamps.created,
                    "ddd, MMM DD, YYYY, hh:mm A"
                )}>
                    <div className='request-card-label request-date'>{formatTimeAgo(requestTimestamps.created)}</div>
                </MouseTooltip>
                <div className='request-sub-header'>
                    <button className='icon icon-info'></button>
                    <div className='request-info'>
                        {isLeaveRequest ? (
                            <div className='leave-request-label-wrapper'>
                                <div className='request-label'>{requestLabel}</div>
                                {!(isBereavementLeave || isMedicalLeave || isMaternityLeave || isPaternityLeave) && (isOverdue || isShortNotice) && (
                                    <div className='request-card-label'>
                                        {isOverdue ? 'Overdue' : 'Short-notice'}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className='request-label'>{requestLabel}</div>
                                <div className='location-type-wrapper'>
                                    <span className={`location-type ${isWFH ? 'remote' : 'office'}`}></span>
                                    <span>{isWFH ? 'Remote' : 'Office'}</span>
                                </div>
                                <div className='location-type-wrapper'>
                                    <span className={`work-type ${isActivityTracked ? 'online' : 'offline'}`}></span>
                                    <span>{isActivityTracked ? 'Online' : 'Offline'}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={`action-btn-wrapper ${showComments ? 'show' : ''}`}>
                {(isMyRequest ? !isApproved : true) && <div className={`action-buttons-inner ${isUnread ? 'hide' : ''}`}>
                    <button className='icon icon-more' onClick={(e) => e.stopPropagation()}></button>
                    {(isDesktopVersion || (!isDesktopVersion && !statusChangeReasonInput)) && <div className='action-buttons'>
                        {isMyRequest ? <>
                            {!isApproved && (
                                <button
                                    className="btn-block"
                                    disabled={dataSubmitInProcess}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        onEditClick(request)
                                    }}
                                >
                                    Edit
                                </button>
                            )}
                            {isPending && (
                                <button
                                    className="btn-block"
                                    disabled={dataSubmitInProcess}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        onRevokeRequest(request)
                                    }}
                                >
                                    Revoke
                                </button>
                            )}
                        </> : <>
                            {!isApproved && (
                                <button
                                    className="btn-block"
                                    disabled={dataSubmitInProcess}
                                    onClick={approveRequest}
                                >
                                    Approve
                                </button>
                            )}
                            {!isRejected && (
                                <button
                                    className="btn-block"
                                    disabled={dataSubmitInProcess || statusChangeReasonInput}
                                    onClick={openDeclineReasonInput}
                                >
                                    Decline
                                </button>
                            )}
                        </>}
                    </div>}
                </div>}
            </div>
            <div className='request-body'>
                {isNotConfidential(request.reason) && <div className={`request-reason`}>
                    {removeHTMLTags(request.reason)}
                </div>}
                {requestCard[request.request_type]}
                {isMedicalLeave && request?.request_attachemnt_url?.length > 0 && (
                    <div className="medical-attachments">
                        <div className="attachments">
                            {request.request_attachemnt_url.split(',').map((url) => {
                                const name = url.split('_T_L_').pop();
                                return (
                                    <button
                                        key={url}
                                        className="attachment"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setPreviewFileData({ url, name })
                                        }}
                                        title={name}
                                    >
                                        <span className='icon-file'></span>
                                        <span className='name'>{name}</span>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                )}
                {previewFileData &&
                    <PreviewModal
                        isOpen={true}
                        url={previewFileData.url}
                        title={previewFileData.name}
                        toggle={(e) => setPreviewFileData(null)} />
                }
            </div>

            {isUnread && !showComments && <div className='notification-dot-large'></div>}

            {hasFooter && <div className='request-footer'>
                {!isPending && !showComments && !statusChangeReasonInput && <div className='comment-wrapper status-comment-wrapper'>
                    <div className='status-avatar-wrapper'>
                        <Avatar
                            imgSrc={manager_img_url}
                            firstName={manager_first_name}
                            lastName={manager_last_name}
                            height={isDesktopVersion ? 30 : 36}
                            width={isDesktopVersion ? 30 : 36}
                            fontSize={12}
                        />
                    </div>
                    <div className="font-bold author-name">{managerName}</div>
                    <div className='request-card-label status-request-date'>
                        {requestTimestamps[status] ? (
                            <>
                                <MouseTooltip
                                    content={format(
                                        requestTimestamps[status],
                                        "ddd, MMM DD, YYYY, hh:mm A"
                                    )}
                                    style={{ lineHeight: "14px" }}
                                >
                                    {formatTimeAgo(requestTimestamps[status], new Date(convertTimeZone()))}
                                </MouseTooltip>
                                {!isDesktopVersion && <span>{format(requestTimestamps[status], ' • MMM DD')}</span>}
                            </>
                        ) : (
                            <span>unknown</span>
                        )}
                    </div>
                    <div className='request-card-label request-status'>
                        <span className={`icon-${status} capitalize`}>{status}</span>
                        {isRejected ? <span className='status-comment'>{removeHTMLTags(request.decline_reason)}</span> : null}
                    </div>
                </div>}

                {showComments && (
                    <Comments
                        resource_request_id={request.resource_request_id}
                        unread_timestamp={request.unread_timestamp}
                        updateCommentCount={updateCommentCount}
                        markRequestAsRead={markRequestAsRead}
                        addCommentRef={addCommentRef}
                        allowEditing={isPending}
                        hideCommentInput={statusChangeReasonInput}
                        comments={comments}
                        setComments={setComments}
                    />
                )}

                {statusChangeReasonInput && <div className='reason-input-wrapper'>
                    <div className='help-text'>
                        You are about to decline this {isApproved ? 'approved' : ''} request. Please provide a reason before submitting.
                    </div>
                    <div className='reason-input-wrapper-inner'>
                        <textarea
                            value={reason}
                            onChange={handleInputChange}
                            placeholder='Message'
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className='input-btn-wrapper'>
                            <button className='btn-block' disabled={dataSubmitInProcess} onClick={handleSubmit}>Submit</button>
                            <button className='btn-block' disabled={dataSubmitInProcess} onClick={closeReasonInput}>Cancel</button>
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}

export default RequestCard