import React, { createContext, useState, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";

// context
import { useGlobalContext } from "contexts/GlobalContext";
import { TabProvider, useTabContext } from 'components/SuperTabs/TabContext';

import { API_BASE_URL, SITE_PREFIX } from "components/Constants.js";
import { SITE_PAGES } from 'components/Navigation';

import NewHeader from "components/header/NewHeader";

import PrivateRoute from "utils/PrivateRoute";

// import Loading from 'views/Loading';
import LineLoader from "components/LineLoader";
import PageNotFound from "views/404";
import Unauthorized from "views/Unauthorized";
import { Logout } from "views/Logout";

import Organization from "views/Organization/Organization";
import GenerateSignature from "views/Generate Signature";
import ProfileHomeNew from "views/ProfileManager";
import RecruitmentFunnel from "views/RecruitmentFunnel";
import PerformanceLog from "views/Performance";
import Wiki from "views/Wiki";

import WebWorker from "utils/WebWorker";
import MyWorker from "services/timeWorkerService";
import Attendance from "views/Attendance";
import NewHome from "views/NewHome";
import { SocketConnectionManager } from "socket";
import useWindowDimensions from "hooks/useWindowDimensions";
import { AliveScope } from 'components/KeepAlive';

export const TimeWorkerContext = createContext(null);

export default function Main() {

  // const isProd = !isDev();
  // const newDesign = !isProd && window.location.href.includes('new');

  return (
    <TabProvider SITE_PREFIX={SITE_PREFIX} API_BASE_URL={API_BASE_URL} SITE_PAGES={SITE_PAGES}>
      <TabContent />
    </TabProvider>
  );
}

const TabContent = () => {
  const { myTimeZone, selectedOrganization } = useGlobalContext();
  const { isTabDataLoaded } = useTabContext();

  const [showOfflineMessage, setShowOfflineMessage] = useState(false);
  const [timeWorkerInstance] = useState(() => new WebWorker(MyWorker));

  useEffect(() => {
    //set the local storage to 0 on refresh for let refresh token api working when flag stuck to 1
    localStorage.setItem(SITE_PREFIX + "reset_token_inprocess", 0);

    return () => timeWorkerInstance.terminate();
  }, [timeWorkerInstance]);

  useEffect(() => {
    const updateOnlineStatus = () => setShowOfflineMessage(!navigator.onLine);

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const width = useWindowDimensions().width;
  const isDesktopVersion = width > 640;

  if (!myTimeZone || !selectedOrganization || !isTabDataLoaded) {
    return <LineLoader />;
  }

  return (
    <SocketConnectionManager>
      <TimeWorkerContext.Provider value={timeWorkerInstance}>
        <AliveScope>
          <div className="teamlink">
            {showOfflineMessage && (
              <div className="offline-message">
                No internet connection. Waiting for network…
              </div>
            )}

            <NewHeader />

            <Switch>
              <PrivateRoute
                path="/"
                exact
                redirectTo="/app"
              />

              <PrivateRoute
                path="/app"
                exact
                component={NewHome}
                tabId="ATTENDANCE"
              />

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/organization"
                  component={Organization}
                  access="ORGANIZATION"
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/profile"
                  exact
                  redirectTo="/app/profile/home"
                  access={[
                    "PROFILE_MANAGEMENT_EMPLOYEE",
                    "PROFILE_MANAGEMENT_HR",
                  ]}
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/profile/:id"
                  component={ProfileHomeNew}
                  access={[
                    "PROFILE_MANAGEMENT_EMPLOYEE",
                    "PROFILE_MANAGEMENT_HR",
                  ]}
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/recruiter"
                  exact
                  redirectTo="/app/recruiter/f-home"
                  access="RECRUITER"
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/recruiter/:id"
                  exact
                  component={RecruitmentFunnel}
                  access="RECRUITER"
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/signgen"
                  component={GenerateSignature}
                  access="EMPLOYEE"
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/performance"
                  exact
                  redirectTo="/performance/home"
                  access="PERFORMANCE_MANAGEMENT"
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/performance/:id"
                  exact
                  component={PerformanceLog}
                  access="PERFORMANCE_MANAGEMENT"
                />
              )}

              <PrivateRoute
                path="/app/attendance"
                exact
                component={Attendance}
                access="ATTENDANCE"
              />

              {isDesktopVersion && (
                <PrivateRoute
                  path="/app/wiki"
                  exact
                  redirectTo="/app/wiki/home"
                  access="WIKI"
                />
              )}

              {isDesktopVersion && (
                <PrivateRoute path="/app/wiki/:id" component={Wiki} access="WIKI" />
              )}

              <PrivateRoute
                path="/unauthorized"
                component={Unauthorized}
                access=""
              />
              <PrivateRoute path="/404" component={PageNotFound} access="" />
              <PrivateRoute path="/Logout" component={Logout} access="" />
              <PrivateRoute path="/temp" component={Temp} access="" />

              <Redirect to="/404" />
            </Switch>
          </div>
        </AliveScope>
      </TimeWorkerContext.Provider>
    </SocketConnectionManager>
  );
}

// We've a feature where the app will redirect to the last visited page after switching the organization.
// In these case, we need to reload the page to get the updated data. If the last visited page is same as the current page, then we need to force reload the page.
// So we using this component to visit and go to the last visited page which would solve the same url issue.
// Check GlobalContext.js for more info. (Use /temp keyword to search)
function Temp() {
  return null;
}
