import { useState, useEffect, Fragment } from "react";
import { StenoRenderer } from "react-steno";
import { useParams } from "react-router-dom";
import BasicForm from "./BasicForm";
import SuccessPage from "./SuccessPage";
import LineLoader from "./LineLoader";
import { useJobInfo } from "../context";

export default function BasicFormComp() {
  const params = useParams();
  const [showLineLoader, setShowLineLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { jobInfo, fetchJobInfo } = useJobInfo();

  useEffect(() => {
    const id = params.id;
    setShowLineLoader(true);
    const controller = new AbortController();
    fetchJobInfo(id, controller).finally(() => setShowLineLoader(false));
    return () => controller.abort();
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Fragment>
      <LineLoader show={showLineLoader || isSubmitting} />
      <div className="forms-container">
        {isSubmitted ? (
          <SuccessPage />
        ) : (
          <div className="main-container">
            {jobInfo.title && (
              <>
                <div className={`job-info ${showForm ? "show-form" : ""}`}>
                  <div className="job-title">
                    <h1>
                      {jobInfo.title}
                    </h1>
                    <div className="job-meta">
                      <span>{jobInfo.employment}</span>
                      <span className="bullet"></span>
                      <span>{jobInfo.location}</span>
                    </div>
                  </div>
                  {!showForm && (
                    <button
                      className="apply-btn"
                      onClick={() => {
                        if (window.bydata) {
                          window.bydata.sendCustomPageClickData("Apply Job");
                        }
                        setShowForm(true)
                      }}
                    >
                      Apply for this Job
                    </button>
                  )}
                </div>
                {showForm ? (
                  <BasicForm
                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}
                    setIsSubmitted={setIsSubmitted}
                  />
                ) : (
                  <div className="job-description">
                    <StenoRenderer html={jobInfo.description} />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
}
