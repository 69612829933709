import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// constants
import { ATTENDANCE_PAGE } from "components/Navigation.js";
import { SITE_PREFIX } from "components/Constants";
import { AL_BASE_URL } from "views/Attendance/constants";
// context
import { useGlobalContext } from 'contexts/GlobalContext';
// component
// import NewTimer from "components/Timer/NewTimer";
import Timer from "components/Timer/Timer";
import ControlPanel from "components/ControlPanel/NewIndex";
import SuperTabs, { emitter } from "components/SuperTabs/SuperTabs";
import AttendanceTabOptions from './AttendanceTabOptions';
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
import { connectSocket, useSocket } from "socket";
// utils and services
import { getUser, isElectronApp } from "utils/Common";
import APIService from "services/apiService";
// styles
import "./Header.scss";
import "../../styles/Header.scss";
import '../../styles/SpeedSelect.scss';
import { useAliveScope } from "components/KeepAlive";
import { useTabContext } from "components/SuperTabs/TabContext";


export default function Header() {
  const location = useLocation();
  const { hasTimerAccess, isAppInActive, selectedOrganization } = useGlobalContext();
  const { tabs, isTabDataLoaded, updateTabProperty } = useTabContext();
  const { width } = useWindowDimensions();
  const isHome = location.pathname === "/";
  const isDesktopVersion = width > 640;
  const user = getUser();

  const [isFullScreen, setIsFullScreen] = useState(() => {
    let key = SITE_PREFIX + 'isFullScreen';
    let storedValue = localStorage.getItem(key);
    if (storedValue === 'undefined') {
      storedValue = false;
      localStorage.setItem(key, JSON.stringify(storedValue));
    }
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const getCountInProgressRef = useRef(false);
  const getCountTimeOutIDRef = useRef(null);
  const unReadDataLoadTSRef = useRef(null);

  useLayoutEffect(() => {
    const key = SITE_PREFIX + 'isFullScreen';
    const updateFullScreen = ({ isFullScreen }) => {
      const storedValue = JSON.parse(localStorage.getItem(key) || 'null');

      if (storedValue !== isFullScreen) {
        localStorage.setItem(key, JSON.stringify(!!isFullScreen));
        setIsFullScreen(isFullScreen);
      }
    };

    async function getIsFullScreen() {
      let isFullScreen = await window.ipcRender.invoke('getIsFullScreen');
      updateFullScreen({ isFullScreen });
    }

    if (isElectronApp) {
      getIsFullScreen();
      window.ipcRender.receive("full-screen", updateFullScreen);
    }

    return () => {
      if (isElectronApp) {
        window.ipcRender?.removeListener("full-screen", updateFullScreen);
      }
    }
  }, [])

  const socket = useSocket();
  const isAttendanceTabPresent = tabs.some(
    (tab) => tab.title === ATTENDANCE_PAGE.title
  );
  const isAttendanceTab = location.pathname.includes(ATTENDANCE_PAGE.url);

  const updateNotificationDot = useCallback((value) => {
    updateTabProperty({
      propertyToUpdate: 'showNotificationDot',
      newValue: value,
      identifierKey: 'title',
      identifierValue: ATTENDANCE_PAGE.title,
    });
  }, [updateTabProperty]);

  useEffect(() => {
    socket.on("unread_count", (data) => {
      if (user.id !== data[0].created_by) {
        updateNotificationDot(data[0].request > 0);
      }
    });

    return () => {
      socket.off("unread_count");
    }

  }, [socket, updateNotificationDot, user.id]);

  async function getUnreadNotificationCount() {
    if (getCountInProgressRef.current) return;
    unReadDataLoadTSRef.current = new Date();
    getCountInProgressRef.current = true;
    try {
      const response = await APIService.apiRequest(
        AL_BASE_URL + "/get_homepage_counts",
        null,
        false,
        "GET"
      );

      if (response.status === 1) {
        updateNotificationDot(response.output.unread_request_count[0]?.unread_count > 0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      getCountInProgressRef.current = false;
    }
  }

  const { clear, drop } = useAliveScope();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clear(), [selectedOrganization.id, isTabDataLoaded]);

  useEffect(() => {
    if (!isTabDataLoaded) return;
    const hasAttendancePrivilege = user?.privileges?.employee?.includes('ATTENDANCE') || false;
    if ((hasAttendancePrivilege && isAttendanceTabPresent && !isAttendanceTab)) {
      getUnreadNotificationCount().then(connectSocket);
    } else if (hasTimerAccess) {
      setTimeout(() => {
        connectSocket();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization.id, isTabDataLoaded, isAttendanceTabPresent, isAttendanceTab, hasTimerAccess]);

  useEffect(() => {
    if (isAttendanceTabPresent && !isAttendanceTab) {
      const callback = () => {
        if (window.navigator.onLine && !isAppInActive && unReadDataLoadTSRef.current?.getTime() + 5000 < new Date().getTime()) {
          getCountTimeOutIDRef.current = setTimeout(() => {
            getUnreadNotificationCount().then(() => !hasTimerAccess && connectSocket());
          }, 2000);
        }
      };

      // window.addEventListener('online', callback);
      socket.on(hasTimerAccess ? "connect" : "connect_error", callback);

      return () => {
        // window.removeEventListener('online', callback);
        socket.off(hasTimerAccess ? "connect" : "connect_error", callback);
        if (getCountTimeOutIDRef.current) clearTimeout(getCountTimeOutIDRef.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTimerAccess, isAppInActive, isAttendanceTab, isAttendanceTabPresent, tabs]);

  const handleDoubleClick = (e) => {
    if (!isElectronApp) return;
    if (
      !['BUTTON', 'A'].includes(e.target.tagName) &&
      (e.target.className.includes('nav') || e.target.className.includes('electron-header'))
    ) {
      window.ipcRender.send("message:headerDoubleClick");
    } else {
      // Prevent default action if double-clicking on a child
      e.preventDefault();
    }
  }

  useEffect(() => {
    const unsub = emitter.subscribe('superTabClose', (e) => {
      const { appId, isSubTab } = e;
      if (appId && !isSubTab) {
        // drop the cache of the tab
        drop(appId);
      }
    })
    return unsub;
  }, []);

  return (
    <div className="header-wrapper">
      <nav
        className={`${isHome && !isDesktopVersion ? "mobile-home" : ""} ${isElectronApp && !isFullScreen ? "electron-header" : ""
          }`}
        onDoubleClick={handleDoubleClick}
      >
        <div className="left-nav">
          <SuperTabs Suffix={{ 1: AttendanceTabOptions }} />
        </div>
        <div className="right-nav">
          {/* Timer Option Button */}
          {isDesktopVersion && hasTimerAccess ? (
            <div className="timer-container">
              <Timer />
            </div>
          ) : null}

          {/* Control Panel Button */}
          <ControlPanel hasTimerAccess={hasTimerAccess} />
        </div>
      </nav>
    </div >
  );
}
