import { useEffect, useMemo, useRef, useState } from "react";

const SearchSelect = ({ value, options, onSelect, placeholder = "Search" }) => {
  const menuRef = useRef();
  const inputRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return options;
    const text = searchTerm.toLowerCase();
    return options.filter((option) =>
      option.value.toLowerCase().includes(text)
    );
  }, [searchTerm, options]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      const menuEl = menuRef.current;
      const inputEl = inputRef.current;
      if (!inputEl?.contains(e.target) && !menuEl?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    const handleFocusOut = (e) => {
      const menuEl = menuRef.current;
      const inputEl = inputRef.current;
      if (!inputEl?.contains(e.relatedTarget) && !menuEl?.contains(e.relatedTarget)) {
        setIsOpen(false);
      }
    };

    const inputEl = inputRef.current;
    const menuEl = menuRef.current;
    inputEl?.addEventListener("focusout", handleFocusOut);
    menuEl?.addEventListener("focusout", handleFocusOut);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      inputEl?.removeEventListener("focusout", handleFocusOut);
      menuEl?.removeEventListener("focusout", handleFocusOut);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    if (value) onSelect(null);
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleSelect = (option) => {
    onSelect(option);
    setSearchTerm("");
    setIsOpen(false);
  };

  return (
    <div className="search-select">
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={value?.label ?? searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setIsOpen(true)}
        className="search-input"
      />
      {isOpen && (
        <ul className="options-list" ref={menuRef} tabIndex="-1">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                className={option.value === value?.value ? "active" : ""}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li>No results found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchSelect;
