import { useMemo, useRef, useState } from "react";
import DatePicker from "components/ReactCalendar/DatePicker";
import { format, isDate } from "utils/date";

const dateFormat = 'MM / DD / YYYY'

const DOBPicker = ({ value, onChange, hasError, onBlur, updateValue }) => {
    const inputRef = useRef(null)
    const [inputValue, setInputValue] = useState('')

    const endDate = useMemo(() => {
        const today = new Date();
        const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        const date = new Date(firstDayOfNextMonth - 1);
        date.setFullYear(today.getFullYear() - 18);
        date.setHours(23, 59, 59, 999);
        return date;
    }, [])

    return (
        <div className='date-picker-wrapper'>
            <input
                ref={inputRef}
                placeholder={dateFormat}
                name="dob"
                type="text"
                id="dob"
                className={'date' + (hasError ? ' has-error' : '')}
                value={inputValue ? inputValue : value ? format(value, dateFormat) : value}
                onChange={(e) => {
                    setInputValue(e.target.value)
                    if (e.target.value === '') {
                        onChange({ target: { name: 'dob', value: e.target.value } })
                    }
                }}
                onFocus={() => {
                    setInputValue(value ? format(value, dateFormat) : value)
                }}
                onBlur={(e) => {
                    const value = e.target.value;
                    // const value = rawValue && !isNaN(+rawValue) ? +rawValue : rawValue || '';

                    const datePattern = /^(0[1-9]|1[0-2])\s*\/\s*(0[1-9]|[12][0-9]|3[01])\s*\/\s*(19|20)\d{2}$/;
                    // if (isDate(value) && new Date(value) <= endDate) {
                    if (typeof value === "string" && datePattern.test(value) && isDate(value) && new Date(value) <= endDate) {
                        onChange({ target: { name: 'dob', value: format(value, 'YYYY-MM-DD') } });
                    } else {
                      onBlur({ target: { name: 'dob', value: '' } });
                    }
                    
                    setInputValue('')
                }}
            />
            <Picker
                value={isDate(value) ? value : ''}
                key={value}
                onChange={(val) => {
                    inputRef.current.value = val
                    updateValue(val)
                    setTimeout(() => {
                        inputRef.current?.blur?.()
                    }, 0);
                    setInputValue('')
                }}
                endDate={endDate}
            />
        </div>
    )
}

const Picker = ({ endDate, value, onChange }) => {

    const defaultValue = useMemo(() => {
        const today = new Date();
        const date = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        return format(date, "YYYY-MM-DD");
    }, [])

    return (
        <div className={`date-picker-container`}
            tabIndex={-1} // This makes the div focusable for handling blur correctly
            onMouseDown={(e) => e.preventDefault()} // Prevent input blur when clicking inside the picker
        >
            <div className={`date-picker ${value ? '' : 'default-value'}`}>
                <DatePicker
                    date={value ? value : defaultValue}
                    onChange={(date) => {
                        onChange(date, 'dob')
                    }}
                    picker={value ? "date" : "year"}
                    showInline={true}
                    disableFn={(date) => date > endDate}
                />
            </div>
        </div>
    )
}

export default DOBPicker