import { useMemo, useState } from "react";

// Helper function to validate form fields
const validateField = (name, value, validations) => {
  const errors = [];

  validations.forEach((validation) => {
    const result = validation(value);
    if (result) {
      errors.push(result);
    }
  });

  return errors;
};

const useForm = (initialValues, validationRules = {}) => {
  const [values, setValues] = useState(() => initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [dirty, setDirty] = useState({});

  const setValue = (name, value) => {
    handleChange({ target: { name, value } });
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update values
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Mark field as dirty
    setDirty((prevDirty) => ({
      ...prevDirty,
      [name]: true,
    }));

    // Validate field if validation rules exist
    if ((touched[name] || errors[name]) && validationRules[name]) {
      const fieldErrors = validateField(name, value, validationRules[name]);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors.length > 0 ? fieldErrors[0] : null,
      }));
    }
  };

  // Handle input blur
  const handleBlur = (e) => {
    const { name } = e.target;

    // Mark field as touched
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));

    // Validate field if validation rules exist and field wasn't dirty
    if (validationRules[name]) {
      const fieldErrors = validateField(name, values[name], validationRules[name]);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors.length > 0 ? fieldErrors[0] : null,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = (callback, errorCallback) => (e) => {
    e.preventDefault();

    const newErrors = {};
    let hasErrors = false;

    // Validate all fields
    for (const name in validationRules) {
      const fieldErrors = validateField(name, values[name], validationRules[name]);
      if (fieldErrors.length > 0) {
        hasErrors = true;
        newErrors[name] = fieldErrors[0];
      }
    }

    setErrors(newErrors);

    if (!hasErrors) {
      callback(values);
    } else {
      // fire error event
      if (window.bydata) {
        window.bydata.sendCustomPageClickData({
          cd1: "Submit Application",
          cd2: "Error",
          cd3: newErrors ? Object.keys(newErrors) : "",
        });
      }
      // window.bydata.sendCustomPageClickData("Submit Application");
      errorCallback?.();
    }
  };

  // Memoize the return values of the hook
  return useMemo(
    () => ({
      values,
      setValue,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
    }),
    [values, errors, touched, dirty] // Dependencies that trigger re-memoization
  );
};

export default useForm;
