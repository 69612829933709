import { useCallback, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { withKeepAlive } from "components/KeepAlive";
import { PAGE_IDS } from "components/Navigation";
import Home from "./Home";
import Funnel from "./Funnel";
import {
    RecruitmentFunnelProvider,
    useRecruitmentFunnelContext,
} from "./context";
import { emitter } from "components/SuperTabs/SuperTabs";
import { useTabContext } from "components/SuperTabs/TabContext";
import { getUniqueId } from "utils/Utils";
import "./styles.scss";

function RecruitmentFunnel() {
    const { openSuperTabOnRowClick } = useTabContext();
    const { setTabsInfo } = useRecruitmentFunnelContext();
    const handleTabAddBtn = useCallback(() => {
        const id = getUniqueId();
        const tabId = `new_funnel_${id}`;
        const tabName = `New Funnel`;
        setTabsInfo((prev) => {
            return {
                tabs: [
                    ...prev.tabs,
                    {
                        id: tabId,
                        name: tabName,
                    },
                ],
                savedFunnelData: [
                    ...prev.savedFunnelData,
                    {
                        id: tabId,
                        step: 1,
                        name: "",
                        description: "",
                        department: [],
                        designation: [],
                        isFunnelCreated: false,
                        funnelId: null,
                        mode: "create",
                        stages: [{ name: "", description: "", isQuestionnaire: false, }],
                    },
                ],
            };
        });
        openSuperTabOnRowClick({
            tab: {
                id: tabId,
                name: tabName,
                url: `/app/recruiter/f-${tabId}`,
            },
            isSubTab: true,
        });
    }, [openSuperTabOnRowClick]);

    useEffect(() => {
        const unsub = emitter.emit("bindCallback", {
            id: PAGE_IDS.RECRUITER,
            callback: handleTabAddBtn,
        });
        return unsub;
    }, [handleTabAddBtn]);

    return (
        <div id="recruitment-console" className="console-wrapper">
            <div className="console-content">
                <Switch>
                    <Route path="/app/recruiter/f-home" exact>
                        <Home />
                    </Route>
                    <Route path="/app/recruiter/:id">
                        <Funnel />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

function Index() {
    return (
        <RecruitmentFunnelProvider>
            <RecruitmentFunnel />
        </RecruitmentFunnelProvider>
    );
}

export default withKeepAlive(Index, PAGE_IDS.RECRUITER);
