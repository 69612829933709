import { SUPPORT_EMAIL } from 'components/Constants';
import '../styles/Global.scss';

const Unauthorized = () => {
    return (
        <div className='unauthorized-wrapper'>
            <h2>Unauthorized access</h2>
            <p>Report accidental issues by emailing <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> for swift resolution.</p>
        </div>
    )
}

export default Unauthorized