import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SpeedSelect from 'react-speedselect';

// components
import * as Constants from 'components/Constants';
import RangePicker from 'components/ReactCalendar/RangePicker';
import ClickOutsideListner from 'components/ClickOutsideListner';
import CandidateProfileModal from './CandidateProfileModal';
import AddCandidateModal from './AddCandidateModal';
import CreateFunnel from '../CreateFunnel';
import Avatar from 'components/Avatar';
import NotAvailable from 'components/SuperTabs/NotAvailable';

// context
import { useTabContext } from 'components/SuperTabs/TabContext';

// hooks
import useScrollRestoration from 'hooks/useScrollRestoration';

// utils
import { useToggle } from 'utils/hooks';
import { debounce, getUser } from 'utils/Common';

// services
import { alertService } from 'services/alertService';
import APIService from "services/apiService";

// styles
import './styles.scss';

export default function RecruitmentFunnelDetails(props) {
    //Fetch and display Funnel data on initial render
    const { activeSubTabRef, openSubTab, modifyTab } = useTabContext();
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('emp_id');
    const abortControllerRef = useRef(new AbortController());
    const isFunnelFilterAvailable = !!props.funnelFilter;

    const departmentRef = useRef(props.departmentsList);
    const designationRef = useRef(props.designationsList);

    const backgroundColor = {
        A: '#986f0b', B: '#7a7574', C: '#986f0b', D: '#a4262c', E: '#8764b8', F: '#038759',
        G: '#881798', H: '#4a154a', I: '#8764b8', J: '#7a7574', K: '#881798', L: '#038759',
        M: '#8764b8', N: '#038759', O: '#a4262c', P: '#5a003a', Q: '#986f0b', R: '#ca5010',
        S: '#038759', T: '#038387', U: '#7a7574', V: '#c239b3', W: '#881798', X: '#8764b8',
        Y: '#986f0b', Z: '#7a7574'
    };

    useEffect(() => {
        if (!isFunnelFilterAvailable) {
            props.handleFunnelFilter(props.id, 'newTab');
        }
    }, [props, isFunnelFilterAvailable]);

    useEffect(() => {
        departmentRef.current = props.departmentsList;
    }, [props.departmentsList]);

    useEffect(() => {
        designationRef.current = props.designationsList;
    }, [props.designationsList]);

    useEffect(() => {
        async function fetchData() {
            searchQuery && props.handleFunnelFilter(props.id, 'searchQueryParam', searchQuery);
            // await getFunnelDetails(false, ['reset', '']);
            if (abortControllerRef.current.signal.aborted) {
                abortControllerRef.current = new AbortController();
            }
            await getFunnelDetails(false);
            await getStatus();
        }

        if (isFunnelFilterAvailable) fetchData();

        return () => {
            // setFunnelData(null);
            setCopyFunnelData(null);
            setUndoData({ backupData: {}, showUndoBtn: false, reorderApiPayLoad: {}, stagePayLoad: {} });
            setShowSettings(false);
            setFunnelSettingsPayload(null);
            setErrorLoadingFunnelData(false);
            setDisplayTimeStamp(false);
            setTimestamp(null);
            abortControllerRef.current.abort();

            // setSelectedDateTab('created_at');
            setFilterCreatedDateRange([]);
            setFilterUpdatedDateRange([]);
            // setPageSize(PAGE_SIZES[1]);
            // handleClearBtn();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.funnelInfo, isFunnelFilterAvailable]);

    useEffect(() => {
        if (searchQuery) {
            const { organization_id } = getUser();
            const orgIdFromURL = urlParams.get('org_id');
            if (organization_id !== +orgIdFromURL) {
                alertService.warning('You are not authorized to view this page');
                props.history.push('/app/recruiter');
            }
        } else {
            setFilterCreatedDateRange(props.funnelFilter?.created_at?.length ? [new Date(props.funnelFilter.created_at[0]), new Date(props.funnelFilter.created_at[1])] : [])
            setFilterUpdatedDateRange(props.funnelFilter?.updated_at?.length ? [new Date(props.funnelFilter.updated_at[0]), new Date(props.funnelFilter.updated_at[1])] : [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    const TIMEOUT = 120;
    // const PAGE_SIZE = 27;
    const PAGE_SIZE = Math.ceil(((window.innerHeight - 146) / 48) * 1.3);

    // State variables that holds data from API
    const [funnelNotAvailable, setFunnelNotAvailable] = useState(false);
    const [copyFunnelData, setCopyFunnelData] = useState(null);
    // console.log('copyFunnelData', copyFunnelData)
    const [errorLoadingFunnelData, setErrorLoadingFunnelData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [reportersList, setReportersList] = useState([]);
    const [funnelSettingsInfo, setFunnelSettingsInfo] = useState({
        id: `settings_${props.id}`,
        funnelId: props.id,
        name: '',
        description: '',
        department: null,
        designation: null,
        stages: [],
        shared: null,
        isFunnelCreated: true,
        step: props.tabInfo?.step ? props.tabInfo.step : 1, // used when settings / share button is clicked from home page
        mode: "edit",
        is_archive: 0,
        loadingInfo: true,
        loadingError: false,
        privileges: ''
    });
    const funnelSettingsInfoRef = useRef({});
    funnelSettingsInfoRef.current = funnelSettingsInfo;
    const [funnelSettingsInfoCopy, setFunnelSettingsInfoCopy] = useState(null);
    const [funnelSettingsPayload, setFunnelSettingsPayload] = useState(null);
    const [displayTimeStamp, setDisplayTimeStamp] = useState(false);
    const [timeStamp, setTimestamp] = useState(null);
    const [timestampTimeOutID, setTimestampTimeOutID] = useState(null);

    useEffect(() => {
        return () => {
            clearTimeout(timestampTimeOutID);
        }
    }, [props.id, timestampTimeOutID]);

    const [loadingResources, setLoadingResources] = useState({});
    const [loadingFunnelData, setLoadingFunnelData] = useState(false);
    const [displayLoadingAnim, setDisplayLoadingAnim] = useState(false);
    const observer = useRef({});

    const getStatus = async () => {
        APIService.apiRequest(Constants.API_BASE_URL + `/status?recruitment_funnel_id=${props.id}`, null, false, 'GET', abortControllerRef.current)
            .then(response => {
                if (response.status === 1) {
                    setStatusList(response.output);
                } else {
                    alertService.warning(response.msg);
                    console.log('getStatus - else response', response.msg);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('getStatus - error', err);
            });
    }

    const getReporter = async () => {
        APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/created_by?recruitment_funnel_id=${props.id}`, null, false, 'GET', abortControllerRef.current)
            .then(response => {
                if (response.status === 1) {
                    setReportersList(response.output.map(reporter => (
                        {
                            ...reporter,
                            full_name: `${reporter.first_name}${` ${reporter.last_name}`}`,
                            url: `<div class="rf-avatar-wrapper">
                                <span class="rf-avatar" style="background-color: ${backgroundColor[reporter.first_name[0].toUpperCase()]}">
                                    ${reporter.first_name[0]}${reporter.last_name ? reporter.last_name[0] : ''}
                                </span>
                                <img
                                    class="rf-image"
                                    onerror=this.style.display="none"
                                    src="${reporter.img_url}">
                                </img>
                            </div>`,
                        }
                    )));
                } else {
                    alertService.warning(response.msg);
                    console.log('getReporter - else response', response.msg);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('getReporter - error', err);
            });
    }

    const handleScroll = (e) => {
        if (observer.current[e.target.dataset.columns]) {
            let funnelDataCopy = copyFunnelData;
            const { scrollTop, scrollHeight, clientHeight } = observer.current[e.target.dataset.columns];
            const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === +e.target.dataset.columns);

            if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
                // alert(`reached bottom ${e.target.dataset.columns}`);
                // Note: page_number < num_pages and not page_number <= num_pages is because page_number is incremented in the API call
                const hasData = funnelDataCopy.resources[rootResourceIndex].page_number < funnelDataCopy.resources[rootResourceIndex].num_pages;
                !loadingResources[e.target.dataset.columns] && hasData && handleLoadResource(e.target.dataset.columns);
                // console.log(`reached bottom ${e.target.dataset.columns}`);
            }
        }
    }

    // State variable that holds individual profile information for side panel
    const [candidateDetails, setCandidateDetails] = useState(null);

    // Flag to show/hide panel
    const [showAddCandidateModal, toggleShowAddCandidateModal] = useToggle(false);
    const [showCandidateInfo, setShowCandidateInfo] = useState(false);
    const [showDatePicker, toggleShowDatePicker] = useToggle();
    const [showSettings, setShowSettings] = useState(props.tabInfo?.isSettingsOpen ? props.tabInfo.isSettingsOpen : false);

    // state variables for undo button
    const [undoTimer, setUndoTimer] = useState(TIMEOUT);
    const [intervalId, setIntervalId] = useState(0);
    const [timeoutId, setTimeoutId] = useState(0);
    const [undoData, setUndoData] = useState({
        showUndoBtn: false,
        backupData: {},
        disabled: false,
    });

    useEffect(() => {
        return () => {
            // Clear interval and timeout on unmount
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        }
    }, [props.id, intervalId, timeoutId]);


    // Filter state variables
    // const [activeStatus, setactiveStatus] = useState([]);
    // const [activeReporter, setActiveReporter] = useState([]);
    // const [homeSearchString, setHomeSearchString] = useState('');
    // const [homeSearchString, setHomeSearchString] = useState(props.funnelFilter.searchString);
    // const [selectedDateTab, setSelectedDateTab] = useState('created_at');
    const [filterCreatedDateRange, setFilterCreatedDateRange] = useState([]);
    const [filterUpdatedDateRange, setFilterUpdatedDateRange] = useState([]);

    const getResourceInfo = (stageIndex, resId) => {
        let funnelDataCopy = copyFunnelData
        const stageId = funnelDataCopy.stages[stageIndex].id;
        const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === stageId);
        const resourceIndex = funnelDataCopy.resources[rootResourceIndex].resources.findIndex(res => res.id === +resId);
        return funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex];
    }

    const handleOnDragStart = (result) => {
        const status = getResourceInfo(result.source.droppableId, result.draggableId).status;

        if ((copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive === 1)) {
            alertService.warning('Cannot move candidate as this funnel is Archived');
            return;
        }

        if (['Employee', 'Rejected', 'BlackListed'].includes(status)) {
            alertService.warning(`Candidate is already ${status} and cannot be moved`);
            return;
        }

        // Add column highlight to indicate valid droppable area
        const column = document.querySelectorAll('.column');
        if (column.length === 1 || +result.source.droppableId === column.length - 1) {
            column[+result.source.droppableId].classList.add('droppable-zone');
        } else {
            column[result.source.droppableId].classList.add('droppable-zone');
            column[(+result.source.droppableId) + 1].classList.add('droppable-zone');
        }
    }

    // Updates candidate's current stage after drag and drop
    const handleOnDragEnd = (result, isUndo = false) => {
        // Remove column highlight after dropping the element
        const column = document.querySelectorAll('.column');
        if (column.length === 1 || +result.source.droppableId === column.length - 1) {
            column[+result.source.droppableId].classList.remove('droppable-zone');
        } else {
            column[result.source.droppableId].classList.remove('droppable-zone');
            column[(+result.source.droppableId) + 1].classList.remove('droppable-zone');
        }

        if (!result.destination) return;
        if (result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index) return;

        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        if (funnelDataCopy.recruitment_funnel[0].is_archive === 1) return;
        const status = getResourceInfo(result.source.droppableId, result.draggableId).status;
        if (['Employee', 'Rejected', 'BlackListed'].includes(status)) {
            return;
        }

        const { source, destination } = result;

        const srcStage = funnelDataCopy.stages[source.droppableId];
        const destStage = funnelDataCopy.stages[destination.droppableId];
        const isDestQuestionnaire = destStage.is_questionnaire === 1;

        const { id: srcStageId, order: srcStageOrder } = srcStage;
        const { id: destStageId, order: destStageOrder } = destStage;

        const srcItems = [...funnelDataCopy.resources.filter(i => i.recruitment_funnel_stage_id === srcStageId)][0].resources;
        const destItems = [...funnelDataCopy.resources.filter(i => i.recruitment_funnel_stage_id === destStageId)][0].resources;

        const srcResIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === srcStageId);
        const destResIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === destStageId);

        if (!isUndo && srcStageOrder > destStageOrder) {
            alertService.warning('A resource cannot be moved back to the previous stage');
            return;
        } else if (!isUndo && srcStageOrder !== destStageOrder && srcStageOrder + 1 !== destStageOrder) {
            alertService.warning('A resource can only be moved one stage ahead at a time');
            return;
        }

        const stageId = destStageId;
        const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === stageId);
        const resource = srcItems.find(res => res.id === +result.draggableId);
        if (isDestQuestionnaire && !resource?.email) {
            alertService.warning('Candidate email is required');
            handleProfileClick(resource, srcStage);
            return;
        }

        // For Undo operation
        // Backup Payload
        if (!isUndo) {
            const bkpStageId = srcStageId;
            const bkpRootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === bkpStageId);
            const bkpResourceIndex = funnelDataCopy.resources[bkpRootResourceIndex].resources.findIndex(res => res.id === +result.draggableId);

            const bkp_rank_after_resource = bkpResourceIndex === 0 ? null : funnelDataCopy.resources[bkpRootResourceIndex].resources[bkpResourceIndex - 1].id;
            const bkp_rank_before_resource = funnelDataCopy.resources[bkpRootResourceIndex].resources.length === bkpResourceIndex + 1 ? null :
                funnelDataCopy.resources[bkpRootResourceIndex].resources[bkpResourceIndex + 1].id;

            const backup = { ...result, destination: { ...result.source }, source: { ...result.destination } };
            const bkpReorderPayload = {
                "resource_id": +result.draggableId,
                "rank_before_resource": bkp_rank_before_resource,
                "rank_after_resource": bkp_rank_after_resource,
                "recruitment_funnel_stage_id": funnelDataCopy.stages[result.source.droppableId].id,
            };

            let bkpStagePayLoad = {
                "resource_id": +result.draggableId,
                "recruitment_funnel_stage_id": funnelDataCopy.stages[result.source.droppableId].id
            }
            setUndoData({ backupData: { ...backup }, showUndoBtn: true, reorderApiPayLoad: { ...bkpReorderPayload }, stagePayLoad: { ...bkpStagePayLoad }, disabled: isDestQuestionnaire });
        }

        let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
        let obj = {};

        isUndo && setUndoData({ backupData: {}, showUndoBtn: false, reorderApiPayLoad: {}, stagePayLoad: {}, disabled: false });

        // If the element's drag column and drop column are same reorder the array
        // Else Remove element from the source array and add the element in destination array
        if (source.droppableId === destination.droppableId) {

            const items = [...funnelDataCopy.resources.filter(i => i.recruitment_funnel_stage_id === stageId)][0].resources;

            const [reOrderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reOrderedItem);

            funnelDataCopy.resources[rootResourceIndex].resources = [...items];

            setCopyFunnelData(funnelDataCopy);

            // Update local storage data
            let updatedSessionObj;
            obj[props.id] = funnelDataCopy;
            updatedSessionObj = sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...obj } : f);

            sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));
            !isUndo && startTimer();

            const resourceIndex = funnelDataCopy.resources[rootResourceIndex].resources.findIndex(res => res.id === +result.draggableId);
            const rank_before_resource = funnelDataCopy.resources[rootResourceIndex].resources.length === resourceIndex + 1 ? null :
                funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex + 1].id;
            const rank_after_resource = resourceIndex === 0 ? null : funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex - 1].id;

            let apiPayLoad = !isUndo ? {
                "resource_id": +result.draggableId,
                "rank_before_resource": rank_before_resource,
                "rank_after_resource": rank_after_resource,
                "recruitment_funnel_stage_id": funnelDataCopy.stages[result.destination.droppableId].id,
            } : { ...undoData.reorderApiPayLoad }

            handleRankChange(apiPayLoad);

        } else {

            const [removed] = srcItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            funnelDataCopy.resources[srcResIndex].resources = [...srcItems];
            funnelDataCopy.resources[destResIndex].resources = [...destItems];

            funnelDataCopy.resources[srcResIndex].total_results = funnelDataCopy.resources[srcResIndex].total_results - 1;
            funnelDataCopy.resources[destResIndex].total_results = funnelDataCopy.resources[destResIndex].total_results + 1;

            setCopyFunnelData(funnelDataCopy);

            // Update local storage data
            let updatedSessionObj;
            obj[props.id] = funnelDataCopy;
            updatedSessionObj = sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...obj } : f);
            sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));

            !isUndo && startTimer();

            const resourceIndex = funnelDataCopy.resources[rootResourceIndex].resources.findIndex(res => res.id === +result.draggableId);

            const rank_before_resource = funnelDataCopy.resources[rootResourceIndex].resources.length === resourceIndex + 1 ? null :
                funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex + 1].id;
            const rank_after_resource = resourceIndex === 0 ? null : funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex - 1].id;

            let stagePayLoad = !isUndo ? {
                "resource_id": +result.draggableId,
                "recruitment_funnel_stage_id": funnelDataCopy.stages[result.destination.droppableId].id
            } : { ...undoData.stagePayLoad, undo: 1 }

            let rankPayLoad = !isUndo ? {
                "resource_id": +result.draggableId,
                "rank_before_resource": rank_before_resource,
                "rank_after_resource": rank_after_resource,
                "recruitment_funnel_stage_id": funnelDataCopy.stages[result.destination.droppableId].id,
            } : { ...undoData.reorderApiPayLoad }

            handleStageChange(stagePayLoad, rankPayLoad);
        }

    }

    const startTimer = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(0);
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(0);
        }
        setUndoTimer(TIMEOUT);

        let interval = setInterval(() => {
            setUndoTimer(prevCount => prevCount - 1);
        }, 1000);
        setIntervalId(interval);

        let timeout = setTimeout(() => {
            clearInterval(interval);
            setUndoData({ backupData: {}, showUndoBtn: false });
        }, TIMEOUT * 1000);
        setTimeoutId(timeout);
    }


    const handleUndo = () => {
        setUndoData({ backupData: {}, showUndoBtn: false });
        clearTimeout(timeoutId);
        clearInterval(intervalId);
        handleOnDragEnd(undoData.backupData, true);
        // alertService.success('Undo operation was successful');
    }

    // Helper functions

    const formatTime = useCallback((undoTimer) => {
        let seconds = undoTimer % 60;
        let mins = Math.trunc(undoTimer / 60);

        seconds = seconds < 10 ? `0${seconds}` : seconds;
        mins = mins < 10 ? `0${mins}` : mins;

        return `${mins}:${seconds}`
    }, [])

    const formatDate = useCallback((date) => {
        let d = new Date(date);
        let dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        let timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
        return `${d.toLocaleDateString('en-us', dateOptions)}, ${d.toLocaleTimeString('en-us', timeOptions)}`;
    }, [])

    const formatDateToISOString = useCallback((_date) => {
        const date = new Date(_date);
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0];
    }, [])

    const formatFilterDate = useCallback((dates) => {
        let d1 = new Date(dates[0]);
        let d2 = new Date(dates[1]);
        return `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()} - ${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`
    }, [])

    const formatText = useCallback((text) => {
        let newText = text.replace('_at', '');
        return newText.charAt(0).toUpperCase() + newText.slice(1);
    }, [])

    //This function is only responsible for managing stage change
    const handleStageChange = async (stagePayLoad, rankPayLoad) => {
        try {
            const response = await APIService.apiRequest(Constants.API_BASE_URL + '/recruiter/resource_stage', stagePayLoad, false, 'PUT')

            if (response.status === 1) {
                alertService.success(`Success: ${response.msg}`);
                // !rankPayLoad && getFunnelDetails(true);
                handleRankChange(rankPayLoad);
            } else {
                alertService.warning(response.msg);
                getFunnelDetails(true);
                console.log('handleStageChange else', response);
            }
        } catch (error) {
            alertService.error('Error: ' + error.msg);
            console.log('handleStageChange error', error);
        }
    }

    const handleRankChange = (rankPayLoad) => {
        console.log('inside handleRankChange - rankPayLoad', rankPayLoad);
        // PUT https://dev-api.thinkteam.net/data_stream/recruiter/resource_stage_rank
        // {"resource_id":207, "rank_before_resource":230, "rank_after_resource": 241}

        APIService.apiRequest(Constants.API_BASE_URL + '/recruiter/resource_stage_rank', rankPayLoad, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    // getFunnelDetails();
                    alertService.success(`${response.msg}`);
                    console.log('handleRankChange if', response);
                } else {
                    alertService.warning(response.msg);
                    console.log('handleRankChange else', response);
                }
            })
            .catch(err => {
                alertService.error('Error ' + err.msg);
                console.log('handleRankChange error', err.msg);
            });
    }

    const getStagesContent = (funnelDataObj) => {
        return <DragDropContext
            onDragStart={(res) => handleOnDragStart(res)}
            onDragEnd={(res) => handleOnDragEnd(res, false)}
        >
            {funnelDataObj.stages.map((stage, index) => {
                // let selectedDateRange = filterDateRange && filterDateRange.length > 0 ? [new Date(filterDateRange[0]), new Date(filterDateRange[1])] : [];
                let selectedDateRange = props.funnelFilter.selectedDateTab === 'created_at' ?
                    filterCreatedDateRange && filterCreatedDateRange.length > 0 ? [new Date(filterCreatedDateRange[0]), new Date(filterCreatedDateRange[1])] : [] :
                    filterUpdatedDateRange && filterUpdatedDateRange.length > 0 ? [new Date(filterUpdatedDateRange[0]), new Date(filterUpdatedDateRange[1])] : [];

                if (selectedDateRange.length > 0) {
                    let [d1, d2] = [...selectedDateRange];
                    d2.setHours(23, 59, 59, 999);
                    selectedDateRange = [new Date(d1), new Date(d2)]
                }

                return (
                    <div className='column' key={stage.id}>
                        <div className='column-header'>
                            <span className='column-title'>{stage.name}</span>
                            <span className='card-count'>
                                {funnelDataObj?.resources?.filter(res => {
                                    return stage.id === res.recruitment_funnel_stage_id;
                                })[0]?.total_results}
                            </span>
                        </div>
                        <Droppable droppableId={String(index)}>
                            {(provided) => (
                                <div className='card' {...provided.droppableProps} ref={provided.innerRef}>
                                    <InnerCard
                                        id={props.id}
                                        observer={observer}
                                        funnelDataObj={funnelDataObj}
                                        handleScroll={handleScroll}
                                        stage={stage}
                                        handleProfileClick={handleProfileClick}
                                        loadingResources={loadingResources}
                                        provided={provided}
                                    />
                                </div>
                            )}
                        </Droppable>
                    </div>
                );
            })
            }
        </DragDropContext>
    }

    // MAIN: Candidates data displayed in column view (swimlanes) by categorizing them based on stage type
    // Stages are ordered and the order numbers are provided in API
    let stageInfo = copyFunnelData && copyFunnelData.recruitment_funnel[0].id === +window.location.pathname.replace('/app/recruiter/f-', '') ?
        copyFunnelData.stages.length ?
            getStagesContent(copyFunnelData) :
            <p className='general-info'>No data to display</p> :
        <p className='general-info'>Loading...</p>;


    const handleProfileClick = (resInfo, stageInfo, responseData = '') => {
        setCandidateDetails({ ...resInfo, status: resInfo.status ? resInfo.status : 'Null', stage_Name: stageInfo.name, stage_Id: stageInfo.id, tab: 'details', stage: stageInfo });
        const { organization_id } = getUser();
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('org_id', organization_id);
        newUrl.searchParams.set('emp_id', resInfo.id);
        props.history.push(newUrl.pathname + newUrl.search);
        setShowCandidateInfo(true);
    }

    const closeDetailsPanel = () => {
        setShowCandidateInfo(false);
        props.history.push(`/app/recruiter/f-${props.id}`);
    }

    // Fetches candidate information using API
    const getFunnelDetails = async (exception, filterValue = ['', '']) => {
        errorLoadingFunnelData && setErrorLoadingFunnelData(false);
        if (filterValue[0] === 'timeStamp') setDisplayLoadingAnim(true);
        const apiPayLoad = null;

        let id = props.id ? props.id : window.location.pathname.replace('/reruiter/f-', '');

        let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
        let funnelData = sessionObj?.filter(f => f.hasOwnProperty(props.id));

        if (sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && funnelData.length !== 0) {
            // setFunnelData(funnelData[0][props.id]);
            setCopyFunnelData(funnelData[0][props.id]);
            setLoadingFunnelData(false);
        } else {
            !copyFunnelData && setLoadingFunnelData(true);
        }

        const url = new URL(Constants.API_BASE_URL + `/recruiter/funnel_details/${id}`)

        // GET https://dev-api.thinkteam.net/data_stream/recruiter/funnel_details/<recruitment_funnel_id>

        APIService.apiRequest(url.toString(), apiPayLoad, false, 'GET', abortControllerRef.current)
            .then(response => {
                if (response.status === 1) {
                    let urlID = window.location.pathname.replace('/app/recruiter/f-', '');
                    errorLoadingFunnelData && setErrorLoadingFunnelData(false);
                    if (filterValue[0] === 'timeStamp') {
                        setDisplayLoadingAnim(false);
                        if (timestampTimeOutID) clearTimeout(timestampTimeOutID); setDisplayTimeStamp(false);
                        setTimestamp(new Date());
                        let timeout = setTimeout(() => {
                            setDisplayTimeStamp(true);
                        }, TIMEOUT * 1000);
                        setTimestampTimeOutID(timeout);
                    }
                    // Update tab name if it is incorrect (used while sharing a URL if the funnel is not opened)
                    // if (props.tabInfo.name !== response.recruitment_funnel[0].name) {
                    //     props.setTabsInfo(tabsInfo => (
                    //         {
                    //             ...tabsInfo,
                    //             tabs: tabsInfo.tabs.map(tab => tab.id === String(response.recruitment_funnel[0].id) ? { ...tab, name: response.recruitment_funnel[0].name } : tab)
                    //         }
                    //     ));
                    // }
                    // TODO: updateTabName

                    let sortedFunnelObj = JSON.parse(JSON.stringify(response));
                    sortedFunnelObj.stages.sort((a, b) => a.order - b.order);

                    getSharedInfo(sortedFunnelObj);
                    getReporter();

                    response.stages.forEach(stage => {
                        getStageDetails({ stage_id: stage.id, stagesInfo: sortedFunnelObj.stages, refresh: true });
                    });

                    let obj = {};
                    obj[props.id] = sortedFunnelObj;

                    if (!sessionStorage[Constants.SITE_PREFIX + 'funnel_data']) {
                        // Create new entry if no item available in local storage
                        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify([obj]));
                        let urlID = window.location.pathname.replace('/app/recruiter/f-', '');

                        // Check if the response funnel id and active tab's funnel id are same and set the state var to avoid flickering issue
                        if (response.recruitment_funnel[0].id === +urlID) {
                            setCopyFunnelData(prev => ({ ...prev, ...sortedFunnelObj }));
                        }
                    } else if (funnelData && funnelData.length === 0) {
                        // Push new entry to the existing item if funnel info is not available in the local storage item
                        sessionObj.push(obj);
                        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(sessionObj));

                        // Check if the response funnel id and active tab's funnel id are same and set the state var to avoid flickering issue
                        if (response.recruitment_funnel[0].id === +urlID) {
                            setCopyFunnelData(prev => ({ ...prev, ...sortedFunnelObj }));
                        }
                    } else {

                        // Check if the response funnel id and active tab's funnel id are same and set the state var to avoid flickering issue
                        if (response.recruitment_funnel[0].id === +urlID) {
                            setCopyFunnelData(prev => ({ ...prev, ...sortedFunnelObj }));
                        }

                        // Modify the funnel data stored in local storage
                        sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
                        obj[props.id] = sortedFunnelObj;
                        const updatedSessionObj = sessionObj ?
                            sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...obj } : f) :
                            // sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify([obj]));
                            [obj];
                        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));

                        // Exception for reloading incase we want to display newly added user to the funnel - old comment
                    }

                    if (response.recruitment_funnel[0].name === 'Loading...') {
                        const tab = {
                            id: props.id,
                            name: response.recruitment_funnel[0].name,
                            url: `/app/recruiter/f-${props.id}`
                        };
                        openSubTab({ tab, isSubTab: true });
                    } else if (response.recruitment_funnel[0].name !== activeSubTabRef.current.name) {
                        const tab = {
                            id: props.id,
                            name: response.recruitment_funnel[0].name,
                            url: `/app/recruiter/f-${props.id}`
                        };
                        modifyTab({ oldTab: activeSubTabRef.current, newTab: tab, isSubTab: true });
                    }

                    setLoadingFunnelData(false);
                } else {
                    if (!response?.message?.includes('aborted')) {
                        !errorLoadingFunnelData && setErrorLoadingFunnelData(true);
                    }
                    setLoadingFunnelData(false);
                    if (response.msg === "User does not have access to this funnel") {
                        setFunnelNotAvailable(true);
                        let sessionArray = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'];
                        if (sessionArray) sessionArray = JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
                        let funnelIndex = sessionArray?.findIndex(f => f.hasOwnProperty(props.id));
                        if (funnelIndex > -1) {
                            sessionArray.splice(funnelIndex, 1);
                            sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(sessionArray));
                        }
                    }
                    if (filterValue[0] === 'timeStamp') setDisplayLoadingAnim(false);
                    alertService.warning(response.msg);
                    console.log('getFunnelDetails else', response);
                }
            })
            .catch(err => {
                // setErrorLoadingFunnelData(true);
                if (filterValue[0] === 'timeStamp') setDisplayLoadingAnim(false);
                setLoadingFunnelData(false);
                console.log('getFunnelDetails error', err);
            });
    }

    const fetchStageDetails = (param) => {
        copyFunnelData.stages.forEach((stage, i, data) => {
            getStageDetails({ stage_id: stage.id, stagesInfo: data, refresh: true, ...param });
        });
    }

    const getStageDetails = async ({ stage_id, stagesInfo, clearFilter = false, refresh = false, search_string, status, reporter, created_at, updated_at } = {}) => {
        if (!stage_id) return;
        try {
            const url = new URL(Constants.API_BASE_URL + `/recruiter/stage_details/${stage_id}?page_size=${PAGE_SIZE}`)
            if (!clearFilter) {
                const candidateSearch = searchQuery || search_string || props.funnelFilter?.searchString;
                if (candidateSearch && search_string !== 'clear_search') {
                    url.searchParams.set('candidate_name', candidateSearch);
                }

                const statusIds = (status || props.funnelFilter?.status)?.map(s => s.id)?.join(',');
                if (statusIds) {
                    url.searchParams.set('status_id', statusIds);
                }

                const reporterIds = (reporter || props.funnelFilter?.reporter)?.map(r => r.id)?.join(',');
                if (reporterIds) {
                    url.searchParams.set('reporter_id', reporterIds);
                }

                const skipDateFilter = searchQuery && !copyFunnelData;
                const createdDateFilter = created_at || props.funnelFilter?.created_at;
                if (props.funnelFilter?.selectedDateTab === 'created_at' && createdDateFilter.length && !skipDateFilter) {
                    const startDate = formatDateToISOString(createdDateFilter[0]);
                    const endDate = formatDateToISOString(createdDateFilter[1]);
                    url.searchParams.set('created_at', startDate + ':' + endDate);
                }

                const updatedDateFilter = updated_at || props.funnelFilter?.updated_at;
                if (props.funnelFilter?.selectedDateTab === 'updated_at' && updatedDateFilter.length && !skipDateFilter) {
                    const startDate = formatDateToISOString(updatedDateFilter[0]);
                    const endDate = formatDateToISOString(updatedDateFilter[1]);
                    url.searchParams.set('updated_at', startDate + ':' + endDate);
                }
            }

            const response = await APIService.apiRequest(url.toString(), null, false, 'GET', abortControllerRef.current)
            if (response.status === 1) {

                const { resources, total_results, num_pages, page_number, page_size } = response.resources;
                let newResources = null;
                setCopyFunnelData(prevState => {
                    let prevResources = prevState?.resources ?? [];
                    if (refresh) {
                        prevResources = prevResources.filter(res => res.recruitment_funnel_stage_id !== stage_id);
                    }
                    prevResources.push({
                        recruitment_funnel_stage_id: stage_id, resources, total_results, num_pages, page_number, page_size
                    });
                    newResources = prevResources;
                    return { ...prevState, resources: prevResources }
                });

                let stages = stagesInfo
                    .map(s => ({
                        name: s.name, description: s.description, id: s.id, order: s.order, countResources: total_results, isQuestionnaire: s.is_questionnaire || false,
                    }));

                setFunnelSettingsInfo(prev => ({ ...prev, stages }));
                setFunnelSettingsInfoCopy(prev => ({ ...prev, stages }));

                if (searchQuery && response.resources.resources.length > 0) {
                    const resourceInfo = response.resources.resources[0];
                    const stageInfo = stages.filter(s => s.id === stage_id)[0];
                    handleProfileClick(resourceInfo, stageInfo, response);
                }

                let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
                let funnelData = sessionObj?.filter(f => f.hasOwnProperty(props.id))[0];
                funnelData[props.id].resources = newResources.map(s => ({
                    ...s, resources: s.resources.map(r => {
                        let last_changed_at = new Date(r.last_changed_at?.replace(/-/g, '/'));
                        last_changed_at.setMinutes(last_changed_at.getMinutes() - last_changed_at.getTimezoneOffset());
                        return ({ ...r, last_changed_at: last_changed_at })
                    })
                }));
                const updatedSessionObj = sessionObj ?
                    sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...funnelData } : f) :
                    // sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify([obj]));
                    [funnelData];
                sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));

            } else {
                alertService.warning(response.msg);
                console.log('getStageDetails else', response);
            }
        } catch (error) {
            console.log('getStageDetails error', error);
        }
    }

    const handleClearBtn = () => {
        // setactiveStatus([]);
        // setActiveReporter([]);
        // setHomeSearchString('');
        setFilterCreatedDateRange([]);
        setFilterUpdatedDateRange([]);
        // setSelectedDateTab('created_at');
        // setPageSize(PAGE_SIZES[1]);
        // if (filteredFunnelData) {
        //     clearInterval(intervalId);
        //     clearTimeout(timeoutId);
        //     setUndoData({ backupData: {}, showUndoBtn: false, reorderApiPayLoad: {}, stagePayLoad: {} });
        // }
        // if (filteredFunnelData) {
        //     getFunnelDetails(false, ['reset', PAGE_SIZE]);
        // }
        // props.history.push(`/recruiter/f-${props.id}`);
        fetchStageDetails({ clearFilter: true });
        props.handleFunnelFilter(props.id, 'reset', null)
        searchInputRef.current.value = '';
        removeSessionStorageData();
    }

    const removeSessionStorageData = () => {
        let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
        let funnelData = sessionObj?.filter(f => !f.hasOwnProperty(props.id + 'f'));
        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(funnelData));
    }

    const handleLoadResource = (stageId, pageNumber, resources = []) => {
        let loadingResourcesCopy = {};
        if (!resources.length) loadingResourcesCopy[stageId] = true;
        !resources.length && setLoadingResources(loadingResourcesCopy);
        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === +stageId);
        const loadPageNumber = pageNumber ? pageNumber : funnelDataCopy.resources[rootResourceIndex].page_number + 1;
        let url = Constants.API_BASE_URL + `/recruiter/stage_details/${stageId}?page_size=${PAGE_SIZE}&page_number=${loadPageNumber}`
            + (props.funnelFilter.searchString ? `&candidate_name=${props.funnelFilter.searchString}` : '')
            + (props.funnelFilter.status.length ? `&status_id=${props.funnelFilter.status.map(s => s.id).join(',')}` : '')
            + (props.funnelFilter.reporter.length ? `&reporter_id=${props.funnelFilter.reporter.map(r => r.id).join(',')}` : '')
            + (props.funnelFilter.selectedDateTab === 'created_at' && filterCreatedDateRange.length ?
                `&created_at=${formatDateToISOString(filterCreatedDateRange[0])}:${formatDateToISOString(filterCreatedDateRange[1])}` : '')
            + (props.funnelFilter.selectedDateTab === 'updated_at' && filterUpdatedDateRange.length ?
                `&updated_at=${formatDateToISOString(filterUpdatedDateRange[0])}:${formatDateToISOString(filterUpdatedDateRange[1])}` : '');

        APIService.apiRequest(url, null, false, 'GET', abortControllerRef.current)
            .then(response => {
                if (response.status === 1) {
                    const { resources: newResources, total_results, num_pages, page_number, page_size } = response.resources;

                    if (!resources.length) {
                        loadingResourcesCopy[stageId] = false;
                        setLoadingResources(prevValue => loadingResourcesCopy);
                    } else {
                        resources.splice(+PAGE_SIZE * (pageNumber - 1), +PAGE_SIZE, ...response.resources[0].resources)
                    }

                    // resources.length ?
                    //     funnelDataCopy.resources[rootResourceIndex] = { ...response.resources[0], resources: [...resources] } :
                    //     funnelDataCopy.resources[rootResourceIndex] = { ...response.resources[0], resources: [...funnelDataCopy.resources[rootResourceIndex].resources, ...response.resources[0].resources] };

                    const rootResource = funnelDataCopy.resources[rootResourceIndex];
                    const oldResources = resources.length ? resources : rootResource.resources;
                    funnelDataCopy.resources[rootResourceIndex] = {
                        ...rootResource,
                        resources: [...oldResources, ...newResources],
                        total_results,
                        num_pages,
                        page_number,
                        page_size,
                    };

                    let obj = {};
                    setCopyFunnelData(funnelDataCopy);
                    obj[props.id] = funnelDataCopy;
                    sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify([obj]));
                } else {
                    loadingResourcesCopy[stageId] = false;
                    setLoadingResources(loadingResourcesCopy);
                    alertService.warning(response.msg);
                    console.log('handleLoadResource else', response);
                }
            })
            .catch(err => {
                if (!resources.length) loadingResourcesCopy[stageId] = false;
                !resources.length && setLoadingResources(loadingResourcesCopy);
                alertService.error('Error: ' + err.msg);
                console.log('handleLoadResource error', err);
            });
    }

    const onAddNewCandidate = (resource) => {
        const last_changed_at = new Date(resource.last_changed_at.replace(/-/g, '/'))
        const newResource = {
            ...resource,
            last_changed_at: last_changed_at.setMinutes(last_changed_at.getMinutes() - last_changed_at.getTimezoneOffset())
        }

        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        let stageId = funnelDataCopy.stages.filter(stage => stage.order === 1)[0].id;
        let rootResIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === stageId);
        let resources = funnelDataCopy.resources[rootResIndex];
        let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
        let updatedSessionObj = {};
        let obj = {};
        if (resources.num_pages === 0 || resources.num_pages === resources.page_number) {
            // console.log('pageSize', pageSize, resources.total_results, (resources.total_results % +pageSize.name) !== 0)
            if (resources.total_results === 0 || (resources.total_results % +PAGE_SIZE) !== 0) {
                resources.resources = [...resources.resources, newResource];
            } else {
                resources.num_pages = resources.num_pages + 1;
            }
        }
        resources.total_results = resources.total_results + 1;
        // setFunnelData(funnelDataCopy);
        setCopyFunnelData(funnelDataCopy);
        obj[props.id] = funnelDataCopy;
        updatedSessionObj = sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...obj } : f);
        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));
    }

    const onRemoved = (candidate) => {
        const stageId = candidate.stage.id
        const candidateId = candidate.id

        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        let resources = [...funnelDataCopy.resources.filter(i => i.recruitment_funnel_stage_id === stageId)][0].resources;

        const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === stageId);
        const resourceIndex = funnelDataCopy.resources[rootResourceIndex].resources.findIndex(res => res.id === +candidateId);
        let rootResources = funnelDataCopy.resources[rootResourceIndex];

        resources.splice(resourceIndex, 1);
        funnelDataCopy.resources[rootResourceIndex].resources = [...resources];
        funnelDataCopy.resources[rootResourceIndex].total_results = funnelDataCopy.resources[rootResourceIndex].total_results - 1;

        if ((rootResources.total_results % +PAGE_SIZE) === 0) {
            rootResources.page_number = rootResources.page_number - 1;
            rootResources.num_pages = rootResources.num_pages - 1;
        }

        setShowCandidateInfo(false);
        setCandidateDetails(null);

        setCopyFunnelData(funnelDataCopy);

        // Update local storage
        let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
        let obj = {};
        obj[props.id] = funnelDataCopy;
        const updatedSessionObj = sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...obj } : f);
        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));

        rootResources.total_results % +PAGE_SIZE !== 0 && handleLoadResource(candidate.stage.id, rootResources.page_number, resources)
    }

    const onHiredOrRejected = ({ candidate, statusId }) => {
        const resourceId = candidate.id
        const stageId = candidate.stage.id

        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === stageId);
        const resourceIndex = funnelDataCopy.resources[rootResourceIndex].resources.findIndex(res => res.id === +resourceId);
        if (resourceIndex >= 0) {
            funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex].status = statusId === 4 ? 'Employee' : 'Rejected';
            setCopyFunnelData(funnelDataCopy);
            updateSessionStorage(funnelDataCopy);
        }

        setCandidateDetails({ ...candidateDetails, status: statusId === 4 ? 'Employee' : 'Rejected' })

    }

    // Modify Funnel Methods
    const onCandidateEmailUpdate = (candidate, email) => {
        const resourceId = candidate.id
        const stageId = candidate.stage.id

        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        const rootResourceIndex = funnelDataCopy.resources.findIndex(res => res.recruitment_funnel_stage_id === stageId);
        const resourceIndex = funnelDataCopy.resources[rootResourceIndex].resources.findIndex(res => res.id === +resourceId);
        if (resourceIndex >= 0) {
            funnelDataCopy.resources[rootResourceIndex].resources[resourceIndex].email = email;
            setCopyFunnelData(funnelDataCopy);
            updateSessionStorage(funnelDataCopy);
        }

        setCandidateDetails({ ...candidateDetails, email })
    }


    const handleCloseBtn = () => {
        setShowSettings(false);
        setFunnelSettingsPayload(null);
        setFunnelSettingsInfo({ ...funnelSettingsInfoCopy, shared: funnelSettingsInfo.shared });
    }

    const handleBackBtn = (id, step, isFunnelCreated) => {
        if (step === 3 && !funnelSettingsInfo.privileges.includes('EDIT')) {
            alertService.warning('You are not authorized for editing this funnel');
            return;
        }
        let funnelSettingsInfoCopy = JSON.parse(JSON.stringify(funnelSettingsInfo));
        if (step >= 2) {
            funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, step: --step }
            setFunnelSettingsInfo(funnelSettingsInfoCopy)
        }
    }

    const handleSaveBtn = (id, step) => {
        if (step <= 0 || step > 3) return;
        // console.log('handleSaveBtn', id, step);
        // Allow navigating to share tab but cannot add/modify any provilege
        // if (step === 2 && !funnelSettingsInfo.privileges.includes('SHARE')) {
        //     alertService.warning('You are not authorized for sharing this funnel');
        //     return;
        // }

        let funnelSettingsInfoCopy = JSON.parse(JSON.stringify(funnelSettingsInfo));
        funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, step: ++step }

        setFunnelSettingsInfo(funnelSettingsInfoCopy);
    }

    const handleFunnelData = (id, key, value, stageIndex) => {
        let funnelSettingsInfoCopy = JSON.parse(JSON.stringify(funnelSettingsInfo));
        let isFunnelCreated = funnelSettingsInfoCopy.isFunnelCreated;
        if (key === 'navToShare' && !isFunnelCreated) return;

        funnelSettingsInfoCopy.stages = funnelSettingsInfoCopy.stages.map(stage => ({ ...stage, newStage: false }));
        let stages = funnelSettingsInfoCopy.stages;
        let stage = funnelSettingsInfoCopy.stages[stageIndex];

        switch (key) {
            case 'name':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, name: value }
                break;
            case 'desc':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, description: value }
                break;
            case 'dept':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, department: value }
                break;
            case 'desig':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, designation: value }
                break;
            case 'stageName':
                stage.name = value;
                break;
            case 'stageDesc':
                stage.description = value;
                break;
            case 'isQuestionnaire':
                stage.isQuestionnaire = value;
                break;
            case 'addStage':
                funnelSettingsInfoCopy.stages.push({ name: '', description: '', id: `add_stage_${stages.length + 1}`, newStage: true });
                break;
            case 'deleteStage':
                stages.splice(stageIndex, 1);
                let payloadCopy = JSON.parse(JSON.stringify(funnelSettingsPayload));
                payloadCopy = payloadCopy?.stages ? payloadCopy?.stages.some(s => s.id === stage.id) ? { ...payloadCopy, stages: [...payloadCopy.stages.filter(s => s.id !== stage.id)] } : payloadCopy : payloadCopy;
                console.log('payloadCopy', payloadCopy)
                payloadCopy = {
                    ...payloadCopy, stages: payloadCopy?.stages && payloadCopy?.stages ? !String(stage.id).includes('add') ? [...payloadCopy.stages, {
                        id: stage.id,
                        action: "delete"
                    }] : [...payloadCopy.stages] :
                        [{
                            id: stage.id,
                            action: "delete"
                        }]
                }
                setFunnelSettingsPayload(payloadCopy);
                !String(stage.id).includes('add') && handleModifyFunnel('deleteStage', stage.id);
                break;
            case 'navToFunInfo':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, step: 1 }
                break;
            case 'navToDefStages':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, step: 2 }
                break;
            case 'navToShare':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, step: 3 };
                break;
            case 'navToAdvanced':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, step: 4 };
                break;
            case 'updateShare':
                funnelSettingsInfoCopy = { ...funnelSettingsInfoCopy, shared: value }
                break;
            default:
                console.log('Default case triggered')
                break;
        }

        setFunnelSettingsInfo(JSON.parse(JSON.stringify(funnelSettingsInfoCopy)));
        key !== 'addStage' && setFunnelSettingsInfo(JSON.parse(JSON.stringify(funnelSettingsInfoCopy)));
    }

    const getSharedInfo = (funnelObj) => {
        let apiPayLoad = null;
        APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/share/${props.id}`, apiPayLoad, false, 'GET', abortControllerRef.current)
            .then(response => {
                if (response.status === 1) {
                    let settingsObj = {
                        id: `settings_${props.id}`,
                        funnelId: props.id,
                        name: funnelObj.recruitment_funnel[0].name,
                        description: funnelObj.recruitment_funnel[0].description,
                        // department: funnelObj.recruitment_funnel[0].department,
                        // designation: funnelObj.recruitment_funnel[0].designation,
                        // If departmentsList or designationsList are not available just create an object just for displaying the department or designation
                        department: departmentRef.current ? departmentRef.current.filter(d => d.name === funnelObj.recruitment_funnel[0].department)[0] : { id: 1, name: funnelObj.recruitment_funnel[0].department },
                        designation: designationRef.current ? designationRef.current.filter(d => d.name === funnelObj.recruitment_funnel[0].designation)[0] : { id: 1, name: funnelObj.recruitment_funnel[0].designation },
                        stages: [],
                        shared: response.data,
                        isFunnelCreated: true,
                        step: funnelSettingsInfoRef.current.step,
                        mode: "edit",
                        is_archive: funnelObj.recruitment_funnel[0].is_archive,
                        loadingInfo: false,
                        loadingError: false,
                        privileges: funnelObj.recruitment_funnel[0].privileges
                    }

                    setFunnelSettingsInfo(settingsObj);
                    setFunnelSettingsInfoCopy(settingsObj);
                } else {
                    alertService.warning(response.msg);
                    console.log(response);
                    let settingsObj = {
                        loadingInfo: false,
                        loadingError: true
                    }
                    setFunnelSettingsInfo(settingsObj);
                    setFunnelSettingsInfoCopy(settingsObj);
                }
            })
            .catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('error', err);
            });
    }

    const handleModifyFunnel = (type, id, value) => {
        let apiPayLoad;
        let funnelDataCopy = JSON.parse(JSON.stringify(copyFunnelData));
        let copyFunnelSettingsInfo = JSON.parse(JSON.stringify(funnelSettingsInfo));

        if (type === 'name') {
            if (funnelSettingsInfo.name === funnelSettingsInfoCopy.name) return;
            if (funnelSettingsInfo.name === '') {
                alertService.error(`Funnel name cannot be blank`);
                return;
            }

            apiPayLoad = {
                name: funnelSettingsInfo.name
            }

            funnelDataCopy.recruitment_funnel[0].name = funnelSettingsInfo.name;
        }

        if (type === 'desc') {
            if (funnelSettingsInfo.description === funnelSettingsInfoCopy.description) {
                return;
            }

            apiPayLoad = {
                description: funnelSettingsInfo.description
            }

            funnelDataCopy.recruitment_funnel[0].description = funnelSettingsInfo.description;
        }

        if (type === 'deleteStage') {
            apiPayLoad = {
                stages: [{
                    id: id,
                    action: "delete"
                }]
            }
            funnelDataCopy.stages = funnelDataCopy.stages.filter(stage => stage.id !== id);
            funnelDataCopy.resources = funnelDataCopy.resources.filter(res => res.recruitment_funnel_stage_id !== id);
            copyFunnelSettingsInfo.stages = copyFunnelSettingsInfo.stages.filter(stage => stage.id !== id);
            setFunnelSettingsInfo(copyFunnelSettingsInfo);
        }

        if (type === 'updateStageDetails') {
            let oldStageInfo = funnelSettingsInfoCopy.stages.filter(s => s.id === id)[0];
            let newStage = String(id).includes('add');

            if (!value.name) {
                alertService.error(`Stage name cannot be blank`);
                return;
            }
            if (!newStage && oldStageInfo.name === value.name && oldStageInfo.description === value.description && oldStageInfo.isQuestionnaire === value.isQuestionnaire) return;

            apiPayLoad = !newStage ? {
                stages: [{
                    id: id,
                    name: value.name,
                    description: value.description,
                    is_questionnaire: value.isQuestionnaire ? 1 : 0,
                    action: "update"
                }]
            } : {
                stages: [{
                    name: value.name,
                    description: value.description,
                    is_questionnaire: value.isQuestionnaire ? 1 : 0,
                    action: "add",
                    order: ++value.order
                }]
            }

            if (!newStage) {
                funnelDataCopy.stages = funnelDataCopy.stages.map(stage => {
                    return stage.id === id ? { ...stage, name: value.name, description: value.description } : stage
                })
            }
        }

        APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/recruitment_funnel/${props.id}`, apiPayLoad, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    alertService.success('Funnel modification was successful');

                    if (type === 'updateStageDetails' && String(id).includes('add')) {
                        funnelDataCopy.stages = [...funnelDataCopy.stages, response.data[0]]
                        funnelDataCopy.resources = [...funnelDataCopy.resources, {
                            "num_pages": 0,
                            "page_number": 1,
                            // "page_size": +pageSize.name,
                            "page_size": 27,
                            "recruitment_funnel_stage_id": response.data[0].id,
                            "resources": [],
                            "total_results": 0
                        }]
                        copyFunnelSettingsInfo.stages = copyFunnelSettingsInfo.stages.map(stage => stage.name === response.data[0].name ? { ...stage, id: response.data[0].id } : stage)
                        setFunnelSettingsInfo(oldSettings => {
                            console.log('oldSettings', oldSettings, { ...oldSettings, stages: oldSettings.stages.map(stage => stage.name === response.data[0].name ? { ...stage, id: response.data[0].id } : stage) });
                            return ({ ...oldSettings, stages: oldSettings.stages.map(stage => stage.name === response.data[0].name ? { ...stage, id: response.data[0].id, order: response.data[0].order } : stage) })
                        });
                    }

                    setCopyFunnelData(funnelDataCopy);

                    let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
                    let obj = {};
                    obj[props.id] = funnelDataCopy;
                    let updatedSessionObj = sessionObj.map(f => f.hasOwnProperty(props.id) ? { ...obj } : f);
                    sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));

                    setFunnelSettingsInfoCopy(JSON.parse(JSON.stringify(copyFunnelSettingsInfo)));
                    setFunnelSettingsPayload(null);

                    // Update tab name if funnel name is modified
                    type === 'name' && props.handleTabNameChange(props.id, funnelSettingsInfo.name);
                } else {
                    alertService.warning(response.msg);
                    console.log('handleModifyFunnel else', response);
                }
            })
            .catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('handleModifyFunnel error', err);
            });
    }

    const updateSessionStorage = (funnelData) => {
        let sessionObj = sessionStorage[Constants.SITE_PREFIX + 'funnel_data'] && JSON.parse(sessionStorage[Constants.SITE_PREFIX + 'funnel_data']);
        let obj1 = {};
        let id = props.id;
        obj1[id] = funnelData;
        let updatedSessionObj = sessionObj.map(f => f.hasOwnProperty(id) ? { ...obj1 } : f);
        sessionStorage.setItem(Constants.SITE_PREFIX + 'funnel_data', JSON.stringify(updatedSessionObj));
    }

    const searchInputRef = useRef(null);
    const handleSearchInputChange = debounce((e) => {
        abortControllerRef.current && abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();

        props.handleFunnelFilter(props.id, 'searchString', e.target.value);
        if (e.target.value === '') {
            fetchStageDetails({ search_string: 'clear_search' });
        } else if (e.target.value.trim() !== '') {
            fetchStageDetails({ search_string: e.target.value.trim() });
        }
    }, 450)

    if (funnelNotAvailable) {
        return (
            <NotAvailable subTabName='Funnel' />
        )
    }

    return (
        props.funnelFilter ? <div id='recuitment-details-console'>
            {/* <div className='console-container'> */}
            {((copyFunnelData && copyFunnelData.recruitment_funnel[0].id === +window.location.pathname.replace('/app/recruiter/f-', '') && copyFunnelData.recruitment_funnel[0].is_archive === 1)) && <div className='funnel-status'>
                ⚠️ This Funnel is Archived
            </div>}
            <div className='filter-wrapper'>
                <div className='filter'>
                    <div className='search-filter'>
                        <input
                            ref={searchInputRef}
                            type='search'
                            placeholder='Search User Name or ID'
                            onChange={handleSearchInputChange}
                            defaultValue={props.funnelFilter.searchString}
                        />
                    </div>
                    <div className='date-picker-wrapper'>
                        <div
                            className='date-displayer'
                            onClick={toggleShowDatePicker}
                        >
                            {/* {filterDateRange && filterDateRange.length > 0 ? `${formatText(selectedDateTab)}: ${formatFilterDate(filterDateRange)}` : 'Date Filter'} */}
                            {props.funnelFilter.selectedDateTab === 'created_at' ?
                                props.funnelFilter.created_at && props.funnelFilter.created_at.length > 0 ? `${formatText(props.funnelFilter.selectedDateTab)}: ${formatFilterDate(props.funnelFilter.created_at)}` : 'Date Filter' :
                                props.funnelFilter.updated_at && props.funnelFilter.updated_at.length > 0 ? `${formatText(props.funnelFilter.selectedDateTab)}: ${formatFilterDate(props.funnelFilter.updated_at)}` : 'Date Filter'
                            }
                            <span className='calendar-icon'></span>
                        </div>
                        {showDatePicker &&
                            <div style={{ position: 'absolute' }}>
                                <ClickOutsideListner onOutsideClick={toggleShowDatePicker}>
                                    <div className='date-picker-container'>
                                        <div className='tabbar'>
                                            <button
                                                // className={selectedDateTab === 'created_at' ? 'selected-tab' : ''}
                                                className={props.funnelFilter.selectedDateTab === 'created_at' ? 'selected-tab' : ''}
                                                onClick={() => {
                                                    // setSelectedDateTab('created_at');
                                                    props.handleFunnelFilter(props.id, 'selectedDateTab', 'created_at');
                                                }}
                                            >
                                                Created At
                                            </button>
                                            <button
                                                className={props.funnelFilter.selectedDateTab === 'updated_at' ? 'selected-tab' : ''}
                                                onClick={() => {
                                                    // setSelectedDateTab('updated_at');
                                                    props.handleFunnelFilter(props.id, 'selectedDateTab', 'updated_at');
                                                }}
                                            >
                                                Updated At
                                            </button>
                                        </div>
                                        <div className='date-picker'>
                                            {props.funnelFilter.selectedDateTab === 'created_at' && <RangePicker
                                                picker="date"
                                                range={filterCreatedDateRange}
                                                // range={props.funnelFilter.created_at}
                                                onChange={(date) => {
                                                    setFilterCreatedDateRange(date);
                                                    // getFunnelDetails(false, ['created_at', date]);
                                                    fetchStageDetails({ created_at: date });
                                                    props.handleFunnelFilter(props.id, 'created_at', date);
                                                }}
                                                // predefinedDates={[]}
                                                placeholder="Created"
                                                // allowClear={false}
                                                // showOkCancelBtns={true}
                                                showInline={true}
                                            />}
                                            {props.funnelFilter.selectedDateTab !== 'created_at' && <RangePicker
                                                picker="date"
                                                range={filterUpdatedDateRange}
                                                // range={props.funnelFilter.updated_at}
                                                // range={[new Date(props.funnelFilter.updated_at[0]), new Date(props.funnelFilter.updated_at[1])]}
                                                onChange={(date) => {
                                                    setFilterUpdatedDateRange(date);
                                                    // getFunnelDetails(false, ['updated_at', date]);
                                                    fetchStageDetails({ updated_at: date });
                                                    props.handleFunnelFilter(props.id, 'updated_at', date);
                                                }}
                                                // predefinedDates={[]}
                                                placeholder="Created"
                                                // allowClear={false}
                                                // showOkCancelBtns={true}
                                                showInline={true}
                                            />}
                                        </div>
                                    </div>
                                </ClickOutsideListner>
                            </div>}
                    </div>
                    <SpeedSelect
                        options={reportersList}
                        // selectedOption={activeReporter}
                        selectedOption={props.funnelFilter.reporter}
                        imgKey='url'
                        onSelect={(reporter) => {
                            // setActiveReporter(reporter);
                            props.handleFunnelFilter(props.id, 'reporter', reporter);
                            // if (!reporter.length && props.funnelFilter.searchString === '' && !props.funnelFilter.status.length && ((props.funnelFilter.selectedDateTab === 'created_at' && !props.funnelFilter.created_at.length) || (props.funnelFilter.selectedDateTab === 'updated_at' && !props.funnelFilter.updated_at.length))) {
                            //     removeSessionStorageData();
                            //     return;
                            // }
                            // getFunnelDetails(false, ['reporter', reporter]);
                            fetchStageDetails({ reporter });
                        }}
                        displayKey='full_name'
                        uniqueKey='id'
                        // hideOkCancelBtns
                        selectLabel='Select'
                        prominentLabel='Reporter'
                        dropdownSelectAllLabel='All'
                        multiple
                    // disable={!reporterList.length}
                    />
                    <SpeedSelect
                        options={statusList}
                        // selectedOption={activeStatus}
                        selectedOption={props.funnelFilter.status}
                        onSelect={(status) => {
                            // setactiveStatus(status);
                            props.handleFunnelFilter(props.id, 'status', status);
                            // if (!status.length && props.funnelFilter.searchString === '' && !props.funnelFilter.reporter.length && ((props.funnelFilter.selectedDateTab === 'created_at' && !props.funnelFilter.created_at.length) || (props.funnelFilter.selectedDateTab === 'updated_at' && !props.funnelFilter.updated_at.length))) {
                            //     removeSessionStorageData();
                            //     return;
                            // }
                            // getFunnelDetails(false, ['status', status]);
                            fetchStageDetails({ status });
                        }}
                        displayKey='name'
                        uniqueKey='id'
                        // hideOkCancelBtns
                        selectLabel='Select'
                        prominentLabel='Status'
                        dropdownSelectAllLabel='All'
                        multiple
                    // disable={!statusList.length}
                    />
                    <button
                        className='clear-button'
                        title='Clear all filters'
                        onClick={handleClearBtn}
                    >
                        Clear
                    </button>
                </div>
                <div className='action-buttons-wrapper'>
                    {undoData.showUndoBtn === true && <button
                        className={`btn btn-with-icon btn-undo btn-small ${undoData.disabled ? 'btn-disabled' : ''}`}
                        onClick={(e) => {
                            if (undoData.disabled) {
                                alertService.warning('Cannot undo; email already sent to candidate');
                                return;
                            }
                            handleUndo(e)
                        }}>
                        <i></i><span>Undo <b>{undoData.disabled ? '' : formatTime(undoTimer)}</b></span>
                    </button>}
                    {/* {timeStamp && <span>{displayTimeStamp ? `Updated on ${formatDate(timeStamp)}` : 'Updated just now'}</span>} */}
                    <div className="tool-tip">
                        <button
                            className={"btn btn-small btn-refresh" + (!(copyFunnelData) || ((copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive === 1)) ? ' btn-disabled' : '') + (displayLoadingAnim ? ' loading-anim' : '')}
                            onClick={() => {
                                getFunnelDetails(false, ['timeStamp']);
                                getStatus();
                            }}
                            // title={copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive !== 1 ? 'Click me to get a fresh copy of data' : ''}
                            disabled={!(copyFunnelData) || displayLoadingAnim || ((copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive === 1))}
                        >
                            Refresh
                        </button>
                        {timeStamp && !displayLoadingAnim &&
                            <span
                                className="tooltiptext"
                                style={{
                                    width: displayTimeStamp ? '225px' : '120px',
                                    left: displayTimeStamp ? '-60%' : '50%',
                                }}
                            >
                                {displayTimeStamp ? `Updated on ${formatDate(timeStamp)}` : 'Updated just now'}
                            </span>}
                        {!timeStamp && !displayLoadingAnim && <span className="tooltiptext">Click here to refresh the data</span>}
                        {displayLoadingAnim && <span className="tooltiptext">Refreshing...</span>}
                    </div>

                    <button
                        className={"btn btn-small btn-blue" + (!copyFunnelData || ((copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive === 1)) ? ' btn-disabled' : '')}
                        onClick={toggleShowAddCandidateModal}
                        title={(copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive === 1) ? 'Cannot add candidate to an Archived funnel' : ''}
                        disabled={!copyFunnelData || ((copyFunnelData && copyFunnelData.recruitment_funnel[0].is_archive === 1))}
                    >
                        Add Candidate
                    </button>
                    <button
                        // className={'btn-with-icon btn-settings btn-small' + (!funnelSettingsInfo || !copyFunnelData || (copyFunnelData ? !copyFunnelData.recruitment_funnel[0].privileges.includes('EDIT') : !filteredFunnelData.recruitment_funnel[0].privileges.includes('EDIT')) ? ' btn-disabled' : '')}
                        // disabled={!funnelSettingsInfo || !copyFunnelData || (copyFunnelData ? !copyFunnelData.recruitment_funnel[0].privileges.includes('EDIT') : !filteredFunnelData.recruitment_funnel[0].privileges.includes('EDIT'))}
                        className={'btn-with-icon btn-settings btn-small' + (!funnelSettingsInfo || !copyFunnelData || (!/(EDIT)|(SHARE)/.test(copyFunnelData.recruitment_funnel[0].privileges)) ? ' btn-disabled' : '')}
                        disabled={!funnelSettingsInfo || !copyFunnelData || (!/(EDIT)|(SHARE)/.test(copyFunnelData.recruitment_funnel[0].privileges))}
                        //need to create obj
                        onClick={(e) => {
                            setShowSettings(true);
                            const privileges = copyFunnelData?.recruitment_funnel[0].privileges;
                            const hasEditPrivilege = (privileges.includes('EDIT'))
                            const hasSharePrivilege = (privileges.includes('SHARE'))
                            setFunnelSettingsInfo({ ...funnelSettingsInfo, step: !hasEditPrivilege && hasSharePrivilege ? 3 : 1 });
                        }}
                    >
                        <i></i>
                        <span>Settings</span>
                    </button>
                </div>
            </div>

            <div className={`card-container ${showCandidateInfo ? 'extra-margin' : ''}`}>
                {errorLoadingFunnelData ? <p className='general-info'>Something went wrong, Unable to Fetch the Data</p> :
                    loadingFunnelData ? <p className='general-info'>Loading...</p> : stageInfo}
            </div>

            {showAddCandidateModal && (
                <AddCandidateModal
                    funnel={copyFunnelData}
                    toggle={toggleShowAddCandidateModal}
                    onAddNewCandidate={onAddNewCandidate}
                />
            )}

            {
                showCandidateInfo &&
                <CandidateProfileModal
                    candidate={candidateDetails}
                    onEmailUpdate={onCandidateEmailUpdate}
                    funnel={copyFunnelData}
                    toggle={closeDetailsPanel}
                    onHired={onHiredOrRejected}
                    onRejected={onHiredOrRejected}
                    onRemoved={onRemoved}
                />
            }

            {
                showSettings &&
                <CreateFunnel
                    funnelInfo={funnelSettingsInfo}
                    handleFunnelData={handleFunnelData}
                    designationsList={props.designationsList}
                    departmentsList={props.departmentsList}
                    handleSaveBtn={handleSaveBtn}
                    handleBackBtn={handleBackBtn}
                    handleCloseBtn={handleCloseBtn}
                    handleModifyFunnel={handleModifyFunnel}
                    setShowSettings={setShowSettings}
                />
            }
        </div> : null
    );
}

function InnerCard({ id, observer, funnelDataObj, handleScroll, stage, handleProfileClick, loadingResources, provided }) {
    const ref = useScrollRestoration(`recruitment-funnel-${id}-stage-${stage.id}`);

    return (
        <div className='inner-card'
            // ref={observer}
            ref={(element) => {
                observer.current[stage.id] = element
                ref.current = element
            }}
            data-columns={stage.id}
            onScroll={(e) => handleScroll(e)}>
            {
                funnelDataObj?.resources?.filter(res => {
                    return stage.id === res.recruitment_funnel_stage_id;
                })[0]?.resources?.map((res, index) => {
                    // Maps over inner array to display candidates information on the stage (swim lane)
                    return (
                        <Draggable key={res.id} draggableId={String(res.id)} index={index}>
                            {(provided) => (
                                <div className={'profile-card ' + (res.status === 'Employee' ? 'bl-green' : res.status === 'Rejected' ? 'bl-red' : 'bl-gray')}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    onClick={(e) => handleProfileClick(res, stage)}
                                >
                                    <div className='profile-links'>
                                        <Avatar
                                            imgSrc={res.img_url}
                                            alt={`${res.first_name}'s pic`}
                                            firstName={res.first_name}
                                            lastName={res.last_name}
                                            height={28}
                                            width={28}
                                            fontSize={12}
                                        />
                                    </div>
                                    <div className='profile-info'>
                                        <p className='record-id'><span>Record ID: </span> {res.id}</p>
                                        <p className='profile-name'>{`${res.first_name} ${res.last_name ?? ''}`}</p>
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    )
                })
            }
            {provided.placeholder}
            <div className='general-info'>{loadingResources[stage.id] && 'Loading...'}</div>
        </div>
    )
}