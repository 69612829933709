import { createContext, useContext, useState } from "react";
import { alertService } from "services/alertService";
import APIService from "services/apiService";
import { API_BASE_URL, SITE_PREFIX } from "components/Constants";
import { useRef } from "react";
import { useEffect } from "react";
import { subDays } from "utils/date";
import { emitter } from "components/SuperTabs/SuperTabs";
import { PAGE_IDS } from "components/Navigation";
import { clearScrollPositions } from "hooks/useScrollRestoration";

export const RecruitmentFunnelContext = createContext({});

export function RecruitmentFunnelProvider({ children }) {
  const [departmentsList, setDepartmentsList] = useState([]);
  const [designationsList, setDesignationsList] = useState([]);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => () => abortControllerRef.current.abort(), []);

  const getDesignations = () => {
    const controller = abortControllerRef.current;
    APIService.apiRequest(
      API_BASE_URL + "/designation",
      null,
      false,
      "GET",
      controller,
    )
      .then((response) => {
        if (response.status === 1) {
          setDesignationsList(response.output);
          // console.log('getDesig - if response', response);
        } else {
          alertService.error(`getDesignations error: ${response.msg}`);
          console.log("getDesig - else response", response.msg);
        }
      })
      .catch((err) => {
        alertService.error(`Error: ${err.msg}`);
        console.log("getDesig - error", err);
      });
  };

  const getDepartments = () => {
    const controller = abortControllerRef.current;
    APIService.apiRequest(
      API_BASE_URL + "/department",
      null,
      false,
      "GET",
      controller,
    )
      .then((response) => {
        if (response.status === 1) {
          setDepartmentsList(response.output);
          // console.log('getDept - if response', response);
        } else {
          alertService.error(`getDepartments error: ${response.msg}`);
          console.log("getDept - else response", response.msg);
        }
      })
      .catch((err) => {
        alertService.error(`Error: ${err.msg}`);
        console.log("getDept - error", err);
      });
  };

  useEffect(() => {
    getDesignations();
    getDepartments();
  }, []);

  const filterObj = {
    searchString: "",
    reporter: [],
    status: [],
    pageSize: "27",
    created_at: [],
    updated_at: [subDays(new Date(), 7), new Date()],
    selectedDateTab: "updated_at",
  };

  // Home Page State
  const [funnelData, setFunnelData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [filteredFunnelData, setFilteredFunnelData] = useState(null);
  const [filteredFunnelMetaData, setFilteredFunnelMetaData] = useState(null);
  const [showDescription, setShowDescription] = useState({
    id: null,
    showColumn: false,
  });

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [activeFilterTab, setActiveFilterTab] = useState("all");
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingFunnelData, setLoadingFunnelData] = useState(false);

  let tableHeight = window.innerHeight - 132;
  let PAGE_SIZE = parseInt((tableHeight / 22) * 1.05);

  const getFunnelData = async (
    hasFilter,
    paginationObj,
    showLoading = true,
    value = ["", "", ""],
  ) => {
    const apiPayLoad = null;
    // showLoading && setLoadingFunnelData(true);
    setLoadingData(true);
    // console.log('getFunnelData', hasFilter, paginationObj, showLoading, value);
    // To make 3rd index optional
    if (value.length <= 2) value[2] = [];
    // console.log('isSearch, paginationObj, showLoading', isSearch, paginationObj, showLoading);
    // const page_size = paginationObj?.pageSizeSelected ? paginationObj?.pageSizeSelected : pageSize.name;
    // const pageNumber = paginationObj?.currentPage ? paginationObj?.currentPage : 1;
    // let pageNumber = value[0] !== 'pageSize' ? filteredFunnelData ? filteredFunnelMetaData.page_number + 1 : value[0] === 'filter' ? 1 : metaData ? metaData.page_number + 1 : 1 : 1;
    let pageNumber = [
      "pageSize",
      "filter",
      "searchString",
      "dept",
      "desig",
    ].includes(value[0])
      ? 1
      : value[0] === "pageNumber"
        ? value[1]
        : filteredFunnelData
          ? filteredFunnelMetaData.page_number + 1
          : metaData
            ? metaData.page_number + 1
            : 1;

    const url = new URL(
      API_BASE_URL + `/recruiter/recruitment_funnel`,
    );

    url.searchParams.set("page_number", value[0] === "reset" ? 1 : pageNumber);
    url.searchParams.set("page_size", PAGE_SIZE);

    if (value[0] !== "reset" || hasFilter) {
      if (value[0] === "searchString" || searchString) {
        if (value[0] === "searchString" && value[1]) {
          url.searchParams.set("search_string", value[1]);
        } else {
          url.searchParams.set("search_string", searchString);
        }
      }

      if (value[0] === "dept" || selectedDepartments.length) {
        if (value[0] === "dept" && value[1].length) {
          url.searchParams.set(
            "department_id",
            value[1].map((s) => s.id).join(","),
          );
        } else {
          url.searchParams.set(
            "department_id",
            selectedDepartments.map((s) => s.id).join(","),
          );
        }
      }

      if (
        value[0] === "desig" ||
        selectedDesignations.length ||
        (value[0] === "dept" && value[2].length)
      ) {
        if (value[0] === "dept" && value[2].length) {
          url.searchParams.set(
            "designation_id",
            value[2].map((s) => s.id).join(","),
          );
        } else if (value[0] === "desig" && value[1].length) {
          url.searchParams.set(
            "designation_id",
            value[1].map((s) => s.id).join(","),
          );
        } else {
          if (selectedDesignations.length) {
            url.searchParams.set(
              "designation_id",
              selectedDesignations.map((s) => s.id).join(","),
            );
          }
        }
      }
    }

    // TODO: Make sure to reset page_number if filter is applied

    // console.log('url', url, selectedDepartments);
    // GET https://dev-api.thinkteam.net/data_stream/recruiter
    const controller = abortControllerRef.current;
    APIService.apiRequest(url.toString(), apiPayLoad, false, "GET", controller)
      .then((response) => {
        setLoadingData(false);
        if (response.status === 1) {
          showLoading && setLoadingFunnelData(false);
          if (hasFilter) {
            if (value[0] === "loadMore") {
              setFilteredFunnelData([
                ...filteredFunnelData,
                ...response.output,
              ]);
            } else {
              setFilteredFunnelData(response.output);
            }
            setFilteredFunnelMetaData(response.meta);
            setCurrentPage(response.meta.page_number);
          } else {
            if (value[0] === "loadMore") {
              setFunnelData([...funnelData, ...response.output]);
            } else {
              setFunnelData(response.output);
            }
            setMetaData(response.meta);
            setCurrentPage(response.meta.page_number);
          }
          // console.log('getFunnelData - if response', response);
          // return response;
        } else {
          showLoading && setLoadingFunnelData(false);
          if (response?.msg) alertService.error(`getFunnelData error: ${response.msg}`);
          console.log("getFunnelData - else response", response);
        }
        // }).then(data => getTabsData(data.output))
      })
      .catch((err) => {
        showLoading && setLoadingFunnelData(false);
        setLoadingData(false);
        alertService.error(`Error: ${err.msg}`);
        console.log("getFunnelData - error", err);
      });
  };

  const resetAbortController=()=>{
    if(!abortControllerRef.current) return;
    abortControllerRef.current.abort();
    abortControllerRef.current =  new AbortController();
  }

  // Recruitment Funnel Page State
  const [tabsInfo, updateTabsInfo] = useState(() => {
    const data = localStorage.getItem(SITE_PREFIX + 'funnel_info');
    if (data) return JSON.parse(data);

    return {
      tabs: [],
      savedFunnelData: [],
    }
  });
  const tabsInfoRef = useRef(tabsInfo);

  const setTabsInfo = (data) => {
    if (typeof data === 'function') {
      tabsInfoRef.current = data(tabsInfoRef.current);
    } else {
      tabsInfoRef.current = data;
    }
    localStorage.setItem(SITE_PREFIX + 'funnel_info', JSON.stringify(tabsInfoRef.current));
    updateTabsInfo(tabsInfoRef.current);
  }

  useEffect(() => {
    const unsub = emitter.subscribe("superTabClose", (e) => {
      if (e.appId === PAGE_IDS.RECRUITER && e.isSubTab) {
        const tabId = String(e.tab.id);
        const tabIndex = tabsInfo.tabs.findIndex(
          (tab) => String(tab.id) === tabId,
        );

        if (tabIndex > -1) {
          const newTabs = [...tabsInfo.tabs];
          newTabs.splice(tabIndex, 1);
          setTabsInfo((oldTabsInfo) => {
            return {
              ...oldTabsInfo,
              tabs: newTabs,
              savedFunnelData: oldTabsInfo.savedFunnelData.filter(
                (f) => f.id !== tabId,
              ),
            };
          });
        }

        const funnelData = JSON.parse(
          sessionStorage.getItem(SITE_PREFIX + "funnel_data"),
        );

        if (funnelData?.length > 0) {
          const funnelIndex = funnelData.findIndex((f) =>
            f.hasOwnProperty(tabId),
          );
          if (funnelIndex > -1) {
            funnelData.splice(funnelIndex, 1);
            sessionStorage.setItem(
              SITE_PREFIX + "funnel_data",
              JSON.stringify(funnelData),
            );
          }
        }

        // remove scroll restoration
        clearScrollPositions(`recruitment-funnel-${tabId}`)
      }
    });

    return unsub;
  }, [tabsInfo]);

  useEffect(() => () => clearScrollPositions('recruitment'), []);

  useEffect(
    () => () => {
      sessionStorage.removeItem(SITE_PREFIX + "funnel_data");
    },
    [],
  );

  const [funnelFilter, setFunnelFilter] = useState({});

  const handleFunnelFilter = (funnelId, type, value) => {
    setFunnelFilter((oldFunnelFilter) => {
      const funnelFilterCopy = structuredClone(oldFunnelFilter);

      if (type === "newTab") {
        funnelFilterCopy[funnelId] = {
          ...filterObj,
          created_at: [],
          updated_at: [subDays(new Date(), 7), new Date()],
        };
        return funnelFilterCopy;
      }
      if (type === "reset") {
        funnelFilterCopy[funnelId] = {
          ...filterObj,
          created_at: [],
          updated_at: [],
        };
        return funnelFilterCopy;
      }

      let filterToUpdate = funnelFilterCopy[funnelId];

      switch (type) {
        case "created_at":
        case "updated_at":
          filterToUpdate[type] = [new Date(value[0]), new Date(value[1])];
          break;
        case "searchQueryParam":
          filterToUpdate.searchString = value;
          filterToUpdate.created_at = [];
          filterToUpdate.updated_at = [];
          break;
        default:
          filterToUpdate[type] = value;
          break;
      }

      return funnelFilterCopy;
    });
  };

  return (
    <RecruitmentFunnelContext.Provider
      value={{
        departmentsList,
        designationsList,
        funnelData,
        setFunnelData,
        metaData,
        setMetaData,
        filteredFunnelData,
        setFilteredFunnelData,
        filteredFunnelMetaData,
        setFilteredFunnelMetaData,
        showDescription,
        setShowDescription,
        selectedDepartments,
        setSelectedDepartments,
        selectedDesignations,
        setSelectedDesignations,
        activeFilterTab,
        setActiveFilterTab,
        searchString,
        setSearchString,
        currentPage,
        setCurrentPage,
        loadingData,
        loadingFunnelData,
        getFunnelData,
        tabsInfo,
        setTabsInfo,
        funnelFilter,
        setFunnelFilter,
        handleFunnelFilter,
        filterObj,
        resetAbortController
      }}
    >
      {children}
    </RecruitmentFunnelContext.Provider>
  );
}

export const useRecruitmentFunnelContext = () => {
  const ctx = useContext(RecruitmentFunnelContext);
  if (!ctx) {
    throw new Error(
      "useRecruitmentFunnelContext must be used within a RecruitmentFunnelProvider",
    );
  }

  return ctx;
};
