import { useEffect, useCallback } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { WikiProvider } from "./context";
import { useTabContext } from "components/SuperTabs/TabContext";
import { emitter } from "components/SuperTabs/SuperTabs";
import { withKeepAlive } from "components/KeepAlive";
import { PAGE_IDS } from "components/Navigation";
import Article from "./Article";
import Home from "./Home";
import { getUniqueId } from "utils/Utils";
import "./index.scss";

function Wiki() {
  const { openSuperTabOnRowClick, activeSubTab } = useTabContext();

  const createNewArticleTab = useCallback(() => {
    const tabId = `new-${getUniqueId()}`;
    const tabName = `Untitled`;
    openSuperTabOnRowClick({
      tab: {
        id: tabId,
        name: tabName,
        url: `/app/wiki/article/${tabId}?edit=true`,
        showEditButton: true,
        isEditMode: true,
      },
    });
  }, [openSuperTabOnRowClick]);

  useEffect(() => {
    const unsub = emitter.emit("bindCallback", {
      id: PAGE_IDS.WIKI,
      callback: createNewArticleTab,
    });
    return unsub;
  }, [createNewArticleTab]);

  const history = useHistory();
  const isArticlePage = useRouteMatch("/app/wiki/article/:id")?.isExact === true;
  useEffect(() => {
    if (isArticlePage) {
      const hasEditMode = new URLSearchParams(history.location.search).get(
        "edit",
      );
      const articleId = history.location.pathname.split("/").pop();
      const tab = {
        id: articleId.startsWith('new-') ? articleId : parseInt(articleId),
        title: activeSubTab?.title || activeSubTab?.name || "Loading...",
        url: `/app/wiki/article/${articleId}`,
        showEditButton: true,
        isEditMode: false,
        skipAPICall: true,
      };

      if (hasEditMode) {
        tab.isEditMode = true;
        tab.url = `/app/wiki/article/${articleId}?edit=true`;
      }

      openSuperTabOnRowClick({ tab, isSubTab: true });
    }
  }, []);

  return (
    <div className="wiki">
      <WikiProvider>
        <Switch>
          <Route path="/app/wiki/home" exact>
            <Home />
          </Route>
          <Route path="/app/wiki/article/:id">
            <Article />
          </Route>
        </Switch>
      </WikiProvider>
    </div>
  );
}

export default withKeepAlive(Wiki, PAGE_IDS.WIKI);
