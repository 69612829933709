import React, { useState, useRef } from 'react';
import APIService from "services/apiService";
import { alertService } from 'services/alertService';
import * as Constants from 'components/Constants';
import ClickOutsideListner from 'components/ClickOutsideListner';
import { getUser } from 'utils/Common';
import Avatar from 'components/Avatar';
import './styles.scss';

import { Steno, StenoRenderer } from 'react-steno'
import { isMacOs } from 'utils/Utils';


const STENO_EDITOR_TOOLBAR_PROPS = {
    disabled: false,
    enableResize: false,
    toolbarPosition: 'bottom',
    toolbarChildVisibility: {
        fontSize: false,
        textTransform: false,
        fontColor: false,
        backgroundColor: false,
        removeTextFormat: true,
        addFiles: false,
        borderType: false,
        borderColor: false,
        borderWidth: false,
    }
}

const COMMENT_TIMEOUT = 5;
function isDateGreaterThanMins(date, mins = 20) {
    const datePlusMins = new Date(date).getTime() + (1000 * 60 * mins);
    return datePlusMins < Date.now();
}

const allowCommentAction = (created_at, action) => {
    if (isDateGreaterThanMins(created_at, COMMENT_TIMEOUT)) {
        alertService.info(
            `A comment/reply cannot be ${action} after ${COMMENT_TIMEOUT} minutes`
        );
        return false;
    }
    return true;
}

const Credit = ({ type, creditDetails, handleApprove, handleAccept, handleDispute, handleRevoke, loggedInUserResId, updateCommentCount }) => {

    const formatDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });

    const userInfo = getUser();
    const isManager = userInfo.privileges.employee.includes('MANAGER');

    const giveDateTimeInString = (date) => {
        let d = new Date(date);
        let dateOptions = { year: 'numeric', month: 'short' };
        let timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
        return `${d.getDate()} ${d.toLocaleDateString('en-us', dateOptions)} ${d.toLocaleTimeString('en-us', timeOptions)}`;
    }

    // STATE VARIABLES FOR COMMENTS
    const [commentSectionExpanded, setCommentSectionExpanded] = useState(false);
    const [comments, setComments] = useState([]);
    const [loadingComments, setLoadingComments] = useState(false);
    const [commentAddFormText, setCommentAddFormText] = useState('');
    const [commentAddInputExpanded, setCommentAddInputExpanded] = useState(creditDetails.notes_count === 0);
    const [commentSaveOpertionInProgress, setCommentSaveOpertionInProgress] = useState(false);
    const [commentsReplies, setCommentsReplies] = useState([]);
    const [loadingCommentsReplies, setLoadingCommentsReplies] = useState(false);
    const [commentsExpanded, setCommentsExpanded] = useState(false);
    const [openedOptionsCommentId, setOpenedOptionsCommentId] = useState(null);
    const [editCommentId, setEditCommentId] = useState(null);
    const [editCommentFormText, setEditCommentFormText] = useState(null);
    const [replyCommentId, setReplyCommentId] = useState(null);
    const [replyCommentFormText, setReplyCommentFormText] = useState('');

    // Form Refs
    const addFormEditorRef = useRef();
    const addFormFnRef = useRef();
    const editFormEditorRef = useRef();
    const editFormFnRef = useRef();
    const replyFormEditorRef = useRef();
    const replyFormFnRef = useRef();


    const id = window.location.pathname.replace('/app/performance/', '');
    // const [isPublic, setIsPublic] = useState(false);
    const firstName = type === 'received' ? creditDetails.evaluator_first_name : creditDetails.recipient_first_name;
    const lastName = type === 'received' ? creditDetails.evaluator_last_name : creditDetails.recipient_last_name;
    const creditDetailsArray = [
        [type === 'received' ? 'From' : 'To', `${firstName} ${lastName}`],
        ['Date', formatDate.format(new Date(creditDetails.created_at))],
        ['Status', creditDetails.status],
    ]

    if (isManager || loggedInUserResId === creditDetails.evaluator_resource_id || loggedInUserResId === creditDetails.recipient_resource_id) creditDetailsArray.push(['Reason', creditDetails.reason]);
    // console.log('creditDetails', id, loggedInUserResId, creditDetails, loggedInUserResId === creditDetails.evaluator_resource_id, creditDetails.recipient_resource_id, isManager);
    // METHODS RELATED TO COMMENTS

    const getComments = (creditId) => {
        setLoadingComments(true);
        APIService.apiRequest(Constants.API_BASE_URL + `/performance/notes/${creditId}`, null, false, 'GET')
            // APIService.apiRequest(Constants.API_BASE_URL + `/recruitment_funnel/notes/${creditId}`, null, false, 'GET')
            .then(response => {
                if (response.status === 1) {
                    // console.log('getComments', response);
                    setComments(response.output);
                    setLoadingComments(false);
                    // response.output.length !== +creditDetails.notes_count && updateCommentCount(type, creditDetails.credit_id, response.output.length);
                } else {
                    setLoadingComments(false);
                }
            })
            .catch(err => {
                setLoadingComments(false)
            });
    }

    const getCommentReplies = (parentNoteId) => {
        return new Promise((resolve, reject) => {
            setLoadingCommentsReplies({ ...loadingCommentsReplies, [parentNoteId]: true })

            APIService.apiRequest(Constants.API_BASE_URL + `/performance/notes/${creditDetails.credit_id}?parent_note_id=${parentNoteId}`, {}, false, 'GET')
                .then(response => {
                    if (response.status === 1) {
                        // console.log('getCommentReplies', response);
                        // const updatedComments = comments.map(n => n.id !== parentNoteId ? n : { ...n, child_notes_count: response.output.length });
                        // setComments(updatedComments);
                        setCommentsReplies({ ...commentsReplies, [parentNoteId]: response.output })
                        setLoadingCommentsReplies({ ...loadingCommentsReplies, [parentNoteId]: false })
                        setCommentsExpanded({ ...commentsExpanded, [parentNoteId]: true })
                        resolve();
                    } else {
                        setLoadingCommentsReplies({ ...loadingCommentsReplies, [parentNoteId]: false })
                        reject();
                    }
                })
                .catch(err => {
                    setLoadingCommentsReplies({ ...loadingCommentsReplies, [parentNoteId]: false })
                    reject();
                });
        });
    }

    const handleFormInputFocus = () => {
        setCommentAddInputExpanded(true);
        setEditCommentId(null); // close edit form input if opened
    }

    const handleFormInputChange = (val) => {
        setCommentAddFormText(val)
    }

    const clearAndCloseInputFocus = () => {
        setCommentAddFormText('')
        setCommentAddInputExpanded(false)
    }

    const handleFormCancelBtn = () => {
        clearAndCloseInputFocus();
    }

    const handleInsightNoteSaveBtn = () => {
        const apiPayLoad = {
            // TODO: fix below ID - test
            // recruitment_funnel_stage_id: candidateDetails.stage_Id,
            // recruitment_funnel_stage_id: currentStage.id,
            note: commentAddFormText,
        };

        // console.log('handleInsightNoteSaveBtn', apiPayLoad, id === 'my-profile' ? loggedInUserResId : id)
        setCommentSaveOpertionInProgress(true);

        APIService.apiRequest(Constants.API_BASE_URL + `/performance/notes/${creditDetails.credit_id}`, apiPayLoad, false, 'POST')
            .then(response => {
                if (response.status === 1) {
                    // add 'child_notes_count' key to response.output[0]
                    response.output[0].child_notes_count = 0;
                    // console.log('handleInsightNoteSaveBtn response', response);

                    clearAndCloseInputFocus();
                    setCommentSaveOpertionInProgress(false);
                    setComments([...comments, response.output[0]]);
                    updateCommentCount(type, creditDetails.credit_id, +creditDetails.notes_count + 1);
                } else {
                    setCommentSaveOpertionInProgress(false);
                }
            })
            .catch(err => {
                setCommentSaveOpertionInProgress(false);
                console.log('Error occured in adding comment: ' + err.message);
            });
    }

    const handleCommentSaveChangesBtn = (parentNoteId) => {
        let payload = {
            note: editCommentFormText,
        };

        setCommentSaveOpertionInProgress(true);
        APIService.apiRequest(Constants.API_BASE_URL + `/performance/notes/${editCommentId}`, payload, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    let updatedState = {};

                    if (parentNoteId) {
                        // child note is edited, update its info in replies list
                        const updatedReplies = commentsReplies[parentNoteId].map(n => n.id !== editCommentId ? n : { ...n, note: payload.note });
                        updatedState.notesReplies = { ...commentsReplies, [parentNoteId]: updatedReplies }
                        setCommentsReplies(updatedState.notesReplies);
                    } else {
                        setComments(comments.map(n => n.id !== editCommentId ? n : ({ ...n, note: payload.note })))
                    }
                    // console.log(updatedState);
                    setCommentSaveOpertionInProgress(false);
                    // Below set funcs are for Cleanup
                    setEditCommentId(null);
                    setEditCommentFormText('');


                } else {
                    setCommentSaveOpertionInProgress(false);
                }
            })
            .catch(err => {
                setCommentSaveOpertionInProgress(false);
                console.log('Error occured in editing comment: ' + err.message);
            });
    }

    const handleCommentReplySaveBtn = () => {
        const apiPayLoad = {
            // TODO: fix below id - test
            // recruitment_funnel_stage_id: currentStage.id,
            note: replyCommentFormText,
            parent_note_id: replyCommentId
        };

        setCommentSaveOpertionInProgress(true);
        APIService.apiRequest(Constants.API_BASE_URL + `/performance/notes/${creditDetails.credit_id}`, apiPayLoad, false, 'POST')
            .then(response => {
                if (response.status === 1) {
                    let updatedNotesReplies = { ...commentsReplies };
                    const newReplyNote = response.output[0];
                    // console.log('reply response', response);

                    let updatedRepliesList = updatedNotesReplies[replyCommentId] || [];
                    updatedRepliesList.push(newReplyNote);
                    updatedNotesReplies[replyCommentId] = updatedRepliesList;

                    // Also update the 'child_notes_count' property for parent note
                    const updatedNotes = comments.map(n => n.id !== replyCommentId ? n : { ...n, child_notes_count: n.child_notes_count + 1 });


                    setCommentSaveOpertionInProgress(false);
                    setCommentsReplies(updatedNotesReplies);
                    setReplyCommentId(null);
                    setReplyCommentFormText('');
                    setComments(updatedNotes);
                    // getCommentReplies(replyCommentId);
                } else {
                    setCommentSaveOpertionInProgress(false);
                }
            })
            .catch(err => {
                setCommentSaveOpertionInProgress(false);
                console.log('Error occured in adding note to comment: ' + err.message);
            });
    }

    const handleViewThreadBtn = (noteId) => {
        // first check if replies for noteId are empty or not, if empty, just expand and show the editor to add reply 
        if (comments.find(n => n.id === noteId).child_notes_count === 0) {
            setCommentsExpanded({ ...commentsExpanded, [noteId]: true });
            setReplyCommentId(noteId);
            return;
        }

        // If replies are not empty and already fetched, expand those replies
        if (commentsReplies[noteId]) {
            // note replies already fetched, so just toggle the visibility of replies
            const isExpanded = commentsExpanded[noteId];
            setCommentsExpanded({ ...commentsExpanded, [noteId]: !isExpanded })
        } else {
            // check if thread/replied notes already available or not
            getCommentReplies(noteId);
        }
    }

    const handleCommentEditBtn = (noteId, parentNoteId, createdAt) => {
        if (!allowCommentAction(createdAt, 'edited')) {
            setOpenedOptionsCommentId(null);
            return;
        }
        let noteInfo;
        if (parentNoteId) {
            // Note being edited is a child note, search for this in noteReplies map
            noteInfo = commentsReplies[parentNoteId].find(n => n.id === noteId);
        } else {
            noteInfo = comments.find(n => n.id === noteId);
        }

        setCommentAddInputExpanded(false); // close add form input if opened
        setOpenedOptionsCommentId(null)
        setEditCommentId(noteId)
        setEditCommentFormText(noteInfo.note)

    }

    const handleEditFormInputChange = (val) => {
        setEditCommentFormText(val);
    }

    const handleCommentReplyFormInputChange = (val) => {
        setReplyCommentFormText(val);
    }

    const handleCommentReplyFormInputFocus = (noteId) => {
        if (replyCommentId !== noteId) setReplyCommentFormText('');
        setReplyCommentId(noteId);
    }

    const handleCommentReplyFormCancelBtn = () => {
        const currentFocusedReplyNoteId = replyCommentId;

        // remove the focus as well by calling .blur() function. It is done so that next time Focus event gets triggered properly.
        setReplyCommentFormText('');
        setReplyCommentId(null);
        // Check if Parent note has zero child note, in that case, we have to hide the reply editor as well, hence set expanded variable to false
        if (comments.find(n => n.id === currentFocusedReplyNoteId).child_notes_count === 0) {
            setCommentsExpanded({ ...commentsExpanded, [currentFocusedReplyNoteId]: false })
        }
    }

    const handleCommentDeleteBtn = (noteId, parentNoteId, createdAt) => {
        if (!allowCommentAction(createdAt, 'deleted')) {
            setOpenedOptionsCommentId(null);
            return;
        }
        // console.log(noteId);
        setCommentSaveOpertionInProgress(true);
        APIService.apiRequest(Constants.API_BASE_URL + `/performance/notes/${noteId}`, {}, false, 'DELETE')
            .then(response => {
                if (response.status === 1) {
                    let updatedState = {
                    };

                    if (parentNoteId) {
                        // child note is deleted, remove it from reply list
                        const updatedReplies = commentsReplies[parentNoteId].filter(n => n.id !== noteId);
                        updatedState.notesReplies = { ...commentsReplies, [parentNoteId]: updatedReplies }
                        setCommentsReplies(updatedState.notesReplies);
                        // Also update the 'child_notes_count' property of parent note
                        updatedState.notes = comments.map(n => n.id !== parentNoteId ? n : { ...n, child_notes_count: n.child_notes_count - 1 });
                        setComments(updatedState.notes);
                    } else {
                        setComments(comments.filter(n => n.id !== noteId));
                        updateCommentCount(type, creditDetails.credit_id, +creditDetails.notes_count - 1);
                    }
                    setCommentSaveOpertionInProgress(false);
                } else {
                    setCommentSaveOpertionInProgress(false);
                    console.log(response);
                }
            })
            .catch(err => {
                setCommentSaveOpertionInProgress(false);
                console.log('Error occured in deleting the comment: ' + err.message);
            });
    }



    const giveCommentsMainContent = () => {

        const giveUsersOfNotes = (notes) => {
            let users = [];
            users.push(notes.length > 0 && { ...notes[0].created_by_name, id: notes[0].id });
            // console.log(users);
            notes.forEach(n => {
                if (!users.some(u => u.id === n.created_by)) {
                    users.push({ ...notes[0].created_by_name, id: notes[0].id });
                }
            });
            return users;
        };

        const usersFilters = giveUsersOfNotes(comments || []);
        // TODO check below code
        // const canComment = copyFunnelData.recruitment_funnel[0].is_archive !== 1 && copyFunnelData.recruitment_funnel[0].privileges.includes('COMMENT') ? true : false;
        const canComment = true;
        return (
            <>
                {/* <div className="main-title"><span>Insight SubTitle</span></div> */}
                <div className="main-content">
                    {loadingComments && !comments.length && <div className="loader-container"><span className='loader'></span></div>}

                    {(!loadingComments || comments.length > 0) &&
                        <>
                            {(usersFilters.length > 0 || canComment) &&
                                <div className="sticky-editor">

                                    {/* <div className='stage-info-wrapper'>
                                            <button className='arrow-left' onClick={() => handleCommentStageChange('left')}></button>
                                            <span className='stage-name'>{currentStage.name}</span>
                                            <button className='arrow-right' onClick={() => handleCommentStageChange('right')}></button>
                                        </div> */}

                                    {canComment && (
                                        <div className='note-add-form'>
                                            <div className={`editor ${commentAddInputExpanded ? 'steno-editor-focused' : ''}`}>
                                                <Steno
                                                    isToolBarVisible={commentAddInputExpanded}
                                                    innerRef={addFormEditorRef}
                                                    ref={addFormFnRef}
                                                    html={commentAddFormText}
                                                    onChange={handleFormInputChange}
                                                    onFocus={handleFormInputFocus}
                                                    placeHolder={'Comment'}
                                                    sendMsgOnEnter={true}
                                                    onEnterClickLogic={(value) => {
                                                        if (!commentSaveOpertionInProgress) {
                                                            handleFormInputChange(value);
                                                            handleInsightNoteSaveBtn();
                                                        }
                                                    }}
                                                    {...STENO_EDITOR_TOOLBAR_PROPS}
                                                />
                                                {commentAddInputExpanded && (
                                                    <div className='form-footer'>
                                                        <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                                                        <div className="submit-cancel-btns">
                                                            <button className="btn btn-small"
                                                                disabled={commentSaveOpertionInProgress}
                                                                onClick={handleInsightNoteSaveBtn}
                                                            >
                                                                <i></i>
                                                                <span>Save</span>
                                                            </button>
                                                            <button
                                                                className="btn btn-small"
                                                                disabled={commentSaveOpertionInProgress}
                                                                onClick={handleFormCancelBtn}
                                                            >
                                                                <i></i>
                                                                <span>Cancel</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            <div className="notes">
                                <>
                                    {/* {comments.filter(n => n.recruitment_funnel_stage_id === currentStage.id).map((note, i) => { */}
                                    {comments && comments.length > 0 && comments.map((note, i) => {
                                        const formattedCreatedDate = giveDateTimeInString(new Date(note.created_at));
                                        const noteReplies = commentsReplies[note.id] || [];
                                        const loadingChildNotes = loadingCommentsReplies[note.id] || false;
                                        const isExpanded = commentsExpanded[note.id] || false;
                                        const isReplyExpanded = replyCommentId === note.id;
                                        // TODO check below code 
                                        const canEditComment = canComment && note.created_by === userInfo.id;

                                        return (
                                            <div key={note.id} className={'note-widget-wrapper' + (isExpanded ? ' expanded' : '') + (note.child_notes_count === 0 ? ' no-replies' : '')}>
                                                {/* <span className="unread-indicator"></span> */}
                                                <div className="note-widget">

                                                    <div className="header">
                                                        <Avatar
                                                            // imgSrc={candidate.img_url}
                                                            // alt={`${candidate.img_url}'s pic`}
                                                            firstName={note.created_by_name.first_name}
                                                            lastName={note.created_by_name.last_name}
                                                            height={20}
                                                            width={20}
                                                            fontSize={8}
                                                        />
                                                        <span className="name">{note.created_by_name.first_name[0].toUpperCase() + note.created_by_name.first_name.slice(1) + ' ' + (note.created_by_name.last_name ? note.created_by_name.last_name[0].toUpperCase() + note.created_by_name.last_name.slice(1) : '')}</span>
                                                        <span className="date">{formattedCreatedDate}</span>
                                                        {canEditComment &&
                                                            <div className="note-action-btn">
                                                                <span className="pop-over-btn" onClick={() => setOpenedOptionsCommentId(note.id)}>
                                                                    {openedOptionsCommentId === note.id &&
                                                                        <ClickOutsideListner onOutsideClick={() => setOpenedOptionsCommentId(null)}>
                                                                            <div className="pop-over-options-wrapper">
                                                                                <ul className="pop-over-options">
                                                                                    <li className={commentSaveOpertionInProgress ? 'disabled' : ''} onClick={(e) => { e.stopPropagation(); handleCommentEditBtn(note.id, null, note.created_at) }}>Edit</li>
                                                                                    <li className={commentSaveOpertionInProgress ? 'disabled' : ''} onClick={(e) => { e.stopPropagation(); handleCommentDeleteBtn(note.id, null, note.created_at) }}>Delete</li>
                                                                                </ul>
                                                                            </div>
                                                                        </ClickOutsideListner>
                                                                    }
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    {editCommentId !== note.id && (
                                                        <div className='content'>
                                                            <StenoRenderer html={note.note} />
                                                        </div>
                                                    )}
                                                    {editCommentId === note.id &&
                                                        <div className="note-edit-form">
                                                            <div className="editor steno-editor-focused">
                                                                <Steno
                                                                    html={editCommentFormText}
                                                                    onChange={handleEditFormInputChange}
                                                                    ref={editFormFnRef}
                                                                    innerRef={editFormEditorRef}
                                                                    placeHolder="Edit Note"
                                                                    sendMsgOnEnter={true}
                                                                    onEnterClickLogic={(value) => {
                                                                        if (!commentSaveOpertionInProgress) {
                                                                            handleEditFormInputChange(value);
                                                                            handleCommentSaveChangesBtn(note.parent_note_id);
                                                                        }
                                                                    }}
                                                                    {...STENO_EDITOR_TOOLBAR_PROPS}
                                                                />
                                                                <div className='form-footer'>
                                                                    <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                                                                    <div className="submit-cancel-btns">
                                                                        <button
                                                                            className="btn btn-small"
                                                                            onClick={() => handleCommentSaveChangesBtn(note.parent_note_id)}
                                                                            disabled={commentSaveOpertionInProgress}
                                                                        >
                                                                            <i></i>
                                                                            <span>Save Changes</span>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-small"
                                                                            onClick={() => setEditCommentId(null)}
                                                                            disabled={commentSaveOpertionInProgress}
                                                                        >
                                                                            <i></i>
                                                                            <span>Cancel</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={'child-note-count' + (loadingChildNotes ? ' disabled' : '')} onClick={() => handleViewThreadBtn(note.id)}>
                                                        <span className="count">{`${note.child_notes_count > 1 ? 'Replies' : 'Reply'}`} {note.child_notes_count || ''}</span>
                                                        {loadingChildNotes && <span className="loading"></span>}
                                                    </div>
                                                </div>


                                                <div className="note-collapsible-content" >
                                                    {canComment && <div className="note-reply-form">
                                                        <div className={`editor ${isReplyExpanded ? 'steno-editor-focused' : ''}`}>
                                                            <Steno
                                                                isToolBarVisible={isReplyExpanded}
                                                                html={replyCommentFormText}
                                                                onChange={handleCommentReplyFormInputChange}
                                                                onFocus={() => handleCommentReplyFormInputFocus(note.id)}
                                                                ref={replyFormFnRef}
                                                                innerRef={replyFormEditorRef}
                                                                placeHolder="Reply"
                                                                sendMsgOnEnter={true}
                                                                onEnterClickLogic={(value) => {
                                                                    if (!commentSaveOpertionInProgress) {
                                                                        handleCommentReplyFormInputChange(value);
                                                                        handleCommentReplySaveBtn();
                                                                    }
                                                                }}
                                                                {...STENO_EDITOR_TOOLBAR_PROPS}
                                                            />
                                                            {isReplyExpanded &&
                                                                <div className='form-footer'>
                                                                    <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                                                                    <div className="submit-cancel-btns">
                                                                        <button
                                                                            className="btn btn-small"
                                                                            disabled={commentSaveOpertionInProgress}
                                                                            onClick={handleCommentReplySaveBtn}
                                                                        >
                                                                            <i></i>
                                                                            <span>Save</span>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-small"
                                                                            disabled={commentSaveOpertionInProgress}
                                                                            onClick={() => handleCommentReplyFormCancelBtn()}
                                                                        >
                                                                            <i></i>
                                                                            <span>Cancel</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>}

                                                    {noteReplies.length > 0 &&
                                                        <div className="note-replies-wrapper">
                                                            {noteReplies.map((noteReply, i) => {
                                                                const formattedCreatedDate = giveDateTimeInString(new Date(noteReply.created_at));
                                                                // TODO check below code - Did some fix
                                                                const canEditReply = canComment && noteReply.created_by === userInfo.id;
                                                                // const canEditReply = true;
                                                                return (
                                                                    <div className="note-widget" key={i}>
                                                                        <div className="header">
                                                                            <Avatar
                                                                                // imgSrc={candidate.img_url}
                                                                                // alt={`${candidate.img_url}'s pic`}
                                                                                firstName={noteReply.created_by_name.first_name}
                                                                                lastName={noteReply.created_by_name.last_name}
                                                                                height={20}
                                                                                width={20}
                                                                                fontSize={8}
                                                                            />
                                                                            <span className="name">{noteReply.created_by_name.first_name[0].toUpperCase() + noteReply.created_by_name.first_name.slice(1) + ' ' + (noteReply.created_by_name.last_name[0] ? noteReply.created_by_name.last_name[0].toUpperCase() + noteReply.created_by_name.last_name.slice(1) : '')}</span>
                                                                            <span className="date">{formattedCreatedDate}</span>
                                                                            {canEditReply &&
                                                                                <div className="note-action-btn">
                                                                                    <span className="pop-over-btn" onClick={() => setOpenedOptionsCommentId(noteReply.id)}>
                                                                                        {openedOptionsCommentId === noteReply.id &&
                                                                                            <ClickOutsideListner onOutsideClick={() => setOpenedOptionsCommentId(null)}>
                                                                                                <div className="pop-over-options-wrapper">
                                                                                                    <ul className="pop-over-options">
                                                                                                        <li className={commentSaveOpertionInProgress ? 'disabled' : ''} onClick={(e) => { e.stopPropagation(); handleCommentEditBtn(noteReply.id, note.id, noteReply.created_at) }}>Edit</li>
                                                                                                        <li className={commentSaveOpertionInProgress ? 'disabled' : ''} onClick={(e) => { e.stopPropagation(); handleCommentDeleteBtn(noteReply.id, note.id, noteReply.created_at) }}>Delete</li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </ClickOutsideListner>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {editCommentId !== noteReply.id && (
                                                                            <div className="content">
                                                                                <StenoRenderer html={noteReply.note} />
                                                                            </div>
                                                                        )}
                                                                        {editCommentId === noteReply.id && (
                                                                            <div className="note-edit-form">
                                                                                <div className="editor steno-editor-focused">
                                                                                    <Steno
                                                                                        html={editCommentFormText}
                                                                                        onChange={handleEditFormInputChange}
                                                                                        ref={editFormFnRef}
                                                                                        innerRef={editFormEditorRef}
                                                                                        placeHolder="Reply"
                                                                                        sendMsgOnEnter={true}
                                                                                        onEnterClickLogic={(value) => {
                                                                                            if (!commentSaveOpertionInProgress) {
                                                                                                handleEditFormInputChange(value);
                                                                                                handleCommentSaveChangesBtn(noteReply.parent_note_id)
                                                                                            }
                                                                                        }}
                                                                                        {...STENO_EDITOR_TOOLBAR_PROPS}
                                                                                    />
                                                                                    <div className='form-footer'>
                                                                                        <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                                                                                        <div className="submit-cancel-btns">
                                                                                            <button
                                                                                                className="btn btn-small"
                                                                                                onClick={() => handleCommentSaveChangesBtn(noteReply.parent_note_id)}
                                                                                                disabled={commentSaveOpertionInProgress}
                                                                                            >
                                                                                                <i></i>
                                                                                                <span>Save Changes </span></button>
                                                                                            <button
                                                                                                className="btn btn-small"
                                                                                                onClick={() => setEditCommentId(null)}
                                                                                                disabled={commentSaveOpertionInProgress}
                                                                                            >
                                                                                                <i></i>
                                                                                                <span>Cancel</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })}
                                    {/* {!comments.filter(n => n.recruitment_funnel_stage_id === currentStage.id).length && */}
                                    {!comments.length &&
                                        <div className="empty-list-widget">
                                            <p>Comments added on this credit will appear here</p>
                                        </div>
                                    }
                                </>
                            </div>
                        </>
                    }
                </div>
            </>
        );
    }

    const creditDetailsContent = creditDetailsArray.filter(c => id !== 'my-profile' && c[0] === 'Status' ? null : c).map((c, i) => {
        return (<div className='credit-details-wrapper' key={i}>
            <div className='label-wrapper'>
                <span className='pl-label'>{c[0]}</span>
                <span className='pl-label'>:</span>
            </div>
            <div>{(c[0] === 'From' || c[0] === 'To') ?
                <div className='name-with-avatar'>
                    <Avatar
                        imgSrc={type === 'received' ? creditDetails.evaluator_img_url : creditDetails.recipient_img_url}
                        firstName={firstName}
                        lastName={lastName}
                        alt={firstName + "'s pic"}
                        height='16px'
                        width='16px'
                        borderRadius='50%'
                        fontSize='7px'
                    />
                    <span>{c[1]}</span>
                </div> : <span dangerouslySetInnerHTML={{ __html: c[1].replace(/\n/g, "<br/>") }}></span>}</div>
        </div>)
    })

    return (
        <div id='credit-wrapper'>
            <div className='metric-details-wrapper'>
                <span className={'metric-icon ' + creditDetails.metric_badge}></span>
                <div className='metric-details'>
                    <p>{creditDetails.metric_name}</p>
                    <p className='pl-label'>{creditDetails.metric_description}</p>
                </div>
                {id === 'my-profile' && <div className='action-btn-wrapper ml-auto'>
                    {/* {type === 'received' && creditDetails.status === 'Accepted' && <><span className='pl-label'>Public</span>
                        <label className="switch">
                            <input type="checkbox" checked={creditDetails.is_public} onChange={() => togglePublicVisibility(creditDetails.credit_id, creditDetails.is_public)} />
                            <span className="slider round"></span>
                            <span className='off'>OFF</span>
                            <span className='on'>ON</span>
                        </label></>} */}
                    {/* {type === 'received' ?
                        creditDetails.status !== 'Accepted' && (creditDetails.created_by_resource_id !== loggedInUserResId ?
                        <button type='button' className='btn btn-small' onClick={() => handleAccept(creditDetails.credit_id, creditDetails.status, creditDetails.is_public)}>Accept</button> :
                        <button type='button' className='btn btn-small' onClick={() => handleRevoke(creditDetails.credit_id, type)}>Revoke</button>) :
                        creditDetails.status !== 'Accepted' && (creditDetails.created_by_resource_id !== loggedInUserResId ?
                            <button type='button' className='btn btn-small' onClick={() => handleApprove(creditDetails.credit_id, creditDetails.status, creditDetails.is_public)}>Approve</button> :
                            <button type='button' className='btn btn-small' onClick={() => handleRevoke(creditDetails.credit_id, type)}>Revoke</button>)} */}

                    {creditDetails.status !== 'Accepted' && (creditDetails.created_by_resource_id !== loggedInUserResId ?
                        <button type='button' className='btn btn-small' onClick={() => type === 'received' ? handleAccept(creditDetails.credit_id, creditDetails.status, creditDetails.is_public) : handleApprove(creditDetails.credit_id, creditDetails.status, creditDetails.is_public)}>{type === 'received' ? 'Accept' : 'Approve'}</button> :
                        <button type='button' className='btn btn-small' onClick={() => handleRevoke(creditDetails.credit_id, type)}>Revoke</button>)}
                </div>}
            </div>
            {creditDetailsContent}
            {/* <div className={'child-note-count' + (loadingChildNotes ? ' disabled' : '')} onClick={() => handleViewThreadBtn(note.id)}> */}
            {(id === 'my-profile' || (type === 'received' && creditDetails.evaluator_resource_id === loggedInUserResId) || (type === 'given' && creditDetails.recipient_resource_id === loggedInUserResId)) && <div className='comments-wrapper'>
                <span
                    className={"comment-count" + (commentSectionExpanded ? ' comment-section-expanded' : '') + (creditDetails.notes_count < 1 ? ' no-comments' : '')}
                    onClick={() => {
                        creditDetails.notes_count > 0 && !commentSectionExpanded && getComments(creditDetails.credit_id);
                        // getComments(creditDetails.credit_id);
                        setCommentSectionExpanded(!commentSectionExpanded);
                    }}>
                    {`${creditDetails.notes_count > 1 ? 'Comments' : 'Comment'}`} {+creditDetails.notes_count || ''}
                </span>
                {/* {loadingChildNotes && <span className="loading"></span>} */}
                {/* {<span className="loading"></span>} */}
                {commentSectionExpanded && <div className='comments-container'>
                    {giveCommentsMainContent()}
                </div>}
            </div>}
        </div>
    )
}

export default Credit