import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AL_BASE_URL, REQUEST_TYPES } from "views/Attendance/constants";
import { API_BASE_URL } from "components/Constants";
// components
import RequestDatePicker from "./RequestDatePicker";
import ClickOutsideListner from "components/ClickOutsideListner";
import CreatePlusIcon from "views/Attendance/icons/CreatePlusIcon";
import RequestType from "./RequestType";
// services
import { alertService } from "services/alertService";
import APIService from "services/apiService";
// utils
import {
  addDays,
  areIntervalsOverlapping,
  differenceInDays,
  format,
  isBefore,
} from "utils/date";
import { convertTimeZone } from "views/Attendance/utils"
import { isBlankHTML, removeHTMLTags } from "utils/Common";
// styles
import "./LeaveRequestForm.scss";

const getLeaveStateObj = () => {
  return {
    start_date: "",
    end_date: "",
  };
};

const getLeaveStateDefault = () => {
  return [getLeaveStateObj()];
};

const getLeaveOptions = (selectedLeaveType) => {
  const { id: leaveOptionId } = selectedLeaveType || {};
  const {
    1: isMedical = false,
    4: isBereavement = false,
    5: isPaternity = false,
    6: isMaternity = false,
  } = {
    [leaveOptionId]: true,
  };

  return { isMedical, isBereavement, isPaternity, isMaternity };
};


export default function LeaveRequestForm({
  leaveOptions,
  selectedLeaveType,
  selectedShiftType,
  setSelectedLeaveType,
  selectedFormType,
  setSelectedFormType,
  isEditMode,
  data,
  onClose,
  isFormChangesSavedFnRef,
  onSubmit,
  onResubmit,
  loggedInUser,
  setDiscardChangesAlert,
}) {

  const leaveRequestFormRef = useRef();

  const { isMedical, isBereavement, isPaternity, isMaternity } = getLeaveOptions(selectedLeaveType);

  const shortNoticeMessage = "Your leave start date is less than 72 hours away and will be categorized as short-notice, potentially impacting your attendance record";
  const overdueMessage = "Your leave start date is past and will be categorized as overdue, potentially impacting your attendance record";

  const [leaveDays, setLeaveDays] = useState(() =>
    isEditMode
      ? structuredClone([...data.leave_days, getLeaveStateDefault()])
      : getLeaveStateDefault()
  );

  const getLeaveDaysData = () => {
    let data = [...leaveDays];
    if (data.length > 1) {
      // Remove the last leave day card if it's empty.
      // This is to prevent to remove the last leave day card (if empty) when the user is trying to submit the form
      const lastLeaveDay = leaveDays[leaveDays.length - 1];
      if (lastLeaveDay.start_date === "" && lastLeaveDay.end_date === "") {
        data.pop();
      }
    }

    return data;
  };

  const [comment, setComment] = useState(() =>
    isEditMode && data.comment ? removeHTMLTags(data.comment) : ""
  );
  const [showRequestTypeMenu, setShowRequestTypeMenu] = useState(false);

  const [medicalAttachments, setMedicalAttachments] = useState(() => {
    if (isEditMode && isMedical && data.request_attachment_url) {
      return data.request_attachment_url.split(",");
    }
    return [];
  });

  const toggleRequestTypeMenu = () => {
    setShowRequestTypeMenu((prev) => !prev);
  };

  function addMedicalFiles() {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/*, .pdf";

    input.onchange = (e) => {
      const files = Array.from(e.target.files).filter((file) => {
        const isAlreadyAdded = medicalAttachments.some((attachment) => {
          return attachment.name === file.name && attachment.size === file.size;
        });

        if (isAlreadyAdded) {
          alertService.error(`${file.name} is already added`);
          return false;
        }

        if (file.size > 10485760) {
          alertService.error(`${file.name} size exceeds 10MB`);
          return false;
        }

        return true;
      });

      setMedicalAttachments((prev) => [...prev, ...files]);
      input.remove();
    };

    input.click();
  }

  function removeMedicalAttachment(index) {
    medicalAttachments.splice(index, 1);
    setMedicalAttachments([...medicalAttachments]);
  }

  const toggleFocus = (index) => {
    if (index !== leaveFocused) setLeaveFocused(index);
  };

  const checkOverdueOrShortNotice = ({ leaveData = leaveDays, leaveType = selectedLeaveType } = {}) => {
    const { isMedical, isBereavement, isPaternity, isMaternity } = getLeaveOptions(leaveType);
    const createdDate = format(isEditMode ? data.created_at : convertTimeZone(), "YYYY-MM-DD");
    const isOverdue = leaveData.some((day) => day.start_date && day.start_date < createdDate);

    const overmorrowDate = format(addDays(createdDate, 2), "YYYY-MM-DD");
    const isShortNotice = leaveData.some((day) => day.start_date && day.start_date <= overmorrowDate);

    if (isOverdue) {
      setErrorMessage(overdueMessage);
      return;
    }

    if (isShortNotice && !isBereavement && !isMedical && !isMaternity && !isPaternity) {
      setErrorMessage(shortNoticeMessage);
      return;
    }

    setErrorMessage("");
  }

  function removeLeave(index) {
    if (leaveDays.length <= 1) {
      setLeaveDays(getLeaveStateDefault());
    } else if (index === leaveDays.length - 1) {
      leaveDays[index] = getLeaveStateObj();
      setLeaveDays([...leaveDays]);
    } else {
      leaveDays.splice(index, 1);
      setLeaveDays([...leaveDays]);
    }
    if (leaveFocused) toggleFocus(null);
    checkOverdueOrShortNotice({ leaveData: leaveDays });
  }

  // contains date picker refs of each leave day card => [[startRef, endRef],...]
  const datePickerRefs = useRef([]);

  function handleDateChange(date, field, index) {
    if (field === "start_date") {
      const endDate = leaveDays[index].end_date;

      if (!endDate) {
        const datepickerRef = datePickerRefs.current[index][1];
        datepickerRef?.open?.();
      } else if (isBefore(endDate, date)) {
        leaveDays[index].end_date = date;
      }
    } else if (field === "end_date") {
      const startDate = leaveDays[index].start_date;

      if (!startDate) {
        const datepickerRef = datePickerRefs.current[index][0];
        datepickerRef?.open?.();
      } else if (isBefore(date, startDate)) {
        leaveDays[index].start_date = date;
      }
    }

    leaveDays[index][field] = date;

    checkOverdueOrShortNotice({ leaveData: leaveDays });
    // Add a new leave day card if the last leave day card is filled
    if (
      index === leaveDays.length - 1 &&
      leaveDays[index].start_date &&
      leaveDays[index].end_date
    ) {
      setLeaveDays([...leaveDays, getLeaveStateObj()]);
      return;
    }

    setLeaveDays([...leaveDays]);
  }

  // Scroll to the last leave card when a new leave day is added
  useEffect(() => {
    if (leaveRequestFormRef.current) {
      leaveRequestFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [leaveDays.length]);

  const [errorMessage, setErrorMessage] = useState("");
  const [leaveFocused, setLeaveFocused] = useState(isEditMode ? null : 0);
  const [submittingRequest, setSubmittingRequest] = useState(false);

  const handleLeaveCardFocus = useCallback((index) => {
    setLeaveFocused(index);
  }, []);

  async function uploadMedicalReports(files) {
    let urls = [];
    let newFiles = [];

    for (const file of files) {
      if (typeof file === "string") {
        // Already uploaded file URL (from edit mode)
        urls.push(file);
      } else {
        newFiles.push(file);
      }
    }

    if (newFiles.length > 0) {
      const promises = await Promise.allSettled(
        newFiles.map((file) => {
          return new Promise(async (resolve, reject) => {
            let formData = new FormData();
            formData.append("request_attachment", file);

            try {
              const response = await APIService.apiRequest(
                API_BASE_URL + "/request_attachment",
                formData,
                false,
                "POST"
              );

              if (response.status === 1) {
                resolve(response.output);
              } else {
                reject(response.msg);
              }
            } catch (error) {
              console.log(
                `Failed to upload medical report ${file.name}`,
                error
              );
              reject(error);
            }
          });
        })
      );

      promises.forEach((result) => {
        result.status === "fulfilled"
          ? urls.push(result.value)
          : alertService.error(result.reason);
      });
    }

    return urls.join(",");
  }

  async function submitRequest() {
    setErrorMessage("");

    const leaveDays = getLeaveDaysData();
    const isAllLeaveDaysFilled = leaveDays.every((day) => {
      return day.start_date !== "" && day.end_date !== "";
    });

    if (!isAllLeaveDaysFilled) {
      setErrorMessage("Please fill all empty leave days input");
      return;
    }

    // Comment is not mandatory for maternity, paternity and bereavement
    if (!comment.trim() && !isMaternity && !isPaternity && !isBereavement) {
      setErrorMessage("Give a reason, please");
      return;
    }

    const isLeaveDaysOverlapping = leaveDays.some((day, index) => {
      if (!day.start_date || !day.end_date) return false;
      const startDate = new Date(day.start_date);
      const endDate = new Date(day.end_date);

      return leaveDays.some((day, i) => {
        if (i === index || !day.start_date || !day.end_date) {
          return false;
        }

        const sDate = new Date(day.start_date);
        const eDate = new Date(day.end_date);

        return areIntervalsOverlapping(
          { start: startDate, end: endDate },
          { start: sDate, end: eDate }
        );
      });
    });

    if (isLeaveDaysOverlapping) {
      setErrorMessage("Leave days are overlapping");
      return;
    }

    if (isMedical && showMedicalAttachBtn && medicalAttachments.length === 0) {
      setErrorMessage("Please attach medical report");
      return;
    }

    setSubmittingRequest(true);

    try {
      const apiPayload = {
        comment,
        data: leaveDays
          .filter(d => d.start_date && d.end_date).map((day) => {
            return {
              start_date: day.start_date,
              end_date: day.end_date,
              leave_type_id: selectedLeaveType.id,
            };
          })
          .sort((a, b) => new Date(a.end_date) - new Date(b.end_date)), // Sort the leave days by end date in ascending order
      };

      if (isMedical && showMedicalAttachBtn) {
        const attachments = await uploadMedicalReports(medicalAttachments);
        apiPayload.request_attachment_url = attachments;
        setMedicalAttachments(attachments.split(","));
      }

      let url = AL_BASE_URL + "/request/leave";
      if (isEditMode) url += `/${data.id}`;
      const response = await APIService.apiRequest(
        url,
        apiPayload,
        false,
        isEditMode ? "PUT" : "POST"
      );

      setSubmittingRequest(false);
      if (response.status === 1) {
        alertService.success(response.msg);
        const date = convertTimeZone();

        const leaveRequest = {
          approval_timestamp: null,
          data: apiPayload.data.map((leave) => {
            const createdDate = format(isEditMode ? data.created_at : convertTimeZone(), "YYYY-MM-DD");
            const overmorrowDate = format(addDays(createdDate, 2), "YYYY-MM-DD");

            const is_overdue = leave.start_date < createdDate ? 1 : 0;
            const is_short_notice = isMedical || isBereavement || isPaternity || isMaternity || leave.start_date > overmorrowDate ? 0 : 1;

            return { ...leave, leave_type: selectedLeaveType.name, is_overdue, is_short_notice };
          }),
          decline_timestamp: null,
          is_approved: 0,
          is_rejected: 0,
          manager_first_name: null,
          manager_img_url: null,
          manager_last_name: null,
          manager_middle_name: null,
          notes_count: 1,
          pending_timestamp: null,
          request_type: REQUEST_TYPES.LEAVE,
          resource_request_id: isEditMode ? data.id : response.request_id,
          reason: isEditMode ? data.comment : comment,
          reason_comment_id: isEditMode
            ? data.reason_comment_id
            : response.reason_comment_id,
          request_attachemnt_url: apiPayload?.request_attachment_url ?? null,
          unread_timestamp: isEditMode ? data.unread_timestamp : "0",
          updated_at: date,
          // utc_offset: myTimeZone.utc_offset,
          ...loggedInUser,
        };

        if (!isEditMode) {
          leaveRequest.created_at = date;
          onSubmit(leaveRequest);
        } else {
          onResubmit(leaveRequest);
          // Edit comment in case it modified
          if (data.comment !== comment) {
            let editCommentPayload = {
              note: comment,
            };

            const editCommentResponse = await APIService.apiRequest(
              AL_BASE_URL + `/notes/${data.reason_comment_id}`,
              editCommentPayload,
              false,
              "PUT"
            );

            if (editCommentResponse.status === 1) {
              const modifiedLeaveRequest = {
                ...leaveRequest,
                reason: editCommentPayload.note,
              };
              onResubmit(modifiedLeaveRequest);
              // alertService.success(editCommentResponse.msg);
            } else {
              alertService.error(
                editCommentResponse.msg || editCommentResponse.message
              );
            }
          }
        }

        onClose();
      } else {
        setErrorMessage(response.msg);
      }
    } catch (error) {
      console.log(error);
      setSubmittingRequest(false);
    }
  }

  const isFormChangesSaved = useCallback(() => {
    const leaveDays = getLeaveDaysData();
    if (!isEditMode) {
      if (
        // selectedLeaveType.id !== selectedLeaveType.id ||
        comment !== "" ||
        !isBlankHTML(comment) ||
        leaveDays.length > 1 ||
        leaveDays[0].start_date !== "" ||
        leaveDays[0].end_date !== ""
      ) {
        return false;
      }

      return true;
    } else {
      if (
        selectedLeaveType.name !== data.leave_days[0].leave_type ||
        comment !== data.comment ||
        leaveDays.length !== data.leave_days.length
      ) {
        return false;
      }

      const isLeaveDaysSame = leaveDays.every((day, index) => {
        return (
          day.start_date === data.leave_days[index].start_date &&
          day.end_date === data.leave_days[index].end_date
        );
      });

      if (!isLeaveDaysSame) {
        return false;
      }

      return true;
    }
  }, [isEditMode, comment, leaveDays, selectedLeaveType, data]);

  useEffect(() => {
    isFormChangesSavedFnRef.current = isFormChangesSaved;
    return () => {
      isFormChangesSavedFnRef.current = null;
    }
  }, [isFormChangesSaved]);

  // const { width: screenWidth } = useWindowDimensions();
  // const isDesktopScreen = screenWidth > 640;

  const showMedicalAttachBtn = useMemo(() => {
    if (!isMedical) return false;

    const totalMedicalLeaves = leaveDays.reduce((acc, day) => {
      if (day.start_date && day.end_date) {
        return (acc += differenceInDays(day.end_date, day.start_date) + 1);
      }
      return acc;
    }, 0);

    return totalMedicalLeaves > 2;
  }, [isMedical, leaveDays]);

  return (
    <div className="leave-request-form" ref={leaveRequestFormRef}>
      {/* Form Header */}
      <div className="leave-request-form-header">
        <RequestType
          selectedLeaveType={selectedLeaveType}
          selectedShiftType={selectedShiftType}
          selectedFormType={selectedFormType}
          options={leaveOptions}
          onFormChange={(formType) => {
            if (typeof isFormChangesSavedFnRef.current === 'function') {
              if (isFormChangesSavedFnRef.current() === false) {
                setDiscardChangesAlert({
                  show: false,
                  yesHandler: () => {
                    setDiscardChangesAlert(null);
                    setSelectedFormType(formType);
                  },
                });
                return;
              }
            }
            setSelectedFormType(formType);
          }}
          onRequestTypeChange={(type) => {
            setSelectedLeaveType(type)
          }}
          disabled={submittingRequest || isEditMode}
        />
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="leave-cards">
        {leaveDays.map((day, index, arr) => {
          const isFocused = leaveFocused === index;
          const showDeleteBtn = isFocused || day.start_date || day.end_date;

          return (
            <div className="leave-card" key={index}>
              {showDeleteBtn && (
                <button
                  className="leave-card-remove-btn"
                  onClick={() => removeLeave(index)}
                  disabled={submittingRequest}
                >
                  <span className="icon icon-minus"></span>
                </button>
              )}
              <div className="leave-card-day">
                <ClickOutsideListner
                  onOutsideClick={(e) => {
                    if (
                      e.target.className !==
                      "request-date-picker-btn focused placeholder" &&
                      e.target.className !== "request-date-picker-btn focused"
                    ) {
                      toggleFocus(null);
                    }
                  }}
                >
                  <div
                    className={`leave-card-range ${isFocused ? " focused" : ""
                      }`}
                    onClick={() => handleLeaveCardFocus(index)}
                  >
                    <div className="start hyphen-in-after">
                      <RequestDatePicker
                        ref={(el) => {
                          if (!datePickerRefs.current[index]) {
                            datePickerRefs.current[index] = [el];
                          } else {
                            datePickerRefs.current[index][0] = el;
                          }
                        }}
                        date={day.start_date}
                        placeholder="Start date"
                        onChange={(date) =>
                          handleDateChange(date, "start_date", index)
                        }
                        isFocused={isFocused}
                        disabled={submittingRequest}
                      />
                    </div>
                    {/* <div className="hyphen"></div> */}
                    <div className="end">
                      <RequestDatePicker
                        ref={(el) => (datePickerRefs.current[index][1] = el)}
                        date={day.end_date}
                        placeholder="End date"
                        onChange={(date) =>
                          handleDateChange(date, "end_date", index)
                        }
                        isFocused={isFocused}
                        disabled={submittingRequest}
                      />
                    </div>
                  </div>
                </ClickOutsideListner>
              </div>
            </div>
          );
        })}
      </div>

      <div className="leave-request-form-comment">
        <textarea
          className="form-comment-area"
          placeholder="Reason"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={250}
        />
        <span className='textLength'>{`${comment?.length} / 250`}</span>
      </div>

      {showMedicalAttachBtn && (
        <div className="medical-attachments-wrapper">
          <span className="help-text">For medical leave exceeding two days, please attach a health report to your leave request.</span>
          <div className="attachments">
            {medicalAttachments.map((attachment, index) => {
              let fileName =
                typeof attachment === "string"
                  ? attachment.split("_T_L_").pop()
                  : attachment.name;

              return (
                <div className="attachment" key={`${index}-${fileName}`}>
                  <span className="icon-file"></span>
                  <div className="name" title={fileName}>
                    {fileName}
                  </div>
                  <button
                    className="remove-btn"
                    onClick={() => removeMedicalAttachment(index)}
                  >
                  </button>
                </div>
              );
            })}
            <button
              className="add-attachment-btn btn-block"
              onClick={() => addMedicalFiles()}
            >
              <span className="icon-attachment"></span>
              <span>Attach Report</span>
            </button>
          </div>
        </div>
      )}

      <div className="leave-request-form-actions">
        <button className="btn-block" onClick={() => submitRequest()} disabled={submittingRequest}>
          Submit
        </button>
        <button className="btn-block" onClick={() => onClose()} disabled={submittingRequest}>
          Cancel
        </button>
      </div>
    </div>
  );
}
