import { useEffect, useState } from 'react';

// services
import APIService from 'services/apiService';
import { alertService } from 'services/alertService';

// context
import { useAttendanceFilters } from '../../context';

// components
import Panel from 'components/Panel';
import LineLoader from 'components/LineLoader';
import { MouseTooltip } from 'components/ToolTip';
import Accordion from 'components/Accordion';
import ClickOutsideListner from 'components/ClickOutsideListner';
import DatePicker from 'components/ReactCalendar/DatePicker';

// hooks
import useUserData from 'hooks/useUserData';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useClickPreventionOnDoubleClick from 'hooks/useClickPreventionOnDoubleClick';

// utils and constants
import { endOfYear, format, startOfYear, subDays, subMonths, subYears } from 'utils/date';
import { AL_BASE_URL } from '../../constants';

// styles
import './UserAnalytics.scss';
import '../../components/RequestsModal/Filter.scss';

const UserAnalytics = ({ id, isLoggedUser, toggleAnalytics }) => {
    const { user, isManager } = useUserData();
    const { date } = useAttendanceFilters();
    const { width } = useWindowDimensions();

    const defaultPeriod = `${format(startOfYear(date))}_${format(endOfYear(date))}`;

    const [showLoader, setShowLoader] = useState(true);
    const [leaveDetails, setLeaveDetails] = useState(null);

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(null);
    const [focusedRangePicker, setFocusedRangePicker] = useState(null);
    const [period, setPeriod] = useState(() => defaultPeriod);
    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);

    const todayDate = format(new Date(), "YYYY-MM-DD");
    // const yesterdayDate = format(subDays(todayDate, 1), "YYYY-MM-DD");
    const lastWeekDate = format(subDays(todayDate, 7), "YYYY-MM-DD");
    const lastMonthDate = format(subMonths(todayDate, 1), "YYYY-MM-DD");
    const pastThreeMonthsBeforeDate = format(
        subMonths(todayDate, 3),
        "YYYY-MM-DD"
    );
    const pastSixMonthsBeforeDate = format(subMonths(todayDate, 6), "YYYY-MM-DD");
    const lastYearDate = format(subYears(todayDate, 1), "YYYY-MM-DD");

    const isFilterApplied = period !== defaultPeriod;

    const openModal = () => {
        !isFilterMenuOpen && setIsFilterMenuOpen(true);
    }

    const onClear = () => {
        setPeriod(defaultPeriod);
        setStartDateRange(null);
        setEndDateRange(null);
    }

    const toggleFilterMenu = () => {
        setIsFilterMenuOpen(!isFilterMenuOpen);
        setFocusedRangePicker(null);
    }

    const [handleFilterClick, handleFilterDoubleClick] = useClickPreventionOnDoubleClick(openModal, () => isFilterApplied && onClear());

    const isDesktopVersion = width > 640;

    const onChange = (dateRange) => {
        setPeriod(dateRange);
    }

    const handleDatePickerToggle = (e) => {
        if (!['start-range', 'end-range'].includes(e.target.id) || (e.target.id === 'start-range' && focusedRangePicker === 'start') || (e.target.id === 'end-range' && focusedRangePicker === 'end')) {
            setFocusedRangePicker(null)
        }
    }

    function handleDateChange(date) {
        let _date = date;
        let start_date = startDateRange, end_date = endDateRange;
        if (focusedRangePicker === 'start') {
            if (endDateRange < _date) {
                end_date = _date;
                setEndDateRange(_date);
            }
            start_date = _date;
            setStartDateRange(_date);
            setFocusedRangePicker(!endDateRange ? 'end' : null);
        } else {
            if (startDateRange > _date) {
                start_date = _date;
                setStartDateRange(_date);
            }
            end_date = _date;
            setEndDateRange(_date);
            setFocusedRangePicker(!startDateRange ? 'start' : null);
        }

        if (start_date && end_date) {
            onChange(`${start_date}_${end_date}`);
        }
    }

    function handleDateRadioChange(e) {
        onChange(e.target.value);
        if (startDateRange) setStartDateRange(null);
        if (endDateRange) setEndDateRange(null);
    }

    useEffect(() => {
        const controller = new AbortController();
        const fetchLeaveDetails = async () => {
            try {
                setShowLoader(true);

                const [startDate, endDate] = period.split('_');
                const url = new URL(AL_BASE_URL + '/resource_analytics/');
                url.searchParams.append('resource_id', id);
                url.searchParams.append('org_id', user.organization_id);
                url.searchParams.append('start_date', format(startDate, 'YYYY-MM-DD'));
                url.searchParams.append('end_date', format(endDate, 'YYYY-MM-DD'));

                const response = await APIService.apiRequest(
                    url.toString(),
                    null,
                    false,
                    "GET",
                    controller
                );

                if (response.status === 1) {
                    setLeaveDetails(response);
                } else {
                    if (response?.message?.includes('aborted')) return;
                    alertService.error(response.msg);
                }
                setShowLoader(false);
            } catch (err) {
                setShowLoader(false);
                console.log('fetchLeaveDetails catch', err);
                alertService.error(err.message);
            }
        };

        fetchLeaveDetails();
        return () => controller.abort();
    }, [id, period]);

    const maxPaidLeaves = leaveDetails?.leaves?.max_paid_leaves
    const totalLeaves = leaveDetails?.leaves?.total_leaves;
    let paidLeaves = 0;
    let unpaidLeaves = 0;
    if (maxPaidLeaves != null) {
        if (totalLeaves > maxPaidLeaves) {
            paidLeaves = maxPaidLeaves;
            unpaidLeaves = totalLeaves - maxPaidLeaves;
        } else {
            paidLeaves = totalLeaves
        }
    }

    return (
        <Panel
            isOpen={!!id}
            onClose={toggleAnalytics}
            width={600}
            id={`user-analytics-${id}`}
            top={`calc(var(--header-height-other-tab) + 2px)`}
        >
            <div className='attendance-modal user-analytics-wrapper'>
                <LineLoader
                    show={showLoader}
                    position="absolute"
                />
                <div className='requests-header'>
                    <div className='title'>Analytics</div>

                    <div className='filter-wrapper'>
                        <div className='filter-container'>
                            <Panel
                                isOpen={isFilterMenuOpen}
                                onClose={toggleFilterMenu}
                                transitionType='fade-in'
                                top="100%"
                                button={
                                    <MouseTooltip
                                        asChild
                                        delay={500}
                                        show={isDesktopVersion && isFilterApplied}
                                        content={'Double click to clear'}
                                        style={{ lineHeight: "14px" }}
                                    >
                                        <div>
                                            <button
                                                className={`filter-icon-wrapper ${isFilterMenuOpen ? 'active' : ''} ${isFilterApplied ? 'filter-applied' : ''}`}
                                                onClick={handleFilterClick}
                                                onDoubleClick={handleFilterDoubleClick}
                                            >
                                                <div className={`icon icon-filter`}></div>
                                                {isFilterApplied && <span>{isFilterApplied ? 1 : 0}</span>}
                                            </button>
                                        </div>
                                    </MouseTooltip>
                                }
                            >
                                <div className='filter-panel'>
                                    <div className={`period-wrapper ${focusedRangePicker ? 'increase-height' : ''}`}>
                                        <Accordion
                                            label={<div className='period-header'>
                                                <div>Period</div>
                                            </div>}
                                            id='selectedPeriod'
                                            // isFilterApplied={!(state.dateCreatedAt === "" && state.requestedAt === "" && state.updatedAt === "")}
                                            isExpanded={true}
                                            // onExpand={() => expandAccordion('selectedPeriod')}
                                            children={<>
                                                <div className='date-range-wrapper'>
                                                    {focusedRangePicker || startDateRange || endDateRange ? (
                                                        <div className={`date-range ${startDateRange && endDateRange && !focusedRangePicker ? 'unfocused' : ''}`}>
                                                            <button
                                                                id='start-range'
                                                                className={`select-date ${focusedRangePicker === 'start' ? 'active' : ''}`}
                                                                onClick={() => setFocusedRangePicker(prev => prev === 'start' ? null : 'start')}
                                                            >
                                                                {startDateRange ?? 'Start Date'}
                                                            </button>
                                                            <button
                                                                id='end-range'
                                                                className={`select-date ${focusedRangePicker === 'end' ? 'active' : ''}`}
                                                                onClick={() => setFocusedRangePicker(prev => prev === 'end' ? null : 'end')}
                                                            >
                                                                {endDateRange ?? 'End Date'}
                                                            </button>

                                                            {focusedRangePicker && <ClickOutsideListner onOutsideClick={handleDatePickerToggle}><div className='date-picker-wrapper'>
                                                                <DatePicker
                                                                    showInline
                                                                    date={focusedRangePicker === 'start' ? startDateRange : endDateRange}
                                                                    onChange={handleDateChange}
                                                                />
                                                            </div></ClickOutsideListner>}
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className='select-date'
                                                            onClick={() => setFocusedRangePicker('start')}
                                                        >
                                                            Select Date
                                                        </button>
                                                    )}
                                                </div>
                                                {/* <div className="radio">
                                                <input
                                                    type="radio"
                                                    id="any-time"
                                                    name="date"
                                                    value={""}
                                                    onChange={handleDateRadioChange}
                                                    checked={state.dateCreatedAt === "" && state.requestedAt === "" && state.updatedAt === ""}
                                                />
                                                <label htmlFor="any-time">Any Time</label>
                                            </div> */}
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id="last-7-days"
                                                        name="date"
                                                        value={`${lastWeekDate}_${todayDate}`}
                                                        onChange={handleDateRadioChange}
                                                        checked={period === `${lastWeekDate}_${todayDate}`}
                                                    />
                                                    <label htmlFor="last-7-days">Last 7 days</label>
                                                </div>
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id="last-30-days"
                                                        name="date"
                                                        value={`${lastMonthDate}_${todayDate}`}
                                                        onChange={handleDateRadioChange}
                                                        checked={period === `${lastMonthDate}_${todayDate}`}
                                                    />
                                                    <label htmlFor="last-30-days">Last 30 days</label>
                                                </div>
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id="last-3-months"
                                                        name="date"
                                                        value={`${pastThreeMonthsBeforeDate}_${todayDate}`}
                                                        onChange={handleDateRadioChange}
                                                        checked={period === `${pastThreeMonthsBeforeDate}_${todayDate}`}
                                                    />
                                                    <label htmlFor="last-3-months">Last 3 months</label>
                                                </div>
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id="last-6-months"
                                                        name="date"
                                                        value={`${pastSixMonthsBeforeDate}_${todayDate}`}
                                                        onChange={handleDateRadioChange}
                                                        checked={period === `${pastSixMonthsBeforeDate}_${todayDate}`}
                                                    />
                                                    <label htmlFor="last-6-months">Last 6 months</label>
                                                </div>
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id="last-12-months"
                                                        name="date"
                                                        value={`${lastYearDate}_${todayDate}`}
                                                        onChange={handleDateRadioChange}
                                                        checked={period === `${lastYearDate}_${todayDate}`}
                                                    />
                                                    <label htmlFor="last-12-months">Last 12 months</label>
                                                </div>
                                            </>}
                                        />
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className='requests-body'>
                    {/* {!showLoader && leaveDetails ? ( */}
                    {!showLoader && leaveDetails ? (
                        <div className='leave-card-wrapper'>
                            <div className='leave-card-header'>
                                <div className='leave-card-title'>Total Leaves</div>
                                <div className='leave-card-value'>{totalLeaves}</div>
                            </div>
                            {maxPaidLeaves != null && (isLoggedUser || isManager) ? (
                                <div className='leave-card-compensation'>
                                    <div className='leave-paid'>
                                        <div className='leave-card-title'>Paid</div>
                                        <div className='leave-card-value'>{paidLeaves} / {maxPaidLeaves}</div>
                                    </div>
                                    <div className='leave-unpaid'>
                                        <div className='leave-card-title'>Unpaid</div>
                                        <div className='leave-card-value'>{unpaidLeaves}</div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </Panel>
    )
}

export default UserAnalytics