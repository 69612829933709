import SpeedSelect from 'react-speedselect';
import { REQUEST_TYPES } from 'views/Attendance/constants';
import './RequestType.scss';

const RequestType = ({
    disabled,
    options,
    onFormChange,
    onRequestTypeChange,
    selectedFormType,
    selectedLeaveType,
    selectedShiftType,
}) => {
    const isLeaveForm = selectedFormType === REQUEST_TYPES.LEAVE;

    return (
        <div className={`request-type-container ${disabled ? 'disabled' : ''}`}>
            <div className="toggle-wrapper">
                <button
                    className={`toggle-option ${isLeaveForm ? 'active' : ''}`}
                    onClick={() => !isLeaveForm && onFormChange(REQUEST_TYPES.LEAVE)}
                    disabled={!isLeaveForm && disabled}
                >
                    <span>Apply Leave</span>
                </button>
                <button
                    className={`toggle-option ${!isLeaveForm ? 'active' : ''}`}
                    onClick={() => isLeaveForm && onFormChange(selectedShiftType.id)}
                    disabled={isLeaveForm && disabled}
                >
                    <span>Change Shift</span>
                </button>
            </div>
            <SpeedSelect
                key={isLeaveForm}
                options={options}
                selectedOption={isLeaveForm ? selectedLeaveType : selectedShiftType}
                onSelect={(type) => {
                    onRequestTypeChange(type);
                }}
                displayKey='name'
                uniqueKey='id'
                selectLabel='Select'
                disable={!isLeaveForm && disabled}
                disableSearch={true}
            />
        </div>
    )
}

export default RequestType