import { useMemo, useState, useEffect } from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import LineLoader from "components/LineLoader";
import EditArticle from "./EditArticle";
import ViewArticle from "./ViewArticle";
import { WIKI_ARTICLES_URL } from "../constants";
import { useWikiContext } from "../context";
import { useTabContext } from "components/SuperTabs/TabContext";
import APIService from "services/apiService";

export default function Article() {
  const { id } = useParams();
  const location = useLocation();
  const isNewArticle = useRouteMatch("/app/wiki/article/new-:id")?.isExact === true;
  const isEditMode = useMemo(() => {
    const url = new URLSearchParams(location.search);
    return url.get("edit") === "true";
  }, [location]);

  const { modifyTab, activeSubTabRef, activeSubTab } = useTabContext();
  const { articlesCache, setArticlesCache } = useWikiContext();
  const article = articlesCache.get(id)?.data;

  const [loadingArticle, setLoadingArticle] = useState(false);

  useEffect(() => {
    if (!isNewArticle && !articlesCache.has(id)) {
      const controller = new AbortController();
      const fetchArticle = async () => {
        setLoadingArticle(true);
        try {
          const url = new URL(WIKI_ARTICLES_URL);
          url.searchParams.append("article_id", id);
          url.searchParams.append("verbose", true);
          const res = await APIService.apiRequest(
            url.toString(),
            null,
            false,
            "GET",
            controller,
          );
          if (res.data.length > 0) {
            const article = res.data[0];
            setArticlesCache((prev) => {
              const temp = new Map(prev);
              temp.set(
                id,
                structuredClone({
                  data: article,
                  editState: article,
                }),
              );
              return temp;
            });

            if (
              activeSubTabRef.current &&
              activeSubTabRef.current.title === "Loading..."
            ) {
              modifyTab({
                oldTab: activeSubTabRef.current,
                newTab: {
                  id: article.id,
                  name: article.title,
                  url: activeSubTabRef.current.url,
                  showEditButton: true,
                  isEditMode: true,
                },
                isSubTab: true,
              });
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingArticle(false);
        }
      };

      fetchArticle();
      return () => controller.abort();
    } else {
      setLoadingArticle(false);
    }
  }, [id]);

  const tabName = activeSubTab?.title ?? activeSubTab?.name;

  return (
    <>
      <LineLoader show={loadingArticle} position="sticky" />
      {!loadingArticle &&
        (!isEditMode && article ? (
          <ViewArticle />
        ) : isNewArticle || article ? (
          <EditArticle isNewArticle={isNewArticle} />
        ) : (
          <div className="article-not-found">
            <h4>{tabName.toLowerCase().startsWith('loading') ? 'Untitled' : tabName}</h4>
            <span>Article is not available</span>
          </div >
        ))
      }
    </>
  );
}
