import { useLayoutEffect, useRef, useState } from 'react'
import { useAttendanceFilters } from '../context'
import clsx from 'utils/clsx'
import '../components/HomeTabs.scss'

export const TABS = {
    ALL_HANDS: 'allHands',
    ANALYTICS: 'analytics',
}

const HomeTabs = () => {
    const { activeHomeTab, setActiveHomeTab } = useAttendanceFilters()    
    const [activeTab, setActiveTab] = useState(activeHomeTab[0])
    const timeoutRef = useRef(null);

    useLayoutEffect(() => {
        if (activeHomeTab.length === 2 && activeTab === TABS.ALL_HANDS) {
            document.querySelector('.company-analytics-wrapper')?.scrollIntoView()
        }
    }, [activeHomeTab, activeTab])

    const updateActiveTab = (tab) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)

        const className = tab === TABS.ALL_HANDS ? '.attendance-table-wrapper' : '.company-analytics-wrapper'
        setActiveTab(tab)
        setActiveHomeTab(prev => [...prev, tab])

        // Render both components and scroll to the selected tab for adding smooth scroll effect
        setTimeout(() => {
            document.querySelector(className)?.scrollIntoView({ behavior: 'smooth', inline: 'start' })
        }, 0)

        // Unmount the other component after a delay
        timeoutRef.current = setTimeout(() => {
            setActiveHomeTab(prev => prev.filter(t => t === tab))
        }, 700);
    }

    return (
        <div className='tab-group'>
            <button
                className={clsx('tab', activeTab === TABS.ALL_HANDS && 'active')}
                onClick={() => updateActiveTab(TABS.ALL_HANDS)}
            >
                All Hands
            </button>
            <button
                className={clsx('tab', activeTab === TABS.ANALYTICS && 'active')}
                onClick={() => updateActiveTab(TABS.ANALYTICS)}
            >
                Analytics
            </button>
        </div>
    )
}

export default HomeTabs