import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import SuperPager from 'super-pager';
// context
import { useGlobalContext } from 'contexts/GlobalContext';
import { useTabContext } from 'components/SuperTabs/TabContext';
// components
import Profile from './Profile/index';
import CreateProfile from './Profile/Edit/index';
import ViewProfile from './Profile/View/index';
import LineLoader from 'components/LineLoader';
import { withKeepAlive } from 'components/KeepAlive';
import { PAGE_IDS } from 'components/Navigation';
// constants
import { API_BASE_URL, SITE_PREFIX } from '../../components/Constants.js';
// context
import { ProfileProvider } from './ProfileContext';
// hooks
import useUserData from 'hooks/useUserData';
import useScrollRestoration, { clearScrollPositions } from 'hooks/useScrollRestoration';
// services
import APIService from 'services/apiService';
import { alertService } from 'services/alertService.js';
import { emitter } from 'components/SuperTabs/SuperTabs';
// utils
import { isEmpty, debounce } from 'utils/Common';
import { getUniqueId } from 'utils/Utils';
// styles
import 'styles/Profile.scss';
import './style.scss';

const getMyProfileTab = (userData) => {
    return {
        id: userData?.resource?.id.toString(),
        name: 'My Profile',
        isFixedTab: true,
        showName: false,
        firstName: userData?.resource?.first_name,
        lastName: userData?.resource?.last_name,
        img_url: userData?.resource?.img_url || null,
        editMode: false,
        privileges: 'EDIT',
    }
}

const ProfileHomeNew = (props) => {
    const { isHR, user: currentUser } = useUserData();
    const { isSelectedOrgEmployee } = useGlobalContext();
    const { openSuperTabOnRowClick } = useTabContext();

    // const { search } = useLocation();
    // const searchParams = new URLSearchParams(search);
    // const isEditMode = searchParams.get("edit");

    // Variables
    const lockScrollRef = useRef(false);
    const currentStatusRef = useRef();
    const searchTextRef = useRef();
    const metaDataRef = useRef();
    const abortControllerRef = useRef(new AbortController());

    const [updateHome, setUpdateHome] = useState(false);
    const [lockScroll, setLockScroll] = useState(false);
    const [metaData, setMetaData] = useState(null);
    metaDataRef.current = metaData;
    const [editMode, setEditMode] = useState([]);
    const [deletedProfile, setDeletedProfile] = useState([]);

    const [currentUserData] = useState(() => {
        return currentUser?.resource?.id ? { resource_id: currentUser.resource.id.toString() } : null
    });

    const [profileImage, setProfileImage] = useState(null);

    const [showTabEditButton, setShowTabEditButton] = useState(false);
    const [imageFileStore, setImageFileStore] = useState({});
    const [fetchedProfile, setFetchProfile] = useState(() => {
        const newProfile = localStorage.getItem(SITE_PREFIX + 'new_profile');
        return newProfile ? JSON.parse(newProfile) : {}
    });
    const fetchProfileRef = useRef(fetchedProfile);
    const [imageData, setImageData] = useState({});
    const [profileSearch, setProfileSearch] = useState(0);
    const [searchFlag, setSearchFlag] = useState(false);
    // const [tabMode, setMode] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [showLineLoader, setShowLineLoader] = useState(false);
    const [profileData, setProfileData] = useState([]);
    const [StoreProfileData, setStoreProfileData] = useState({
        profileData: [],
        metaData: [],
    });
    const [searchText, setSearchText] = useState('');
    searchTextRef.current = searchText;
    const [currentStatus, setCurrentStatus] = useState('All');
    currentStatusRef.current = currentStatus;
    const [country, setCountry] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [error_contact_phone, setContactPhone] = useState({});
    const [error_contact_email, setContactEmail] = useState({});
    const [error_contact_social, setContactSocial] = useState({});

    const profileListWrapperDOMRef = useRef();
    const [pageSize, setPageSize] = useState(60);

    const handleSearchInputChange = debounce((e) => {
        const searchText = e.target.value.trim();
        setSearchText(searchText);
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        fetchProfiles(currentStatus, searchText, pageSize, 1, abortControllerRef.current);
    }, 450);
    // const location = useLocation();

    const updateFetchProfile = (profile) => {
        if (typeof profile === 'function') {
            fetchProfileRef.current = profile(fetchProfileRef.current);
        } else {
            fetchProfileRef.current = profile;
        }
        localStorage.setItem(SITE_PREFIX + 'new_profile', JSON.stringify(fetchProfileRef.current));
        setFetchProfile(fetchProfileRef.current);
    }

    // Profile View
    const handleProfileView = async (profile) => {
        const tab = {
            firstName: profile.first_name,
            lastName: profile.last_name,
            imgSrc: profile.img_url,
            id: profile.id,
            showAvatar: true,
            showEditButton: parseInt(currentUserData?.resource_id) === profile.id || 'HR',
            isEditMode: false,
            url: `/app/profile/${profile.id}`
        }

        if (profile && profile.img_url && profile.img_url !== '') {
            setProfileImage(profile.img_url);
        }
        openSuperTabOnRowClick({ tab });
    };

    const fetchNextProfiles = async (statusFilter = '', search = '', pageNo) => {
        setLockScroll(true);
        lockScrollRef.current = true;

        let url = new URL(API_BASE_URL + '/resource');
        url.searchParams.append('page_size', pageSize);
        url.searchParams.append('page_number', pageNo);

        if (!isHR) {
            url.searchParams.append('status', 'employee');
        }

        if (search.trim()) {
            url.searchParams.append('search_string', search.trim());
        }

        url = url.toString();

        setCurrentStatus(statusFilter || 'All');
        try {
            const response = await APIService.apiRequest(url, null, false, 'GET');

            if (response.status === 1 && response.output) {
                const profilesData = response.output;
                setProfileData((prevValue) => [...prevValue, ...profilesData.data]);
                setMetaData(profilesData.meta);
                setProfileSearch(profilesData.meta.total_results);
            }
        } catch (error) {
            alertService.error(error.message || 'Some error occured while fetching profiles');
        } finally {
            setLockScroll(false);
            lockScrollRef.current = false;
        }
    };

    useEffect(() => {
        // if (isEditMode) {
        //     props.history.replace(location.pathname)
        // }

        let page_size = pageSize;
        if (profileListWrapperDOMRef.current) {
            const PROFILE_BOX_HEIGHT = 90;
            const clientHeight = profileListWrapperDOMRef.current.clientHeight;
            // 4 is the number of profiles are shown in a row
            page_size = Math.floor(clientHeight / PROFILE_BOX_HEIGHT) * 4;
            setPageSize(page_size);
        }

        (async () => {
            fetchLanguageData();
            fetchCountryData();
            // if (isDefaultOrganization) await setResourceId(currentUser);
            // fetchTabData(url_id);
            await fetchProfiles('', '', page_size, 1);
        })();
        return () => {
            clearScrollPositions('profile-');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unsub = emitter.subscribe('superTabClose', ({ appId, isSubTab, tab }) => {
            if (appId === PAGE_IDS.PROFILE && isSubTab) {
                const id = SITE_PREFIX + tab.id.toString().replace('new/', '');

                setFetchProfile(({ [id]: _, ...newState }) => {
                    localStorage.setItem(SITE_PREFIX + 'new_profile', JSON.stringify(newState));
                    return newState;
                })
            }
        })
        return () => unsub();
    }, []);

    const fetchCountryData = async () => {
        try {
            const response = await APIService.apiRequest(
                API_BASE_URL + `/country`,
                null,
                false,
                'GET'
            );

            if (response.status === 1 && response?.output?.length > 0) {
                const countryData = response.output;
                let country_array = [];

                if (countryData && countryData.length > 0) {
                    countryData.forEach((country) => {
                        country_array.push(country.name);
                    });
                }

                setCountry(country_array);
            }
        } catch (error) {
            alertService.error(error.message);
        }
    };

    const fetchLanguageData = async () => {
        try {
            const response = await APIService.apiRequest(
                API_BASE_URL + `/language`,
                null,
                false,
                'GET'
            );

            if (response.status === 1 && response?.output?.length > 0) {
                setLanguages(response.output);
            }
        } catch (error) {
            alertService.error(error.message);
        }
    };

    // Function to fetch profile data.
    const fetchProfiles = async (statusFilter = '', search = '', pageSize, pageNo = 1, abortController) => {
        setLockScroll(true);
        lockScrollRef.current = true;

        let url = new URL(API_BASE_URL + '/resource');
        url.searchParams.append('page_size', pageSize);
        url.searchParams.append('page_number', pageNo);

        if (!isHR) {
            url.searchParams.append('status', 'employee');
        }

        if (search.trim()) {
            url.searchParams.append('search_string', search.trim());
        }

        url = url.toString();

        setCurrentStatus(statusFilter || 'All');

        try {
            const response = await APIService.apiRequest(url, null, false, 'GET', abortController);

            if (response.status === 1 && isEmpty(response.output) === false) {
                if (search === '' && (statusFilter === 'All' || statusFilter === '')) {
                    setSearchFlag(false);
                } else {
                    setSearchFlag(true);
                }

                const profilesData = response.output;
                setMetaData(profilesData.meta);
                setProfileSearch(profilesData.meta.total_results);
                setProfileData(profilesData.data);

                if (search === '' && statusFilter === '') {
                    setStoreProfileData({
                        profileData: profilesData.data,
                        metaData: profilesData.meta,
                    });
                }
                setLoading(false);
                setLockScroll(false);
                lockScrollRef.current = false;
            }
        } catch (error) {
            alertService.error(error.message || 'Some error occured while fetching profiles');
            setLoading(false);
            setLockScroll(false);
            lockScrollRef.current = false;
        }
    };

    // Declaration of tab variable
    const [tabsInfo, setTabsInfo] = useState(() => {
        let myProfileTab = null;
        if (isSelectedOrgEmployee) {
            myProfileTab = getMyProfileTab(currentUser);
        }
        return {
            tabs: [{ id: 'home', name: 'home' }, ...(myProfileTab ? [myProfileTab] : [])],
            activeTabId: 'home', // stores the id of currently opened tab
            newTabCounter: 1, // to name the tabs when more than one tab is being added
        }
    });

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchText !== '') {
            setLoading(true);
            fetchProfiles(currentStatus, searchText, pageSize, 1);
        }
    };

    const handleClearBtn = () => {
        if (!updateHome) {
            setProfileData(StoreProfileData);
            setProfileData(StoreProfileData.profileData);
            setMetaData(StoreProfileData.metaData);
        } else {
            fetchProfiles('', '', pageSize, 1);
            setUpdateHome(false);
        }
        document.querySelector('#txt-search').value = '';
        setSearchText('');
        setSearchFlag(false);
        setCurrentStatus('All');
    };

    const openNewTab = useCallback(() => {
        const id = `new/${getUniqueId()}`;
        openSuperTabOnRowClick({ tab: { id, name: 'New Profile', url: `/app/profile/${id}` }, isSubTab: true });
    }, [openSuperTabOnRowClick]);

    useEffect(() => {
        const unsub = emitter.emit('bindCallback', { id: PAGE_IDS.PROFILE, callback: openNewTab });
        return () => unsub?.();
    }, [openNewTab]);

    return (
        <div className='app-wrapper profile-home'>
            <ProfileProvider>
                <div style={{ height: '1px', position: 'relative' }}>
                    <LineLoader show={lockScroll || isLoading || loadingProfile || showLineLoader} position='absolute' />
                </div>
                <div className="profile-details-wrapper" ref={profileListWrapperDOMRef}>
                    <div className='profile-home-inner'>
                        <Switch>
                            <Route path="/app/profile/home" exact>
                                {!isLoading && <div id='search-wrapper'>
                                    <div className='search-button-wrapper'>
                                        <div className='search-input-wrapper'>
                                            <form onSubmit={handleSearchSubmit}>
                                                <input
                                                    type='text'
                                                    name='txt-search'
                                                    id='txt-search'
                                                    defaultValue={searchText}
                                                    className='profile-input'
                                                    placeholder='Search by resource or current organization name'
                                                    onChange={handleSearchInputChange}
                                                />
                                            </form>
                                            <button
                                                className='btn-search'
                                            // onClick={handleSearchSubmit}
                                            >
                                                Search
                                            </button>
                                        </div>
                                        <button
                                            type='button'
                                            className='clear-button'
                                            onClick={handleClearBtn}
                                            title='Clear all filters'
                                        >
                                            Clear
                                        </button>
                                    </div>
                                    {!searchFlag && (
                                        <div id='profile-listing-header'>
                                            <span>Recently Added</span>
                                        </div>
                                    )}
                                    {searchFlag && profileSearch !== 0 && (
                                        <div id='profile-listing-header'>
                                            <span>Showing Results {profileSearch}</span>
                                        </div>
                                    )}
                                </div>}
                                <Home
                                    isLoading={isLoading}
                                    lockScrollRef={lockScrollRef}
                                    profileData={profileData}
                                    fetchNextProfiles={fetchNextProfiles}
                                    currentStatusRef={currentStatusRef}
                                    metaDataRef={metaDataRef}
                                    searchTextRef={searchTextRef}
                                    metaData={metaData}
                                    handleProfileView={handleProfileView}
                                />
                            </Route>
                            <Route path="/app/profile/new/:id">
                                <CreateProfile
                                    country={country}
                                    languages={languages}
                                    setLanguages={setLanguages}
                                    editModeData={editMode}
                                    history={props.history}
                                    tabsInfo={[tabsInfo, setTabsInfo]}
                                    setProfile={[fetchedProfile, updateFetchProfile]}
                                    // tabMode={[tabMode, setMode]}
                                    errorPhone={[error_contact_phone, setContactPhone]}
                                    errorEmail={[error_contact_email, setContactEmail]}
                                    errorSocial={[error_contact_social, setContactSocial]}
                                    openTab={async (profile) =>
                                        await handleProfileView(profile)
                                    }
                                    setImage={[imageData, setImageData]}
                                    setFile={[imageFileStore, setImageFileStore]}
                                    profileImage={[profileImage, setProfileImage]}
                                    showTabEdit={[showTabEditButton, setShowTabEditButton]}
                                    updateHome={[updateHome, setUpdateHome]}
                                    refreshHome={() => fetchProfiles(currentStatus, searchText, pageSize, 1)}
                                    setShowLineLoader={setShowLineLoader}
                                />
                            </Route>
                            <Route path="/app/profile/:id" render={(props) => {
                                const { id } = props.match.params;
                                const isCurrentUser = id === currentUserData?.resource_id;
                                const isEditMode = editMode.includes(id);

                                return (
                                    <>
                                        {(!loadingProfile && !deletedProfile.includes(id) && (isHR || isCurrentUser)) ? (
                                            <div className='toggle-button-wrapper'>
                                                <button onClick={() => setEditMode(prev => {
                                                    if (prev.includes(id)) {
                                                        return prev.filter(item => item !== id);
                                                    }
                                                    return [...prev, id];
                                                })}>
                                                    {isEditMode ? 'Editing' : 'Viewing'}
                                                </button>
                                            </div>
                                        ) : null}
                                        {isEditMode ? (
                                            <CreateProfile
                                                country={country}
                                                languages={languages}
                                                setLanguages={setLanguages}
                                                editModeData={editMode}
                                                history={props.history}
                                                tabsInfo={[tabsInfo, setTabsInfo]}
                                                setProfile={[fetchedProfile, setFetchProfile]}
                                                // tabMode={[tabMode, setMode]}
                                                errorPhone={[error_contact_phone, setContactPhone]}
                                                errorEmail={[error_contact_email, setContactEmail]}
                                                errorSocial={[error_contact_social, setContactSocial]}
                                                openTab={async (profile) => await handleProfileView(profile)}
                                                setImage={[imageData, setImageData]}
                                                setFile={[imageFileStore, setImageFileStore]}
                                                profileImage={[profileImage, setProfileImage]}
                                                showTabEdit={[showTabEditButton, setShowTabEditButton]}
                                                updateHome={[updateHome, setUpdateHome]}
                                                refreshHome={() => fetchProfiles(currentStatus, searchText, pageSize, 1)}
                                                currentUserData={currentUserData}
                                                setShowLineLoader={setShowLineLoader}
                                            />
                                        ) : (
                                            <ViewProfile
                                                country={country}
                                                setProfile={[fetchedProfile, setFetchProfile]}
                                                showTabEdit={[showTabEditButton, setShowTabEditButton]}
                                                profileImage={[profileImage, setProfileImage]}
                                                currentUserData={currentUserData}
                                                loadingProfile={loadingProfile}
                                                setLoadingProfile={setLoadingProfile}
                                                deletedProfile={deletedProfile}
                                                setDeletedProfile={setDeletedProfile}
                                            />
                                        )}
                                    </>
                                );
                            }} />
                        </Switch>
                    </div>
                </div>
            </ProfileProvider>
        </div>
    );
};

export default withKeepAlive(ProfileHomeNew, PAGE_IDS.PROFILE);

const Home = ({ isLoading, lockScrollRef, profileData, fetchNextProfiles, currentStatusRef, metaDataRef, searchTextRef, metaData, handleProfileView }) => {
    const homeScrollRef = useScrollRestoration('profile-home');
    return (<div id='profile-listing-wrapper' ref={homeScrollRef}>
        <div className='profile-listing-wrapper-inner'>
            <SuperPager
                type='infiniteScroll'
                dataLength={profileData.length || null}
                loadMore={() =>
                    !lockScrollRef.current &&
                    fetchNextProfiles(
                        currentStatusRef.current,
                        searchTextRef.current,
                        metaDataRef.current.page_number + 1
                    )
                }
                hasMore={metaData?.page_number < metaData?.num_pages}
                wrapper={true}
                children={
                    isLoading ? null : (
                        <>
                            {profileData.length > 0 ? (
                                profileData.map((profile) => (
                                    <Profile
                                        key={profile.id}
                                        profile={profile}
                                        onClick={() => handleProfileView(profile)}
                                    />
                                ))
                            ) : (
                                <div className='default-message'>
                                    <div className='default-message-text'>
                                        No relevant profiles found. Please try again later.
                                    </div>
                                </div>
                            )}
                        </>
                    )
                }
            />
        </div>
    </div>)
}