import clsx from "utils/clsx";
import { getShiftDetails } from "views/Attendance/utils";

export default function ShiftButton({
  data,
  date,
  selectedShiftMemberId,
  onClick,
  disabled = false
}) {
  const { isStandard } = getShiftDetails(data);
  const isStandardShiftChanged =
    isStandard && data.date === data.schedule_start_date;

  return (
    <button
      className={clsx(
        "shift-value",
        data.resource_id === selectedShiftMemberId &&
        data.date === date &&
        "active",
        isStandard && "on-standard-shift",
        isStandardShiftChanged && "star"
      )}
      id={`shift-details-${data.resource_id}-${data.date}`}
      onClick={onClick}
      disabled={disabled}
    >
      {data.shift}
    </button>
  );
}
