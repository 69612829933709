import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import APIService from "services/apiService";
import { alertService } from "services/alertService";

import { useAttendanceFilters } from "../../context";
import { useGlobalContext } from "contexts/GlobalContext";

import { MouseTooltip } from "components/ToolTip";

import { convertTimeZone } from "utils/Common";
import { format, formatTimeAgo } from "utils/date";
import { AL_BASE_URL } from "../../constants";

import "./CompanyTotalHours.scss";
import LineLoader from "components/LineLoader";

const convertToHrs = seconds => parseInt(seconds / 3600);
function isTodayOrYesterday(date) {
    const today = convertTimeZone();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0);

    return inputDate.getTime() === today.getTime() || inputDate.getTime() === yesterday.getTime();
}

const CompanyTotalHours = () => {
    const { date } = useAttendanceFilters();
    const { isAppInActive } = useGlobalContext();

    const abortControllerRef = useRef(new AbortController());
    const timeoutIdRef = useRef();

    const [totalHoursData, setTotalHoursData] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);

    const formattedDate = useMemo(() => format(date, 'YYYY-MM-DD'), [date]);
    const formattedTotalHours = useMemo(() => convertToHrs(totalHoursData[formattedDate]?.time || 0), [totalHoursData, formattedDate]);

    const getCompanyTotalHours = useCallback(async () => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
        setIsDataLoading(true);

        try {
            const response = await APIService.apiRequest(
                AL_BASE_URL + `/company_hour/${formattedDate}`,
                null,
                false,
                "GET",
                abortControllerRef.current
            );
            if (response.status === 1) {
                setIsDataLoading(false);
                const { total_time_in_sec: time, next_call_sec } = response.output;
                const fetchedAt = convertTimeZone();
                const nextCallAt = next_call_sec > 0 && isTodayOrYesterday(formattedDate) ? new Date(fetchedAt.getTime() + (next_call_sec * 1000)) : null;

                setTotalHoursData(prev => ({
                    ...prev,
                    [formattedDate]: {
                        time,
                        nextCallAt,
                        fetchedAt,
                    }
                }));
                if (nextCallAt) {
                    timeoutIdRef.current = setTimeout(getCompanyTotalHours, next_call_sec * 1000);
                }
            } else {
                if (response?.message?.includes('aborted')) return;
                console.log(`[getCompanyTotalHours] else - ${response?.msg || response?.message || 'Some error occured'}`);
                alertService.error(response.msg);
                setIsDataLoading(false);
            }
        } catch (error) {
            if (!error.name.includes('AbortError')) {
                alertService.error('Failed to fetch company total hours');
                console.error(`[getCompanyTotalHours] - ${error.message || 'Some error occurred'}`);
            }
            if (!abortControllerRef.current?.signal?.aborted || error.message === 'Failed to fetch') {
                setIsDataLoading(false);
            }
        }
    }, [formattedDate]);

    useEffect(() => {
        if (isAppInActive) return;
        abortControllerRef.current = new AbortController();
        getCompanyTotalHours();
        return () => {
            abortControllerRef.current.abort();
            clearTimeout(timeoutIdRef.current);
        }
    }, [getCompanyTotalHours, isAppInActive]);

    return (
        <MouseTooltip
            show={!!totalHoursData[formattedDate]?.nextCallAt}
            content={
                <ToolTip
                    key={showToolTip}
                    totalHoursData={totalHoursData}
                    formattedDate={formattedDate}
                />
            }
            style={{ lineHeight: "14px" }}
        >
            <div
                className="company-total-hours-wrapper"
                onClick={getCompanyTotalHours}
            >
                <LineLoader show={isDataLoading} position="absolute" />
                <div className="label">Total Hours</div>
                <div
                    className="company-total-hours"
                    onMouseEnter={() => setShowToolTip(!showToolTip)}
                    onMouseLeave={() => setShowToolTip(!showToolTip)}
                >
                    <span className="total-hrs">{formattedTotalHours}</span>
                </div>
            </div>
        </MouseTooltip >
    )
}

export const ToolTip = ({ totalHoursData, formattedDate }) => {
    const now = convertTimeZone();

    return (
        <div className="total-hours-tooltip">
            {totalHoursData[formattedDate]?.fetchedAt && <div>
                <span>Refreshed </span>
                {/* <span>{format(new Date(totalHoursData[formattedDate]?.fetchedAt), "ddd, MMM DD, YYYY, hh:mm A")}</span> */}
                <span>{formatTimeAgo(totalHoursData[formattedDate].fetchedAt, now)}</span>
            </div>}
            {totalHoursData[formattedDate]?.nextCallAt && <>
                <div>
                    <span>Auto Refresh </span>
                    <span>{formatTimeAgo(totalHoursData[formattedDate].nextCallAt, now).replace('just now', 'in a min')}</span>
                </div>
                <span>Click to refresh</span>
            </>}
        </div>
    )
}


export default CompanyTotalHours