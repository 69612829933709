import { fontStyle, AvatarSize, MarginLeft, SubTabPadding } from './Constants'
export const homeTab = { title: 'Home', id: 0, url: '/app' };
export const getUserDetailsFromToken = (token) => {
    if (token) {
        try {
            return JSON.parse(window.atob(token.split('.')[1].replace(/-/g, "+").replace(/_/g, "/")));
        } catch (error) {
            // ignore
        }
    }
    return null;
}

export const getUser = (key = 'token') => {
    let userDetails = getUserDetailsFromToken(localStorage.getItem(key))?.identity;
    return userDetails ? userDetails : null;
}

export const isEmpty = (value) => {
    if (value === null) return true;
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim().length === 0) return true;
    if (typeof value === 'object') {
        if (Array.isArray(value) && value.length === 0) {
            return true
        } else {
            return Object.keys(value).length === 0
        }
    }
    return false;
}

export const hasPrivilege = (prefix, { privilege }) => {
    const user = getUser(`${prefix}token`);
    const accessArray = Array.isArray(privilege) ? privilege : [privilege];

    return Object.values(user.privileges).some((rolePrivileges) =>
        accessArray.some((item) => rolePrivileges.includes(item))
    );
}

export function findBestMatchFromPages(pages, pathname) {
    // Check for exact match first
    let match = pages.find(page => page.url === pathname);
    if (match) {
        return match;
    }

    // Split the pathname into segments
    const pathSegments = pathname.split('/').filter(segment => segment);

    // Trim the pathname and check for matches
    while (pathSegments.length > 0) {
        const currentPath = '/' + pathSegments.join('/');
        match = pages.find(page => page.url.includes(currentPath));
        if (match) {
            return match;
        }
        pathSegments.pop();
    }

    return null; // No match found
}

export function isChildPath(parentPathName, childPathName) {
    // Check for exact match first
    if (parentPathName === childPathName) {
        return true;
    }

    // Split the childPathName into segments
    const pathSegments = childPathName.split('/').filter(segment => segment);

    // Trim the childPathName and check for matches
    while (pathSegments.length > 0) {
        const currentPath = '/' + pathSegments.join('/');
        if (parentPathName.includes(currentPath)) {
            return true;
        }
        pathSegments.pop();
    }

    return false; // No match found
}

export const getUniqueId = () => {
    return Math.random().toString(36).substring(2, 9);
}

export function reorder(list, startIndex, finishIndex) {
    if (startIndex === -1 || finishIndex === -1) {
        return list
    }

    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(finishIndex, 0, removed)

    return result
}

export const splitSubTabs = (subTabs) => {
    const itemsWithOrderZero = [];
    const itemsWithNonZeroOrder = [];

    subTabs.forEach(item => {
        if (item.order === 0) {
            itemsWithOrderZero.push(item);
        } else {
            itemsWithNonZeroOrder.push(item);
        }
    });
    return { itemsWithOrderZero, itemsWithNonZeroOrder };
}

export function reorderSubTabs(array, sourceIndex, destinationIndex) {
    const { itemsWithOrderZero, itemsWithNonZeroOrder } = splitSubTabs(array);

    const [movedItem] = itemsWithNonZeroOrder.splice(sourceIndex, 1);
    itemsWithNonZeroOrder.splice(destinationIndex, 0, movedItem);

    return itemsWithOrderZero.concat(
        itemsWithNonZeroOrder.map((item, index) => ({ ...item, order: index + 1 }))
    );
}

export function isDev() {
    return process.env.REACT_APP_CUSTOM_NODE_ENV === 'development';
}

export function removeSpaces(str) {
    return str.replace(/\s/g, '');
}

export const sortByOrder = (a, b) => {
    if (a.order === b.order) return a.open_order - b.open_order;
    return a.order - b.order
}

export function addOrUpdateSubTab({ array, newTab, maxOrder = 0, sort = false } = {}) {
    const existingObjectIndex = array.findIndex(obj => obj.id === newTab.id);
    if (existingObjectIndex !== -1) {
        const existingTab = { ...array[existingObjectIndex], ...newTab };
        const oldOrder = existingTab.order;
        const oldOpenOrder = existingTab.open_order;
        const minLegth = Math.min(array.length, maxOrder);

        if (existingTab.order !== 0 && oldOrder === existingTab.order) {
            return array.map(tab => {
                return tab.id === existingTab.id ? { ...tab, open_order: 1 } : { ...tab, open_order: tab.open_order < oldOpenOrder ? tab.open_order + 1 : tab.open_order };
            });
        }

        // Update the existing object
        array[existingObjectIndex] = { ...existingTab, order: oldOrder === 0 ? minLegth : oldOrder, open_order: 1 };

        // Increment the order of other objects with less than the old order
        for (let i = 0; i < array.length; i++) {
            if (array[i].id !== existingTab.id) {
                // if ((oldOrder !== 0 ? array[i].order <= oldOrder : true)) {

                if ((array[i].open_order === minLegth)) {
                    // array[i].order = (array[i].order + 1) % (maxOrder + 1);
                    array[i].order = 0;
                }
                if ((array[i].order === minLegth)) {
                    array[i].order -= 1;
                }
                // if (array[i].open_order >= maxOrder) {
                //     array[i].order = 0;
                // } else {
                //     array[i].order += 1;
                // }
                array[i].open_order += 1;
            }
        }

    } else {
        // New object, set its order and insert at the start
        newTab.order = array.length + 1;
        newTab.open_order = 1;
        array.push(newTab);

        // Update the order of existing objects
        for (let i = 0; i < array.length - 1; i++) {
            // array[i].order = (array[i].order + 1) % (maxOrder + 1);
            if (array[i].open_order >= maxOrder) {
                array[i].order = 0;
            }
            // array[i].order = array[i].order === 0 || array[i].order + 1 > maxOrder ? 0 : array[i].order + 1;
            array[i].open_order += 1;
        }
    }

    array.sort(sortByOrder);

    return array;
}

export function getTextWidth(text, font) {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set the font properties
    context.font = font;

    // Measure the text width
    const metrics = context.measureText(text);
    return Math.ceil(metrics.width * 100) / 100;
}

export function calculateWidth({ text, hasAvatar = false, addMargin = false, } = {}) {
    const textWidth = getTextWidth(text, fontStyle);

    const avatarWidth = hasAvatar ? AvatarSize : 0;
    const marginWidth = addMargin ? MarginLeft : 0;

    return textWidth + SubTabPadding + marginWidth + avatarWidth;
}

export function compareDecimals(num1, num2, decimalPlaces = 1) {
    // Convert numbers to strings
    let strNum1 = num1.toFixed(decimalPlaces + 1);
    let strNum2 = num2.toFixed(decimalPlaces + 1);

    // Extract the relevant portion
    strNum1 = strNum1.substring(0, strNum1.indexOf('.') + decimalPlaces + 1);
    strNum2 = strNum2.substring(0, strNum2.indexOf('.') + decimalPlaces + 1);

    if (strNum1 === strNum2) {
        return 0; // numbers are equal
    } else if (+strNum1 < +strNum2) {
        return -1; // num1 is less than num2
    } else {
        return 1; // num1 is greater than num2
    }
}

export function getCssUnitDifference(value1, value2, includeUnit = true) {
    // A regex pattern to match the number and the unit
    const regex = /^(-?\d*\.?\d+)([a-zA-Z%]+)$/;

    const match1 = value1.match(regex);
    const match2 = value2.match(regex);

    if (!match1 || !match2) {
        throw new Error("Invalid CSS value provided.");
    }

    const number1 = parseFloat(match1[1]);
    const unit1 = match1[2];

    const number2 = parseFloat(match2[1]);
    const unit2 = match2[2];

    // Check if both values have the same unit
    if (unit1 !== unit2) {
        throw new Error("CSS units do not match.");
    }

    const difference = number1 - number2;
    return `${difference}${includeUnit ? unit1 : ''}`;
}

export const getTitle = (tab) => {
    return (tab.title || tab.name || (tab?.firstName ? `${tab.firstName} ${tab.lastName || ''}` : 'Untitled')).trim();
}