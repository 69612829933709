import { useEffect, useMemo, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import LineLoader from "components/LineLoader";
import { MouseTooltip } from "components/ToolTip";
import { AL_BASE_URL } from "views/Attendance/constants";
import { useAttendanceFilters } from "views/Attendance/context";
import APIService from "services/apiService";
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";
import { alertService } from "services/alertService";
import clsx from "utils/clsx";
import "./GroupsPopup.scss";

export const GROUPS_DEFAULT_OPT = {
  name: "All Hands",
  default: true,
  resource_ids: [],
};

export default function GroupsPopup({ activeGroup, onGroupSelect, sortBy, handleSort }) {
  const controller = new AbortController();
  const [showLineLoader, setShowLineLoader] = useState(false);
  const { groups, setGroups, loadingGroups } = useAttendanceFilters();

  const searchInputRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);

  const suggestions = useMemo(() => {
    if (!searchInput || !showSearchInput) return groups;
    return groups.filter((group) =>
      group.name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [groups, showSearchInput, searchInput]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = (open) => {
    if (open === false) setActiveEditId(null);
    setIsPopoverOpen(open);
    setShowSearchInput(false);
    setSearchInput("");
    setShowCreateInput(false);
    setCreateInput("");
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    togglePopover,
    () => {
      if (isPopoverOpen) setIsPopoverOpen(false);
      onGroupSelect(GROUPS_DEFAULT_OPT);
    }
  );

  const createNewGroup = async (name) => {
    setShowLineLoader(true);
    try {
      const response = await APIService.apiRequest(
        AL_BASE_URL + "/group",
        { name },
        false,
        "POST",
        controller
      );
      if (response.status === 1) {
        setGroups((prev) => {
          return [
            {
              id: response.new_id,
              name,
              resource_ids: [],
            },
            ...prev,
          ];
        });
        setCreateInput("");
        setShowCreateInput(false);
      } else {
        alertService.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      alertService.error(`Error: ${error.message}`);
    } finally {
      setShowLineLoader(false);
    }
  };

  const [activeEditId, setActiveEditId] = useState(null);
  const [editText, setEditText] = useState("");
  const activeEditInputRef = useRef(null);

  useEffect(() => {
    const inputEl = activeEditInputRef.current;
    if (activeEditId && inputEl) {
      inputEl.focus();
      const index = inputEl.value.length;
      inputEl.setSelectionRange(index, index);
    }
  }, [activeEditId, activeEditInputRef]);

  const handleEdit = (id, name) => {
    setEditText(name);
    setActiveEditId(id);
  };

  const handleDelete = async (id) => {
    setShowLineLoader(true);
    try {
      const groupName = editText.trim();
      const response = await APIService.apiRequest(
        AL_BASE_URL + `/group/${id}`,
        { name: groupName },
        false,
        "DELETE",
        controller
      );
      if (response.status === 1) {
        setGroups((prev) => prev.filter((group) => group.id !== id));
        if (!activeGroup?.default && activeGroup.id === id) {
          onGroupSelect(GROUPS_DEFAULT_OPT);
        }
      } else {
        alertService.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      alertService.error(`Error: ${error.message}`);
    } finally {
      setShowLineLoader(false);
    }
  };

  const handleSubmit = async (group) => {
    const groupName = editText.trim();
    if (groupName === "" || groupName === group.name) {
      setActiveEditId(null);
      return;
    }

    setShowLineLoader(true);
    try {
      const response = await APIService.apiRequest(
        AL_BASE_URL + `/group/${group.id}`,
        { name: groupName },
        false,
        "PUT",
        controller
      );

      if (response.status === 1) {
        setGroups((prev) => {
          return prev.map((item) => {
            return {
              ...item,
              name: item.id === group.id ? groupName : item.name,
            };
          });
        });

        setActiveEditId(null);
        if (!activeGroup?.default && activeGroup.id === group.id) {
          onGroupSelect({ ...activeGroup, name: groupName });
        }
      } else {
        alertService.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      alertService.error(`Error: ${error.message}`);
    } finally {
      setShowLineLoader(false);
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setActiveEditId(null);
    setEditText("");
  };

  const ref = useRef(null);
  const [createInput, setCreateInput] = useState("");
  const [showCreateInput, setShowCreateInput] = useState(false);

  const handleOptionClick = (opt) => {
    onGroupSelect(opt);
    togglePopover(false);
  };

  const showDefaultGroup = useMemo(() => {
    if (searchInput && showSearchInput) {
      return GROUPS_DEFAULT_OPT.name
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    }

    return true;
  }, [searchInput, showSearchInput]);

  return (
    <div className="groups-popover" ref={ref}>
      <Popover
        placement="bottom-start"
        open={isPopoverOpen}
        onOpenChange={handleClick}
      >
        <MouseTooltip
          asChild
          delay={500}
          show={!isPopoverOpen && activeGroup?.default == null}
          content={"Double click for All Hands"}
          style={{ lineHeight: "17px", fontWeight: "normal" }}
        >
          <PopoverTrigger
            asChild
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
          >
            <button
              className={clsx(
                "groups-popover-trigger",
                isPopoverOpen && "active"
              )}
            >
              <span className="groups-icon"></span>
              <span className="group-name">{activeGroup.name}</span>
              {sortBy.column === "office_floor" && (
                <span
                  className={clsx(
                    "chevron-icon",
                    sortBy.order === "ascending"
                      ? "chevron-icon-up"
                      : "chevron-icon-down"
                  )}
                ></span>
              )}
            </button>
          </PopoverTrigger>
        </MouseTooltip>
        {/* Adding zIndex to fix the table sticky zIndex hide issue */}
        <PopoverContent root={ref} style={{ zIndex: 10, top: '4px' }}>
          <LineLoader
            show={loadingGroups || showLineLoader}
            position="absolute"
            style={{ top: "0px" }}
          />
          <div className="groups-popover-wrapper">
            <div className="wrapper-header">
              <div className="sort-wrapper">
                <span className="title">Sort</span>
                <div className="sort-inner-wrapper">
                  <div className="custom-radio">
                    <input
                      type="radio"
                      id="ascending"
                      checked={sortBy.column === "office_floor" && sortBy.order === "ascending"}
                      onChange={() => handleSort("office_floor", "ascending")}
                    />
                    <label htmlFor="ascending">
                      <span className="radio-mark"></span>
                      <span>Ascending</span>
                    </label>
                  </div>
                  <div className="custom-radio">
                    <input
                      type="radio"
                      id="descending"
                      checked={sortBy.column === "office_floor" && sortBy.order === "descending"}
                      onChange={() => handleSort("office_floor", "descending")}
                    />
                    <label htmlFor="descending">
                      <span className="radio-mark"></span>
                      <span>Descending</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="separator"></div>
              <div className="inner-wrapper">
                <button
                  className="add-group-btn"
                  onClick={() => {
                    if (activeEditId) setActiveEditId(null);
                    setShowCreateInput((prev) => !prev);
                  }}
                >
                  <i className="icon-plus"></i>
                  <span>Group</span>
                </button>
                <button
                  className="icon-search"
                  onClick={() => {
                    setSearchInput("");
                    setShowSearchInput(true);
                    setShowCreateInput(false);
                    setTimeout(() => searchInputRef?.current?.focus(), 201);
                  }}
                ></button>
                <div
                  className={clsx(
                    "groups-search-input-wrapper",
                    showSearchInput && "show"
                  )}
                >
                  <div className="groups-search-input">
                    <i className="search-icon"></i>
                    <input
                      type="text"
                      ref={searchInputRef}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <button
                    className="clear-icon"
                    onClick={() => setShowSearchInput(false)}
                  ></button>
                </div>
              </div>
            </div>
            <div className="groups">
              {!loadingGroups && (
                <ul>
                  {showDefaultGroup && (
                    <li className="group">
                      <button
                        className="group-btn"
                        onClick={() => handleOptionClick(GROUPS_DEFAULT_OPT)}
                      >
                        {GROUPS_DEFAULT_OPT.name}
                      </button>
                    </li>
                  )}
                  {showCreateInput && (
                    <li className="group edit-enabled">
                      <input
                        type="text"
                        placeholder="New Group"
                        autoFocus
                        className="edit-input"
                        value={createInput}
                        onChange={(e) => {
                          e.stopPropagation();
                          setCreateInput(e.target.value);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            createNewGroup(createInput.trim());
                          }
                          if (e.key === "Escape") handleCancel(e);
                        }}
                      />
                      <div className="group-cta">
                        {createInput.trim().length > 0 && (
                          <button
                            className="icon submit-icon slide-in-right"
                            disabled={showLineLoader}
                            onClick={(e) => createNewGroup(createInput.trim())}
                          ></button>
                        )}
                        <button
                          className="icon cancel-icon"
                          style={{ animation: "none" }}
                          disabled={showLineLoader}
                          onClick={() => setShowCreateInput(false)}
                        ></button>
                      </div>
                    </li>
                  )}
                  {suggestions.length > 0
                    ? suggestions.map((group) => {
                      return (
                        <li
                          key={group?.id ?? group.name}
                          className={`group ${activeEditId === group.id ? "edit-enabled" : ""
                            }`}
                          onClick={() => handleOptionClick(group)}
                        >
                          {activeEditId === group.id ? (
                            <input
                              type="text"
                              ref={activeEditInputRef}
                              className="edit-input"
                              value={editText}
                              onChange={(e) => {
                                e.stopPropagation();
                                setEditText(e.target.value);
                              }}
                              onClick={(e) => e.stopPropagation()}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit(group);
                                if (e.key === "Escape") handleCancel(e);
                              }}
                            />
                          ) : (
                            <button className="group-btn">
                              {group.name}
                            </button>
                          )}
                          {!group?.default && (
                            <div
                              className="group-cta"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button
                                className={clsx("icon submit-icon", {
                                  hidden: activeEditId !== group.id,
                                })}
                                onClick={(e) => handleSubmit(group)}
                                disabled={showLineLoader}
                              ></button>
                              <button
                                className={clsx("icon cancel-icon", {
                                  hidden: activeEditId !== group.id,
                                })}
                                onClick={handleCancel}
                                disabled={showLineLoader}
                              ></button>
                              <button
                                className={clsx("icon edit-icon", {
                                  hidden: activeEditId === group.id,
                                })}
                                onClick={(e) => {
                                  if (showCreateInput)
                                    setShowCreateInput(false);
                                  handleEdit(group.id, group.name);
                                }}
                                disabled={showLineLoader}
                              ></button>
                              <button
                                className={clsx("icon delete-icon", {
                                  hidden: activeEditId === group.id,
                                })}
                                onClick={(e) => handleDelete(group.id)}
                                disabled={showLineLoader}
                              ></button>
                            </div>
                          )}
                        </li>
                      );
                    })
                    : searchInput &&
                    suggestions.length === 0 &&
                    !showDefaultGroup && (
                      <li className="group empty-msg">
                        Nothing matches your search
                      </li>
                    )}
                </ul>
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
