import { useState, useRef } from "react";
// components
import { ATTENDANCE_STATUS_FILTER_OPTIONS, ATTENDANCE_PRESENCE_FILTER_OPTIONS } from "../constants";
import SearchInput from "components/SearchInput/SearchInput";
import Portal from "components/Portal";
import FilterButton from "components/FilterModal/FilterButton";
import Panel from "components/Panel";
import Accordion from "components/Accordion";
// context
import { useAttendanceFilters } from "../context";
// utils
import useWindowDimensions from "hooks/useWindowDimensions";
import { debounce } from "utils/Common";
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";
// styles
import "./AttendanceFilterBy.scss";
import './../components/RequestsModal/Filter.scss'

export default function AttendanceFilterBy({ showFilterButton }) {
  const {
    search,
    updateSearch,
    clearSearch,
  } = useAttendanceFilters();
  const isDesktopVersion = useWindowDimensions().width > 640;

  const [expandSearch, setExpandSearch] = useState(!!search);
  const searchInputElRef = useRef(null);

  const handleSearchInputChange = debounce((e) => {
    const searchText = e.target.value.trim();
    updateSearch(searchText);
  }, 450);

  function handleClearSearch() {
    if (searchInputElRef.current) searchInputElRef.current.value = "";
    if (expandSearch) setExpandSearch(false);
    clearSearch();
  }

  return (
    <div className="filter-by">
      {showFilterButton && <div className="filter-wrapper">
        <AttendanceFilterModal />
      </div>}

      {isDesktopVersion || expandSearch ? (
        <Portal
          to="#mobile-search-input-portal-wrapper"
          disabled={!expandSearch || isDesktopVersion}
        >
          <SearchInput
            ref={searchInputElRef}
            expand={expandSearch}
            setExpand={setExpandSearch}
            value={search}
            onChange={handleSearchInputChange}
            blurDelay={200}
            showCloseBtn={search}
            onClear={handleClearSearch}
            autoFocus={!isDesktopVersion}
            keepExpanded={isDesktopVersion}
          />
        </Portal>
      ) : null}

      {!isDesktopVersion && (
        <button
          className={"mobile-search-icon" + (expandSearch ? " active" : "")}
          onClick={() => setExpandSearch(true)}
        ></button>
      )}

    </div>
  );
}

const AttendanceFilterModal = () => {
  const {
    department,
    attendance,
    status,
    clearFilter,
    updateDepartment,
    resetDepartment,
    updateStatus,
    updateAttendance,
    departmentOptions,
    noOfFiltersApplied,
  } = useAttendanceFilters();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState('department');

  const expandAccordion = (val) => {
    if (val !== expandedAccordion) setExpandedAccordion(val);
  }

  function handleDepartmentChange({ isChecked, option }) {
    if (option.id === "all") {
      if (!isChecked) {
        resetDepartment();
      } else {
        updateDepartment(departmentOptions);
      }
    } else {
      if (!isChecked) {
        updateDepartment(department.filter((dep) => dep.id !== option.id));
      } else {
        updateDepartment([...department, option]);
      }
    }
  }

  const openModal = () => {
    !showFilterModal && setShowFilterModal(true)
  }

  const [handleFilterClick, handleFilterDoubleClick] = useClickPreventionOnDoubleClick(openModal, clearFilter);

  function handleAttendanceChange({ isChecked, option }) {
    if (!isChecked) {
      updateAttendance(attendance.filter((opt) => opt !== option.id));
    } else {
      updateAttendance([...attendance, option.id]);
    }
  }

  function handleStatusChange({ isChecked, option }) {
    if (!isChecked) {
      updateStatus(status.filter((opt) => opt !== option.id));
    } else {
      updateStatus([...status, option.id]);
    }
  }

  return (
    <div className={`filter-container ${showFilterModal ? 'active' : ''}`}>
      <Panel
        isOpen={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        button={
          <FilterButton
            onClick={handleFilterClick}
            onDoubleClick={() => noOfFiltersApplied > 0 && handleFilterDoubleClick()}
            filterCount={noOfFiltersApplied}
            tooltip={'Double click to clear'}
          />
        }
      >
        <div className='filter-panel'>
          <Accordion
            label='Department'
            id='department'
            isExpanded={expandedAccordion === 'department'}
            onExpand={() => expandAccordion('department')}
            data={[
              {
                id: "all",
                name: "All",
                checked: departmentOptions.length === department.length,
              },
              ...departmentOptions.map((option) => {
                return {
                  ...option,
                  checked: department.some((dep) => dep.id === option.id),
                };
              }),
            ]}
            onChange={handleDepartmentChange}
          />
          <Accordion
            label='Attendance'
            id='attendance'
            isExpanded={expandedAccordion === 'attendance'}
            onExpand={() => expandAccordion('attendance')}
            data={ATTENDANCE_STATUS_FILTER_OPTIONS.map((option) => ({
              ...option,
              checked: attendance.includes(option.id),
            }))}
            onChange={handleAttendanceChange}
          />
          <Accordion
            label='Presence'
            id='presence'
            isExpanded={expandedAccordion === 'presence'}
            onExpand={() => expandAccordion('presence')}
            data={ATTENDANCE_PRESENCE_FILTER_OPTIONS.map((option) => ({
              ...option,
              checked: status.includes(option.id),
            }))}
            onChange={handleStatusChange}
          />
        </div>
      </Panel>
    </div>
  );
}
