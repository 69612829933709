import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import { format } from 'utils/date';

// components
import { API_BASE_URL } from 'components/Constants';
// import Avatar from 'components/Avatar';
import APIService from 'services/apiService';
import { alertService } from 'services/alertService';

// utils
import { copyToClipBoard } from 'utils/Utils'
import { isEmail } from 'utils/Common';

function getSiteName(url) {
    let parsedUrl = new URL(url);
    let hostname = parsedUrl.hostname;
    hostname = hostname.replace(/^www\./, '');
    let siteName = hostname.split('.')[0];

    return siteName;
}

function formatDate(date) {
    const d = new Date(date);
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return `${d.toLocaleDateString('en-us', dateOptions)}, ${d.toLocaleTimeString('en-us', timeOptions)}`;
}

/**
 * Removes url protocols like http(s), www. & in. for UI
 */
function formatLink(link) {
    return link.replace(/^https?:\/\/(www.)?(in.)?/, '')
}

export default function CandidateDetails({ candidate, onEmailUpdate }) {
    const { user } = useUserData();
    const hasProfilePrivilege = user?.privileges?.hr?.includes('PROFILE_MANAGEMENT_HR');
    const profileUrl = `/app/profile/${candidate.id}`;
    const fullProfileUrl = `${window.location.origin}${profileUrl}`;

    const [submittingEmail, setSubmittingEmail] = useState(false);
    const [showEmailInput, setShowEmalInput] = useState(candidate?.email ? false : true);
    const [email, setEmail] = useState('');

    const submitEmail = () => {
        if (!isEmail(email)) {
            alertService.error("Invalid email address");
            return;
        }

        setSubmittingEmail(true);
        APIService.apiRequest(
            API_BASE_URL + `/resource/${candidate.id}`,
            { resource: { email } },
            false,
            "PUT"
        )
            .then((response) => {
                if (response.status === 1) {
                    alertService.success("Email has been updated");
                    onEmailUpdate(candidate, email);
                    setShowEmalInput(false);
                } else {
                    if (response.msg.includes('1062')) {
                        alertService.error(
                            'Duplicate entry found, please check email'
                        );
                        return;
                    }
                    alertService.error(response.msg);
                }
            })
            .catch((error) => {
                console.log(error);
                alertService.error("Failed to update email");
            }).finally(() => setSubmittingEmail(false));
    };

    return (
        <>
            <div className='candidate-details'>
                <div className="info">
                    <div className="label">Stage&nbsp;:&nbsp;</div>
                    <div className="value">{candidate.stage_Name}</div>
                </div>

                <div className="contact-info">
                    <div className="console-widget">
                        <div className="widget-header">
                            <div className="widget-title">Contact Details</div>
                        </div>
                        <div className="widget-content contact-details">

                            {candidate.phone && (
                                <div className='contact'>
                                    <a href={`tel:${candidate.phone}`}>
                                        <span className='icon icon-phone'></span>
                                    </a>
                                    <span className='contact-tooltip'>{candidate.phone}</span>
                                </div>
                            )}
                            {candidate.email && (
                                <div className='contact'>
                                    <a href={`mailto:${candidate.email}`}>
                                        <span className='icon icon-email'></span>
                                    </a>
                                    <span className='contact-tooltip'>{candidate.email}</span>
                                </div>
                            )}
                            {candidate.linkedin && (
                                <div className='contact'>
                                    <a href={candidate.linkedin} target='_blank' rel="noreferrer">
                                        <span className='icon icon-linkedin'></span>
                                    </a>
                                    <span className='contact-tooltip'>{candidate.linkedin}</span>
                                </div>
                            )}

                            {
                                !candidate.phone &&
                                !candidate.email &&
                                !candidate.linkedin &&
                                <span className='fs-10'>No Contact Info Available</span>
                            }
                        </div>
                        {showEmailInput && (
                            <div className='email-input-wrapper'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                                    <path
                                        fill="#B1B2B3"
                                        d="M10.512.282H1.5a1.5 1.5 0 0 0-1.5 1.5v6.436a1.5 1.5 0 0 0 1.5 1.5h9.01a1.5 1.5 0 0 0 1.5-1.5V1.782a1.5 1.5 0 0 0-1.498-1.5Zm-.38 2.484-3.862 3a.429.429 0 0 1-.527 0l-3.862-3a.43.43 0 1 1 .527-.677l3.6 2.8 3.6-2.8a.43.43 0 1 1 .527.677h-.003Z"
                                    />
                                </svg>
                                <input
                                    type='email'
                                    id="candidate-email"
                                    placeholder='Enter Email'
                                    autoComplete='off'
                                    autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={submittingEmail}
                                />
                                <button className='submit-icon' onClick={submitEmail} disabled={submittingEmail}></button>
                            </div>
                        )}
                    </div>
                </div>

                {(
                    candidate.date_of_birth ||
                    candidate.gender ||
                    candidate.city ||
                    candidate.state ||
                    candidate.social?.git ||
                    candidate.social?.portfolio
                ) && <div className="contact-info other-info">
                        <div className="console-widget">
                            {candidate.date_of_birth && <div className="widget-content">
                                <div className="label">Birth Date</div>
                                <div className="value">{format(new Date(candidate.date_of_birth), 'MMM DD, YYYY')}</div>
                            </div>}
                            {candidate.gender && <div className="widget-content">
                                <div className="label">Gender</div>
                                <div className="value">{candidate.gender}</div>
                            </div>}
                            {(candidate.city || candidate.state) && <div className="widget-content">
                                <div className="label">Location</div>
                                <div className="value">{`${candidate.city ? `${candidate.city}, ` : ''}${candidate.state ?? ''}`}</div>
                            </div>}
                            {candidate.social?.git && <a href={candidate.social.git} target='_blank' rel="noreferrer">
                                <div className="widget-content hyperlink">
                                    <div className="label">Git Respository</div>
                                    <div className="value">{getSiteName(candidate.social.git)}</div>
                                </div>
                            </a>}
                            {candidate.social?.portfolio && <a href={candidate.social.portfolio} target='_blank' rel="noreferrer">
                                <div className="widget-content hyperlink">
                                    <div className="label">Portfolio</div>
                                    <div className="value">{getSiteName(candidate.social.portfolio)}</div>
                                </div>
                            </a>}
                        </div>
                    </div>}

                <div className="contact-info">
                    <div className="console-widget">
                        <div className="widget-header">
                            <div className="widget-title">Share</div>
                        </div>
                        <div className="widget-content">
                            <div className='brand-logo details'>
                                <span className='link'>{formatLink(window.location.href)}</span>&nbsp;&nbsp;
                                <span
                                    className="btn-copy"
                                    title="Copy to clipboard"
                                    onClick={() => copyToClipBoard(window.location.href)}
                                >
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {hasProfilePrivilege && <div className="contact-info">
                    <div className="console-widget">
                        <div className="widget-header">
                            <div className="widget-title">Profile</div>
                        </div>
                        <div className="widget-content">
                            <div className='brand-logo details'>
                                <NavLink to={profileUrl}>
                                    <span className='link'>{formatLink(fullProfileUrl)}</span>
                                </NavLink>
                                <span
                                    className="btn-copy"
                                    title="Copy to clipboard"
                                    onClick={() => copyToClipBoard(fullProfileUrl)}
                                >
                                </span>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>

            <div className='candidate-funnel-history'>
                <span>{`Created on: ${formatDate(candidate.entered_recruitment_funnel_at)}`}</span>
                <span>{`Updated on: ${formatDate(candidate.last_changed_at)}`}</span>
            </div>
        </>
    )
}