import { useEffect, useRef, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Steno } from "react-steno";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover";
import StenoEditorToolbar from "../components/StenoEditorToolbar";
import AddTags from "../components/AddTags";
import { useTabContext } from "components/SuperTabs/TabContext";
import { getWikiArticleKey, useWikiContext } from "../context";
import { WIKI_ARTICLES_URL } from "../constants";
import useScrollRestoration from "hooks/useScrollRestoration";
import APIService from "services/apiService";
import { debounceAsync } from "utils/Common";
import { alertService } from "services/alertService";
import "./EditArticle.scss";

const saveArticle = debounceAsync(async (payload, isNew = false) => {
  const { id, title, featured_image_url, tags, body, controller } = payload;

  const url = new URL(WIKI_ARTICLES_URL);
  const formData = new FormData();
  formData.append("title", title);
  if (featured_image_url instanceof File) {
    formData.append("featured_image", featured_image_url);
    if (isNew) {
      formData.append("thumbnail_image", featured_image_url);
    }
  }

  if (tags.length > 0) {
    formData.append(
      "tags",
      JSON.stringify(tags.map((tag) => ({ id: tag.id }))),
    );
  }

  if (body.trim().length > 0) formData.append("body", body);

  if (isNew) {
    formData.append("slug", title.trim().replace(/\s+/g, "-").toLowerCase());
  } else {
    url.searchParams.append("article_id", id);
  }

  const res = await APIService.apiRequest(
    url.toString(),
    formData,
    false,
    !isNew ? "PUT" : "POST",
    controller,
  );

  return res;
}, 750);

export default function EditArticle({ isNewArticle }) {
  const history = useHistory();
  const { id } = useParams();

  const editorRef = useRef(null);
  const fnRef = useRef(null);

  const { articlesCache, setArticlesCache } = useWikiContext();
  const editState = useMemo(() => {
    if (!articlesCache.has(id)) return {};
    return articlesCache.get(id).editState;
  }, [id, articlesCache]);

  const {
    title = "",
    featured_image_url = "",
    tags = [],
    body = "",
  } = editState;

  const imageURL = useMemo(() => {
    if (featured_image_url instanceof File) {
      return URL.createObjectURL(featured_image_url);
    }
    return featured_image_url;
  }, [featured_image_url]);

  useEffect(() => {
    if (imageURL && imageURL.startsWith("blob")) {
      return () => URL.revokeObjectURL(imageURL);
    }
  }, [imageURL]);

  const { modifyTab, activeSubTab } = useTabContext();
  const { addArticleInList, updateArticleInList } = useWikiContext();

  const saveArticleData = ({ data, id, isNewArticle }) => {
    if (data) {
      const title = data?.title?.trim();
      const body = data?.body?.trim();
      const { featured_image_url, tags } = data;

      if (
        (id && !isNewArticle) ||
        (isNewArticle && title.length > 0 && featured_image_url instanceof File)
      ) {
        const payload = {
          id,
          title: title.trim(),
          featured_image_url,
          tags,
          body: body,
        };

        saveArticle(payload, isNewArticle)
          .then((res) => {
            if (res.article_id) {
              if (isNewArticle) localStorage.removeItem(getWikiArticleKey(id));
              const articleUrl = new URL(WIKI_ARTICLES_URL);
              articleUrl.searchParams.append("article_id", res.article_id);
              articleUrl.searchParams.append("verbose", true);

              APIService.apiRequest(articleUrl.toString(), null, false, "GET")
                .then((response) => {
                  if (response.data.length > 0) {
                    const article = response.data[0];
                    if (isNewArticle) {
                      setArticlesCache((prev) => {
                        const temp = new Map(prev);
                        // update the cache with the new article data
                        temp.set(
                          String(article.id),
                          structuredClone({
                            data: article,
                            editState: article,
                          }),
                        );
                        return temp;
                      });

                      // add the article in the list
                      addArticleInList({
                        ...article,
                        id: article.id,
                        title,
                        featured_image_url: article?.featured_image_url,
                        tags,
                        body,
                      });

                      history.replace(
                        `/app/wiki/article/${res.article_id}` +
                        history.location.search,
                      );

                      // remove the temp article from cache
                      setArticlesCache((prev) => {
                        const temp = new Map(prev);
                        temp.delete(id);
                        return temp;
                      });

                      modifyTab({
                        oldTab: activeSubTab,
                        newTab: {
                          id: article.id,
                          name: title,
                          url: `/app/wiki/article/${article.id}?edit=true`,
                          showEditButton: true,
                          isEditMode: true,
                        },
                        isSubTab: true,
                      });
                    } else {
                      if (activeSubTab.title !== title) {
                        modifyTab({
                          oldTab: activeSubTab,
                          newTab: {
                            id: activeSubTab.id,
                            name: title,
                            url: `/app/wiki/article/${article.id}?edit=true`,
                            showEditButton: true,
                            isEditMode: true,
                          },
                          isSubTab: true,
                        });
                      }

                      updateArticleInList({
                        ...article,
                        id: article.id,
                        title,
                        featured_image_url: article?.featured_image_url,
                        tags,
                        body,
                      });

                      setArticlesCache((prev) => {
                        const temp = new Map(prev);
                        temp.set(
                          String(article.id),
                          structuredClone({
                            data: article,
                            editState: article,
                          }),
                        );
                        return temp;
                      });
                    }
                  }
                })
                .catch((error) => console.log(error));
            } else {
              alertService.error(res.detail.msg);
            }
          })
          .catch((error) => console.log(error));
      }
    }
  };

  const updateEditState = (data, key) => {
    const temp = new Map(articlesCache);
    if (isNewArticle && !temp.has(id)) {
      temp.set(id, {
        data: null,
        editState: {
          title: "",
          featured_image_url: "",
          tags: [],
          body: "",
          [key]: data,
        },
      });
    } else {
      const payload = {
        ...temp.get(id),
        editState: { ...temp.get(id).editState, [key]: data },
      };
      temp.set(id, payload);

      if (isNewArticle) {
        localStorage.setItem(getWikiArticleKey(id), JSON.stringify(payload));
      }
      saveArticleData({ data: { ...payload.editState }, id, isNewArticle });
    }

    setArticlesCache(temp);
  };

  const uploadImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        updateEditState(file, "featured_image_url");
      }
    };
    input.click();
  };

  const popoverTriggerRef = useRef(null);

  const [editorState, setEditorState] = useState({
    horizontalAlignment: "left",
    fontSize: 12,
    fontFamily: "Open Sans",
  });

  useEffect(() => {
    const editor = editorRef.current;
    if (editor && editor?.firstChild?.style) {
      editor.firstChild.style.fontSize = editorState.fontSize;
      editor.firstChild.style.fontFamily = editorState.fontFamily;
    }
  }, [editorState.fontFamily, editorState.fontSize, editorRef]);

  const handleAlignment = (alignment) => {
    setEditorState({
      ...editorState,
      horizontalAlignment: alignment,
    });
  };

  const handleFontSize = (fontSize) => {
    if (fontSize > 0) {
      setEditorState({
        ...editorState,
        fontSize: fontSize,
      });
    }
  };

  const handleFontFamily = (fontFamily) => {
    setEditorState({
      ...editorState,
      fontFamily: fontFamily,
    });
  };

  const ref = useScrollRestoration(`wiki-article-edit-${id}`);
  return (
    <div className="edit-article">
      <StenoEditorToolbar
        fnRef={fnRef}
        handleAlignment={handleAlignment}
        fontSize={editorState.fontSize}
        handleFontSize={handleFontSize}
        fontFamily={editorState.fontFamily}
        handleFontFamily={handleFontFamily}
      />
      <div className="edit-article-wrapper" ref={ref}>
        <input
          className="title"
          type="text"
          placeholder="Enter Title here"
          onChange={(e) => updateEditState(e.target.value, "title")}
          value={title}
        />
        {imageURL ? (
          <div className="article-thumbnail-cover">
            <img
              className="article-thumbnail"
              src={imageURL}
              alt="article-thumbnail"
            />
            <div className="article-menu-wrapper" ref={popoverTriggerRef}>
              <Popover>
                <PopoverTrigger asChild>
                  <button className="menu-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="12"
                      fill="none"
                    >
                      <path
                        fill="#B1B2B3"
                        d="M.5 1.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM.5 10.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                      />
                    </svg>
                  </button>
                </PopoverTrigger>
                <PopoverContent root={popoverTriggerRef}>
                  <div className="menu">
                    <PopoverClose onClick={uploadImage}>
                      Upload New
                    </PopoverClose>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        ) : (
          <button className="upload-btn" onClick={uploadImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              fill="none"
            >
              <path
                fill="#B1B2B3"
                d="M.579 10.5A.58.58 0 0 1 0 9.921V.58A.583.583 0 0 1 .579 0h10.509c.32 0 .579.26.579.58V9.92a.583.583 0 0 1-.579.58H.578ZM10.5 7V1.167H1.167v8.167L7 3.5 10.5 7Zm0 1.65L7 5.15 2.816 9.334H10.5V8.65Zm-7-3.983a1.167 1.167 0 1 1 0-2.333 1.167 1.167 0 0 1 0 2.333Z"
              />
            </svg>
            <span>Upload Image</span>
          </button>
        )}
        <AddTags
          value={tags}
          onChange={(tags) => updateEditState(tags, "tags")}
        />
        <Steno
          placeHolder="Write something here"
          html={body ?? ""}
          isToolBarVisible={false}
          innerRef={editorRef}
          backgroundColor={"#FFFFFF"}
          ref={fnRef}
          onChange={(value) => updateEditState(value, "body")}
          horizontalAlignment={editorState.horizontalAlignment}
          fontSize={editorState.fontSize}
        />
      </div>
    </div>
  );
}
