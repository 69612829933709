import React from 'react';
import Avatar from 'components/Avatar';
import SuperLink from 'components/SuperTabs/SuperLink';
import './styles.scss'

const ProfileCard = (props) => {
    const { img_url, first_name, last_name, resource_id, designation, department, department_id } = props.profileInfo
    const tabInfo = {
        firstName: first_name,
        lastName: last_name,
        id: resource_id,
        url: `/app/performance/${props.isLoggedInUser ? 'my-profile' : resource_id}`,
        level: 2,
        showAvatar: true,
        imgSrc: img_url,
        department,
        designation,
        department_id,
        isFixed: props.isLoggedInUser,
        // hideTitle: true,
        // showEditButton: true,
        // isEditMode: true,
    }
    return (
        <div id='profile-card-wrapper' onClick={() => props.handleProfileClick(props.profileInfo)}>
            {/* <div id='profile-card-wrapper' onClick={() => props.handleProfileClick(props.profileInfo)}> */}
            <SuperLink tab={tabInfo}>
                <div className='user-details-wrapper'>
                    <Avatar
                        imgSrc={img_url}
                        firstName={first_name}
                        lastName={last_name}
                        alt={`${first_name}'s pic`}
                        borderRadius={2}
                        height={70}
                        width={70}
                        fontSize={26}
                    />
                    <div className='user-details'>
                        <span className='pl-label fs-10'>{resource_id}</span>
                        <span title={`${first_name} ${last_name}`}>{`${first_name} ${last_name}`}</span>
                        <span className='pl-label' title={designation}>{designation}</span>
                        <span className='pl-label'>{department}</span>
                    </div>
                </div>
            </SuperLink>
        </div >
    )
}

export default ProfileCard