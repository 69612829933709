import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useForm from "../useForm";

import DOBPicker from "./DOBPicker"
import SearchSelect from "./SearchSelect"
import PhotoCapture, { base64ToFile } from "./PhotoCapture"
import ResumeInput from "./ResumeInput"

import { API_BASE_URL } from "components/Constants"
import APIService from "services/apiService"

import { addHttpsToURLString, formatURL } from "utils/Common"
import { cityOptions, EMAIL_REGEX, genderOptions, LINKEDIN_PROFILE_REGEX, stateOptions, URL_REGEX } from "../constants"

const required = (value) => !value || value == null || (typeof value === 'string' && value.trim() === "") ? `This field is required` : null;
const email = (value) => !(EMAIL_REGEX.test(value)) ? `Email is invalid` : null;
const number = (value) => isNaN(value) ? `Phone must be a number` : null;
const url = (value) => !(URL_REGEX.test(String(value).trim())) ? `URL is invalid` : null;
const optional = (cb) => (value) => value ? cb(value) : null;

const BasicForm = ({ isSubmitting, setIsSubmitting, setIsSubmitted }) => {
    const { id } = useParams();

    const formRef = useRef(null);
    const { values, setValue, errors, handleChange, handleBlur, handleSubmit } =
        useForm(
            {
                firstName: "",
                middleName: "",
                lastName: "",
                dob: "",
                gender: null,
                phone: "",
                email: "",
                city: "",
                state: "",
                profileImage: null,
                resume: null,
                linkedinProfile: "",
                gitRepo: "",
                portfolio: "",
            },
            {
                firstName: [required],
                dob: [required],
                phone: [
                    required,
                    number,
                    (value) =>
                        String(value).length === 10
                            ? null
                            : "Phone number must be 10 digits",
                ],
                email: [required, email],
                profileImage: [required],
                linkedinProfile: [
                    required,
                    (value) =>
                        LINKEDIN_PROFILE_REGEX.test(String(value).trim())
                            ? null
                            : "Linkedin profile is invalid",
                ],
                resume: [
                    optional((value) =>
                        value && value.size > 5 * 1024 * 1024
                            ? "File size must be less than 5MB"
                            : null
                    ),
                ],
                gitRepo: [optional(url)],
                portfolio: [optional(url)],
            }
        );

    const [resumeUrl, setResumeUrl] = useState('');
    const submitForm = async (values) => {
        try {
            setIsSubmitting(true);
            const payload = {
                first_name: values.firstName,
                date_of_birth: values.dob,
                phone: values.phone,
                email: values.email,
                linkedin: formatURL(values.linkedinProfile),
                current_employer: "",
                img_url: "",
            };

            if (values.middleName) payload.middle_name = values.middleName;
            if (values.lastName) payload.last_name = values.lastName;
            if (values.gender?.value) payload.gender = values.gender.value;
            if (values.city?.value) payload.city = values.city.value;
            if (values.state?.value) payload.state = values.state.value;
            if (values.gitRepo) payload.social = { git: addHttpsToURLString(values.gitRepo) };
            if (values.portfolio) {
                if (typeof payload.social === 'object') {
                    payload.social.portfolio = addHttpsToURLString(values.portfolio);
                } else {
                    payload.social = { portfolio: addHttpsToURLString(values.portfolio) };
                }
            }

            if (values.resume) {
                if (!resumeUrl) {
                    try {
                        const formData = new FormData();
                        formData.append("user_resume", values.resume);
                        const response = await APIService.apiRequest(
                            API_BASE_URL + "/upload_resume",
                            formData,
                            false,
                            "POST"
                        );

                        if (response.status === 1) {
                            payload.resume_url = response.output;
                            setResumeUrl(response.output);
                        }
                    } catch (error) {
                        console.error("Error uploading resume", error);
                    }
                } else {
                    payload.resume_url = resumeUrl;
                }
            }

            const response = await APIService.apiRequest(API_BASE_URL + `/recruiter/resource_using_forms/${id}`, payload, false, "POST")
            if (response.status === 1) {
                const isDuplicateEmail = response?.msg && response.msg === "Resource email exists";
                const isDuplicatePhone = response?.msg && response.msg === "Resource phone exists";
                const isDuplicateLinkedin = response?.msg && response.msg === "Resource linkedin exists";
                if (isDuplicateEmail || isDuplicatePhone || isDuplicateLinkedin) {
                    if (window.bydata) {
                        window.bydata.sendCustomPageClickData({
                            cd1: "Submit Application",
                            cd2: "Error",
                            cd3: [response.msg],
                        });
                    }
                    alert(
                        isDuplicateEmail
                            ? `The email \`${payload.email}\` has already been used to apply.`
                            : isDuplicateLinkedin
                                ? `The LinkedIn profile \`${payload.linkedin}\` has already been used to apply.`
                                : `The phone number \`${payload.phone}\` has already been used to apply.`
                    );
                    setIsSubmitting(false);
                    return;
                }

                // fire success event
                if (window.bydata) {
                    window.bydata.sendCustomPageClickData({
                        cd1: "Submit Application",
                        cd2: "Success",
                    });
                }

                const formData = new FormData();
                formData.append("resource_img", base64ToFile(values.profileImage, "profile-image"));
                try {
                    await APIService.apiRequest(API_BASE_URL + `/resource_img/${response.id}`, formData, false, "PUT");
                } catch (error) {
                    console.error("Error uploading image", error);
                }
                setIsSubmitting(false);
                setIsSubmitted(true);
            } else {
                console.error("Error submitting form", response);
                if (window.bydata) {
                    window.bydata.sendCustomPageClickData({
                        cd1: "Submit Application",
                        cd2: "Error",
                        cd3: [response.msg || response.message],
                    });
                }
                setIsSubmitting(false);
                alert("Error submitting form. Please try again later!");
            }
        } catch (error) {
            console.error("Error submitting form", error);
            setIsSubmitting(false);
            alert("Error submitting form. Please try again later!");
        }
    };

    return (
        <>
            <div className="job-form">
                <h4>Personal Details</h4>
                <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                    <div className="group name">
                        <label className="required">
                            Name
                        </label>
                        <div className="name-group">
                            <div className="field">
                                <input
                                    className={errors.firstName ? "has-error" : ''}
                                    type="text"
                                    placeholder="First"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.firstName && <span className="error">{errors.firstName}</span>}
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Middle"
                                    name="middleName"
                                    value={values.middleName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Last"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="group">
                        <div className="input-group dob-group">
                            <label className="required" htmlFor="dob">
                                Birth Date
                            </label>
                            <div className="field">
                                <DOBPicker
                                    value={values.dob}
                                    onChange={handleChange}
                                    updateValue={value => setValue('dob', value)}
                                    hasError={errors.dob}
                                    onBlur={handleBlur}
                                />
                                {errors.dob && <span className="error">{errors.dob}</span>}
                            </div>
                        </div>
                        <div className="input-group gender-group">
                            <label htmlFor="gender">Gender</label>
                            <SearchSelect
                                options={genderOptions}
                                value={values.gender}
                                onSelect={opt => setValue("gender", opt)}
                            />
                        </div>
                    </div>
                    <div className="group">
                        <div className="group">
                            <div className="input-group phone-group">
                                <label className="required" htmlFor="phone">
                                    Phone
                                </label>
                                <div className="field">
                                    <input
                                        className={errors.phone ? "has-error" : ''}
                                        type="tel"
                                        id="phone"
                                        placeholder="000-000-0000"
                                        name="phone"
                                        minLength={10}
                                        maxLength={10}
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.phone && <span className="error">{errors.phone}</span>}
                                </div>
                            </div>
                            <div className="input-group email-group">
                                <label className="required" htmlFor="email">
                                    Email
                                </label>
                                <div className="field">
                                    <input
                                        className={errors.email ? "has-error" : ''}
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="example@abc.com"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="group location">
                            <div className="input-group">
                                <label htmlFor="location">Location</label>
                                <SearchSelect
                                    value={values.city}
                                    options={cityOptions}
                                    placeholder="City"
                                    onSelect={(opt) => {
                                        setValue("city", opt);
                                        if (opt && values?.city?.value !== opt.value) {
                                            setValue(
                                                "state",
                                                stateOptions.find((state) => state.cities.includes(opt.value))
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div className="input-group state-group">
                                <SearchSelect
                                    value={values.state}
                                    options={stateOptions}
                                    placeholder="State"
                                    onSelect={opt => {
                                        setValue("state", opt);
                                        if (opt && values?.state?.value !== opt.value) {
                                            setValue("city", null);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="group upload-group">
                        <div className="input-group w-auto">
                            <label className="required">Profile Image</label>
                            <div className="field">
                                <PhotoCapture
                                    value={values.profileImage}
                                    onChange={(value) => setValue("profileImage", value)}
                                />
                                {errors.profileImage && <span className="error">Profile image is required</span>}
                            </div>
                        </div>
                        <div className="input-group w-auto">
                            <label>Resume</label>
                            {!values.resume ? (
                                <ResumeInput
                                    onChange={(file) => {
                                        setValue("resume", file)
                                        setResumeUrl('')
                                    }}
                                />
                            ) : (
                                <div>
                                    <div className="pill">
                                        <svg
                                            width="19"
                                            height="20"
                                            viewBox="0 0 19 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M2.63473 7.54545V1H14.5L17.3636 3.86364V19H1.81818M13.2727 1V5.09091H17.3636M12.0455 15.7273V10H15.3182M12.0455 12.8636H14.5M1 10H2.22727C3.86364 10 4.06818 11.0227 4.06818 11.6364C4.06818 12.25 3.86364 13.2727 2.22727 13.2727H1.20455V14.9091H1V10ZM6.31818 14.9091V10H7.71318C8.64345 10 9.59091 10.4091 9.59091 12.4545C9.59091 14.5 8.64345 14.9091 7.71318 14.9091H6.31818Z"
                                                stroke="#646464"
                                                strokeWidth="1.33333"
                                            />
                                        </svg>
                                        <button
                                            className="pdf-btn"
                                            onClick={() => {
                                                const url = URL.createObjectURL(values.resume)
                                                window.open(url, "_blank")
                                                URL.revokeObjectURL(url)
                                            }}>
                                            {values.resume.name}
                                        </button>
                                        <button onClick={() => setValue("resume", null)}>
                                            <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 13 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.36353 11L11.263 1.1005"
                                                    stroke="#646464"
                                                    strokeWidth="1.8"
                                                    strokeLinecap="round"
                                                />
                                                <path
                                                    d="M1.36353 1L11.263 10.8995"
                                                    stroke="#646464"
                                                    strokeWidth="1.8"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    {errors.resume && <span className="error">{errors.resume}</span>}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="group">
                        <div className="input-group w-full">
                            <label className="required" htmlFor="linkedin-profile">
                                LinkedIn Profile
                            </label>
                            <div className="field">
                                <input
                                    className={errors.linkedinProfile ? "has-error" : ''}
                                    type="url"
                                    id="linkedin-profile"
                                    name="linkedinProfile"
                                    placeholder="https://www.linkedin.com/in/abc"
                                    value={values.linkedinProfile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.linkedinProfile && <span className="error">{errors.linkedinProfile}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="group">
                        <div className="input-group w-full">
                            <label htmlFor="git-repo">Git Repository</label>
                            <div className="field">
                                <input
                                    className={errors.gitRepo ? "has-error" : ''}
                                    type="url"
                                    id="git-repo"
                                    name="gitRepo"
                                    placeholder="https://www.example.com/abc"
                                    value={values.gitRepo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.gitRepo && <span className="error">{errors.gitRepo}</span>}
                            </div>
                        </div>
                        <div className="input-group w-full">
                            <label htmlFor="portfolio">Portfolio</label>
                            <div className="field">
                                <input
                                    className={errors.portfolio ? "has-error" : ''}
                                    type="url"
                                    id="portfolio"
                                    name="portfolio"
                                    placeholder="https://www.example.com/abc"
                                    value={values.portfolio}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.portfolio && <span className="error">{errors.portfolio}</span>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <button
                className="submit-btn"
                disabled={isSubmitting}
                onClick={handleSubmit(submitForm, () => {
                    setTimeout(() => {
                        const span = formRef.current.querySelector("span.error");
                        if (span) span.scrollIntoView({ behavior: "smooth", block: "center" });
                    }, 0);
                })}
            >
                SUBMIT APPLICATION
            </button>
        </>
    )
}

export default BasicForm