import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { Steno, StenoRenderer } from "react-steno";

// components
import * as Constants from 'components/Constants';
import Avatar from 'components/Avatar'
import ClickOutsideListner from "components/ClickOutsideListner";

// services
import APIService from "services/apiService";
import { alertService } from 'services/alertService';

// utils
import { useToggle } from "utils/hooks";
import { getUser } from "utils/Common";
import { isMacOs } from "utils/Utils";

const STENO_EDITOR_TOOLBAR_PROPS = {
    disabled: false,
    enableResize: false,
    toolbarPosition: 'bottom',
    toolbarChildVisibility: {
        fontSize: false,
        textTransform: false,
        fontColor: false,
        backgroundColor: false,
        removeTextFormat: true,
        addFiles: false,
        borderType: false,
        borderColor: false,
        borderWidth: false,
    }
}

const CommentsContext = createContext()
const useCommentsContext = () => useContext(CommentsContext)
const COMMENT_TIMEOUT = 5;
function isDateGreaterThanMins(date, mins = 20) {
    const datePlusMins = new Date(date).getTime() + (1000 * 60 * mins);
    return datePlusMins < Date.now();
}

const allowCommentAction = (created_at, action) => {
    if (isDateGreaterThanMins(created_at, COMMENT_TIMEOUT)) {
        alertService.info(
            `A comment/reply cannot be ${action} after ${COMMENT_TIMEOUT} minutes`
        );
        return false;
    }
    return true;
}

export function CommentsContextProvider({ children, }) {
    const [comments, setComments] = useState([]);
    let userInfo = getUser()

    const insertComment = (comment) => {
        setComments(comments => [...comments, comment])
    }

    const insertCommentReply = ({ commentId, reply }) => {
        setComments(comments => {
            return comments.map(comment => {
                if (comment.id === commentId) {
                    if (Array.isArray(comment?.replies)) {
                        comment.replies.push(reply)
                    } else {
                        comment.replies = [reply]
                    }

                    comment.child_notes_count = comment.replies.length
                }

                return comment
            })
        })
    }

    const insertCommentReplies = ({ commentId, replies }) => {
        setComments(comments => {
            return comments.map(comment => {
                if (comment.id === commentId) {
                    if (Array.isArray(comment?.replies)) {
                        comment.replies.push(...replies)
                    } else {
                        comment.replies = [...replies]
                    }

                    comment.child_notes_count = comment.replies.length
                }

                return comment
            })
        })
    }

    const updateComment = ({ commentId, commentText }) => {
        setComments(comments => {
            return comments.map(comment => {
                if (comment.id === commentId) {
                    return {
                        ...comment,
                        note: commentText
                    }
                }

                return comment
            })
        })
    }

    const updateCommentReply = ({ commentId, replyId, replyText }) => {
        setComments(comments => {
            return comments.map(comment => {
                if (comment.id === commentId) {
                    let replies = comment.replies.map(reply => {
                        if (reply.id === replyId) {
                            return { ...reply, note: replyText }
                        }

                        return reply
                    })

                    return { ...comment, replies }
                }

                return comment
            })
        })
    }

    const removeComment = (commentId) => {
        setComments(comments => {
            return comments.filter(comment => comment.id !== commentId)
        })
    }

    const removeCommentReply = ({ commentId, replyId }) => {
        setComments(comments => {
            return comments.map(comment => {
                let replies = []
                if (comment.id === commentId && comment?.replies?.length) {
                    replies = comment.replies.filter(reply => reply.id !== replyId)
                }

                comment.child_notes_count = replies.length

                return { ...comment, replies }
            })
        })
    }

    return (
        <CommentsContext.Provider value={
            {
                userInfo,
                comments,
                setComments,
                insertComment,
                insertCommentReply,
                insertCommentReplies,
                updateComment,
                updateCommentReply,
                removeComment,
                removeCommentReply
            }
        }>
            {children}
        </CommentsContext.Provider>
    )
}

function giveDateTimeInString(date) {
    let d = new Date(date);
    let dateOptions = { year: 'numeric', month: 'short' };
    let timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return `${d.getDate()} ${d.toLocaleDateString('en-us', dateOptions)} ${d.toLocaleTimeString('en-us', timeOptions)}`;
}

export default function CandidateComments({ candidate, funnel }) {
    const { comments, setComments, userInfo } = useCommentsContext()
    const [loadingComments, setLoadingComments] = useState(true);

    const commentStages = funnel.stages.filter(stage => stage.order <= candidate.stage.order)

    const hasCommentPrivilege = funnel.recruitment_funnel[0].privileges.includes('COMMENT')
    const isFunnelArchived = funnel.recruitment_funnel[0].is_archive === 1
    const canComment = isFunnelArchived === false && hasCommentPrivilege === true

    useEffect(() => {
        const getComments = () => {
            setLoadingComments(true);

            APIService.apiRequest(
                Constants.API_BASE_URL + `/recruiter/notes/${candidate.id}`,
                null,
                false,
                'GET'
            ).then(response => {
                if (response.status === 1) {
                    setComments(response.output);
                } else {
                    alertService.warning(`get comments error: ${response.msg}`);
                    setComments([])
                }

                setLoadingComments(false)
            }).catch(error => {
                setLoadingComments(false)
            })
        }

        getComments()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [commentCurrentStage, setCommentCurrentStage] = useState(candidate.stage);
    const currentStageComments = comments.filter(comment => comment.recruitment_funnel_stage_id === commentCurrentStage.id)

    const handleCommentStageChange = (direction) => {
        if (direction === 'left') {
            const stage = commentStages.find(stage => stage.order === commentCurrentStage.order - 1)
            if (stage) {
                setCommentCurrentStage(stage)
            }
        }

        if (direction === 'right') {
            const stage = commentStages.find(stage => stage.order === commentCurrentStage.order + 1)

            if (stage) {
                // Dont let user to go beyond current stage to comment
                if (stage.order > candidate.stage.order) {
                    return
                }

                setCommentCurrentStage(stage)
            }
        }
    }

    return (
        <>
            <div className="main-content">
                {loadingComments && <p style={{ textAlign: 'center' }}>Loading comments...</p>}
                {
                    !loadingComments &&
                    <>
                        {
                            canComment &&
                            <div className="sticky-editor">
                                <div className='stage-info-wrapper'>
                                    <button className='arrow-left' onClick={() => handleCommentStageChange('left')}></button>
                                    <span className='stage-name'>{commentCurrentStage.name}</span>
                                    <button className='arrow-right' onClick={() => handleCommentStageChange('right')}></button>
                                </div>

                                <AddCommentForm candidate={candidate} stage={commentCurrentStage} />
                            </div>
                        }
                        <div className="notes">
                            {
                                currentStageComments.length === 0 &&
                                <div className="empty-list-widget">
                                    <p>Comments added on this stage will appear here</p>
                                </div>
                            }
                            {
                                currentStageComments
                                    .map((comment) => (
                                        <CommentDetails
                                            key={comment.id}
                                            candidate={candidate}
                                            stage={commentCurrentStage}
                                            comment={comment}
                                            canComment={canComment}
                                            userInfo={userInfo}
                                        />
                                    ))
                            }
                        </div>
                    </>
                }
            </div>
        </>
    )
}

function AddCommentForm({ candidate, stage }) {
    const [comment, setComment] = useState('');
    const [isFormFocused, setIsFormFocused] = useState(false);

    const { insertComment } = useCommentsContext()

    const editorRef = useRef(null);
    const fnRef = useRef(null);

    const handleFormClose = () => {
        setComment('')
        setIsFormFocused(false)
        if (editorRef?.current) editorRef.current?.blur();
    }

    const [savingComment, toggleSavingComment] = useToggle(false);

    const handleCommentSave = () => {
        if (comment.trim()?.length === 0) return;

        toggleSavingComment()

        const apiPayLoad = {
            // recruitment_funnel_stage_id: candidateDetails.stage_Id,
            recruitment_funnel_stage_id: stage.id,
            note: comment,
        };

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${candidate.id}`,
            apiPayLoad,
            false,
            'POST'
        ).then(response => {

            if (response.status === 1) {
                // add 'child_notes_count' key to response.output[0]
                response.output[0].child_notes_count = 0;
                // console.log('handleInsightNoteSaveBtn response', response);

                insertComment(response.output[0]);
                toggleSavingComment()
                handleFormClose()
            } else {
                alertService.warning(`Comment save error: ${response.msg}`);
            }

        }).catch(error => {
            toggleSavingComment()
            console.log('Error occured in adding comment: ' + error.message);
        });
    }

    return (
        <div className="note-add-form">
            <div className={`editor ${isFormFocused ? 'steno-editor-focused' : ''}`}>
                <Steno
                    placeHolder="Comment"
                    html={comment}
                    isToolBarVisible={isFormFocused}
                    innerRef={editorRef}
                    ref={fnRef}
                    onChange={value => setComment(value)}
                    onFocus={() => setIsFormFocused(true)}
                    sendMsgOnEnter={true}
                    onEnterClickLogic={(value) => {
                        setComment(value);
                        handleCommentSave();
                    }}
                    {...STENO_EDITOR_TOOLBAR_PROPS}
                />
                {isFormFocused && (
                    <div className="form-footer">
                        <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                        <div className="submit-cancel-btns">
                            <button
                                className="btn btn-small"
                                disabled={!editorRef?.current?.textContent?.length || savingComment}
                                onClick={handleCommentSave}
                            >
                                <i></i>
                                <span>Save</span>
                            </button>
                            <button
                                className="btn btn-small"
                                disabled={savingComment}
                                onClick={handleFormClose}
                            >
                                <i></i>
                                <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function CommentDetails({ candidate, stage, comment, canComment, userInfo }) {
    const [isOptionsPopoverOpen, toggleOptionsPopover] = useToggle(false);

    const { removeComment, updateComment } = useCommentsContext()

    const createdByName = `${comment.created_by_name.first_name} ${comment.created_by_name.last_name}`
    const formattedCreatedDate = giveDateTimeInString(new Date(comment.created_at));
    const canEditComment = canComment && comment.created_by === userInfo.id

    const [showCommentEditForm, toggleShowCommentEditForm] = useToggle(false);
    const [commentUpdateInProgress, setCommentUpdateInProgress] = useState(false);

    const handleCommentDelete = () => {
        setCommentUpdateInProgress(true);

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${comment.id}`,
            {},
            false,
            'DELETE'
        ).then(response => {
            if (response.status === 1) {
                removeComment(comment.id)
            }

            setCommentUpdateInProgress(false);
        }).catch(err => {
            setCommentUpdateInProgress(false);
            console.log('Error occured in deleting the comment: ' + err.message);
        });
    }

    // const editFormQuillDOMRef = useRef()
    const [editCommentText, setEditCommentText] = useState(comment.note);

    const handleEditFormClose = () => {
        toggleShowCommentEditForm()
        setEditCommentText(comment.note)
    }

    const handleCommentEditSave = () => {
        const payload = {
            note: editCommentText,
        };

        setCommentUpdateInProgress(true);

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${comment.id}`,
            payload,
            false,
            'PUT'
        ).then(response => {
            if (response.status === 1) {
                updateComment({
                    commentId: comment.id,
                    commentText: editCommentText,
                })
            } else {
                alertService.warning(`comment edit error: ${response.msg}`);
            }

            toggleShowCommentEditForm()
            setCommentUpdateInProgress(false);
        }).catch(err => {
            setCommentUpdateInProgress(false);
            console.log('Error occured in editing comment: ' + err.message);
        });

    }

    const editorRef = useRef(null);
    const fnRef = useRef(null);

    return (
        <div
            className={`note-widget-wrapper ${comment.child_notes_count === 0 ? " no-replies" : ""
                }`}
        >
            <div className="note-widget">
                <div className="header">
                    <Avatar
                        // imgSrc={candidate.img_url}
                        alt={createdByName}
                        firstName={comment.created_by_name.first_name}
                        lastName={comment.created_by_name.last_name}
                        height={20}
                        width={20}
                        fontSize={8}
                    />
                    <span className="name">{createdByName}</span>
                    <span className="date">{formattedCreatedDate}</span>
                    {canEditComment && (
                        <div className="note-action-btn">
                            <span className="pop-over-btn" onClick={toggleOptionsPopover}>
                                {isOptionsPopoverOpen && (
                                    <ClickOutsideListner onOutsideClick={toggleOptionsPopover}>
                                        <div className="pop-over-options-wrapper">
                                            <ul className="pop-over-options">
                                                <li
                                                    className={
                                                        commentUpdateInProgress ? "disabled" : ""
                                                    }
                                                    onClick={() => {
                                                        if (allowCommentAction(comment.created_at, 'edited')) {
                                                            toggleShowCommentEditForm()
                                                        }
                                                    }}
                                                >
                                                    Edit
                                                </li>
                                                <li
                                                    className={
                                                        commentUpdateInProgress ? "disabled" : ""
                                                    }
                                                    onClick={() => {
                                                        if (allowCommentAction(comment.created_at, 'deleted')) {
                                                            handleCommentDelete()
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </li>
                                            </ul>
                                        </div>
                                    </ClickOutsideListner>
                                )}
                            </span>
                        </div>
                    )}
                </div>
                {!showCommentEditForm ? (
                    <div className="content steno-content">
                        <StenoRenderer html={comment.note} />
                    </div>
                ) : (
                    <div className="note-edit-form">
                        <div className="editor steno-editor-focused">
                            <Steno
                                placeHolder="Comment"
                                html={editCommentText}
                                innerRef={editorRef}
                                ref={fnRef}
                                onChange={(value) => setEditCommentText(value)}
                                sendMsgOnEnter={true}
                                onEnterClickLogic={(value) => {
                                    setEditCommentText(value);
                                    handleCommentEditSave();
                                }}
                                {...STENO_EDITOR_TOOLBAR_PROPS}
                            />
                            <div className="form-footer">
                                <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                                <div className="submit-cancel-btns">
                                    <button
                                        className="btn btn-small"
                                        disabled={!editorRef?.current?.textContent?.length && commentUpdateInProgress}
                                        onClick={handleCommentEditSave}
                                    >
                                        <i></i>
                                        <span>Save Changes</span>
                                    </button>
                                    <button
                                        className="btn btn-small"
                                        disabled={commentUpdateInProgress}
                                        onClick={handleEditFormClose}
                                    >
                                        <i></i>
                                        <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <CommentReplies
                    candidate={candidate}
                    comment={comment}
                    canComment={canComment}
                    stage={stage}
                />
            </div>
        </div>
    );
}

function CommentReplies({ candidate, comment, canComment, stage }) {
    const [isOpen, setIsOpen] = useState(false);
    const [loadingReplies, setLoadingReplies] = useState(false);
    const { insertCommentReplies } = useCommentsContext()

    // Form States
    const replyFormDOMRef = useRef()
    const [reply, setReply] = useState('');
    const [isReplyFormFocused, setIsReplyFormFocused] = useState(false);
    const [replyUpdateInProgress, setReplyUpdateInProgress] = useState(false);
    const { insertCommentReply } = useCommentsContext()

    const handleReplyFormClose = () => {
        const editor = replyFormDOMRef.current
        if (editor) {
            editor.blur()
        }

        setReply('')
        setIsReplyFormFocused(false)
    }

    const handleCommentReplySave = () => {
        const apiPayLoad = {
            recruitment_funnel_stage_id: stage.id,
            note: reply,
            parent_note_id: comment.id
        };

        setReplyUpdateInProgress(true);

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${candidate.id}`,
            apiPayLoad,
            false,
            'POST'
        ).then(response => {
            if (response.status === 1) {
                insertCommentReply({
                    commentId: comment.id,
                    reply: response.output[0]
                })
                handleReplyFormClose()
            } else {
                alertService.warning(`reply save error: ${response.msg}`);
            }

            setReplyUpdateInProgress(false)
        })
            .catch(err => {
                setReplyUpdateInProgress(false)
                console.log('Error occured in adding note to chart: ' + err.message);
            });

    }

    const getCommentReplies = () => {
        setLoadingReplies(true)

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${candidate.id}?parent_note_id=${comment.id}`,
            {},
            false,
            'GET'
        ).then(response => {
            if (response.status === 1) {
                insertCommentReplies({ commentId: comment.id, replies: response.output })
            } else {
                alertService.warning(`get replies error: ${response.msg}`);
            }

            setLoadingReplies(false)
            setIsOpen(true)
        }).catch(error => {
            setLoadingReplies(false)
            console.log(error);
        });

    }

    const toggleOpen = () => {
        if (!isOpen) {
            if (comment.child_notes_count > 0 && !comment?.replies?.length) {
                // Get comment children and then show the reply list
                getCommentReplies()

                // getCommentReplies function will open the list when the children comments are fetched.
                // So return immediately after initiating the function
                return
            }

            if (comment.child_notes_count === 0) {
                setIsReplyFormFocused(true)
            }

            setIsOpen(true)
        } else {
            setIsOpen(false)
        }

    }

    const editorRef = useRef()
    const fnRef = useRef()

    return (
        <>
            <div
                className={`child-note-count ${isOpen ? "expanded" : ""}`}
                onClick={() => toggleOpen()}
            >
                <span className="count">
                    {`${comment.child_notes_count > 1 ? "Replies" : "Reply"}`}{" "}
                    {comment.child_notes_count || ""}
                </span>
                {loadingReplies ? <span className="loading"></span> : null}
            </div>
            {isOpen && (
                <div>
                    <div className="note-reply-form">
                        <div
                            className={`editor ${isReplyFormFocused ? "steno-editor-focused" : ""
                                }`}
                        >
                            <Steno
                                placeHolder="Reply"
                                html={reply}
                                innerRef={editorRef}
                                ref={fnRef}
                                isToolBarVisible={isReplyFormFocused}
                                onChange={(value) => setReply(value)}
                                onFocus={() => setIsReplyFormFocused(true)}
                                sendMsgOnEnter={true}
                                onEnterClickLogic={(value) => {
                                    setReply(value);
                                    handleCommentReplySave();
                                }}
                                {...STENO_EDITOR_TOOLBAR_PROPS}
                            />
                            {isReplyFormFocused && (
                                <div className="form-footer">
                                    <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                                    <div className="submit-cancel-btns">
                                        <button
                                            className="btn btn-small"
                                            disabled={!editorRef?.current?.textContent?.length || replyUpdateInProgress}
                                            onClick={handleCommentReplySave}
                                        >
                                            <i></i>
                                            <span>Save</span>
                                        </button>
                                        <button
                                            className="btn btn-small"
                                            disabled={replyUpdateInProgress}
                                            onClick={handleReplyFormClose}
                                        >
                                            <i></i>
                                            <span>Cancel</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="note-replies-wrapper">
                        {comment?.replies?.map((reply) => {
                            return (
                                <CommentReply
                                    key={reply.id}
                                    reply={reply}
                                    parentId={comment.id}
                                    canComment={canComment}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

function CommentReply({ parentId, reply, canComment }) {
    const { userInfo, updateCommentReply, removeCommentReply } = useCommentsContext()
    const [isOptionsPopoverOpen, toggleOptionsPopover] = useToggle(false);
    const [isReplyFormOpened, setIsReplyFormOpened] = useState(false);
    const [replyText, setReplyText] = useState(reply.note);

    const formattedCreatedDate = giveDateTimeInString(new Date(reply.created_at));
    const canEditReply = canComment && reply.created_by === userInfo.id;
    const createdByName = `${reply.created_by_name.first_name} ${reply.created_by_name.last_name}`

    const handleReplyFormClose = () => {
        setReplyText(reply.note)
        setIsReplyFormOpened(false)
    }

    const [replyUpdateInProgress, setReplyUpdateInProgress] = useState(false);

    const handleReplyEditUpdate = () => {
        let payload = {
            note: replyText,
        };

        setReplyUpdateInProgress(true);

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${reply.id}`,
            payload,
            false,
            'PUT'
        )
            .then(response => {
                if (response.status === 1) {
                    updateCommentReply({ commentId: parentId, replyId: reply.id, replyText })
                } else {
                    alertService.warning(`reply edit error: ${response.msg}`);
                }

                setReplyUpdateInProgress(false)
                setIsReplyFormOpened(false)
            })
            .catch(err => {
                setReplyUpdateInProgress(false)
                console.log('Error occured in editing comment: ' + err.message);
            });
    }

    const handleReplyDelete = () => {
        setReplyUpdateInProgress(true);

        APIService.apiRequest(
            Constants.API_BASE_URL + `/recruiter/notes/${reply.id}`,
            {},
            false,
            'DELETE').then(response => {
                if (response.status === 1) {
                    alertService.success('Comment Deleted Successfully');
                    removeCommentReply({ commentId: parentId, replyId: reply.id })
                } else {
                    alertService.warning(`reply delete error: ${response.msg}`);
                }

                setReplyUpdateInProgress(false)
            })
            .catch(err => {
                setReplyUpdateInProgress(false)
                console.log('Error occured in deleting the comment: ' + err.message);
            });
    }

    const editorRef = useRef()
    const fnRef = useRef()

    return (
        <div className="note-widget">
            <div className="header">
                <Avatar
                    // imgSrc={candidate.img_url}
                    // alt={`${candidate.img_url}'s pic`}
                    firstName={reply.created_by_name.first_name}
                    lastName={reply.created_by_name.last_name}
                    height={20}
                    width={20}
                    fontSize={8}
                />
                <span className="name">{createdByName}</span>
                <span className="date">{formattedCreatedDate}</span>
                {canEditReply && (
                    <div className="note-action-btn">
                        <span className="pop-over-btn" onClick={toggleOptionsPopover}>
                            {isOptionsPopoverOpen && (
                                <ClickOutsideListner onOutsideClick={toggleOptionsPopover}>
                                    <div className="pop-over-options-wrapper">
                                        <ul className="pop-over-options">
                                            <li
                                                className={replyUpdateInProgress ? "disabled" : ""}
                                                onClick={() => {
                                                    if (allowCommentAction(reply.created_at, 'edited')) {
                                                        setIsReplyFormOpened(true)
                                                    }
                                                }}
                                            >
                                                Edit
                                            </li>
                                            <li
                                                className={replyUpdateInProgress ? "disabled" : ""}
                                                onClick={() => {
                                                    if (allowCommentAction(reply.created_at, 'deleted')) {
                                                        handleReplyDelete()
                                                    }
                                                }}
                                            >
                                                Delete
                                            </li>
                                        </ul>
                                    </div>
                                </ClickOutsideListner>
                            )}
                        </span>
                    </div>
                )}
            </div>
            {!isReplyFormOpened ? (
                <div className="content steno-content">
                    <StenoRenderer html={reply.note} />
                </div>
            ) : (
                <div className="note-edit-form">
                    <div className="editor steno-editor-focused">
                        <Steno
                            placeHolder="Reply"
                            html={replyText}
                            innerRef={editorRef}
                            ref={fnRef}
                            onChange={(value) => setReplyText(value)}
                            sendMsgOnEnter={true}
                            onEnterClickLogic={(value) => {
                                setReplyText(value);
                                handleReplyEditUpdate();
                            }}
                            {...STENO_EDITOR_TOOLBAR_PROPS}
                        />
                        <div className="form-footer">
                            <span className='help-text'>Shift + {isMacOs() ? 'Return' : 'Enter'} to add new line</span>
                            <div className="submit-cancel-btns">
                                <button
                                    className="btn btn-small"
                                    disabled={!editorRef?.current?.textContent?.length || replyUpdateInProgress}
                                    onClick={handleReplyEditUpdate}
                                >
                                    <i></i>
                                    <span>Save Changes </span>
                                </button>
                                <button
                                    className="btn btn-small"
                                    disabled={replyUpdateInProgress}
                                    onClick={handleReplyFormClose}
                                >
                                    <i></i>
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}