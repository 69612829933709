import { useRef, useState } from "react";
import { useOnClickOutside } from "hooks/useClickOutside";
import { NavLink } from "react-router-dom";
import SuperPager from "super-pager";
import { StenoRenderer } from "react-steno";
import Avatar from "components/Avatar";
import { useWikiContext } from "../context";
import { alertService } from "services/alertService";
import useScrollRestoration from "hooks/useScrollRestoration";
import { convertUTCToTimezone } from "utils/Common";
import { format } from "utils/date";
import "./GridView.scss";

export default function GridView({ data, loadingData, pageMeta, getArticles }) {
  const ref = useScrollRestoration("wiki-home-grid-scroll");

  return (
    <div className="data-grid" ref={ref}>
      <SuperPager
        type="infiniteScroll"
        dataLength={loadingData ? 0 : data?.length || null}
        loadMore={() =>
          getArticles({ page_number: pageMeta.pagination.current_page + 1 })
        }
        hasMore={data.length < pageMeta.pagination.total_count}
        wrapper={true}
        children={
          data?.length === 0 ? (
            <div>No data to display</div>
          ) : (
            data.map((item) => <ArticleCard key={item.id} item={item} />)
          )
        }
      />
    </div>
  );
}

const ArticleCard = ({ item }) => {
  const {
    openArticle,
    openArticleInEditMode,
    deleteArticle,
    removeArticleInList,
  } = useWikiContext();

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setShowMenu(false));

  const [deletingArticle, setDeletingArticle] = useState(false);
  const handleArticleDelete = async () => {
    try {
      const res = await deleteArticle(item);
      if (res.article_id) {
        removeArticleInList(item);
      } else {
        setDeletingArticle(false);
        alertService.error(
          res?.detail?.msg ?? "Something went wrong. Please try again.",
        );
      }
    } catch (error) {
      console.log(error);
      setDeletingArticle(false);
    }
  };

  return (
    <NavLink
      to={"/app/wiki/article/" + item.id}
      title={item.title}
      onClick={() => openArticle(item)}
    >
      <div className="card">
        <div className="card-content">
          <div>
            <div className="title-header">
              <span className="icon-file"></span>
              <div className="menu-wrapper">
                <button
                  className="icon-more"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMenu(!showMenu);
                  }}
                ></button>
                {showMenu && (
                  <div className="menu" ref={menuRef}>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openArticleInEditMode(item);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleArticleDelete(e);
                      }}
                      disabled={deletingArticle}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
            <h6 className="title">{item.title}</h6>
          </div>
          <StenoRenderer html={item.body} />
          {item?.tags?.length > 0 && (
            <div className="tags">
              {item?.tags?.map((tag, index) => (
                <span className="tag" key={index}>
                  {tag.tag}
                </span>
              ))}
            </div>
          )}
          {/* {item.type === "article" ? (
          <>
            <span>
              Multiple employees can contribute and edit content, fostering a collaborative environment for creating.
            </span>
            <div className="tags">
              {item?.tags?.map((tag, index) => (<span className="tag" key={index}>{tag}</span>))}
            </div>
          </>
        ) : (
          <div className="folder-placeholder">
            <span className="icon-folder"></span>
            <span>{item.files.length === 0 ? 'Empty Folder' : item.files.length > 1 ? `${item.files.length} Files` : '1 File'}</span>
          </div>
        )} */}
        </div>
        <div className="card-footer">
          <Avatar
            firstName="John"
            lastName="Doe"
            // imgSrc={item.imgSrc}
            height="20px"
            width="20px"
            borderRadius="2px"
          />
          <span>
            {format(
              convertUTCToTimezone(item?.updated_at ?? item.created_at),
              "dddd, MMM DD, YYYY",
            )}
          </span>
        </div>
      </div>
    </NavLink>
  );
};
