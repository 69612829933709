import { useState } from 'react';

import { useAttendanceFilters } from 'views/Attendance/context';
import useUserData from 'hooks/useUserData';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Avatar from 'components/Avatar';
import ClickOutsideListner from 'components/ClickOutsideListner';
import { MouseTooltip } from 'components/ToolTip';

import { GROUPS_DEFAULT_OPT } from '../AttendanceTable/GroupsPopup';

import './RequestTypeFilter.scss';

const RequestTypeFilter = ({ selectedGroup, onChange }) => {
    const { user } = useUserData();
    const { width } = useWindowDimensions();
    const { groups } = useAttendanceFilters();
    const groupsWithAllHands = [GROUPS_DEFAULT_OPT, ...groups];

    const isDesktopVersion = width > 640;

    const isMyRequests = selectedGroup.name === 'myRequests';
    const [open, setOpen] = useState(false);

    const closeMenu = (e) => {
        if (e.target.className === 'request-type-btn' || e.target.className === 'icon-group-wrapper') {
            return;
        }
        setOpen(false);
    }

    return (
        <div className='request-type-filter'>
            <MouseTooltip
                asChild
                delay={500}
                show={isDesktopVersion && !isMyRequests}
                content={selectedGroup.name}
                style={{ lineHeight: "14px" }}>
                <button
                    type='button'
                    className='request-type-btn'
                    onClick={() => setOpen(!open)}
                >
                    {isMyRequests ? (
                        <Avatar
                            imgSrc={user.resource.img_url}
                            firstName={user.resource.first_name}
                            lastName={user.resource.last_name}
                            height={isDesktopVersion ? 24 : 30}
                            width={isDesktopVersion ? 24 : 30}
                            fontSize={12}
                        />
                    ) : (
                        <div className='icon-group-wrapper'>
                            <span className='icon-group'></span>
                        </div>
                    )}
                </button>
            </MouseTooltip>

            <div className={`dropdown-menu ${open ? 'show' : ''}`}>
                <ClickOutsideListner onOutsideClick={closeMenu}>
                    <div className='dropdown-menu-options'>
                        <div
                            className={`dropdown-option ${isMyRequests ? 'active' : ''}`}
                            onClick={() => {
                                if (!isMyRequests) {
                                    onChange({ name: 'myRequests' });
                                }
                                setOpen(false);
                            }}
                        >
                            <Avatar
                                imgSrc={user.resource.img_url}
                                firstName={user.resource.first_name}
                                lastName={user.resource.last_name}
                                height={isDesktopVersion ? 24 : 30}
                                width={isDesktopVersion ? 24 : 30}
                                fontSize={12}
                            />
                            <span>My Requests</span>
                        </div>
                        <div className='groups-label'>
                            <div className='icon-group-wrapper'>
                                <span className='icon-group'></span>
                            </div>
                            <span>Groups</span>
                        </div>
                        {groupsWithAllHands.map((group) => {
                            const isActiveGroup = selectedGroup.name === group.name;
                            return <div
                                className={`dropdown-option group ${isActiveGroup ? 'active' : ''}`}
                                key={group?.id || group.name}
                                onClick={() => {
                                    if (!isActiveGroup) {
                                        onChange(group);
                                    }
                                    setOpen(false);
                                }}
                            >
                                <span>{group.name}</span>
                            </div>
                        })}
                    </div>
                </ClickOutsideListner>
            </div>
        </div>
    )
}

export default RequestTypeFilter