import CompanyTotalHours from './CompanyTotalHours'

import './CompanyAnalytics.scss'

const CompanyAnalytics = () => {
    return (
        <div className='company-analytics-wrapper'>
            <CompanyTotalHours />
        </div>
    )
}

export default CompanyAnalytics