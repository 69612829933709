import { useAttendanceSearch } from "views/Attendance/context/search";
import GroupPopup from "./GroupPopup";
import React, { useEffect, useState } from "react";
import { getFormattedTimeAndDiff, isNotNone } from "views/Attendance/utils";
import { convertTimeZone, convertUTCToTimezone } from "utils/Common";
import { useAttendanceFilters } from "views/Attendance/context";
import { useSocket } from "socket";

const { MouseTooltip } = require("components/ToolTip");
const { default: clsx } = require("utils/clsx");
const { default: UserProfileCard } = require("./UserProfileCard");

const SelectedMemberTab = ({ resourceId }) => {
    const [details, setDetails] = useState({});

    const { lastSearchedResult, setLastSearchedResult } = useAttendanceSearch();
    const { groups } = useAttendanceFilters()
    const { activeGroup, setActivePopupResourceId = () => { }, removeResourceFromGroup = () => { }, toggleAnalytics, showAnalyticsFor, prevSelectedDate, formattedSelectedDate } = lastSearchedResult || {};
    const { status, tooltip, isOverdue, isClockedIn, isClockedOut, isDarkMode, user = {} } = details || {};
    const socket = useSocket();

    const getAdditionalEmployeeDetails = (employee) => {

        const expected_back = isNotNone(employee.expected_back_at) ? convertUTCToTimezone(employee.expected_back_at) : employee.expected_back_at;
        const clock_out_time = isNotNone(employee.clock_out_time) ? convertUTCToTimezone(employee.clock_out_time) : employee.clock_out_time;
        const clock_in_time = isNotNone(employee.clock_in_time) ? convertUTCToTimezone(employee.clock_in_time) : employee.clock_in_time;


        const isClockedIn = isNotNone(clock_in_time);
        const isClockedOut = isNotNone(clock_out_time);
        const isOnLeave = employee.attendance?.includes("leave");
        const isOnline = employee.status === "On";

        let expected_back_at =
            expected_back === "None"
                ? ""
                : getFormattedTimeAndDiff(expected_back, prevSelectedDate);
        let formattedExpectedBackAt = "",
            isOverdue = false;
        if (expected_back_at) {
            let d = convertTimeZone();
            let expectedTimePlus5Mins = new Date(expected_back).getTime();
            formattedExpectedBackAt =
                expected_back_at.time +
                (expected_back_at.diff ? ` (${expected_back_at.diff})` : "");
            isOverdue = d > expectedTimePlus5Mins;
        }

        let status = "",
            tooltip = "";
        if (isOnline) {
            status = "online";
            tooltip = "Online";
        } else {
            if (isClockedOut) {
                status = "sign-off";
                tooltip = "Signed off";
            } else {
                // we add leave class for both leave and not clocked in
                if (isOnLeave) {
                    status = isClockedIn ? "away" : "leave";
                    tooltip = isClockedIn
                        ? `${isOverdue ? "Was expected back" : "Back"} at ${!expected_back || expected_back === "None"
                            ? "unknown"
                            : formattedExpectedBackAt
                        }`
                        : "On Leave";
                } else {
                    status = isClockedIn ? "away" : "sign-off";
                    tooltip = isClockedIn
                        ? `${isOverdue ? "Was expected back" : "Back"} at ${!expected_back || expected_back === "None"
                            ? "unknown"
                            : formattedExpectedBackAt
                        }`
                        : "Not started";
                }
            }
        }
        const isDarkMode = document.body.dataset.theme === "dark";

        return {
            status, tooltip, isDarkMode, user: employee, isOverdue, groups, activeGroup, setActivePopupResourceId, removeResourceFromGroup, toggleAnalytics, showAnalyticsFor
        }
    }

    useEffect(() => {
        if (resourceId && lastSearchedResult) {
            const calcDetails = getAdditionalEmployeeDetails(lastSearchedResult);
            setDetails(calcDetails);
        }
    }, [resourceId, lastSearchedResult])

    useEffect(() => {

        const handleAttendanceEvent = ({ data }) => {
            if (data.resource_id === resourceId && data.date === formattedSelectedDate) {
                setLastSearchedResult(prev => ({ ...prev, ...data }));
            }
        }

        if (resourceId) {
            socket.on('attendance', handleAttendanceEvent);
        }

        return () => {
            socket.off('attendance', handleAttendanceEvent)
        }
    }, [resourceId])


    return (
        <div className="name-container">
            <MouseTooltip
                asChild
                delay={500}
                content={tooltip}
                style={{
                    lineHeight: "14px",
                    color:
                        isOverdue && isClockedIn && !isClockedOut
                            ? "#F00"
                            : isDarkMode
                                ? "#B1B2B3"
                                : "#000",
                }}
            >
                <div className={`status-container ${status}`}></div>
            </MouseTooltip>
            <UserProfileCard
                enlargedTab={true}
                resourceId={resourceId}
                user={user}
                selectedMemberId={resourceId}
                showCross={true}
                showTooltip={true}
            >
                <div className="icon-wrapper">
                    <MouseTooltip
                        asChild
                        delay={500}
                        content="Analytics"
                        style={{ lineHeight: "17px" }}
                    >
                        <button
                            className="icon icon-anlytics icon-enlarged "
                            id={`user-analytics-${resourceId}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (showAnalyticsFor === resourceId) return;
                                toggleAnalytics(e, resourceId);
                            }}
                        ></button>
                    </MouseTooltip>

                    {groups.length > 0 && (
                        <GroupPopup
                            empId={resourceId}
                            activeGroup={activeGroup}
                            setActivePopupResourceId={setActivePopupResourceId}
                            removeResourceFromGroup={removeResourceFromGroup}
                        />
                    )}
                </div>
            </UserProfileCard>
        </div>
    )
}

export default React.memo(SelectedMemberTab);
