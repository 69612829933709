import { useMemo, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import { useAttendanceFilters } from "views/Attendance/context";
import { AL_BASE_URL } from "views/Attendance/constants";
import APIService from "services/apiService";
import LineLoader from "components/LineLoader";
import { alertService } from "services/alertService";
import clsx from "utils/clsx";
import "./GroupPopup.scss";

export default function GroupPopup({
  activeGroup,
  empId,
  setActivePopupResourceId,
  removeResourceFromGroup,
}) {
  const searchInputRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { groups, setGroups } = useAttendanceFilters();
  const [showLineLoader, setShowLineLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (open) => {
    setIsOpen(open);
    setActivePopupResourceId(open ? empId : null);
    setSearchInput("");
    setShowSearchInput(false);
  };

  const suggestions = useMemo(() => {
    if (!searchInput || !showSearchInput) return groups;
    return groups.filter((group) =>
      group.name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [groups, showSearchInput, searchInput]);

  const updateResourceGroup = async (group, action) => {
    setShowLineLoader(true);
    try {
      const response = await APIService.apiRequest(
        AL_BASE_URL + "/group_resource",
        { group_id: group.id, resource_id: empId },
        false,
        action === "add" ? "PUT" : "DELETE"
      );

      if (response.status === 1) {
        setGroups((prevGroups) => {
          return prevGroups.map((item) => {
            if (group.id === item.id) {
              return {
                ...item,
                resource_ids:
                  action === "add"
                    ? [...item.resource_ids, empId]
                    : item.resource_ids.filter((id) => id !== empId),
              };
            }
            return item;
          });
        });

        if (
          action === "remove" &&
          !activeGroup?.default &&
          activeGroup.id === group.id
        ) {
          removeResourceFromGroup(empId);
        }
      } else {
        alertService.error(response.msg);
      }
    } catch (error) {
      console.error(error);
      alertService.error(`Error: ${error.message}`);
    } finally {
      if (activeGroup?.id !== group.id) {
        setShowLineLoader(false);
      }
    }
  };

  return (
    <div className="group-popup">
      <Popover placement="bottom-end" open={isOpen} onOpenChange={toggle}>
        <PopoverTrigger
          asChild
          onClick={(e) => {
            e.stopPropagation();
            toggle(!isOpen);
          }}
        >
          <button
            className={clsx("group-popover-trigger", isOpen && "active")}
          ></button>
        </PopoverTrigger>
        <PopoverContent style={{ zIndex: 10, top: '6px' }}>
          <LineLoader
            show={showLineLoader}
            position="absolute"
            style={{ top: "0px" }}
          />
          <div className="group-popover" onClick={e => e.stopPropagation()}>
            <div className="wrapper-header">
              <div className="inner-wrapper">
                <h6 className="title">Groups</h6>
                <button
                  className="icon-search"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchInput("");
                    setShowSearchInput(true);
                    setTimeout(() => searchInputRef?.current?.focus(), 201);
                  }}
                ></button>
                <div
                  className={clsx(
                    "groups-search-input-wrapper",
                    showSearchInput && "show"
                  )}
                >
                  <div className="groups-search-input">
                    <span className="search-icon"></span>
                    <input
                      type="text"
                      ref={searchInputRef}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <button
                    className="clear-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSearchInput(false);
                    }}
                  ></button>
                </div>
              </div>
            </div>
            <div className="groups">
              <ul onClick={e => e.stopPropagation()}>
                {suggestions.length > 0 ? (
                  suggestions.map((group) => {
                    const addedInGroup = group.resource_ids.includes(empId);
                    return (
                      <li key={group.id}>
                        <span className={`${addedInGroup ? "added" : ""}`}>
                          {group.name}
                        </span>
                        <button
                          className={clsx("cta", addedInGroup && "show")}
                          onClick={(e) => {
                            updateResourceGroup(
                              group,
                              addedInGroup ? "remove" : "add"
                            );
                          }}
                          disabled={showLineLoader}
                          title={
                            addedInGroup ? "Remove from group" : "Add to group"
                          }
                        >
                          <span
                            className={`icon icon-${addedInGroup ? "remove" : "add"
                              }`}
                          ></span>
                        </button>
                      </li>
                    );
                  })
                ) : searchInput && suggestions.length === 0 && (
                  <li className="empty-msg">Nothing matches your search</li>
                )}
              </ul>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
