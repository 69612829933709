import { useState, useEffect, useRef } from "react";

import { useRecruitmentFunnelContext } from "./context";
import { useTabContext } from "components/SuperTabs/TabContext";

import SpeedSelect from "react-speedselect";
import SuperPager from "super-pager";
import LineLoader from "components/LineLoader";

import useScrollRestoration from "hooks/useScrollRestoration";
import { alertService } from "services/alertService";
import APIService from "services/apiService";

import { debounce, getUser } from "utils/Common";
import { getUniqueId } from "utils/Utils";
import * as Constants from "components/Constants";

import "./styles.scss";

export default function Home() {
  // const abortController = useRef(new AbortController());
  // const resetAbortController = () => {
  //   abortController.current.abort();
  //   abortController.current = new AbortController();
  // };
  const { openSuperTabOnRowClick, activeTab, closeTab } = useTabContext();

  const {
    departmentsList,
    designationsList,
    funnelData,
    setFunnelData,
    metaData,
    setMetaData,
    filteredFunnelData,
    setFilteredFunnelData,
    filteredFunnelMetaData,
    setFilteredFunnelMetaData,
    setCurrentPage,
    showDescription,
    setShowDescription,
    selectedDepartments,
    setSelectedDepartments,
    selectedDesignations,
    setSelectedDesignations,
    activeFilterTab,
    setActiveFilterTab,
    searchString,
    setSearchString,
    currentPage,
    loadingData,
    getFunnelData,
    tabsInfo,
    setTabsInfo,
    funnelFilter,
    setFunnelFilter,
    filterObj,
    resetAbortController: resetFunnelDataAbortController
  } = useRecruitmentFunnelContext();

  const userInfo = getUser(); // Get user info from locals storage

  useEffect(() => {
    if (!loadingData && !funnelData) getFunnelData();
  }, []);

  const loadingDataRef = useRef();

  const [toDelete, setToDelete] = useState(null);
  loadingDataRef.current = loadingData;

  // Variable that holds funnel count based on their status, used for displaying the count on tab buttons
  let allFunnelCount = filteredFunnelData
    ? filteredFunnelData.length
    : funnelData && funnelData.length;
  let activeFunnelCount = filteredFunnelData
    ? filteredFunnelData.filter((f) => f.is_active).length
    : funnelData && funnelData.filter((f) => f.is_active).length;
  let archivedFunnelCount = filteredFunnelData
    ? filteredFunnelData.filter((f) => f.is_archive).length
    : funnelData && funnelData.filter((f) => f.is_archive).length;
  let deletedFunnelCount = filteredFunnelData
    ? filteredFunnelData.filter((f) => f.is_deleted).length
    : funnelData && funnelData.filter((f) => f.is_deleted).length;

  // Helper function

  const formatDate = (date) => {
    let d = new Date(date);
    let dateOptions = { year: "numeric", month: "short", day: "numeric" };
    let timeOptions = { hour12: true, hour: "2-digit", minute: "2-digit" };
    return `${d.toLocaleDateString(
      "en-us",
      dateOptions,
    )}, ${d.toLocaleTimeString("en-us", timeOptions)}`;
  };

  // Provides HTML content for displaying funnel data on the table
  const getFunnelTableContent = (obj) => {
    return obj
      .filter((i) => {
        // Apply filter if any or display entire data
        if (activeFilterTab === "active") {
          return i["is_active"] === 1;
        } else if (activeFilterTab === "deleted") {
          return i["is_deleted"] === 1;
        } else if (activeFilterTab === "archived") {
          return i["is_archive"] === 1;
        } else {
          return i;
        }
      })
      .map((item) => (
        <tr
          key={item.id}
          className={toDelete === item.id ? "hide" : ""}
          onClick={() => handleRowClick(item)}
        >
          <td>
            <span title={item.name}>{item.name}</span>
            {item.description && (
              <button
                className="btn btn-funnel-desc"
                title="Click here to view the description of this funnel"
                onClick={(e) => toggleDescription(e, item.id)}
              >
                DESCRIPTION
              </button>
            )}
            {showDescription.id === item.id && (
              <p className="funnel-desc">{item.description}</p>
            )}
          </td>
          <td>{item.department}</td>
          <td>{item.designation}</td>
          <td>
            {item.stages !== undefined && item.stages.length > 0
              ? item.stages.reduce(
                (a, b) => {
                  return {
                    count_resources: a.count_resources + b.count_resources,
                  };
                },
                { count_resources: 0 },
              ).count_resources
              : "No Stage Info Available"}
          </td>
          <td>
            {item.created_by_first_name +
              (item.created_by === userInfo.id ? "" : " • Shared with you")}
          </td>
          <td>{formatDate(item.updated_at)}</td>
          <td className="actions">
            <div className="recent-action">
              {item.privileges.includes("EDIT") && (
                <button
                  className="btn-settings"
                  onClick={(e) => funnelSettings(e, item.id, item.name, 1)}
                  title="Modify Funnel Settings"
                ></button>
              )}

              {item.privileges.includes("EDIT") && (
                <button
                  className="btn-clone"
                  onClick={(e) => handleCloneFunnel(e, item)}
                  title="Clone Funnel"
                ></button>
              )}

              {item.privileges.includes("DELETE") && (
                <button
                  className="btn-delete"
                  onClick={(e) => handleDeleteFunnel(e, item.id)}
                  title="Delete Funnel"
                ></button>
              )}

              {item.is_archive === 0 &&
                item.is_active === 1 &&
                (item.privileges.includes("EDIT") ||
                  item.privileges.includes("DELETE")) && (
                  <button
                    className="btn-archive"
                    onClick={(e) =>
                      handleArchiveAndActiveFunnel(e, item.id, "archive")
                    }
                    title="Archive Funnel"
                  ></button>
                )}

              {item.is_archive === 1 &&
                item.is_active === 0 &&
                (item.privileges.includes("EDIT") ||
                  item.privileges.includes("DELETE")) && (
                  <button
                    className="btn-activate"
                    onClick={(e) =>
                      handleArchiveAndActiveFunnel(e, item.id, "activate")
                    }
                    title="Activate Funnel"
                  ></button>
                )}

              {item.privileges.includes("SHARE") && (
                <button
                  className="btn-share"
                  onClick={(e) => funnelSettings(e, item.id, item.name, 3)}
                  title="Share Funnel"
                ></button>
              )}
            </div>
          </td>
        </tr>
      ));
  };

  // Data to be displayed in table body
  let displayFunnelData =
    funnelData != null ? getFunnelTableContent(funnelData) : null;
  let displaySearchFunnelData =
    filteredFunnelData != null
      ? getFunnelTableContent(filteredFunnelData)
      : null;

  // Appends funnel ID to the URL and adds a new tab in tabs component
  function handleRowClick(e) {
    // props.history.push(`/app/recruiter/f-${e.id}`);
    const tab = { id: e.id, name: e.name, url: `/app/recruiter/f-${e.id}` };
    openSuperTabOnRowClick({ tab });
    if (!tabsInfo.tabs.some((i) => i.id === String(e.id))) {
      // setTabsInfo({ ...tabsInfo, activeTabId: String(e.id) });
      // } else {
      let funnelFilterCopy = JSON.parse(JSON.stringify(funnelFilter));
      funnelFilterCopy[e.id] = filterObj;
      setFunnelFilter(funnelFilterCopy);
      setTabsInfo({
        ...tabsInfo,
        tabs: [...tabsInfo.tabs, { id: String(e.id), name: e.name }],
      });
      // handleTabOpenCloseAPI(e.id, 'open');
    }
  }

  function handleClearBtn() {
    // resetAbortController();
    resetFunnelDataAbortController();
    searchInputRef.current.value = "";
    if (searchString) setSearchString("");
    if (!filteredFunnelData) return;
    setSelectedDepartments([]);
    setSelectedDesignations([]);
    setActiveFilterTab("all");
    setFilteredFunnelData(null);
    setFilteredFunnelMetaData(null);
    setCurrentPage(metaData.page_number);
    getFunnelData(false, null, false, ["reset", ""]);
  }

  const toggleDescription = (e, rowID) => {
    e.stopPropagation();
    !rowID || showDescription.id === rowID
      ? setShowDescription({ id: null })
      : setShowDescription({ id: rowID });
  };

  const handleDeleteFunnel = (e, funnelId) => {
    e.stopPropagation();
    let apiPayLoad = null;
    setToDelete(funnelId);

    APIService.apiRequest(
      Constants.API_BASE_URL + `/recruiter/recruitment_funnel/${funnelId}`,
      apiPayLoad,
      false,
      "DELETE",
    )
      .then((response) => {
        if (response.status === 1) {
          setToDelete(null);
          alertService.success(`Success: ${response.msg}`);
          if (tabsInfo.tabs.some((tab) => tab.id === String(funnelId))) {
            let tabsInfoCopy = JSON.parse(JSON.stringify(tabsInfo));
            tabsInfoCopy.tabs = tabsInfoCopy.tabs.filter(
              (tab) => tab.id !== String(funnelId),
            );
            setTabsInfo(tabsInfoCopy);

            if (activeTab?.subTabs?.length > 0) {
              const tabData = activeTab.subTabs.find(
                (tab) => String(tab.id) === String(funnelId),
              );

              if (tabData) {
                closeTab({
                  tab: tabData,
                  isSubTab: true,
                });
              }
            }
          }

          if (filteredFunnelData) {
            // let searchFunnelResultsCopy = JSON.parse(JSON.stringify(filteredFunnelData));
            // searchFunnelResultsCopy = searchFunnelResultsCopy.filter(funnel => funnel.id !== funnelId);
            let searchMetaDataCopy = JSON.parse(
              JSON.stringify(filteredFunnelMetaData),
            );
            let paginationObj = {
              currentPage:
                searchMetaDataCopy.page_number ===
                  searchMetaDataCopy.num_pages &&
                  searchMetaDataCopy.total_results %
                  searchMetaDataCopy.page_size ===
                  1
                  ? searchMetaDataCopy.page_number - 1
                  : searchMetaDataCopy.page_number,
            };
            let showLoading =
              searchMetaDataCopy.page_number !== paginationObj.currentPage
                ? true
                : false;
            let isReloadingRequired =
              searchMetaDataCopy.page_number !== searchMetaDataCopy.num_pages ||
              showLoading;
            isReloadingRequired &&
              getFunnelData(true, paginationObj, showLoading);
            searchMetaDataCopy.total_results =
              searchMetaDataCopy.total_results - 1;
            setFilteredFunnelData(
              filteredFunnelData.filter((funnel) => funnel.id !== funnelId),
            );
            setFilteredFunnelMetaData(searchMetaDataCopy);
          } else {
            // let funnelDataCopy = JSON.parse(JSON.stringify(funnelData));
            // funnelDataCopy = funnelDataCopy.filter(funnel => funnel.id !== funnelId);
            let metaDataCopy = JSON.parse(JSON.stringify(metaData));
            let paginationObj = {
              currentPage:
                metaDataCopy.page_number === metaDataCopy.num_pages &&
                  metaDataCopy.total_results % metaDataCopy.page_size === 1
                  ? metaDataCopy.page_number - 1
                  : metaDataCopy.page_number,
            };
            let showLoading =
              metaDataCopy.page_number !== paginationObj.currentPage
                ? true
                : false;
            let isReloadingRequired =
              metaDataCopy.page_number !== metaDataCopy.num_pages ||
              showLoading;
            isReloadingRequired &&
              getFunnelData(false, paginationObj, showLoading);
            metaDataCopy.total_results = metaDataCopy.total_results - 1;
            setFunnelData(
              funnelData.filter((funnel) => funnel.id !== funnelId),
            );
            setMetaData(metaDataCopy);
          }
          // console.log('handleDeleteFunnel if response', response);
        } else {
          // getFunnelData(false);
          alertService.warning(`Warning: ${response.msg}`);
          console.log("handleDeleteFunnel else response", response);
          setToDelete(null);
        }
      })
      .catch((err) => {
        alertService.error(`Error: ${err.msg}`);
        console.log("error", err);
        setToDelete(null);
      });
  };

  const handleCloneFunnel = (e, funnelDetails) => {
    e.stopPropagation();
    const uniqueId = getUniqueId();
    const cloneId = `clone_${funnelDetails.id}_${uniqueId}`;
    const cloneName = `${funnelDetails.name} - Clone`;
    let updatedTabsInfo = {
      tabs: [
        ...tabsInfo.tabs,
        {
          id: cloneId,
          name: cloneName,
        },
      ],
      savedFunnelData: [
        ...tabsInfo.savedFunnelData,
        {
          id: cloneId,
          step: 1,
          name: cloneName,
          description: `${funnelDetails.description}`,
          department: departmentsList.filter(
            (d) => d.name === funnelDetails.department,
          )[0],
          designation: designationsList.filter(
            (d) => d.name === funnelDetails.designation,
          )[0],
          isFunnelCreated: false,
          isQuestionnaire: false,
          funnelId: null,
          mode: "create",
          stages: [{ name: "", description: "" }],
          loadingInfo: false,
          loadingStageInfo: true,
        },
      ],
    };
    setTabsInfo(updatedTabsInfo);
    const tab = {
      id: cloneId,
      name: cloneName,
      url: `/app/recruiter/f-${cloneId}`,
      skipAPICall: true,
    };
    openSuperTabOnRowClick({ tab });

    APIService.apiRequest(
      Constants.API_BASE_URL + `/recruiter/funnel_details/${funnelDetails.id}`,
      null,
      false,
      "GET",
    )
      .then((response) => {
        if (response.status === 1) {
          let sortedFunnelObj = JSON.parse(JSON.stringify(response));
          sortedFunnelObj.stages.sort((a, b) => a.order - b.order);
          let tabsInfoCopy = JSON.parse(JSON.stringify(updatedTabsInfo));
          let index = tabsInfoCopy.savedFunnelData.findIndex(
            (funnelInfo) =>
              funnelInfo.id ===
              `clone_${funnelDetails.id}_${uniqueId}`,
          );
          tabsInfoCopy.savedFunnelData[index].loadingStageInfo = false;
          tabsInfoCopy.savedFunnelData[index].stages =
            sortedFunnelObj.stages.map((s) => ({
              name: s.name,
              description: s.description,
            }));
          // console.log('handleCloneFunnel - if response', response, tabsInfoCopy);
          setTabsInfo(tabsInfoCopy);
        } else {
          console.log("handleCloneFunnel - else response", response.msg);
        }
      })
      .catch((err) => {
        alertService.error(`Error: ${err.msg}`);
        console.log("handleCloneFunnel - error", err);
      });
  };

  const handleArchiveAndActiveFunnel = (e, funnelId, action) => {
    e.stopPropagation();

    let apiPayLoad = null;

    // PUT https://dev-api.hrbase.net/data_stream/recruiter/archive/<recruitment_funnel_id>
    // PUT https://dev-api.hrbase.net/data_stream/recruiter/activate/<recruitment_funnel_id>

    APIService.apiRequest(
      Constants.API_BASE_URL + `/recruiter/${action}/${funnelId}`,
      apiPayLoad,
      false,
      "PUT",
    )
      .then((response) => {
        if (response.status === 1) {
          let updatedFunnelData = !filteredFunnelData
            ? action === "archive"
              ? funnelData.map((f) =>
                f.id === funnelId ? { ...f, is_active: 0, is_archive: 1 } : f,
              )
              : funnelData.map((f) =>
                f.id === funnelId ? { ...f, is_active: 1, is_archive: 0 } : f,
              )
            : action === "archive"
              ? filteredFunnelData.map((f) =>
                f.id === funnelId ? { ...f, is_active: 0, is_archive: 1 } : f,
              )
              : filteredFunnelData.map((f) =>
                f.id === funnelId ? { ...f, is_active: 1, is_archive: 0 } : f,
              );

          if (!filteredFunnelData) {
            setFunnelData(updatedFunnelData);
          } else {
            setFilteredFunnelData(updatedFunnelData);
          }
          // console.log('handleArchiveAndActiveFunnel PUT Response', response);
          alertService.success(`Success: ${response.message}`);
        } else {
          // alertService.warning(`Warning: ${response.message}`);
          console.log(response);
        }
      })
      .catch((err) => {
        alertService.error(`Error: ${err.msg}`);
        console.log("error", err);
      });
  };

  const funnelSettings = (e, funnelId, funnelName, step = 1) => {
    e.stopPropagation();
    let funnelFilterCopy = JSON.parse(JSON.stringify(funnelFilter));
    funnelFilterCopy[funnelId] = filterObj;
    setFunnelFilter(funnelFilterCopy);
    let updatedTabsInfo;
    if (tabsInfo.tabs.some((i) => i.id === String(funnelId))) {
      updatedTabsInfo = {
        ...tabsInfo,
        tabs: tabsInfo.tabs.map((tab) =>
          tab.id === String(funnelId)
            ? { ...tab, step: step, isSettingsOpen: true }
            : tab,
        ),
      };
      setTimeout(() => {
        setTabsInfo({
          ...tabsInfo,
          tabs: tabsInfo.tabs.map((tab) =>
            tab.id === String(funnelId)
              ? { ...tab, isSettingsOpen: false }
              : tab,
          ),
        });
      }, 5000);
      // console.log('updatedTabsInfo', updatedTabsInfo);
    } else {
      updatedTabsInfo = {
        ...tabsInfo,
        tabs: [
          ...tabsInfo.tabs,
          {
            id: String(funnelId),
            name: funnelName,
            step: step,
            isSettingsOpen: true,
          },
        ],
      };
    }
    setTabsInfo(updatedTabsInfo);
    openSuperTabOnRowClick({
      tab: { id: funnelId, name: funnelName, url: `/app/recruiter/f-${funnelId}` },
      isSubTab: true,
    });
  };

  const getTableContent = () => {
    return filteredFunnelData ? (
      displaySearchFunnelData.length ? (
        displaySearchFunnelData
      ) : (
        <tr>
          <td colSpan="7" style={{ textAlign: "center" }}>
            No Data to display
          </td>
        </tr>
      )
    ) : displayFunnelData && displayFunnelData.length ? (
      displayFunnelData
    ) : (
      <tr>
        <td colSpan="7" style={{ textAlign: "center" }}>
          No Data to display
        </td>
      </tr>
    );
  };

  const searchInputRef = useRef(null);

  const handleSearchChange = debounce((value) => {
    // resetAbortController();
    resetFunnelDataAbortController();
    setSearchString(value);
    if (
      !value.trim() &&
      !selectedDepartments.length &&
      !selectedDesignations.length
    ) {
      setFilteredFunnelData(null);
      setCurrentPage(metaData.page_number);
    } else {
      getFunnelData(true, null, true, ["searchString", value]);
    }
  }, 450);

  return (
    <div className="home-content-wrapper">
      <LineLoader show={loadingData} position="absolute" />
      {funnelData && (
        <>
          <div className="recruitment-funnel-search-wrapper">
            <div className="home-search-container">
              <div className="home-search-wrapper">
                <input
                  ref={searchInputRef}
                  type="search"
                  id="search"
                  name="funnel-search"
                  className="search"
                  placeholder="Search"
                  defaultValue={searchString}
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-search"
                  // If search str is not empty or search string is empty and dept/desig filter is still there trigger the API on press of search button
                  // onClick={() => (searchString !== '' || (searchString === '' && (selectedDepartments.length || selectedDesignations.length))) && getFunnelData(true, null, true, ['filter', '', ''])}
                  title="Search for a funnel"
                >
                  Search
                </button>
              </div>
              <button
                type="button"
                className="clear-button"
                onClick={handleClearBtn}
                title="Clear all filters"
              >
                Clear
              </button>
            </div>
            <div className="home-filter-wrapper">
              <div className="filter-control">
                <div className="filter-buttons">
                  <button
                    className={
                      activeFilterTab === "all" ? "activeFilterTab" : ""
                    }
                    onClick={() => setActiveFilterTab("all")}
                  >
                    <p>All</p>{" "}
                    {allFunnelCount && (filteredFunnelData || funnelData) && (
                      <span>
                        &nbsp;
                        {`${allFunnelCount} / ${filteredFunnelData
                          ? filteredFunnelMetaData &&
                          filteredFunnelMetaData.total_results
                          : metaData && metaData.total_results
                          }`}
                      </span>
                    )}
                  </button>
                  <button
                    className={
                      activeFilterTab === "active" ? "activeFilterTab" : ""
                    }
                    onClick={() => setActiveFilterTab("active")}
                  >
                    <p>Active</p> <span>&nbsp;{activeFunnelCount}</span>
                  </button>
                  <button
                    className={
                      activeFilterTab === "deleted" ? "activeFilterTab" : ""
                    }
                    onClick={() => setActiveFilterTab("deleted")}
                  >
                    <p>Closed</p> <span>&nbsp;{deletedFunnelCount}</span>
                  </button>
                  <button
                    className={
                      activeFilterTab === "archived" ? "activeFilterTab" : ""
                    }
                    onClick={() => setActiveFilterTab("archived")}
                  >
                    <p>Archived</p> <span>&nbsp;{archivedFunnelCount}</span>
                  </button>
                </div>
                <div className="filter-select">
                  <SpeedSelect
                    options={departmentsList}
                    selectedOption={selectedDepartments}
                    onSelect={(dept) => {
                      setSelectedDepartments(dept);
                      // Do not call API just reset the filteredFunnelData to null if no filter persists
                      if (
                        !dept.length &&
                        !searchString &&
                        !selectedDesignations.length
                      ) {
                        setFilteredFunnelData(null);
                        setCurrentPage(metaData.page_number);
                        return;
                      }
                      // Designation is based on dept so filter it out accordingly and call API if required
                      let updatedDesig = dept.length
                        ? selectedDesignations.filter((desig) =>
                          dept.some((d) => d.id === desig.department_id),
                        )
                        : selectedDesignations;
                      selectedDesignations.length &&
                        setSelectedDesignations(updatedDesig);
                      selectedDesignations.length
                        ? getFunnelData(true, null, true, [
                          "dept",
                          dept,
                          updatedDesig,
                        ])
                        : getFunnelData(true, null, true, ["dept", dept, []]);
                    }}
                    displayKey="name"
                    uniqueKey="id"
                    maxHeight={120}
                    // hideOkCancelBtns
                    selectLabel="Select"
                    prominentLabel="Department"
                    dropdownSelectAllLabel="All"
                    multiple
                  />
                  <SpeedSelect
                    options={
                      selectedDepartments.length
                        ? designationsList.filter((desig) =>
                          selectedDepartments.some(
                            (dept) => dept.id === desig.department_id,
                          ),
                        )
                        : designationsList
                    }
                    selectedOption={selectedDesignations}
                    onSelect={(desig) => {
                      setSelectedDesignations(desig);
                      // Do not call API just reset the filteredFunnelData to null if no filter persists
                      if (
                        !desig.length &&
                        !searchString &&
                        !selectedDepartments.length
                      ) {
                        setFilteredFunnelData(null);
                        setCurrentPage(metaData.page_number);
                        return;
                      }
                      // Call API if designation is changed
                      getFunnelData(true, null, true, ["desig", desig]);
                    }}
                    displayKey="name"
                    uniqueKey="id"
                    maxHeight={120}
                    // hideOkCancelBtns
                    selectLabel="Select"
                    prominentLabel="Designation"
                    dropdownSelectAllLabel="All"
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>
          <RecruitmentTable
            loadingData={loadingData}
            filteredFunnelData={filteredFunnelData}
            funnelData={funnelData}
            loadingDataRef={loadingDataRef}
            getFunnelData={getFunnelData}
            currentPage={currentPage}
            filteredFunnelMetaData={filteredFunnelMetaData}
            metaData={metaData}
            getTableContent={getTableContent}
          />
        </>
      )}
    </div>
  );
}

function RecruitmentTable({
  loadingData,
  filteredFunnelData,
  funnelData,
  loadingDataRef,
  getFunnelData,
  currentPage,
  filteredFunnelMetaData,
  metaData,
  getTableContent,
}) {
  const tableBodyRef = useScrollRestoration("recruitment-home-table-body");

  return (
    <div className="asc-custom-table-wrapper" ref={tableBodyRef}>
      {/* <table className='asc-custom-table' ref={observer} onScroll={(e) => handleScroll(e)}> */}
      <table className="asc-custom-table">
        <thead>
          <tr>
            <th>Funnel</th>
            <th>Department</th>
            <th>Designation</th>
            <th>Candidates</th>
            <th>Created by</th>
            <th>Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <SuperPager
            type="infiniteScroll"
            isTable={true}
            dataLength={
              loadingData
                ? 0
                : filteredFunnelData
                  ? filteredFunnelData?.length
                  : funnelData?.length || 0
            }
            loadMore={() =>
              !loadingDataRef.current &&
              getFunnelData(
                filteredFunnelData ? true : false,
                { currentPage: currentPage + 1 },
                false,
                ["loadMore", ""],
              )
            }
            hasMore={
              filteredFunnelMetaData
                ? filteredFunnelMetaData.page_number <
                filteredFunnelMetaData.num_pages
                : metaData?.page_number < metaData?.num_pages || false
            }
            wrapper={true}
            children={getTableContent()}
          />
        </tbody>
      </table>
    </div>
  );
}
