import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import Forms from 'views/Forms';
import reportWebVitals from './reportWebVitals';
import { isDev } from 'utils/Common';

function isFormsSubdomain() {
  const hostname = window.location.hostname;
  return hostname.startsWith(`${isDev() ? 'dev-' : ''}forms.`);
}

const isForms = isFormsSubdomain();

if (isForms) {
  const script = document.createElement('script');
  script.src = "https://s3.us-east-2.amazonaws.com/telemetry.bydata/forms.teamlink.com/bydataAnalytics.js?cId=asc00000&version=0.4";
  script.async = true;
  document.head.appendChild(script);
}

ReactDOM.render(
  <React.StrictMode>
    {isForms ? <Forms /> : <App />}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
