import ShiftLogPopover from "./ShiftLogPopover";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import { isNone, isNotNone } from "views/Attendance/utils";
import { formatTimeInSeconds } from "utils/date";

export default function ShiftLog({ log }) {
  const showScheduledHrsBars =
    isNotNone(log.clock_in_time) &&
    log.shift === "Standard";

  const NUM_BARS = Math.ceil(log.max_seconds / 3600);
  const secondsPerBar = log.max_seconds / NUM_BARS;
  const filledBars = Math.floor(log.overlap_seconds / secondsPerBar);
  const currentBarHeight = ((log.overlap_seconds % secondsPerBar) / secondsPerBar) * 100;

  return (
    <div className="shift-total-wrapper">
      <div className="shift-total-time bg-gray">
        {isNone(log.total_time) ? "" : formatTimeInSeconds(+log.total_time)}
      </div>
      <Popover mouseover placement="bottom-end" disabled={!showScheduledHrsBars}>
        <PopoverTrigger asChild>
          <div className="overlap-progress-wrapper">
            <div className="overlap-progress bg-gray">
              {showScheduledHrsBars &&
                Array.from({ length: NUM_BARS }).map((_, i) => (
                  <div key={i} className="progress-block">
                    <div
                      className="bar"
                      style={{
                        height:
                          i < filledBars
                            ? "100%"
                            : i === filledBars
                              ? `${currentBarHeight}%`
                              : "0%",
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent  style={{ zIndex: 10 }}>
          <ShiftLogPopover log={log} />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function SkeletonShiftLog() {
  return (
    <div className="shift-total-wrapper">
      <div className="shift-total-time bg-gray"></div>
      <div className="overlap-progress bg-gray"></div>
    </div>
  );
}
