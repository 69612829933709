import { useState } from "react";
import { ATTENDANCE_STATUS_FILTER_OPTIONS } from "../constants";
// components
import FilterButton from "components/FilterModal/FilterButton";
import FilterModal from "components/FilterModal/FilterModal";
// context
import { useTeamMemberFilter } from "../context/teamMemberFilterContext";
// styles
import "./AttendanceFilterBy.scss";
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";

const userAttendanceStatusFilterOptions = [
  ...ATTENDANCE_STATUS_FILTER_OPTIONS,
  {
    name: "Holiday",
    id: "holiday",
  },
];

export default function TeamMemberAttendanceFilterBy() {
  const [showFilterModal, setShowFilterModal] = useState(false);

  function toggleShowFilterModal() {
    setShowFilterModal(!showFilterModal);
  }

  const openModal = () => {
    toggleShowFilterModal()
  }
  const { filterQuery, setFilterQuery } = useTeamMemberFilter();
  const [handleFilterClick, handleFilterDoubleClick] = useClickPreventionOnDoubleClick(openModal, handleClear);

  function handleClear() {
    setFilterQuery([]);
  }

  function handleAttendanceChange(opt, isChecked) {
    const newFilterQuery = isChecked
      ? [...filterQuery, opt.id]
      : filterQuery.filter((item) => item !== opt.id);

    setFilterQuery(() => newFilterQuery);
  }

  return (
    <div className={`filter-by ${showFilterModal ? 'active' : ''}`}>
      <FilterButton
        onClick={handleFilterClick}
        onDoubleClick={handleFilterDoubleClick}
        filterCount={filterQuery.length}
        tooltip={'Double click to clear'}
      />


      <FilterModal
        show={showFilterModal}
        toggle={toggleShowFilterModal}
        options={[
          {
            label: "Attendance",
            options: userAttendanceStatusFilterOptions.map((option) => ({
              id: option.id,
              name: option.name,
              checked: filterQuery.includes(option.id),
            })),
            onChange: handleAttendanceChange,
          },
        ]}
      />
    </div>
  );
}