import ClickOutsideListner from "components/ClickOutsideListner";
import RequestDatePicker from "./RequestDatePicker";

import './DateForm.scss'

const DateForm = ({
    data,
    disabled,
    isSlotFocused,
    removeSlot,
    toggleFocus,
    handleDateChange,
    datePickerRefs,
}) => {
    return (
        <div className="leave-cards">
            {data.map((day, index) => {
                const isFocused = isSlotFocused === index;
                const showDeleteBtn = isFocused || day.start_date || day.end_date;

                return (
                    <div className="leave-card" key={index}>
                        {showDeleteBtn && (
                            <button
                                className="leave-card-remove-btn"
                                onClick={() => removeSlot(index)}
                                disabled={disabled}
                            >
                                <span className="icon icon-minus"></span>
                            </button>
                        )}
                        <div className="leave-card-day">
                            <ClickOutsideListner
                                onOutsideClick={(e) => {
                                    if (
                                        e.target.className !==
                                        "request-date-picker-btn focused placeholder" &&
                                        e.target.className !== "request-date-picker-btn focused"
                                    ) {
                                        toggleFocus(null);
                                    }
                                }}
                            >
                                <div
                                    className={`leave-card-range ${isFocused ? " focused" : ""
                                        }`}
                                    onClick={() => toggleFocus(index)}
                                >
                                    <div className="start hyphen-in-after">
                                        <RequestDatePicker
                                            ref={(el) => {
                                                if (!datePickerRefs.current[index]) {
                                                    datePickerRefs.current[index] = [el];
                                                } else {
                                                    datePickerRefs.current[index][0] = el;
                                                }
                                            }}
                                            date={day.start_date}
                                            placeholder="Start date"
                                            onChange={(date) =>
                                                handleDateChange(date, "start_date", index)
                                            }
                                            isFocused={isFocused}
                                            disabled={disabled}
                                        />
                                    </div>
                                    {/* <div className="hyphen"></div> */}
                                    <div className="end">
                                        <RequestDatePicker
                                            ref={(el) => (datePickerRefs.current[index][1] = el)}
                                            date={day.end_date}
                                            placeholder="End date"
                                            onChange={(date) =>
                                                handleDateChange(date, "end_date", index)
                                            }
                                            isFocused={isFocused}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </ClickOutsideListner>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default DateForm