import { useEffect, useState } from "react";
import { AL_BASE_URL } from "views/Attendance/constants";
import { formatTime, getShiftDetails } from "views/Attendance/utils";
import StandardShiftDetailCard from "../StandardShiftDetailCard";
import Panel from "components/Panel";
import LineLoader from "components/LineLoader";
import { useGlobalContext } from "contexts/GlobalContext";
import APIService from "services/apiService";
import { alertService } from "services/alertService";
import { addDays, format, getWeek } from "utils/date";
import { convertHhmmToUserTimezone } from "utils/Common";
import "./ShiftDetailsModal.scss";

const ShiftDetailsModal = ({ resourceId, date, shiftData, toggle }) => {
  const {
    isOnLeave,
    isOnSpecialShift,
    holiday,
    isAdhocShift,
  } = getShiftDetails(shiftData);

  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const { checkIsDSTActive, myTimeZone } = useGlobalContext();
  useEffect(() => {
    const controller = new AbortController();
    async function getCurrentShiftData() {
      const formattedDate = format(date, "YYYY-MM-DD");
      try {
        let url = new URL(AL_BASE_URL + `/shift/${resourceId}`);
        url.searchParams.append("date", formattedDate);
        url.searchParams.append("shift_type", "standard");
        url = url.toString();

        const response = await APIService.apiRequest(
          url,
          null,
          false,
          "GET",
          controller
        );

        if (response.status === 1) {
          const tzOffset = myTimeZone.utc_offset;
          const dstTZOffset = myTimeZone.dst_utc_offset ?? tzOffset;
          const isDST = checkIsDSTActive(
            null,
            new Date(`${formattedDate} 12:00:00`)
          );
          const key = isDST ? "dst_schedule" : "schedule";
          const offset = isDST ? dstTZOffset : tzOffset;

          setSchedule(
            response.output[key].map((shift) => {
              const [start_time, start_time_offset] = convertHhmmToUserTimezone(
                shift.start_time.time,
                offset,
                shift.start_time.time_offset,
              );
              const [end_time, end_time_offset] = convertHhmmToUserTimezone(
                shift.end_time.time,
                offset,
                shift.end_time.time_offset,
              );

              return {
                ...shift,
                start_time: {
                  time: start_time,
                  time_offset: start_time_offset,
                },
                end_time: {
                  time: end_time,
                  time_offset: end_time_offset,
                },
              };
            })
          );
        } else {
          alertService.error(response.msg);
        }
      } catch (error) {
        alertService.error(error.message);
      } finally {
        setLoadingSchedule(false);
      }
    }

    if (resourceId) getCurrentShiftData();
    return () => {
      setSchedule([]);
      setLoadingSchedule(true);
      controller.abort();
    }
  }, [resourceId, date]);

  const [loadingAdhocShift, setLoadingAdhocShift] = useState(true);
  const [adhocShift, setAdhocShift] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    async function fetchAdhocShiftData() {
      const startDate = format(date, "YYYY-MM-DD");
      const endDate = format(addDays(date, 1), "YYYY-MM-DD");
      try {
        const url = new URL(AL_BASE_URL + "/request");
        url.searchParams.append("request_date", `${startDate}_${endDate}`);
        url.searchParams.append("resource_id", resourceId);
        url.searchParams.append("request_type", "adhoc_shift");
        url.searchParams.append("status", "approved");

        const response = await APIService.apiRequest(
          url.toString(),
          null,
          false,
          "GET",
          controller
        );

        if (response.status === 1) {
          setAdhocShift(response.output[0].data);
        } else {
          alertService.error(response.msg);
        }
      } catch (error) {
        alertService.error(error.message);
      } finally {
        setLoadingAdhocShift(false);
      }
    }

    if (isAdhocShift && resourceId && date) fetchAdhocShiftData();
    return () => {
      setAdhocShift([]);
      setLoadingAdhocShift(true);
      controller.abort();
    }
  }, [resourceId, date]);

  const [loadingLeave, setLoadingLeave] = useState(true);
  const [leave, setLeave] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    async function fetchLeaveData() {
      const requestDate = format(date, "YYYY-MM-DD");
      try {
        const url = new URL(AL_BASE_URL + "/request");
        url.searchParams.append(
          "request_date",
          `${requestDate}_${requestDate}`
        );
        url.searchParams.append("resource_id", resourceId);
        url.searchParams.append("request_type", "leave");
        url.searchParams.append("status", "approved");

        const response = await APIService.apiRequest(
          url.toString(),
          null,
          false,
          "GET",
          controller
        );

        if (response.status === 1) {
          setLeave(response.output[0].data);
        } else {
          alertService.error(response.msg);
        }
      } catch (error) {
        alertService.error(error.message);
      } finally {
        setLoadingLeave(false);
      }
    }

    if (isOnLeave && resourceId && date) fetchLeaveData();
    return () => {
      controller.abort();
      setLoadingLeave(true);
      setLeave([]);
    }
  }, [resourceId, date]);

  const showLineLoader =
    loadingSchedule ||
    (isOnLeave && loadingLeave) ||
    (isAdhocShift && loadingAdhocShift);

  return (
    <Panel
      isOpen={resourceId != null}
      onClose={toggle}
      id={`shift-details-${resourceId}-${date}`}
      width={330}
      top="var(--header-height-other-tab)"
    >
      <div className="shift-details-modal">
        <LineLoader show={showLineLoader} position="absolute" />
        {!showLineLoader && (
          <>
            {isAdhocShift ? (
              adhocShift.length > 0 ? (
                <div className="shift-info-wrapper">
                  <h6 className="title">Adhoc Shift</h6>
                  <WorkAt
                    activeTracking={adhocShift.some(
                      (day) => day.is_activity_tracked === 1
                    )}
                    isWFH={adhocShift.some((day) => day.is_wfh === 1)}
                  />
                  <div className="adhoc-shift-info">
                    {adhocShift.map((leave, index) => {
                      return (
                        <div className="info" key={index}>
                          <span>{format(leave.start_date, "ddd, MMM DD")}</span>
                          <span className="hyphen"></span>
                          <span>{format(leave.end_date, "ddd, MMM DD")}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null
            ) : isOnSpecialShift ? (
              shiftData?.special_shift_time?.length > 0 ? (
                <div className="shift-info-wrapper">
                  <h6 className="title">Special Shift</h6>
                  <WorkAt
                    activeTracking={shiftData.special_shift_activity_tracked === 1}
                    isWFH={shiftData.special_shift_wfh === 1}
                  />
                  <table className="special-shift-body">
                    <tbody>
                      {shiftData.special_shift_time.map((ss) => {
                        const formattedStartTime = formatTime(
                          ss.special_shift_start_time.time
                        )
                          .replace("-", "")
                          .replace(":", " : ");
                        const formattedEndTime = formatTime(
                          ss.special_shift_end_time.time
                        )
                          .replace("-", "")
                          .replace(":", " : ");
                        const isNegativeStartTime =
                          ss.special_shift_start_time.time.includes("-");
                        const isNegativeEndTime =
                          ss.special_shift_end_time.time.includes("-");
                        return (
                          <tr
                            className="special-shift-info"
                            key={ss.special_shift_start_time.time}
                          >
                            <td>
                              <div
                                className={
                                  isNegativeStartTime
                                    ? "icon-info-bubble-after-wrapper"
                                    : ""
                                }
                              >
                                {formattedStartTime}
                                {/* {isNegativeStartTime && (
                                                    <MouseTooltip
                                                        asChild
                                                        content={
                                                            <span>
                                                                Clock-In will occur a day before in your selected timezone
                                                            </span>
                                                        }
                                                        style={{ lineHeight: "17px" }}
                                                    >
                                                        <span className="icon-info-bubble-absolute" />
                                                    </MouseTooltip>
                                                )} */}
                              </div>
                            </td>
                            <td>
                              <div className="hyphen-wrapper">
                                <span className="hyphen"></span>
                              </div>
                            </td>
                            <td>
                              <div
                                className={
                                  isNegativeEndTime
                                    ? "icon-info-bubble-after-wrapper"
                                    : ""
                                }
                              >
                                {formattedEndTime}
                                {/* {isNegativeEndTime && (
                                                    <MouseTooltip
                                                        asChild
                                                        content={
                                                            <span>
                                                                Clock-Out will occur a day before in your selected timezone
                                                            </span>
                                                        }
                                                        style={{ lineHeight: "17px" }}
                                                    >
                                                        <span className="icon-info-bubble-absolute" />
                                                    </MouseTooltip>
                                                )} */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null
            ) : holiday ? (
              <div className="shift-info-wrapper">
                <h6 className="title">On Holiday</h6>
                <div className="holiday-info">{holiday}</div>
              </div>
            ) : isOnLeave ? (
              leave.length > 0 ? (
                <div className="shift-info-wrapper">
                  <h6 className="title">On Leave</h6>
                  <div className="leave-details">
                    {leave.map((item, index) => {
                      return (
                        <div className="info" key={index}>
                          <span>{format(item.start_date, "ddd, MMM DD")}</span>
                          <span className="hyphen"></span>
                          <span>{format(item.end_date, "ddd, MMM DD")}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null
            ) : null}
            {schedule.length > 0 && (
              <div className="shift-info-wrapper">
                <h6 className="title">Standard Shift</h6>
                <WorkAt
                  activeTracking={schedule[0].is_activity_tracked === 1}
                  isWFH={schedule[0].is_wfh === 1}
                />
                <StandardShiftDetailCard
                  data={schedule}
                  trimWeekDay="3"
                  moreSpace={true}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Panel>
  );
};

function WorkAt({ isWFH, activeTracking = false }) {
  return (
    <div className="work-at">
      <div className="group">
        <span className={`location-type ${isWFH ? "remote" : "office"}`}></span>
        <span className="label">{isWFH ? "Remote" : "Office"}</span>
      </div>
      <div className="group">
        <span
          className={`work-type ${activeTracking ? "online" : "offline"}`}
        ></span>
        <span className="label">
          {activeTracking ? "Online" : "Offline"} work
        </span>
      </div>
    </div>
  );
}

export default ShiftDetailsModal;
