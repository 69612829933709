import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

// services
import APIService from "services/apiService";
import { alertService } from 'services/alertService';

// hooks
import { useHistory } from "react-router-dom";
// import { useGlobalContext } from "../../contexts/GlobalContext";

// utils and constants
import { addOrUpdateSubTab, homeTab, findBestMatchFromPages, getUniqueId, getUser, getTitle, hasPrivilege, isChildPath, calculateWidth, getTextWidth, splitSubTabs, sortByOrder, } from "./Utils";
import { HomeTabWidth, MainTabGap, MarginLeft, SubTabWrapperPadding, StackButtonWidth, fontStyle, } from './Constants';

function sortSubTabs(tab) {
    if (tab.subTabs.length > 0) {
        tab.subTabs.sort(sortByOrder);
        tab.subTabs.forEach(sortSubTabs); // Recursively sort each subTab's subTabs
    }
}

const getUniqueIdentifier = (prefix = '') => {
    const { id, organization_id } = getUser(`${prefix}token`);
    let uniqueId = localStorage.getItem(`${prefix}unique_identifier`);
    if (!uniqueId) {
        uniqueId = getUniqueId();
        localStorage.setItem(`${prefix}unique_identifier`, uniqueId);
    }
    return `${id}_${organization_id}_${uniqueId}`;
}

const maxOrder = 9;

export const TabContext = createContext({
    activeTab: {},
    activeSubTab: {},
    lastOpenedUrl: { current: {} },
    isTabDataLoaded: false,
    tabs: [],
    updateCurrentTab: ({ tab, ignoreActiveTabUpdate = false, parentId, isHomeTab = false, isSubTab = false, sort = false } = {}) => { },
    openTab: ({ tab, isHomeTab = false, isSubTab = false }) => { },
    openSubTab: ({ tab, parentId }) => { },
    modifyTab: ({ oldTab, newTab, isSubTab = false }) => { },
    openSuperTabOnRowClick: ({ tab, isSubTab = true }) => { },
    closeTab: ({ tab, isSubTab = false }) => { },
    reorderTabs: ({ tab, order }) => { },
    updateActiveTab: (tab) => { },
    updateActiveSubTab: (tab) => { },
    updateTabProperty: ({ propertyToUpdate, newValue, identifierKey, identifierValue }) => { },
    updateTabs: (tabs) => { },
});

export function TabProvider({ children, SITE_PREFIX, API_BASE_URL, SITE_PAGES }) {
    const history = useHistory();
    const user = getUser(`${SITE_PREFIX}token`);

    const homeTabIdRef = useRef(null);
    const superTabsWrapperElemRef = useRef(null);
    const [isTabDataLoaded, setIsTabDataLoaded] = useState(false);
    const [openingUnexpandedSubTab, setOpeningUnexpandedSubTab] = useState(false);
    const [closingUnexpandedSubTab, setClosingUnexpandedSubTab] = useState(false);
    const [subTabChange, setSubTabChange] = useState(null);
    const [expandedTabID, setExpandedTabID] = useState(null);
    const expandedTabIDRef = useRef(expandedTabID);
    const expandedTabIDTimeoutRef = useRef(null);

    const [subTabWrapperWidth, setSubTabWrapperWidth] = useState({});

    const [maxWidth, setMaxWidth] = useState(null);
    const openingUnexpandedSubTabTimeoutRef = useRef(null);
    const closingUnexpandedSubTabTimeoutRef = useRef(null);
    const [tabs, setTabs] = useState([]);
    const tabsRef = useRef(tabs);
    const [activeTab, setActiveTab] = useState({});
    const [activeSubTab, setActiveSubTab] = useState({});
    const activeTabRef = useRef(null);
    const activeSubTabRef = useRef(null);
    const lastOpenedUrl = useRef({});
    const updateCurrentTabRef = useRef(new AbortController());

    const updateTabs = useCallback((tabs) => {
        if (typeof tabs === 'function') {
            tabsRef.current = tabs(tabsRef.current);
        } else {
            tabsRef.current = tabs;
        }
        setTabs(tabsRef.current);
    }, []);
    const updateExpandedID = useCallback((val) => {
        setExpandedTabID(val);
        expandedTabIDRef.current = val;
    }, []);
    const updateSubTabChange = useCallback((val) => {
        clearTimeout(expandedTabIDTimeoutRef.current);
        setSubTabChange(val);
        if (val) {
            expandedTabIDTimeoutRef.current = setTimeout(() => {
                setSubTabChange(null);
            }, 1000);
        }
    }, []);
    const updateActiveTab = useCallback((tab) => {
        setActiveTab(tab)
        activeTabRef.current = tab;
    }, []);
    const updateActiveSubTab = useCallback((tab) => {
        setActiveSubTab(tab)
        activeSubTabRef.current = tab;
    }, []);

    const updateMaxWidth = useCallback((data = tabsRef.current) => {
        const openParentTabsLength = data.length;
        const parentIndex = data.findIndex((data) => data.id === activeTabRef.current?.id);
        let subTabs = data[parentIndex]?.subTabs || [];
        const subTabWithOpenOrderOne = subTabs?.find(t => t.open_order === 1);
        const openedSubTabsLength = subTabs.filter(t => t.order !== 0).length;
        const totalTabs = openParentTabsLength + (expandedTabIDRef.current ? openedSubTabsLength : (subTabWithOpenOrderOne ? 1 : 0));


        const containerWidth = (superTabsWrapperElemRef.current.offsetWidth - (HomeTabWidth + (MainTabGap * openParentTabsLength) + (openedSubTabsLength * MarginLeft) + StackButtonWidth + SubTabWrapperPadding));
        let maxWidth = Math.ceil((containerWidth / totalTabs) * 100) / 100;

        let totalMinWidth = 0;
        let numElementsNeedingSpace = 0;

        // Calculate the total width and minimum width of all elements
        const activeTabWidth = getTextWidth(getTitle(activeTabRef.current), fontStyle) + 42;
        const tabLengthWithoutActiveTab = totalTabs - 1;
        maxWidth = maxWidth - ((activeTabWidth - maxWidth) / tabLengthWithoutActiveTab);
        setMaxWidth(maxWidth);


        let newData = data.map(tab => {
            const title = getTitle(tab);
            const isActiveTab = tab.id === activeTabRef.current.id;
            const elementWidth = getTextWidth(title, fontStyle) + 42;
            const minWidth = isActiveTab ? elementWidth : Math.min(elementWidth, maxWidth);
            totalMinWidth += (minWidth - (isActiveTab ? 10 : 0));
            if (elementWidth > maxWidth) numElementsNeedingSpace++;
            return { ...tab, elementWidth, width: minWidth };
        });

        if (expandedTabIDRef.current) {
            newData[parentIndex].subTabs = subTabs.map(tab => {
                if (tab.order === 0) {
                    return { ...tab, width: 0 };
                }
                const title = getTitle(tab);
                const elementWidth = Math.ceil(calculateWidth({ text: title, hasAvatar: tab.showAvatar, addMargin: false, }) * 100) / 100;
                const minWidth = Math.min(elementWidth, maxWidth);
                totalMinWidth += minWidth;
                if (elementWidth > maxWidth) numElementsNeedingSpace++;
                return { ...tab, elementWidth, width: minWidth };
            });
        } else if (subTabWithOpenOrderOne) {
            newData[parentIndex].subTabs = newData[parentIndex].subTabs.map(tab => {
                if (tab.open_order === 1) {
                    const title = getTitle(tab);
                    const elementWidth = Math.ceil(calculateWidth({ text: title, hasAvatar: tab.showAvatar, addMargin: false, }) * 100) / 100;
                    const minWidth = Math.min(elementWidth, maxWidth);
                    totalMinWidth += minWidth;
                    if (elementWidth > maxWidth) numElementsNeedingSpace++;
                    return { ...tab, elementWidth, width: elementWidth };
                }
                return { ...tab, width: 0 };
            });
        }

        let remainingSpace = Math.ceil(containerWidth - totalMinWidth);

        // Identify elements that need extra space
        // Distribute remaining space among elements that need extra width
        if (remainingSpace > 0 && numElementsNeedingSpace > 0) {
            newData = newData.map(tab => {
                const spaceNeeded = tab.elementWidth - tab.width;
                const spaceAllocated = remainingSpace > spaceNeeded ? spaceNeeded : remainingSpace;
                remainingSpace -= spaceAllocated;
                return { ...tab, width: tab.width + spaceAllocated };
            });
            if (remainingSpace > 0) {
                newData[parentIndex].subTabs = newData[parentIndex].subTabs.map(tab => {
                    const spaceNeeded = tab.elementWidth - tab.width;
                    const spaceAllocated = remainingSpace > spaceNeeded ? spaceNeeded : remainingSpace;
                    remainingSpace -= spaceAllocated;
                    return { ...tab, width: tab.width + spaceAllocated };
                });
            }
        }
        updateTabs(newData);

    }, [updateTabs]);

    const resetMaxWidth = () => {
        setMaxWidth(null);
    }

    const updateTabProperty = useCallback(({ propertyToUpdate, newValue, identifierKey, identifierValue } = {}) => {
        setTabs(tabs => {
            const updatedTabs = tabs.map(tab => {
                if (tab[identifierKey] === identifierValue) {
                    return {
                        ...tab,
                        [propertyToUpdate]: newValue
                    };
                }
                return tab;
            });
            tabsRef.current = updatedTabs;
            return updatedTabs;
        });
    }, []);

    const openSuperTabOnRowClick = ({ tab, isSubTab = true } = {}) => {
        const isTabOpen = !isSubTab && tabsRef.current.some(t => t.url === tab.url);
        history.push(tab.url);
        // if (!isSubTab && tab.skipAPICall) return;
        isSubTab ? openSubTab({ tab }) : !isTabOpen && openTab({ tab })
    };

    const openTab = useCallback(async ({ tab, isHomeTab = false, isSubTab = false, keepInactive = false } = {}) => {
        // return;
        try {
            if (!isSubTab) {
                updateActiveTab(tab);
                lastOpenedUrl.current[user.organization_id + '_' + tab.id] = tab;
            }
            if (!isHomeTab && (isSubTab ? !activeTabRef.current.tabId : !homeTabIdRef.current)) {
                // alertService.error('Parent id not found');
                console.log('Parent id not found', tab, activeTabRef.current.tabId);
                return;
            }

            const url = new URL(API_BASE_URL + `/super_tab/open_tab/`);
            const unique_identifier = getUniqueIdentifier(SITE_PREFIX);
            const { current: { id: activeTabId } } = activeTabRef;

            const { subTabs, level, order, tabId, unique_identifier: tabUniqueId, parent_tab_id, width, ...filteredTab } = tab;

            const payload = {
                unique_identifier,
                created_by: unique_identifier.split('_')[0],
                tab_info: {
                    ...filteredTab,
                    ...(isSubTab && { parent_id: activeTabId })
                }
            };

            if (!isHomeTab) {
                payload.parent_tab_id = isSubTab ? activeTabRef.current.tabId : homeTabIdRef.current;
            }

            // return;
            const response = await APIService.apiRequest(
                url.toString(),
                payload,
                false,
                "PUT"
            )

            if (response.status === 1) {
                const tabId = response.tab.id
                if (isHomeTab) {
                    homeTabIdRef.current = tabId;
                    return { tab_info: tab, tabId, level: 0 };
                } else {
                    const newTab = { ...tab, tabId };
                    updateTabs(prev => {
                        let tabsCopy = structuredClone(prev);
                        if (isSubTab) {
                            const parentIndex = tabsCopy.findIndex(t => t.id === activeTabRef.current.id);
                            tabsCopy[parentIndex].subTabs = tabsCopy[parentIndex].subTabs.map(t => t.id === tab.id ? { ...t, tabId } : t);
                            if (!keepInactive) {
                                updateActiveSubTab(newTab);
                                lastOpenedUrl.current[user.organization_id + '_' + activeTabRef.current.id] = newTab;
                            }
                        } else {
                            let subTabs = response.tab.children ? response.tab.children.map(t => {
                                const { tab_info, ...rest } = t;
                                const flattenedSubTab = { ...rest, ...tab_info, tabId: rest.id };
                                return flattenedSubTab;
                            }) : [];

                            tabsCopy = tabsCopy.map(t => t.id === tab.id ? { ...t, tabId, subTabs: subTabs.sort(sortByOrder) } : t)
                            const pathName = window.location.pathname;
                            const isSubTabActive = subTabs.find(t => t.url === pathName);
                            if (isSubTabActive) updateActiveSubTab(isSubTabActive);

                            updateActiveTab(newTab);
                        }
                        return tabsCopy;
                    });
                    isSubTab ? !keepInactive && updateActiveSubTab(newTab) : updateActiveTab(newTab);
                    return { tab_info: tab, id: tabId, level: isSubTab ? 2 : 1, parent_tab_id: isSubTab ? activeTabRef.current.tabId : homeTabIdRef.current };
                }
            } else {
                if (response instanceof DOMException && response.name === 'AbortError') return;
                alertService.error(response.msg);
            }
        }

        catch (error) {
            alertService.error(`Error: ${error.message}`);
            console.log("openTab - error", error);
        }
    }, [API_BASE_URL, SITE_PREFIX, updateActiveSubTab, updateActiveTab, updateTabs, user.organization_id]);

    const updateCurrentTab = useCallback(async ({ tab, ignoreActiveTabUpdate = false, parentId, isHomeTab = false, isSubTab = false, sort = false } = {}) => {
        try {
            if (isSubTab) {
                lastOpenedUrl.current[user.organization_id + '_' + parentId] = tab;
                updateActiveSubTab(tab);
                updateTabs(prev => {
                    const updatedData = prev.map(t => t.url === activeTabRef.current.url ? { ...t, subTabs: addOrUpdateSubTab({ array: t.subTabs, newTab: tab, maxOrder, sort }) } : t)
                    // console.log('updateCurrentTab - tabsCopy', updatedData.find(t => t.id === parentId).subTabs.map(t => t.firstName + '_' + t.open_order + '_O-' + t.order));
                    return updatedData
                })
                // setLastOpenedSubTabs((prev) => {
                //     const updated = { ...prev };
                //     updated[parentId] = isSubTab ? tab : null;
                //     return updated;
                // });
            } else {
                if (!ignoreActiveTabUpdate) {
                    lastOpenedUrl.current[user.organization_id + '_' + tab.id] = tab;
                    updateActiveTab(tab);
                    if (activeSubTabRef.current?.url && isChildPath(tab.url, activeSubTabRef.current.url)) {
                        updateActiveSubTab({});
                    }
                }
                // updateActiveSubTab({});
                // setLastOpenedSubTabs((prev) => {
                //     const updated = { ...prev };
                //     updated[tab.id] = null;
                //     return updated;
                // });
            }

            // if (tab.skipAPICall) return;
            if (updateCurrentTabRef.current) {
                updateCurrentTabRef.current.abort();
                updateCurrentTabRef.current = new AbortController();
            }

            const tabId = isHomeTab ? homeTabIdRef.current : isSubTab ? activeSubTabRef.current?.tabId : activeTabRef.current?.tabId;
            if (!tabId) {
                // if (!isHomeTab && !homeTabIdRef.current && isSubTab ? !activeTabRef.current.tabId : false) {
                console.log('Tab not found opening a new tab');
                openTab({ tab, isHomeTab, isSubTab });
                return;
            }

            const url = new URL(API_BASE_URL + `/super_tab/open_tab/`);
            const unique_identifier = getUniqueIdentifier(SITE_PREFIX);
            const { current: { id: activeTabId } } = activeTabRef;

            const { subTabs, level, order, tabId: tabIdFromTab, unique_identifier: tabUniqueId, parent_tab_id, width, ...filteredTab } = tab;

            const payload = {
                unique_identifier,
                tab_id: tabId,
                created_by: unique_identifier.split('_')[0],
                tab_info: {
                    ...filteredTab,
                    ...(isSubTab && { parent_id: tab.parent_id || activeTabId })
                }
            };

            if (!isHomeTab) {
                payload.parent_tab_id = parent_tab_id || (isSubTab ? activeTabRef.current.tabId : homeTabIdRef.current);
            }

            if (!isHomeTab && !payload.parent_tab_id) {
                alertService.error('Parent id not found for updating current tab');
                console.log('Parent id not found for updating current tab', tab, activeTabRef.current.tabId);
                return;
            }

            // console.log('updateCurrentTab - payload', payload);
            // return;

            const response = await APIService.apiRequest(
                url.toString(),
                payload,
                false,
                "PUT",
                updateCurrentTabRef.current,
            )

            if (response.status === 1) {
            } else {
                if (response instanceof DOMException && response.name === 'AbortError') return;
                alertService.error(response.msg);
            }
        }

        catch (error) {
            alertService.error(`Error: ${error.message}`);
            console.log("updateCurrentTab - error", error);
        }
    // }, [API_BASE_URL, SITE_PREFIX, updateActiveSubTab, updateActiveTab, updateTabs, user.organization_id]);
    }, [API_BASE_URL, SITE_PREFIX, openTab, updateActiveSubTab, updateActiveTab, updateTabs, user.organization_id]);

    const openSubTab = useCallback(async ({ tab, parentId, keepInactive = false }) => {
        let isSubTabOpen = false;
        let parentTab = null;
        const tabsCopy = structuredClone(tabsRef.current);
        parentTab = tabsCopy.find(t => t.id === activeTabRef.current.id);
        const subTabWithOpenOrderOne = parentTab.subTabs.find(t => t.open_order === 1);
        isSubTabOpen = parentTab.subTabs.find(t => t.url.split('?')[0] === tab.url.split('?')[0]);
        updateTabs(() => {
            const updatedState = tabsCopy.map(t => t.url === activeTabRef.current.url ? { ...t, subTabs: addOrUpdateSubTab({ array: t.subTabs, newTab: tab, maxOrder }) } : t)
            // console.log('openSubTab - tabsCopy', updatedState.find(t => t.id === activeTabRef.current.id).subTabs.map(t => t.firstName + '_' + t.open_order + '_O-' + t.order));
            return updatedState;
            // return prev.map(t => t.url === activeTabRef.current.url ? { ...t, subTabs: t?.subTabs?.length > 0 ? [...t.subTabs, tab] : [tab] } : t)
            // return tabsCopy.map(t => t.url === activeTabRef.current.url ? { ...t, subTabs: !isSubTabOpen ? [...t.subTabs, tab] : t.subTabs } : t)
        })
        if (!keepInactive) {
            lastOpenedUrl.current[user.organization_id + '_' + parentTab.id] = tab;
            setActiveSubTab(tab);
            activeSubTabRef.current = tab;
        }
        clearTimeout(openingUnexpandedSubTabTimeoutRef.current);
        setOpeningUnexpandedSubTab(true);
        openingUnexpandedSubTabTimeoutRef.current = setTimeout(() => {
            setOpeningUnexpandedSubTab(false);
        }, 300);
        updateMaxWidth();
        updateSubTabChange({
            parentTabId: activeTabRef.current.id,
            oldTab: subTabWithOpenOrderOne,
            newTab: tab,
        });
        // if (!isSubTabOpen && !tab.skipAPICall) openTab({ tab, parentId, isSubTab: true, keepInactive });
        if (!isSubTabOpen) openTab({ tab, parentId, isSubTab: true, keepInactive });
        if (subTabWrapperWidth[user.organization_id + '_' + parentTab?.id]) {
            setSubTabWrapperWidth((prev) => {
                const updated = { ...prev };
                delete updated[user.organization_id + '_' + parentTab.id];
                return updated;
            });
        }
    }, [updateTabs, updateMaxWidth, updateSubTabChange, openTab, subTabWrapperWidth, user.organization_id]);

    const modifyTab = useCallback(({ oldTab, newTab, isSubTab = false }) => {
        const tab = { ...newTab, tabId: oldTab.tabId || null }
        updateTabs(prev => {
            let tabsCopy = structuredClone(prev);
            const parentIndex = tabsCopy.findIndex(t => t.id === activeTabRef.current.id);
            if (isSubTab) {
                const childIndex = tabsCopy[parentIndex].subTabs.findIndex(t => t.id === oldTab.id);
                if (childIndex !== -1) {
                    tabsCopy[parentIndex].subTabs[childIndex] = tab;
                } else {
                    tabsCopy[parentIndex].subTabs.push(tab);
                }
                lastOpenedUrl.current[user.organization_id + '_' + activeTabRef.current.id] = newTab;
                updateActiveSubTab(newTab);
            } else {
                tabsCopy = tabsCopy.map(t => t.id === oldTab.id ? newTab : t);
                updateActiveTab(newTab);
            }
            return tabsCopy;
        });
        // openTab({ tab, isSubTab });
        updateCurrentTab({ tab, isSubTab, parentId: activeTabRef.current.id });
    }, [updateCurrentTab, updateActiveSubTab, updateActiveTab, updateTabs, user.organization_id]);

    const closeTab = useCallback(async ({ tab, isSubTab = false } = {}) => {
        try {
            if (isSubTab) {
                clearTimeout(closingUnexpandedSubTabTimeoutRef.current);
                setClosingUnexpandedSubTab(true);
                closingUnexpandedSubTabTimeoutRef.current = setTimeout(() => {
                    setClosingUnexpandedSubTab(false);
                }, 300);
            }
            updateTabs(prev => {
                let newTabs = structuredClone(prev);
                if (isSubTab) {
                    const parentTabIndex = newTabs.findIndex((data) => data.id === activeTabRef.current.id);
                    const subTabIndex = newTabs[parentTabIndex].subTabs.findIndex((data) => data.id === tab.id);
                    const { order: tabOrder, open_order: tabOpenOrder } = tab;
                    const subTabsLength = newTabs[parentTabIndex].subTabs.length;
                    newTabs[parentTabIndex].subTabs = newTabs[parentTabIndex].subTabs.filter(subTab => subTab.id !== tab.id);
                    const { itemsWithOrderZero } = splitSubTabs(newTabs[parentTabIndex].subTabs);

                    let nextNonZeroOrderObj = subTabsLength > maxOrder && itemsWithOrderZero.length > 0 ? itemsWithOrderZero.reduce((prev, curr) => prev.open_order < curr.open_order ? prev : curr) : null;

                    if (tab.id === activeSubTabRef.current?.id) {
                        if (newTabs[parentTabIndex].subTabs.length > 0) {
                            const nextSubTab = newTabs[parentTabIndex].subTabs[subTabIndex];
                            const newActiveSubTab = nextSubTab ?? newTabs[parentTabIndex].subTabs[subTabIndex - 1];

                            newTabs[parentTabIndex].subTabs = newTabs[parentTabIndex].subTabs.map(subTab => {
                                if (subTab.id === newActiveSubTab.id) {
                                    const order = nextSubTab ? subTab.order - 1 : subTab.order;
                                    return { ...subTab, order, open_order: 1 };
                                }
                                if (nextNonZeroOrderObj && subTab.id === nextNonZeroOrderObj.id) {
                                    return { ...subTab, order: maxOrder, open_order: subTab.open_order - 1 };
                                }

                                return {
                                    ...subTab,
                                    order: subTab.order > tabOrder ? subTab.order - 1 : subTab.order,
                                    open_order: subTab.open_order > tabOpenOrder ? subTab.open_order - 1 : subTab.open_order,
                                };
                            });

                            updateActiveSubTab(newActiveSubTab);
                            lastOpenedUrl.current[user.organization_id + '_' + activeTabRef.current.id] = newActiveSubTab;
                            history.replace(newActiveSubTab.url);
                        } else {
                            updateActiveSubTab({});
                            lastOpenedUrl.current[user.organization_id + '_' + activeTabRef.current.id] = null;
                            history.replace(activeTabRef.current.url);
                        }
                    } else {
                        newTabs[parentTabIndex].subTabs = newTabs[parentTabIndex].subTabs.map(subTab => {
                            if (tabOrder === 0) {
                                return {
                                    ...subTab,
                                    open_order: subTab.open_order > tabOpenOrder ? subTab.open_order - 1 : subTab.open_order,
                                };
                            }

                            if (nextNonZeroOrderObj && subTab.id === nextNonZeroOrderObj.id) {
                                return { ...subTab, order: maxOrder, open_order: subTab.open_order - 1 };
                            }
                            return {
                                ...subTab,
                                order: subTab.order > tabOrder ? subTab.order - 1 : subTab.order,
                                open_order: subTab.open_order > tabOpenOrder ? subTab.open_order - 1 : subTab.open_order,
                            };
                        });
                    }
                    newTabs[parentTabIndex].subTabs.sort(sortByOrder);
                } else {
                    if (tab.id === activeTabRef.current?.id) {
                        const tabIndex = newTabs.findIndex((data) => data.id === tab.id);
                        if (newTabs.length > 1) {
                            const nextTab = newTabs[tabIndex + 1];
                            const newActiveTab = nextTab ?? newTabs[tabIndex - 1];
                            updateActiveTab(newActiveTab);
                            lastOpenedUrl.current[user.organization_id + '_' + activeTabRef.current.id] = newActiveTab;
                            history.replace(newActiveTab.url);
                        } else {
                            lastOpenedUrl.current[user.organization_id + '_' + activeTabRef.current.id] = null;
                            updateActiveTab({});
                            history.replace("/");
                        }
                    }
                    newTabs = newTabs.filter(t => t.id !== tab.id);
                }
                return newTabs;
            });
            if (isSubTab) {
                updateMaxWidth();
            }

            // if (tab.skipAPICall) return;
            if (!tab.tabId) {
                // alertService.error('Tab id not found');
                console.log('Tab id not found', tab);
                return;
            }
            // return;
            const url = new URL(API_BASE_URL + `/super_tab/close_tab/${tab.tabId}`);

            const response = await APIService.apiRequest(
                url.toString(),
                null,
                false,
                "PUT"
            )

            if (response.status === 1) {
                // console.log('response', response);
            } else {
                if (response instanceof DOMException && response.name === 'AbortError') return;
                alertService.error(response.msg);
            }
        }

        catch (error) {
            alertService.error(`Error: ${error.message}`);
            console.log("closeTab - error", error);
        }
    }, [API_BASE_URL, history, updateActiveSubTab, updateActiveTab, updateMaxWidth, updateTabs, user.organization_id]);

    const reorderTabs = useCallback(async ({ tab, order } = {}) => {
        try {
            // if (tab.skipAPICall) return;
            if (!tab.tabId) {
                // alertService.error('Tab id not found');
                console.log('Tab id not found', tab);
                return;
            }
            const url = new URL(API_BASE_URL + `/super_tab/reorder_tab`);
            const payload = {
                tab_id: tab.tabId.toString(),
                new_order: order,
            }

            const response = await APIService.apiRequest(
                url.toString(),
                payload,
                false,
                "PUT"
            )

            if (response.status === 1) {
                console.log('response', response);
            } else {
                if (response instanceof DOMException && response.name === 'AbortError') return;
                alertService.error(response.msg);
            }
        }

        catch (error) {
            alertService.error(`Error: ${error.message}`);
            console.log("closeTab - error", error);
        }
    }, [API_BASE_URL]);

    const fetchTabDetails = useCallback(async ({ abortController = new AbortController(), level, parentId }) => {
        try {
            const href = new URL(window.location.href);
            const orgId = href.searchParams.get('org_id');
            if (orgId && +orgId !== user.organization_id) return;

            const payload = { unique_identifier: getUniqueIdentifier(SITE_PREFIX) };
            if (level) payload.level = level;
            if (parentId) payload.parent_tab_id = parentId;

            const url = new URL(API_BASE_URL + `/super_tab/get_tab`);
            const response = await APIService.apiRequest(
                url.toString(),
                payload,
                false,
                "PUT",
                abortController
            )

            if (response.status === 1) {
                let pathName = window.location.pathname;
                let isHomeTab = pathName === '/app';
                const tabsOutput = response.output.tabs;

                let activeTab = null;
                let activeSubTab = null;
                let currentActiveTab = null;

                homeTabIdRef.current = tabsOutput.find(tab => tab.level === 0).id;

                if (isHomeTab && response.output.current_tab.level !== 0) {
                    const { tab_info, ...rest } = response.output.current_tab;
                    const flattenedObj = { ...rest, ...tab_info, tabId: rest.id, subTabs: [] };
                    activeTab = flattenedObj;
                    // lastOpenedUrl.current[activeTab.id] = activeTab.url;
                    pathName = activeTab?.url;
                    if (activeTab.level > 1) {
                        activeSubTab = activeTab;
                        // lastOpenedUrl.current[activeTab.id] = activeSubTab.url;
                    }
                    isHomeTab = false;
                    history.replace(pathName);
                }

                const isCurrentTabOpen = !isHomeTab && tabsOutput.find(tab => tab.tab_info.url?.split('?')?.[0] === pathName);

                if (!isCurrentTabOpen) {
                    const openedParentTab = SITE_PAGES.find(page => page.url === pathName);

                    if (openedParentTab) {
                        if (hasPrivilege(SITE_PREFIX, openedParentTab)) {
                            const updatedParentTab = await openTab({ tab: openedParentTab });
                            const flattenedParentObj = { ...updatedParentTab, ...updatedParentTab.tab_info, tabId: updatedParentTab.id };
                            activeTab = flattenedParentObj;
                            tabsOutput.push(updatedParentTab);
                        } else {
                            history.push('/app');
                        }
                    } else if (!isHomeTab) {
                        const parentTab = findBestMatchFromPages([homeTab, ...SITE_PAGES], pathName);
                        if (parentTab) {
                            let parentTabFromData = tabsOutput.find(tab => tab.tab_info.id === parentTab.id);
                            if (!parentTabFromData) {
                                parentTabFromData = await openTab({ tab: parentTab });
                                tabsOutput.push(parentTabFromData);
                            }
                            const flattenedParentObj = parentTabFromData ? { ...parentTabFromData, ...parentTabFromData.tab_info, tabId: parentTabFromData.id } : null;
                            activeTab = flattenedParentObj ?? parentTab;
                            activeSubTab = { ...parentTab, id: `sub_${parentTab.id}`, url: pathName, name: 'Loading...', title: '' };
                            if (activeSubTabRef.current?.tabId) activeSubTab.tabId = activeSubTabRef.current.tabId;
                        }
                        // alertService.warning('Open sub tab');
                        // openTab({ tab: openedPage });
                    }
                } else {
                    const { tab_info, ...rest } = isCurrentTabOpen;
                    const flattenedObj = { ...rest, ...tab_info, tabId: rest.id };
                    const isSubTab = isCurrentTabOpen.level !== 1;
                    if (isSubTab) {
                        const parent = tabsOutput.find(tab => tab.id === flattenedObj.parent_tab_id);
                        const { tab_info: parentTabInfo, ...parentRest } = parent;
                        const flattenedParentObj = { ...parentRest, ...parentTabInfo, tabId: parentRest.id };
                        activeTab = flattenedParentObj;
                        activeSubTab = flattenedObj;
                    } else {
                        activeTab = flattenedObj;
                    }
                    currentActiveTab = { tab: flattenedObj, isSubTab, parentId: flattenedObj.parent_tab_id };
                }

                const tabsMap = {};
                const restTabs = [];

                tabsOutput.sort((a, b) => a.level - b.level).forEach(obj => {
                    const { tab_info, ...rest } = obj;
                    const flattenedObj = { ...rest, ...tab_info, tabId: rest.id, subTabs: [] };

                    tabsMap[flattenedObj.tabId] = flattenedObj;

                    if (flattenedObj.level !== 0) {
                        if (flattenedObj.parent_tab_id in tabsMap) {
                            tabsMap[flattenedObj.parent_tab_id].subTabs.push(flattenedObj);
                        } else {
                            tabsMap[flattenedObj.parent_tab_id] = { subTabs: [flattenedObj] };
                        }
                    }
                });

                // Collect top-level tabs that have children
                for (const id in tabsMap) {
                    if (tabsMap[id].level === 0 && tabsMap[id].subTabs.length > 0) {
                        tabsMap[id].subTabs = tabsMap[id]?.subTabs?.map(tab => {
                            if (tab?.subTabs?.length > 0) {
                                let level2Tabs = tab.subTabs.filter(subTab => subTab.level === 2);
                                // level2Tabs.sort((a, b) => a.order - b.order); // Sort by the existing order
                                tab.subTabs = level2Tabs.map((subTab, index) => {
                                    subTab.open_order = index + 1;
                                    return subTab;
                                });
                            }
                            return tab;
                        });

                        sortSubTabs(tabsMap[id]);
                        restTabs.push(...tabsMap[id].subTabs);
                    }
                }

                updateTabs(restTabs);
                // if (!activeTab) {
                //     if (SITE_PAGES.find(page => page.url === formattedPathname)) { // add hasPrivilege
                //         console.log('activeTab if', activeTab, formattedPathname);
                //         // check privilege and open tab if found and make sure to replace the tab with latest info
                //         activeTab = SITE_PAGES.find(page => page.url === formattedPathname);
                //     }
                // }

                if (activeTab) {
                    setActiveTab(activeTab);
                    activeTabRef.current = activeTab;
                    lastOpenedUrl.current[user.organization_id + '_' + activeTab.id] = activeTab;
                }
                if (activeSubTab) {
                    setActiveSubTab(activeSubTab);
                    activeSubTabRef.current = activeSubTab;
                    lastOpenedUrl.current[user.organization_id + '_' + activeTab.id] = activeSubTab;
                }
                if (currentActiveTab && currentActiveTab.tab.url !== response.output.current_tab.tab_info.url) {
                    updateCurrentTab(currentActiveTab);
                }
                setIsTabDataLoaded(true);
            } else {
                if (response?.msg === 'No tabs') {
                    await openTab({ tab: homeTab, isHomeTab: true });
                    setIsTabDataLoaded(true);
                    return;
                }
                if (response instanceof DOMException && response.name === 'AbortError') return;
                alertService.error(response.msg);
            }

        } catch (error) {
            alertService.error(`Error: ${error.message}`);
            console.log("fetchTabDetails - error", error);
        }
    }, [API_BASE_URL, SITE_PAGES, SITE_PREFIX, history, openTab, updateCurrentTab, updateTabs, user.organization_id]);

    useEffect(() => {
        const abortController = new AbortController();
        if (user.organization_id) {
            fetchTabDetails({ controller: abortController });
        }

        return () => abortController.abort();
    }, [fetchTabDetails, user.organization_id])

    useEffect(() => {
        return () => {
            homeTabIdRef.current = null;
            setTabs([]);
            tabsRef.current = [];
        }
    }, [user.organization_id])

    useEffect(() => {
        return () => {
            clearTimeout(expandedTabIDTimeoutRef.current);
            clearTimeout(closingUnexpandedSubTabTimeoutRef.current);
            clearTimeout(openingUnexpandedSubTabTimeoutRef.current);
        }
    }, [])

    return (
        <TabContext.Provider
            value={{
                activeTab,
                activeTabRef,
                activeSubTab,
                activeSubTabRef,
                closingUnexpandedSubTab,
                setClosingUnexpandedSubTab,
                closingUnexpandedSubTabTimeoutRef,
                openingUnexpandedSubTab,
                setOpeningUnexpandedSubTab,
                openingUnexpandedSubTabTimeoutRef,
                isTabDataLoaded,
                lastOpenedUrl,
                SITE_PAGES,
                SITE_PREFIX,
                tabs,
                user,
                superTabsWrapperElemRef,
                maxWidth,
                subTabChange,
                expandedTabID,
                updateExpandedID,
                subTabWrapperWidth,
                setSubTabWrapperWidth,
                expandedTabIDRef,
                updateMaxWidth,
                setSubTabChange,
                updateSubTabChange,
                resetMaxWidth,
                updateCurrentTab,
                closeTab,
                reorderTabs,
                openTab,
                openSubTab,
                modifyTab,
                openSuperTabOnRowClick,
                updateActiveTab,
                updateActiveSubTab,
                updateTabProperty,
                updateTabs,
            }}
        >
            {children}
        </TabContext.Provider>
    );
}

export function useTabContext() {
    return useContext(TabContext);
}