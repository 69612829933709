import './styles.scss';

const Accordion = ({ children, count, data, disabled, id, isExpanded, isFilterApplied, label, onChange, onExpand, type = 'checkbox' }) => {  
  const handleExpand = () => {
    if (disabled) return;
    onExpand();
  }
  const checkedOptions = data?.filter(option => option.checked);
  return (
    <div className="accordion-container">
      <div className={`accordion-item ${isExpanded ? 'expanded' : ''}`}>
        <div className="accordion-label" onClick={handleExpand}>
          {label}
          {isFilterApplied && !isExpanded && <span className='notification-dot'></span>}
          {checkedOptions?.length > 0 && <span className='select-count'>{count ?? checkedOptions.length}</span>}
        </div>
        <div className="accordion-content">
          <div className='accordion-content-inner'>
            {children ? children : data.map((option, index) => {
              return (
                <div className={type} key={index}>
                  <input
                    type={type}
                    id={option.id}
                    checked={option.checked}
                    onChange={(e) => onChange({ isChecked: e.target.checked, key: id, option, type })}
                    disabled={option.disabled}
                  />
                  <label htmlFor={option.id}>{option.name}</label>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion