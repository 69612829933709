import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { alertService } from 'services/alertService';
import default_image from 'images/icon-thumb-profile.svg';
import SpeedSelect from 'react-speedselect';
import './styles.scss';
import DatePicker from 'components/ReactCalendar/DatePicker';
import Autocomplete from 'views/ProfileManager/Autocomplete';
import Modal from "../../Modal/index";
import APIService from "services/apiService";
import { API_BASE_URL, SITE_PREFIX } from '../../../../components/Constants';
import { addHttpsToURLString, formatURL, isString, isURL } from 'utils/Common';
import SkillSelect from '../../SkillSelect/SkillSelect';
import useUserData from 'hooks/useUserData';
import { useTabContext } from 'components/SuperTabs/TabContext';
import { useParams } from 'react-router-dom';
import { useProfileContext } from 'views/ProfileManager/ProfileContext';
import { LINKEDIN_PROFILE_REGEX } from 'views/Forms/constants';

const phoneNoRegex = /^\d{10}$/;
const emailRegex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;

export default function CreateProfile(props) {
    const { user: currentUser, isHR, isManager } = useUserData()
    const { selectedContactTypes, updateSelectedContactType } = useProfileContext();
    const history = useHistory();
    const { setProfile, editModeData } = props
    const { id } = useParams();
    const isEditMode = editModeData.includes(id);

    const tab_id = id;
    const selectedContactType = selectedContactTypes[tab_id] || "phone";

    // Variables
    var form = {
        resource: {
            img_url: "",
            first_name: "",
            last_name: "",
            middle_name: "",
            date_of_birth: "",
            gender: "",
            languages: "",
            about_resource: "",
            email: null,
            phone: null,
            secondary_emails: [],
            secondary_phones: [],
            family: [],
        },
        skill_id: [],
        work_experience: [],
        education: [],
        address: [],
        selectedContactType: "phone",
    }

    const { closeTab, modifyTab, activeSubTab, activeSubTabRef } = useTabContext();

    //DOM for error
    var inputErrorDom = {};
    inputErrorDom["firstName"] = document.getElementById("first_name");

    var imageAPIError = false;
    const [showAutocompleteLanguage, setShowAutocompleteLanguage] = useState(false);
    const [formsubmit, setFormSubmit] = useState(form);
    const [editForm, setEditForm] = useState({});
    const [profile_id, setProfileId] = useState(null);
    const [showImageModel, setShowImageModel] = useState(false);
    const [error_contact_phone, setContactPhone] = useState({ index: "", error: "" });
    const [error_contact_email, setContactEmail] = useState({ index: "", error: "" });
    const [error_social, setContactSocial] = useState({});
    // const [skills, setSkills] = useState(props.skills);
    const [gender] = useState(['Male', 'Female', 'Transgender']);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [details, setDetails] = useState({
        phone: [""],
        email: [""],
        company: [],
        social: [""],
        member: [],
        education: [],
        address: [{
            street_address: "",
            state: "",
            country_id: 1,
            city: "",
            zipcode: 0,
            is_permanent: 0,
            is_present: 0
        }],
    });

    const [previousSkills, setPreviousSkills] = useState([])

    // Function for compressing image

    const getExifOrientation = (file, callback) => {
        if (file.slice) {
            file = file.slice(0, 131072);
        } else if (file.webkitSlice) {
            file = file.webkitSlice(0, 131072);
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            var view = new DataView(e.target.result);
            if (view.getUint16(0, false) !== 0xFFD8) {
                callback(-2);
                return;
            }
            var length = view.byteLength, offset = 2;
            while (offset < length) {
                var marker = view.getUint16(offset, false);
                offset += 2;
                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        callback(-1);
                        return;
                    }
                    var little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    var tags = view.getUint16(offset, little);
                    offset += 2;
                    for (var i = 0; i < tags; i++)
                        if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                            callback(view.getUint16(offset + (i * 12) + 8, little));
                            return;
                        }
                }
                else if ((marker & 0xFF00) !== 0xFF00) break;
                else offset += view.getUint16(offset, false);
            }
            callback(-1);
        };
        reader.readAsArrayBuffer(file);
    }

    const imgToCanvasWithOrientation = async (img, rawWidth, rawHeight, orientation) => {
        var canvas = document.createElement('canvas');
        if (orientation > 4) {
            canvas.width = rawHeight;
            canvas.height = rawWidth;
        } else {
            canvas.width = rawWidth;
            canvas.height = rawHeight;
        }

        if (orientation > 1) {
            console.log("EXIF orientation = " + orientation + ", rotating picture");
        }

        var ctx = canvas.getContext('2d');
        switch (orientation) {
            case 2: ctx.transform(-1, 0, 0, 1, rawWidth, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, rawHeight); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, rawHeight, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, rawHeight, rawWidth); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, rawWidth); break;
            default: break;
        }
        ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
        return canvas;
    }

    const reduceFileSize = async (file, acceptFileSize, maxWidth, maxHeight, quality, callback) => {
        if (file.size < acceptFileSize) {
            callback(file);
            return;
        }
        var img = new Image();
        img.onload = function () {
            getExifOrientation(file, async (orientation) => {
                var w = img.width, h = img.height;
                var scale = (orientation > 4 ?
                    Math.min(maxHeight / w, maxWidth / h, 1) :
                    Math.min(maxWidth / w, maxHeight / h, 1));
                h = Math.round(h * scale);
                w = Math.round(w * scale);

                var canvas = await imgToCanvasWithOrientation(img, w, h, orientation);
                canvas.toBlob(function (blob) {
                    console.log("Resized image to " + w + "x" + h + ", " + (blob.size >> 10) + "kB");
                    callback(blob);
                }, 'image/jpeg', quality);
            });
        };
        img.src = URL.createObjectURL(file);
    }

    const [socialChangesDone, setSocialChangesDone] = useState(false);
    const [social, setSocial] = useState(() => {
        let obj = {
            linkedin: '',
            git: '',
            portfolio: ''
        };

        const profile = props.setProfile?.[0]?.[SITE_PREFIX + tab_id];
        if (profile) {
            const social = profile?.resource?.social;
            const linkedin = profile?.resource?.linkedin ?? "";
            if (typeof social === 'object') {
                obj.linkedin = linkedin;
                obj.git = social?.git ?? "";
                obj.portfolio = social?.portfolio ?? "";
            } else if (Array.isArray(social)) {
                obj.linkedin = social[0] ?? "";
            }
        }

        return obj;
    })

    const handleSocialChange = (key, value) => {
        setSocialChangesDone(true);
        setSocial({ ...social, [key]: value });

        if (key === "linkedin") {
            setContactSocial({
                ...error_social,
                linkedin: !LINKEDIN_PROFILE_REGEX.test(value.trim())
                    ? "Please enter a valid LinkedIn profile URL"
                    : "",
            });
        }

        if (key === "git") {
            setContactSocial({
                ...error_social,
                git: !isURL(value.trim()) ? "URL is invalid" : "",
            });
        }

        if (key === "profile") {
            setContactSocial({
                ...error_social,
                profile: !isURL(value.trim()) ? "URL is invalid" : "",
            });
        }
    };


    // Handle the changes in form input
    const handleOnChange = async (e, type, index = 0, position = 0) => {
        // These variables are used to avoid delay in storing state.
        let selectedSkillObject = [...selectedSkills];
        let currentLanguages = [...selectedLanguages];
        let currentMode = isEditMode;
        let form_data = props.setProfile[0][SITE_PREFIX + tab_id];

        if (type === "gender") {
            formsubmit["resource"][type] = e.toLowerCase();
            if (currentMode) {
                if (editForm.resource) {
                    editForm.resource[type] = e.toLowerCase();
                } else {
                    editForm["resource"] = {
                        [type]: e.toLowerCase()
                    }
                }

                setEditForm({ ...editForm });
            }
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "languages") {
            if (currentMode) {
                let string = ""
                e.forEach((value, index) => {
                    if (index !== e.length - 1) {
                        string = string + value.name + ", ";
                    } else {
                        string = string + value.name
                    }

                })
                if (editForm.resource) {
                    editForm.resource[type] = string;
                } else {
                    editForm["resource"] = {
                        [type]: string
                    }
                }
                setEditForm({ ...editForm });
            }
            setSelectedLanguages([...e]);
            currentLanguages = [...e];
            formsubmit["resource"][type] = e.reduce((acc, val, index) => {
                if (index !== e.length - 1) {
                    return acc + val.name + ", ";
                } else {
                    return acc + val.name
                }
            }, "");
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "email") {
            if (e.target.value.match(emailRegex)) {
                let object = {
                    index: index,
                    error: ""
                }
                setContactEmail({ ...object });
                props.errorEmail[1]({ ...props.errorEmail[0], [tab_id]: object })
                details.email[index] = e.target.value;
                setDetails({ ...details, email: details.email })
                formsubmit["resource"]["email"] = details.email[0];
                if (currentMode) {
                    if (editForm.resource) {
                        editForm.resource["email"] = details.email[0];
                        editForm.resource["secondary_emails"] = details.email;
                    } else {
                        editForm["resource"] = {
                            "email": details.email[0],
                            "secondary_emails": details.email
                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["resource"]["secondary_emails"] = details.email;
                setFormSubmit({ ...formsubmit });
            }
            else {
                let object = {
                    index: index,
                    error: "Must be an email"
                }
                details.email[index] = e.target.value;
                setDetails({ ...details, email: details.email })
                props.errorEmail[1]({ ...props.errorEmail[0], [tab_id]: object })
                setContactEmail({ ...object });
            }
        }
        else if (type === "skills") {
            let sk_id = [];
            selectedSkillObject = [];
            if (!isEditMode) {
                if (e.length > 0) {
                    e.forEach(element => {
                        sk_id.push({
                            skill_id: element.id,
                            skill_name: element.name,
                            is_deleted: 0
                        });
                        selectedSkillObject.push(element);
                    });
                    setSelectedSkills([...selectedSkillObject]);

                    formsubmit["skill_id"] = sk_id;
                    setFormSubmit({ ...formsubmit });
                } else {
                    setSelectedSkills([]);
                    formsubmit["skill_id"] = sk_id;
                    setFormSubmit({ ...formsubmit });
                }
            } else {
                if (e.length > 0) {
                    e.forEach(element => {
                        let object = {
                            is_deleted: 0,
                            skill_id: element.id,
                            skill_name: element.name,
                        }
                        sk_id.push(object);
                        selectedSkillObject.push(element);
                    });
                    setSelectedSkills([...selectedSkillObject]);
                    if (currentMode) {
                        editForm.skill_id = sk_id;
                        setEditForm({ ...editForm });
                    }
                    formsubmit["skill_id"] = sk_id;
                    setFormSubmit({ ...formsubmit });
                } else {
                    setSelectedSkills([]);
                    if (currentMode) {
                        editForm.skill_id = sk_id;
                        setEditForm({ ...editForm });
                    }
                    formsubmit["skill_id"] = sk_id;
                    setFormSubmit({ ...formsubmit });
                }
            }
        }
        else if (type === "image") {
            if (e.target.files[0]) {
                await reduceFileSize(e.target.files[0], 200, 200, 200, 0.5, async blob => {
                    if (isEditMode) {
                        saveUserProfileImage({ blob, profileId: profile_id })
                    } else {
                        try {
                            let imageUrl = ""
                            var reader = new FileReader();
                            reader.onloadend = async function () {
                                imageUrl = reader.result;
                                if ((new TextEncoder().encode(imageUrl)).length <= 65000) {
                                    props.profileImage[1](imageUrl);
                                    props.setFile[1]({ ...props.setFile[0], [SITE_PREFIX + tab_id]: blob });
                                    if (currentMode) {
                                        if (editForm.resource) {
                                            editForm.resource["img_url"] = imageUrl;
                                        } else {
                                            editForm["resource"] = {
                                                "img_url": imageUrl
                                            }
                                        }
                                        setEditForm({ ...editForm });
                                    }
                                    formsubmit["resource"]["img_url"] = imageUrl;
                                    let storeData = {
                                        formsubmit: formsubmit,
                                        member: details.member,
                                        education: details.education,
                                        company: details.company,
                                        phone: details.phone,
                                        email: details.email,
                                        social: details.social,
                                        address: details.address,
                                        country: selectedCountry,
                                        skillsObject: selectedSkillObject,
                                        selectedLanguages: currentLanguages
                                    }
                                    if (currentMode) {
                                        storeData.editForm = { ...editForm };
                                        storeData.profileId = profile_id
                                    }
                                    props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: storeData });
                                    setFormSubmit({ ...formsubmit });
                                } else {
                                    alertService.error("Please reduce the size of image");
                                }
                            }
                            reader.readAsDataURL(blob);

                        } catch (error) {
                            console.log('Could not upload image  ', error);
                            alertService.error('Could not upload image - ' + error)
                        }
                    }
                })
            }
        }
        else if (type === "alternate_phone") {
            if (e.target.value.match(phoneNoRegex)) {
                let object = {
                    index: index,
                    error: ""
                }
                setContactPhone({ ...object });
                props.errorPhone[1]({ ...props.errorPhone[0], [tab_id]: object })
                details.phone[index] = e.target.value;
                setDetails({ ...details, phone: details.phone });
                if (currentMode) {
                    if (editForm.resource) {
                        editForm.resource["phone"] = details.phone[0];
                        editForm.resource["secondary_phones"] = [...details.phone];
                    } else {
                        editForm["resource"] = {
                            "phone": details.phone[0],
                            "secondary_phones": [...details.phone]
                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["resource"]["phone"] = details.phone[0];
                formsubmit["resource"]["secondary_phones"] = [...details.phone];
                setFormSubmit({ ...formsubmit });
            }
            else {
                let object = {
                    index: index,
                    error: "Must be a Number and of 10 digit"
                }
                details.phone[index] = e.target.value;
                setDetails({ ...details, phone: details.phone });
                setContactPhone({ ...object });
                props.errorPhone[1]({ ...props.errorPhone[0], [tab_id]: object })
            }

        }
        else if (type === "social") {
            let social = /[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi;
            var regex = new RegExp(social);
            if (e.target.value.match(regex)) {
                let object = {
                    index: index,
                    error: ""
                }
                setContactSocial({ ...object });
                props.errorSocial[1]({ ...props.errorSocial[0], [tab_id]: object })
                details.social[index] = e.target.value.toLowerCase();
                setDetails({ ...details, social: [...details.social] })
                if (currentMode) {
                    if (editForm.resource) {
                        editForm.resource["social"] = [...details.social]
                    } else {
                        editForm["resource"] = {
                            "social": [...details.social]
                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["resource"]["social"] = [...details.social]
                setFormSubmit({ ...formsubmit });
            } else {
                details.social[index] = e.target.value;
                let object = {
                    index: index,
                    error: "Must be a valid social link"
                }
                props.errorSocial[1]({ ...props.errorSocial[0], [tab_id]: object })
                setContactSocial({ ...object });
                setDetails({ ...details, social: [...details.social] })
            }

        }
        else if (
            type === "state" ||
            type === "country" ||
            type === "zipcode" ||
            type === "city" ||
            type === "street_address") {
            if (editForm && !editForm.address) {
                editForm.address = [{}]
            }
            if (editForm && editForm.address && editForm.address.length - 1 < index) {
                let len = editForm.address.length;
                for (let i = 0; i < (index - (len === 0 ? -1 : len - 1)); i++) {
                    editForm.address.push({});
                }
            }
            let zip_no = /^\d{6}$/;
            if (type === "zipcode") {
                if (e.target.value.match(zip_no)) {
                    details.address[index][type] = e.target.value;
                    delete details.address[index].error;
                    setDetails({ ...details, address: [...details.address] })
                    if (currentMode) {
                        if (form_data.editForm && form_data.editForm.address) {
                            if (details.address[index].id) {
                                editForm.address[index][type] = e.target.value;
                                editForm.address[index].id = details.address[index].id;

                            } else {
                                editForm.address[index][type] = e.target.value;
                            }
                        } else {
                            if (details.address[index].id) {
                                editForm.address[index].id = details.address[index].id;
                                editForm.address[index][type] = e.target.value;
                            } else {
                                editForm.address[index][type] = e.target.value;

                            }
                        }
                        setEditForm({ ...editForm });
                    }
                    formsubmit["address"] = [...details.address];
                    setFormSubmit({ ...formsubmit });
                } else {
                    details.address[index][type] = e.target.value;
                    details.address[index]["error"] = "Zip code should be a number and of 6 digit";
                    setDetails({ ...details, address: [...details.address] })
                }
            } else if (type === "country") {
                let country_index = props.country.indexOf(e);
                details.address[index]["country_id"] = country_index + 1;
                selectedCountry[index] = e;
                setSelectedCountry([...selectedCountry])
                setDetails({ ...details, address: [...details.address] })
                if (currentMode) {
                    if (form_data.editForm && form_data.editForm.address) {
                        if (details.address[index].id) {
                            editForm.address[index]["country_id"] = country_index + 1;
                            editForm.address[index].id = details.address[index].id;

                        } else {
                            editForm.address[index]["country_id"] = country_index + 1;
                        }
                    } else {
                        if (details.address[index].id) {
                            editForm.address[index].id = details.address[index].id;
                            editForm.address[index]["country_id"] = country_index + 1;
                        } else {
                            editForm.address[index]["country_id"] = country_index + 1;

                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["address"] = [...details.address];
                setFormSubmit({ ...formsubmit });
            }
            else {
                details.address[index][type] = e.target.value;
                setDetails({ ...details, address: [...details.address] })
                if (currentMode) {
                    if (form_data.editForm && form_data.editForm.address) {
                        if (details.address[index].id) {
                            editForm.address[index][type] = e.target.value;
                            editForm.address[index].id = details.address[index].id;

                        } else {
                            editForm.address[index][type] = e.target.value;
                        }
                    } else {
                        if (details.address[index].id) {
                            editForm.address[index].id = details.address[index].id;
                            editForm.address[index][type] = e.target.value;
                        } else {
                            editForm.address[index][type] = e.target.value;

                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["address"] = [...details.address];
                setFormSubmit({ ...formsubmit });
            }
        }
        else if (type === "address_type") {
            if (editForm && !editForm.address) {
                editForm.address = [{}]
            }
            if (editForm && editForm.address && editForm.address.length - 1 < index) {
                let len = editForm.address.length;
                for (let i = 0; i < (index - (len === 0 ? -1 : len - 1)); i++) {
                    editForm.address.push({});
                }
            }
            if (e.target.value === "current_address") {
                details.address[index]["is_present"] = 1;
                details.address[index]["is_permanent"] = 0;
                setDetails({ ...details, address: [...details.address] })
                if (currentMode) {
                    if (form_data.editForm && form_data.editForm.address) {
                        if (details.address[index].id) {
                            editForm.address[index]["is_present"] = 1;
                            editForm.address[index]["is_permanent"] = 0;
                            editForm.address[index].id = details.address[index].id;

                        } else {
                            editForm.address[index]["is_present"] = 1;
                            editForm.address[index]["is_permanent"] = 0;
                        }
                    } else {
                        if (details.address[index].id) {
                            editForm.address[index].id = details.address[index].id;
                            editForm.address[index]["is_present"] = 1;
                            editForm.address[index]["is_permanent"] = 0;
                        } else {
                            editForm.address[index]["is_present"] = 1;
                            editForm.address[index]["is_permanent"] = 0;
                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["address"] = [...details.address];
                setFormSubmit({ ...formsubmit });

            } else {
                details.address[index]["is_permanent"] = 1;
                details.address[index]["is_present"] = 0;
                setDetails({ ...details, address: [...details.address] })
                if (currentMode) {
                    if (form_data.editForm && form_data.editForm.address) {
                        if (details.address[index].id) {
                            editForm.address[index]["is_present"] = 0;
                            editForm.address[index]["is_permanent"] = 1;
                            editForm.address[index].id = details.address[index].id;

                        } else {
                            editForm.address[index]["is_present"] = 0;
                            editForm.address[index]["is_permanent"] = 1;
                        }
                    } else {
                        if (details.address[index].id) {
                            editForm.address[index].id = details.address[index].id;
                            editForm.address[index]["is_present"] = 0;
                            editForm.address[index]["is_permanent"] = 1;
                        } else {
                            editForm.address[index]["is_present"] = 0;
                            editForm.address[index]["is_permanent"] = 1;

                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["address"] = [...details.address];
                setFormSubmit({ ...formsubmit });
            }

        }
        else if (type === "company_name") {
            details.company[index].company_name = e.target.value;
            setDetails({ ...details, company: [...details.company] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.work_experience) {
                    form_data.editForm.work_experience.forEach((value, i) => {
                        if (value.action) {
                            details.company.push(value);
                        }
                    })
                    editForm.work_experience = [...details.company];
                } else {
                    editForm.work_experience = [...details.company];
                }
                setEditForm({ ...editForm });
            }
            formsubmit["work_experience"] = [...details.company];
            setFormSubmit({ ...formsubmit });
        }
        else if (
            type === "designation" ||
            type === "employment_type" ||
            type === "location" ||
            type === "work_description") {
            details.company[index].tenure[position][type] = e.target.value;
            setDetails({ ...details, company: [...details.company] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.work_experience) {
                    form_data.editForm.work_experience.forEach((value, i) => {
                        if (value.tenure[0].is_deleted === 1 || value.action) {
                            details.company.push(value);
                        }
                    })
                    editForm.work_experience = [...details.company];
                } else {
                    editForm.work_experience = [...details.company];
                }
                setEditForm({ ...editForm });
            }
            formsubmit["work_experience"] = [...details.company];
            setFormSubmit({ ...formsubmit });
        }
        else if (
            type === "degree" ||
            type === "field" ||
            type === "score" ||
            type === "highlights") {
            if (editForm && !editForm.education) {
                editForm.education = [{}]
            }
            if (editForm && editForm.education && editForm.education.length - 1 < index) {
                let len = editForm.education.length;
                for (let i = 0; i < (index - (len === 0 ? -1 : len - 1)); i++) {
                    editForm.education.push({});
                }
            }
            details.education[index][type] = e.target.value;
            setDetails({ ...details, education: [...details.education] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.education) {
                    if (details.education[index].id) {
                        editForm.education[index][type] = e.target.value;
                        editForm.education[index].id = details.education[index].id;

                    } else {
                        editForm.education[index][type] = e.target.value;
                    }
                } else {
                    if (details.education[index].id) {
                        editForm.education[index].id = details.education[index].id;
                        editForm.education[index][type] = e.target.value;
                    } else {
                        editForm.education[index][type] = e.target.value;

                    }
                }
                setEditForm({ ...editForm });
            }
            formsubmit["education"] = [...details.education];
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "school") {
            if (editForm && !editForm.education) {
                editForm.education = [{}]
            }
            if (editForm && editForm.education && editForm.education.length - 1 < index) {
                let len = editForm.education.length;
                for (let i = 0; i < (index - (len === 0 ? -1 : len - 1)); i++) {
                    editForm.education.push({});
                }
            }
            details.education[index]["name"] = e.target.value;
            setDetails({ ...details, education: [...details.education] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.education) {
                    if (details.education[index].id) {
                        editForm.education[index]["name"] = e.target.value;
                        editForm.education[index].id = details.education[index].id;

                    } else {
                        editForm.education[index]["name"] = e.target.value;
                    }
                } else {
                    if (details.education[index].id) {
                        editForm.education[index].id = details.education[index].id;
                        editForm.education[index]["name"] = e.target.value;
                    } else {
                        editForm.education[index]["name"] = e.target.value;

                    }
                }
                setEditForm({ ...editForm });
            }
            formsubmit["education"] = [...details.education];
            setFormSubmit({ ...formsubmit });
        }
        else if (
            type === "member" ||
            type === "occupation" ||
            type === "phone" ||
            type === "relation") {
            if (type === "phone") {
                if (e.target.value.match(phoneNoRegex)) {
                    details.member[index][type] = e.target.value;
                    delete details.member[index].error;
                    setDetails({ ...details, member: [...details.member] })
                    if (currentMode) {
                        if (editForm.resource) {
                            editForm.resource["family"] = [...details.member];
                        } else {
                            editForm["resource"] = {
                                "family": [...details.member]
                            }
                        }
                        setEditForm({ ...editForm });
                    }
                    formsubmit["resource"]["family"] = [...details.member];
                    setFormSubmit({ ...formsubmit });
                } else {
                    details.member[index][type] = e.target.value;
                    details.member[index]["error"] = "Must be a Number and of 10 digit";
                    setDetails({ ...details, member: [...details.member] })

                }
            } else {
                details.member[index][type] = e.target.value;
                setDetails({ ...details, member: [...details.member] })
                if (currentMode) {
                    if (editForm.resource) {
                        editForm.resource["family"] = [...details.member];
                    } else {
                        editForm["resource"] = {
                            "family": [...details.member]
                        }
                    }
                    setEditForm({ ...editForm });
                }
                formsubmit["resource"]["family"] = [...details.member];
                setFormSubmit({ ...formsubmit });
            }
        }
        else if (type === "education_date_start") {
            if (editForm && !editForm.education) {
                editForm.education = [{}]
            }
            if (editForm && editForm.education && editForm.education.length - 1 < index) {
                let len = editForm.education.length;
                for (let i = 0; i < (index - (len === 0 ? -1 : len - 1)); i++) {
                    editForm.education.push({});
                }
            }
            details.education[index]["start_date"] = e;
            setDetails({ ...details, education: [...details.education] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.education) {
                    if (details.education[index].id) {
                        editForm.education[index]["start_date"] = e;
                        editForm.education[index].id = details.education[index].id;

                    } else {
                        editForm.education[index]["start_date"] = e;
                    }
                } else {
                    if (details.education[index].id) {
                        editForm.education[index].id = details.education[index].id;
                        editForm.education[index]["start_date"] = e;
                    } else {
                        editForm.education[index]["start_date"] = e;

                    }
                }
                setEditForm({ ...editForm });
            }
            formsubmit["education"] = [...details.education];
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "education_date_end") {
            if (editForm && !editForm.education) {
                editForm.education = [{}]
            }
            if (editForm && editForm.education && editForm.education.length - 1 < index) {
                let len = editForm.education.length;
                for (let i = 0; i < (index - (len === 0 ? -1 : len - 1)); i++) {
                    editForm.education.push({});
                }
            }
            details.education[index]["end_date"] = e;
            setDetails({ ...details, education: [...details.education] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.education) {
                    if (details.education[index].id) {
                        editForm.education[index]["end_date"] = e;
                        editForm.education[index].id = details.education[index].id;

                    } else {
                        editForm.education[index]["end_date"] = e;
                    }
                } else {
                    if (details.education[index].id) {
                        editForm.education[index].id = details.education[index].id;
                        editForm.education[index]["end_date"] = e;
                    } else {
                        editForm.education[index]["end_date"] = e;

                    }
                }
                setEditForm({ ...editForm });
            }
            formsubmit["education"] = [...details.education];
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "position_date_start") {
            let date = e.split("-");
            details.company[index].tenure[position]["start_year"] = date[0];
            details.company[index].tenure[position]["start_month"] = date[1];
            setDetails({ ...details, company: [...details.company] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.work_experience) {
                    form_data.editForm.work_experience.forEach((value, i) => {
                        if (value.tenure[0].is_deleted === 1 || value.action) {
                            details.company.push(value);
                        }
                    })
                    editForm.work_experience = [...details.company];
                } else {
                    editForm.work_experience = [...details.company];
                }
                setEditForm({ ...editForm });
            }
            formsubmit["work_experience"] = [...details.company];
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "position_date_end") {
            let date = e.split("-");
            details.company[index].tenure[position]["end_year"] = date[0];
            details.company[index].tenure[position]["end_month"] = date[1];
            setDetails({ ...details, company: [...details.company] })
            if (currentMode) {
                if (form_data.editForm && form_data.editForm.work_experience) {
                    form_data.editForm.work_experience.forEach((value, i) => {
                        if (value.tenure[0].is_deleted === 1 || value.action) {
                            details.company.push(value);
                        }
                    })
                    editForm.work_experience = [...details.company];
                } else {
                    editForm.work_experience = [...details.company];
                }
                setEditForm({ ...editForm });
            }
            formsubmit["work_experience"] = [...details.company];
            setFormSubmit({ ...formsubmit });
        }
        else if (type === "date_of_birth") {
            if (currentMode) {
                if (editForm.resource) {
                    editForm.resource[type] = e;
                } else {
                    editForm["resource"] = {
                        [type]: e
                    }
                }
                setEditForm({ ...editForm });
            }
            formsubmit["resource"][type] = e;
            setFormSubmit({ ...formsubmit });

        }
        else if (
            type === "first_name" ||
            type === "last_name" ||
            type === "middle_name" ||
            type === "about_resource") {
            formsubmit["resource"][type] = e.target.value;
            if (type === "about_resource") {
                formsubmit["resource"][type].replace(/\n/g, '');
            }
            if (currentMode) {
                if (editForm.resource) {
                    editForm.resource[type] = formsubmit["resource"][type];
                } else {
                    editForm.resource = {
                        [type]: formsubmit["resource"][type]
                    }
                }
                setEditForm({ ...editForm });
            }
            setFormSubmit({ ...formsubmit });
        }

        if (type !== "image") {
            let storeData = {
                formsubmit: formsubmit,
                member: details.member,
                education: details.education,
                company: details.company,
                phone: details.phone,
                email: details.email,
                social: details.social,
                address: details.address,
                country: selectedCountry,
                skillsObject: selectedSkillObject,
                selectedLanguages: currentLanguages
            }
            if (currentMode) {
                storeData.editForm = { ...editForm };
                storeData.profileId = profile_id
            }
            let object = { ...props.setProfile[0] }
            object[SITE_PREFIX + tab_id] = storeData;
            props.setProfile[1]({ ...object });
        }

    }

    const [newImgUrl, setNewImgUrl] = useState('')

    async function saveUserProfileImage({ blob, profileId }) {
        try {
            const formData = new FormData();
            formData.append("resource_img", blob, "jpeg");
            const res = await APIService.apiRequest(
                API_BASE_URL + `/resource_img/${profileId}`,
                formData,
                false,
                "PUT"
            );

            if (res.status === 1) {
                const { img_url } = res.output;
                const profileRes = await APIService.apiRequest(
                    API_BASE_URL + `/resource/${profileId}`,
                    {
                        resource: {
                            img_url,
                        },
                    },
                    false,
                    "PUT"
                );

                if (profileRes.status === 1) {
                    // remove cached image
                    URL.revokeObjectURL(img_url);
                    let imgUrl = img_url + `?${new Date().getTime()}`;

                    setNewImgUrl(imgUrl);

                    props.profileImage[1](imgUrl);
                    props.tabsInfo[1]((prev) => {
                        return {
                            ...prev,
                            tabs: prev.tabs.map((tab) => {
                                if (tab.id === prev.activeTabId) {
                                    tab.img_url = imgUrl;
                                }

                                return tab;
                            }),
                        };
                    });

                    const profile = props.setProfile[0][SITE_PREFIX + tab_id];

                    if (profile) {
                        props.setProfile[1]({
                            ...props.setProfile[0],
                            [SITE_PREFIX + tab_id]: {
                                ...profile,
                                resource: {
                                    ...profile.resource,
                                    img_url: imgUrl,
                                }
                            },
                        });
                    }

                    const imgHeaders = new Headers();
                    imgHeaders.append("Content-Type", "image/jpeg");

                    const imgOptions = {
                        method: "GET",
                        cache: "reload",
                        headers: imgHeaders,
                    };

                    const imgRefreshRequest = new Request(img_url, imgOptions);

                    fetch(imgRefreshRequest).then((response) => {
                        console.log('response', response, img_url);
                    });
                }
            }
        } catch (error) {
            console.log("Could not upload image  ", error);
            alertService.error("Could not upload image - " + error);
        }
    }

    //Function for image model
    const handleImageModel = (e) => {
        e.stopPropagation();
        setShowImageModel(true);
    }

    const handleClose = () => {
        setShowImageModel(false);
        setShowAutocompleteLanguage(false);
    }

    useEffect(() => {

        //To show the tab edit button for my profile
        // if (props.currentTerminal === "employee") {
        props.showTabEdit[1](true);
        // }

        // Change the style of input on tab change
        if (
            inputErrorDom.firstName
        ) {
            inputErrorDom.firstName.style.border = "";
        }

        //Handle the crop component
        handleClose();


        // Fetch the data of phone and email error on tab change
        props.errorPhone[0][`${tab_id}`] ? setContactPhone({ ...props.errorPhone[0][`${tab_id}`] }) : setContactPhone({ index: "", error: "" });
        props.errorEmail[0][`${tab_id}`] ? setContactEmail({ ...props.errorEmail[0][`${tab_id}`] }) : setContactEmail({ index: "", error: "" });
        props.errorSocial[0][`${tab_id}`] ? setContactSocial({ ...props.errorSocial[0][`${tab_id}`] }) : setContactSocial({ index: "", error: "" });


        // Fetch the data of form on tab change
        let form_data = props.setProfile[0][SITE_PREFIX + tab_id];


        if (form_data && form_data.resource && form_data.resource.img_url) {
            props.profileImage[1](form_data.resource.img_url);
        } else {
            props.profileImage[1](null);
        }

        /*
            During the edit of company only that data should be send that has been edited , but here for 
            company and position the code is more complex and less efficient in terms of sending specific data
            so , we are refracting the data received from backend which is more efficient and easy to handle.
        */
        if (form_data && form_data.work_experience) {
            form_data.company = form_data.work_experience;
        }
        if (form_data && form_data.company && form_data.company.length > 0) {
            let company_array = [];
            for (let i = 0; i < form_data.company.length; i++) {
                let tenure = [];
                for (let j = 0; j < form_data.company[i].tenure.length; j++) {
                    let object = {
                        designation: form_data.company[i].tenure[j].designation,
                        employment_type: form_data.company[i].tenure[j].employment_type,
                        location: form_data.company[i].tenure[j].location,
                        work_description: form_data.company[i].tenure[j].work_description,
                        start_month: form_data.company[i].tenure[j].start_month,
                        start_year: form_data.company[i].tenure[j].start_year,
                        end_month: form_data.company[i].tenure[j].end_month,
                        end_year: form_data.company[i].tenure[j].end_year,
                        id: form_data.company[i].tenure[j].id
                    }
                    tenure.push(object);
                }
                company_array.push({
                    company_name: form_data.company[i].company_name,
                    location: form_data.company[i].location,
                    tenure: tenure
                })
            }
            form_data.company = company_array;
            form_data.work_experience = company_array;
        }

        //If form data is not there , reset the state
        if (!form_data) {
            setFormSubmit(form);
            setDetails({
                phone: [""],
                email: [""],
                company: [],
                social: [""],
                member: [],
                education: [],
                address: [{
                    street_address: "",
                    state: "",
                    country_id: 1,
                    city: "",
                    zipcode: 0,
                    is_permanent: 0,
                    is_present: 0
                }]
            });
            setSelectedCountry([]);
            setSelectedSkills([]);
            setSelectedLanguages([]);

        } else {
            if (!isEditMode) { // If it is in not edit mode ,means it is for new profile form ,to retain data on tab change
                setFormSubmit(form_data.formsubmit);
                setDetails({
                    phone: [...form_data.phone],
                    email: [...form_data.email],
                    company: [...form_data.company],
                    social: [...form_data.social],
                    member: [...form_data.member],
                    education: [...form_data.education],
                    address: [...form_data.address]
                });

                setSelectedCountry([...form_data.country]);
                setSelectedSkills([...form_data.skillsObject]);
                setSelectedLanguages([...form_data.selectedLanguages]);
            } else { // It is for edit mode.
                let data = form_data;

                if (data.formsubmit && data.formsubmit.resource && data.skillsObject) {
                    data["resource"] = data.formsubmit.resource;
                    data.skill_id = data.formsubmit.skill_id;
                }

                if (data.editForm) {
                    setEditForm({ ...data.editForm });
                }

                if (isString(data?.resource?.languages) && data.resource.languages.length > 0) {
                    let selectedLanguages = data.resource.languages
                        .split(",")
                        .map(language => ({ name: language.trim() }));

                    setSelectedLanguages(selectedLanguages);
                }

                if (data?.skill_id?.length > 0) {
                    let selectedSkills = data.skill_id.map((skill) => {
                        return {
                            name: skill.skill_name,
                            id: skill.skill_id,
                        };
                    });

                    setSelectedSkills(selectedSkills);
                    setPreviousSkills([...data.skill_id]);
                }

                let country = [];
                let form = {
                    resource: {
                        img_url: data?.resource?.img_url || "",
                        first_name: data?.resource?.first_name || "",
                        middle_name: data?.resource?.middle_name || "",
                        last_name: data?.resource?.last_name || "",
                        date_of_birth: data?.resource?.date_of_birth || "",
                        gender: data?.resource?.gender || "",
                        languages: data?.resource?.languages || "",
                        about_resource: data?.resource?.about_resource || "",
                        email: data?.resource?.email || null,
                        phone: data?.resource?.phone || null,
                        secondary_emails: data?.resource?.secondary_emails || [],
                        secondary_phones: data?.resource?.secondary_phones || [],
                        family: data?.resource?.family || [],
                        social: data?.resource?.social || []
                    },
                    skill_id: data?.skill_id || [],
                    work_experience: data?.work_experience || [],
                    education: data?.education || [],
                    address: data?.address || []

                }
                setDetails({
                    phone: data?.resource?.secondary_phones?.length > 0 ? data.resource.secondary_phones : [""],
                    email: data?.resource?.secondary_emails?.length > 0 ? data.resource.secondary_emails : [""],
                    company: data?.work_experience || [],
                    social: data?.resource?.social?.length > 0 ? data.resource.social : [""],
                    member: data?.resource?.family || [],
                    education: data?.education || [],
                    address: data?.address?.length > 0 ? data.address : [{
                        street_address: "",
                        state: "",
                        country_id: 1,
                        city: "",
                        zipcode: 0,
                        is_permanent: 0,
                        is_present: 0
                    }]
                });
                if (data && data.resource && data.resource.id) {
                    setProfileId(data.resource.id);
                } else if (data && data.profileId) {
                    setProfileId(data.profileId);
                }
                if (data.address) {
                    data.address.forEach((sub_address) => {
                        country.push(props.country[sub_address.country_id - 1]);
                    })
                    setSelectedCountry([...country]);
                }
                setFormSubmit({ ...form });
            }

        }
    }, [tab_id]) // eslint-disable-line react-hooks/exhaustive-deps


    // To add company in form
    const handleAddCompany = () => {
        let len = details.company.length;
        let object = {
            designation: "",
            employment_type: "",
            location: "",
            work_description: "",
            start_month: "",
            end_month: ""
        }
        if (len === 0) {
            setDetails({ ...details, company: [{ tenure: [object] }] })
        } else {
            setDetails({ ...details, company: [...details.company, { tenure: [object] }] })
        }
    };



    // To add education in form
    const handleAddEducation = () => {
        let len = details.education.length;
        if (len === 0) {
            setDetails({ ...details, education: [{}] })
        } else {
            setDetails({ ...details, education: [...details.education, {}] })
        }
    };


    // To add member in form
    const handleAddMember = () => {
        let len = details.member.length;
        if (len === 0) {
            setDetails({ ...details, member: [{}] })
        } else {
            setDetails({ ...details, member: [...details.member, {}] })
        }
    };



    // To add phone in form
    const Phone = () => {
        const handlePhoneAdd = () => {
            setDetails({ ...details, phone: [...details.phone, ""] });
        }
        const handleCross = (e, i) => {
            let phone_list = [...details.phone];
            phone_list.splice(i, 1);
            setDetails({ ...details, phone: [...phone_list] });
            if (phone_list.length > 0) {
                formsubmit["resource"]["phone"] = phone_list[0];
                formsubmit["resource"]["secondary_phones"] = [...phone_list];
                setFormSubmit({ ...formsubmit });
            } else {
                formsubmit["resource"]["phone"] = null;
                formsubmit["resource"]["secondary_phones"] = [];
                setFormSubmit({ ...formsubmit });
            }

            let form_data = props.setProfile[0][SITE_PREFIX + tab_id];

            if (isEditMode) {
                if (editForm.resource) {
                    editForm.resource["phone"] = phone_list[0];
                    editForm.resource["secondary_phones"] = [...phone_list];
                } else {
                    editForm["resource"] = {
                        "phone": phone_list[0],
                        "secondary_phones": [...phone_list]
                    }
                }
                setEditForm({ ...editForm });
                form_data.editForm = editForm;
            }

            form_data.resource = formsubmit.resource;
            form_data.formsubmit = formsubmit;
            form_data.phone = [...phone_list];
            form_data.profileId = profile_id;
            props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });

        }
        return (
            <div className='phone-wrapper'>
                <div className='phone-inner'>
                    <div className='add-btn' onClick={handlePhoneAdd}>
                        <span>Add Alternate Number</span>
                    </div>
                    {details.phone.map((phone, i) => {
                        return (
                            <div className="phone-field-wrapper" key={i}>
                                <div className='field' >
                                    <input value={phone ? phone : ""} key={`phone_${i}`} placeholder='Enter Phone' type="text" name="phone_input" onChange={(e) => handleOnChange(e, "alternate_phone", i)} />
                                    <label className='red-text'>{(i === error_contact_phone.index) ? error_contact_phone.error : ""}</label>
                                </div>
                                {details.phone.length > 1 ? <div className='close-btn' onClick={(e) => handleCross(e, i)}></div> : ""}
                            </div>
                        )
                    })}
                </div>

            </div>
        )

    }



    // To add email in form
    const Email = () => {
        const handleEmailAdd = () => {
            setDetails({ ...details, email: [...details.email, ""] })
        }
        const handleCross = (e, i) => {
            let email_list = details.email;
            email_list.splice(i, 1);
            setDetails({ ...details, email: [...email_list] })
            if (email_list.length > 0) {
                formsubmit["resource"]["email"] = email_list[0];
                formsubmit["resource"]["secondary_emails"] = email_list;
                setFormSubmit({ ...formsubmit });
            } else {
                formsubmit["resource"]["email"] = null;
                formsubmit["resource"]["secondary_emails"] = [];
                setFormSubmit({ ...formsubmit });
            }

            let form_data = props.setProfile[0][SITE_PREFIX + tab_id];

            if (isEditMode) {
                if (editForm.resource) {
                    editForm.resource["email"] = email_list[0];
                    editForm.resource["secondary_emails"] = email_list;
                } else {
                    editForm["resource"] = {
                        "email": email_list[0],
                        "secondary_emails": email_list
                    }
                }
                setEditForm({ ...editForm });
                form_data.editForm = editForm;
            }

            form_data.resource = formsubmit.resource;
            form_data.formsubmit = formsubmit;
            form_data.email = [...email_list];
            form_data.profileId = profile_id;
            props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });
        }
        return (
            <div className='phone-wrapper'>
                <div className='phone-inner'>
                    <div className='add-btn' onClick={handleEmailAdd}>
                        <span>Add Email</span>
                    </div>
                    {details.email.map((email, i) => {
                        return (
                            <div className="phone-field-wrapper" key={i}>
                                <div className='field'>
                                    <input value={email ? email : ""} placeholder='Enter Email' type="email" onChange={(e) => handleOnChange(e, "email", i)} />
                                    <label className='red-text'>{(i === error_contact_email.index) ? error_contact_email.error : ""}</label>
                                </div>
                                {details.email.length > 1 ? <div className='close-btn' onClick={(e) => handleCross(e, i)}></div> : ""}
                            </div>
                        )
                    }
                    )}
                </div>

            </div>
        )

    }


    // To add address in form
    const Address = () => {
        const handleAddressAdd = () => {
            selectedCountry.push("");
            setSelectedCountry([...selectedCountry]);
            setDetails({
                ...details, address: [...details.address, {
                    street_address: "",
                    state: "",
                    country_id: 1,
                    city: "",
                    zipcode: 0,
                    is_permanent: 0,
                    is_present: 0
                }]
            })
        }
        const handleCross = (i) => {
            let address_list = details.address;
            if (i > -1) {
                selectedCountry.splice(i, 1);
            }
            setSelectedCountry([...selectedCountry]);

            let form_data = props.setProfile[0][SITE_PREFIX + tab_id];
            let check_delete = 0;

            if (editForm && !editForm.address) {
                editForm.address = [{}]
            }

            if (editForm && editForm.address && editForm.address.length - 1 < i) {
                let len = editForm.address.length;
                for (let j = 0; j < (i - (len === 0 ? -1 : len - 1)); j++) {
                    editForm.address.push({});
                }
            }

            if (isEditMode && address_list[i] && address_list[i].id) { // To delete address which is there in backend
                check_delete = 1;
                editForm.address.push({
                    id: address_list[i].id,
                    action: "delete"
                })
                setEditForm({ ...editForm });
                form_data.editForm = editForm;
            }

            address_list.splice(i, 1);
            setDetails({ ...details, address: [...address_list] })

            if (isEditMode && !address_list[i] && check_delete === 0) { // To delete address which is not there in backend
                if (form_data.editForm && form_data.editForm.address) {
                    editForm.address.splice(i, 1);
                }
                setEditForm({ ...editForm });
                form_data.editForm = editForm;
            }

            formsubmit["address"] = [...address_list];

            form_data.formsubmit = formsubmit;
            form_data.address = [...address_list];
            form_data.profileId = profile_id;
            props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });

            setFormSubmit({ ...formsubmit });
        }
        return (
            <div className='phone-wrapper'>
                <div className='phone-inner address-wrapper'>
                    <div className='add-btn' onClick={handleAddressAdd}>
                        <span>Add Address</span>
                    </div>
                    {details.address.map((address, i) => {
                        return (
                            <div className="phone-field-wrapper address-field-wrapper" key={i}>
                                {details.address.length > 1 ? <div className='close-btn' onClick={() => { handleCross(i) }}></div> : <></>}
                                <div className='field'>
                                    <label>Street Address</label>
                                    <input value={address.street_address ? address.street_address : ""} placeholder='Enter Address' type="text" onChange={(e) => handleOnChange(e, "street_address", i)} />
                                </div>
                                <div className='field'>
                                    <label>City</label>
                                    <input value={address.city ? address.city : ""} placeholder='Enter City' type="text" onChange={(e) => handleOnChange(e, "city", i)} />
                                </div>
                                <div className='field'>
                                    <label>State</label>
                                    <input value={address.state ? address.state : ""} placeholder='Enter State' type="text" onChange={(e) => handleOnChange(e, "state", i)} />
                                </div>
                                <div className='field'>
                                    <label>Country</label>
                                    <div className='country-field'>
                                        <SpeedSelect
                                            options={props.country}
                                            selectedOption={selectedCountry[i]}
                                            onSelect={(e) => { handleOnChange(e, "country", i) }}
                                            displayKey="name"
                                            uniqueKey="id"
                                            maxHeight={200}
                                            isLabelClickable={true}
                                            dropdownAlignment="right"
                                        />
                                    </div>
                                </div>
                                <div className='field'>
                                    <label>Zip code</label>
                                    <input value={address.zipcode && address.zipcode !== "" ? address.zipcode : ""} placeholder='Enter Zip code' type="text" onChange={(e) => handleOnChange(e, "zipcode", i)} />
                                </div>
                                <div className='field'>
                                    <label className='red-text'>{address.error}</label>
                                </div>
                                <div className='field'>
                                    <label></label>
                                    <div className='address-types'>
                                        <div className='radio'>
                                            <input type="radio" id={`current_address_${i}`} name={`address_type_${i}`} checked={address.is_present ? true : false} value={"current_address"} onChange={(e) => handleOnChange(e, "address_type", i)} />
                                            <label htmlFor="current_address">Current</label>
                                        </div>
                                        <div className='radio'>
                                            <input type="radio" id={`permanent_address_${i}`} name={`address_type_${i}`} checked={address.is_permanent ? true : false} value={"permanent_address"} onChange={(e) => handleOnChange(e, "address_type", i)} />
                                            <label htmlFor="permanent_address">Permanent</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }



    // To add social in form
    const Social = () => {
        return (
            <div className='social-wrapper'>
                <div className='form-group'>
                    <label>LinkedIn</label>
                    <input type='url' value={social.linkedin} onChange={e => handleSocialChange('linkedin', e.target.value)} />
                    {error_social.linkedin && <span className='red-text'>{error_social.linkedin}</span>}
                </div>
                <div className='form-group'>
                    <label>Git Repository</label>
                    <input type='url' value={social.git} onChange={e => handleSocialChange('git', e.target.value)} />
                    {error_social.git && <span className='red-text'>{error_social.git}</span>}
                </div>
                <div className='form-group'>
                    <label>Portfolio</label>
                    <input type='url' value={social.portfolio} onChange={e => handleSocialChange('portfolio', e.target.value)} />
                    {error_social.portfolio && <span className='red-text'>{error_social.portfolio}</span>}
                </div>
            </div>
        )
    }

    //Function to generate Unique id
    const GenerateId = async () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    //Function to generate image Url
    const imageUrlGenerate = async (formdata, type) => {
        imageAPIError = false;
        if (type === "edit") {
            if (formdata && formdata.resource && formdata.resource.img_url !== "") {
                // let access_token = localStorage.getItem("thinkTeam_token");
                let blob = props.setFile[0][SITE_PREFIX + tab_id];
                if (blob) {
                    let body = new FormData();
                    body.append('resource_img', blob, "jpeg");
                    await APIService.apiRequest(API_BASE_URL + `/resource_img/${profile_id}`, body, false, 'PUT')
                        // await fetch(API_BASE_URL + `/resource_img/${profile_id}`,
                        // {
                        //     method: 'PUT',
                        //     headers: {
                        //         'Authorization': "Bearer " + access_token
                        //     },
                        //     cache: "reload",
                        //     body: body
                        // })
                        .then(async response => {
                            // response = await response.json();
                            if (response.status.toString() === "1") {
                                let {
                                    output: { img_url },
                                } = response;
                                formdata.resource.img_url = img_url;
                            } else {
                                delete formdata.resource["img_url"];
                                imageAPIError = true;
                                console.log("API rejected");
                                alertService.error('Image Uploading error');
                            }
                        }).catch(e => {
                            delete formdata.resource["img_url"];
                            imageAPIError = true;
                            console.log('Image Uploading error', e);
                            alertService.error(e);
                        });
                }
            }
        } else if (type === "submit") {
            if (formdata && formdata.resource && formdata.resource.img_url !== "") {
                // let access_token = localStorage.getItem("thinkTeam_token");
                let blob = props.setFile[0][SITE_PREFIX + tab_id];
                if (blob) {
                    let body = new FormData();
                    let Unique_id = formdata.resource.first_name + await GenerateId();
                    body.append('user_cover_img', blob, Unique_id);
                    await APIService.apiRequest(API_BASE_URL + `/user_cover_img`, body, false, 'POST')
                        // await fetch(API_BASE_URL + `/user_cover_img`,
                        //     {
                        //         method: 'POST',
                        //         headers: {
                        //             'Authorization': "Bearer " + access_token
                        //         },
                        //         cache: "reload",
                        //         body: body
                        //     })
                        .then(async response => {
                            // response = await response.json();
                            if (response.status.toString() === "1") {
                                let {
                                    output: { cover_img_url },
                                } = response;
                                formdata.resource.img_url = cover_img_url;
                            } else {
                                delete formdata.resource["img_url"];
                                imageAPIError = true;
                                console.log("API rejected");
                                alertService.error('Image Uploading error');
                            }
                        }).catch(e => {
                            delete formdata.resource["img_url"];
                            imageAPIError = true;
                            console.log('Image Uploading error', e);
                            alertService.error(e);
                        });
                }
            }
        }
    }

    const handleEdit = async () => {
        let check_flag = 0;
        if (formsubmit.resource.first_name === ""
        ) {
            if (formsubmit.resource.first_name === "") inputErrorDom.firstName.style.border = "2px solid red";
            check_flag = 1;
            alertService.error("Please enter the required field");
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        if (socialChangesDone) {
            const payload = { resource: {} }
            if (social.linkedin) payload.resource.linkedin = formatURL(social.linkedin);
            if (social.git) {
                if (typeof payload.resource.social === "object") {
                    payload.resource.social['git'] = addHttpsToURLString(social.git);
                } else {
                    payload.resource.social = { git: addHttpsToURLString(social.git) };
                }
            }
            if (social.portfolio) {
                if (typeof payload.resource.social === "object") {
                    payload.resource.social['portfolio'] = addHttpsToURLString(social.portfolio);
                } else {
                    payload.resource.social = { portfolio: addHttpsToURLString(social.portfolio) };
                }
            }

            await APIService.apiRequest(
                API_BASE_URL + `/resource/${profile_id}`,
                payload,
                false,
                'PUT')
                .then(response => {
                    setSocialChangesDone(false);
                    let profile_data = props.setProfile[0][SITE_PREFIX + tab_id];
                    profile_data.resource.linkedin = payload.resource.linkedin;
                    profile_data.resource.social = {
                        ...profile_data.resource.social,
                        ...payload.resource.social
                    }

                    props.setProfile[1]({
                        ...props.setProfile[0],
                        [SITE_PREFIX + tab_id]: { ...profile_data }
                    });

                    if (response.status === 1) {
                        alertService.success('Social links updated successfully');
                    } else {
                        alertService.error(response.msg);
                    }
                })
                .catch(error => console.log(error))
        }
        if (check_flag !== 1 && Object.keys(editForm).length > 0) {
            props.setShowLineLoader(true);
            await imageUrlGenerate(editForm, "edit");
            if (!imageAPIError) {
                let profile_data = props.setProfile[0][SITE_PREFIX + tab_id];
                profile_data.formsubmit.skill_id = [...formsubmit.skill_id];

                if (isEditMode && newImgUrl) {
                    profile_data.formsubmit.resource.img_url = newImgUrl;
                }

                props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: profile_data });

                let final_form = { ...editForm };
                if (editForm && editForm.address) {
                    let newArray = final_form.address.filter(value => Object.keys(value).length !== 0);
                    final_form.address = newArray;
                }
                if (editForm && editForm.education) {
                    let newArray = final_form.education.filter(value => Object.keys(value).length !== 0);
                    final_form.education = newArray;
                }

                if (editForm && editForm.skill_id) {
                    final_form.skill_id = editForm.skill_id.map(skill => ({
                        is_deleted: 0,
                        skill_id: skill.skill_id,
                    }))

                    if (previousSkills.length > 0) {
                        previousSkills.forEach(previousSkill => {
                            const skill = final_form.skill_id.find(s => s.skill_id === previousSkill.skill_id)
                            // If skill is not found in the new skills array, it means it has been deleted
                            if (!skill) {
                                final_form.skill_id.push({
                                    is_deleted: 1,
                                    skill_id: previousSkill.skill_id,
                                })
                            }
                        })
                    }
                }

                await APIService.apiRequest(API_BASE_URL + `/resource/${profile_id}`, final_form, false, 'PUT')
                    .then(response => {
                        if (response.status === 1) {
                            setEditForm({});
                            alertService.success('Profile Edited Successfuly');
                            props.updateHome[1](true);
                        } else {
                            let error = response.msg;
                            console.log('Error mesg ', error);
                            if (error.includes('1062')) {
                                alertService.error(
                                    'Duplicate entry found, please check name/phone/email'
                                );
                                return;
                            }
                            if (error.includes('1048')) {
                                alertService.error(
                                    'Please fill address completely'
                                );
                                return;
                            }
                            if (error.includes('1366')) {
                                alertService.error(
                                    'Please enter the duration of working for all the positions'
                                );
                                return;
                            }
                            if (error.includes('1406')) {
                                alertService.error(
                                    'Please reduce the size of the image'
                                );
                                return;
                            }
                            alertService.error(error);
                        }
                    }).catch(error => {
                        console.log('Edit prof errr', error);
                        let startIdx = error.indexOf(':') + 2;
                        let endIdx = error.indexOf('[');
                        let errorMesg = error.slice(startIdx, endIdx);
                        console.log('Error mesg ', errorMesg);
                        if (error.includes('1062')) {
                            alertService.error(
                                'Duplicate entry found, please check name/phone/email'
                            );
                            return;
                        }
                        if (error.includes('1048')) {
                            alertService.error(
                                'Please fill address completely'
                            );
                            return;
                        }
                        if (error.includes('1366')) {
                            alertService.error(
                                'Please enter the duration of working for all the positions'
                            );
                            return;
                        }
                        if (error.includes('1406')) {
                            alertService.error(
                                'Please reduce the size of the image'
                            );
                            return;
                        }
                        alertService.error(errorMesg);
                    });
                props.setImage[1]({ ...props.setImage[0], [SITE_PREFIX + tab_id]: {} });
                // props.history.push(`/app/profile/${profile_id}`)
                // const index = props.tabsInfo[0].tabs.findIndex(object => {
                //     console.log('object.id', object, tab_id);
                //     return String(object.id) === String(tab_id);
                // });
                // let temp_tabs = { ...props.tabsInfo[0] };

                // temp_tabs[index].isEditMode = true;
                // temp_tabs[index].name = formsubmit.resource.first_name && formsubmit.resource.middle_name ?
                //     formsubmit.resource.first_name + " " + formsubmit.resource.middle_name + " " + formsubmit.resource.last_name :
                //     formsubmit.resource.first_name + " " + formsubmit.resource.last_name;
                // temp_tabs[index].img_url = formsubmit.resource.img_url ? formsubmit.resource.img_url : null;

                // if (isEditMode && newImgUrl) {
                //     temp_tabs[index].img_url = newImgUrl;
                // }

                // temp_tabs[index].firstName = formsubmit.resource.first_name;
                // temp_tabs[index].lastName = formsubmit.resource.last_name;
                // props.tabsInfo[1]({
                //     activeTabId: tab_id,
                //     tabs: [
                //         ...temp_tabs
                //     ],
                //     newTabCounter: props.tabsInfo[0].newTabCounter

                // });
                // let mode = props.tabMode[0]
                // if (mode) {
                //     const index = mode.findIndex(object => {
                //         return object.tabId === tab_id;
                //     });
                //     mode[index].isEditMode = true
                //     props.tabMode[1]([...mode]);
                // }
            }
            props.setShowLineLoader(false);
        } else if (!socialChangesDone) {
            alertService.error('Nothing to edit in form');
        }
    }


    const handleDelete = async () => {
        props.setShowLineLoader(true);
        await APIService.apiRequest(API_BASE_URL + `/resource/${profile_id}`, null, false, 'DELETE')
            .then(response => {
                props.setShowLineLoader(false);
                alertService.success('Profile Deleted Successfuly');

                const id = SITE_PREFIX + profile_id;
                props.setProfile[1](({ [id]: _, ...newState }) => {
                    return newState;
                })
                props.updateHome[1](true);
                closeTab({ tab: activeSubTab, isSubTab: true });
                props.refreshHome();
            }).catch(e => {
                props.setShowLineLoader(false);
                console.log('Delete prof errr', e);
                alertService.error(e);
            });
    }



    // To handle the form submit after entering the valid input
    const handleSubmit = async () => {
        let check_flag = 0;
        if (formsubmit.resource.first_name === ""
        ) {
            if (formsubmit.resource.first_name === "") inputErrorDom.firstName.style.border = "2px solid red";
            check_flag = 1;
            alertService.error("Please enter the required field");
            document.body.querySelector("#form-wrapper").scrollTop = document.documentElement.scrollTop = 0;
        }
        if (check_flag !== 1) {
            props.setShowLineLoader(true);
            await imageUrlGenerate(formsubmit, "submit");

            if (!imageAPIError) {
                const data = { ...formsubmit }
                if (!error_social?.linkedin && social?.linkedin) data.resource.linkedin = formatURL(social.linkedin);
                if (!error_social?.git && social?.git) {
                    if (typeof data.resource.social === "object") {
                        data.resource.social['git'] = addHttpsToURLString(social.git);
                    } else {
                        data.resource.social = { git: addHttpsToURLString(social.git) };
                    }
                }
                if (!error_social?.portfolio && social?.portfolio) {
                    if (typeof data.resource.social === "object") {
                        data.resource.social['portfolio'] = addHttpsToURLString(social.portfolio);
                    } else {
                        data.resource.social = { portfolio: addHttpsToURLString(social.portfolio) };
                    }
                }

                if (data.skill_id) {
                    data.skill_id = data.skill_id.map((skill) => skill.skill_id)
                }

                await APIService.apiRequest(API_BASE_URL + `/resource`, data, false, 'POST')
                    .then(result => {
                        props.setShowLineLoader(false);
                        if (result.status === 1) {
                            let response = result;
                            formsubmit.resource["id"] = response.id;
                            props.setProfile[1]((prev) => {
                                const updatedProfiles = { ...prev };
                                updatedProfiles[SITE_PREFIX + response.id] = formsubmit;
                                delete updatedProfiles[SITE_PREFIX + activeSubTabRef.current.id.replace('new/', '')];
                                return updatedProfiles
                            });
                            const newTab = {
                                firstName: formsubmit.resource.first_name,
                                lastName: formsubmit.resource.last_name,
                                imgSrc: formsubmit.resource.img_url,
                                id: result.id,
                                showAvatar: true,
                                showEditButton: 'HR',
                                isEditMode: false,
                                url: `/app/profile/${result.id}`
                            }
                            modifyTab({ oldTab: activeSubTabRef.current, newTab, isSubTab: true });
                            history.replace(newTab.url);
                            alertService.success('Profile Added Successfuly');
                            props.updateHome[1](true);
                            props.refreshHome();
                        } else {
                            let error = result.msg;
                            console.log('New prof errr', error);
                            let startIdx = error.indexOf(':') + 2;
                            let endIdx = error.indexOf('[');
                            let errorMesg = error.slice(startIdx, endIdx);
                            console.log('Error mesg ', errorMesg);
                            if (error.includes('1062')) {
                                alertService.error(
                                    'Duplicate entry found, please check name/phone/email/social'
                                );
                                return;
                            }
                            if (error.includes('1048')) {
                                alertService.error(
                                    'Please fill address completely'
                                );
                                return;
                            }
                            if (error.includes('1366')) {
                                alertService.error(
                                    'Please enter the duration of working for all the positions'
                                );
                                return;
                            }
                            if (error.includes('1406')) {
                                alertService.error(
                                    'Please reduce the size of the image'
                                );
                                return;
                            }
                            alertService.error(errorMesg);
                        }
                    }).catch(error => {
                        console.log('New prof errr', error);
                        props.setShowLineLoader(false);
                        let startIdx = error.indexOf(':') + 2;
                        let endIdx = error.indexOf('[');
                        let errorMesg = error.slice(startIdx, endIdx);
                        console.log('Error mesg ', errorMesg);
                        if (error.includes('1062')) {
                            alertService.error(
                                'Duplicate entry found, please check name/phone/email/social'
                            );
                            return;
                        }
                        if (error.includes('1048')) {
                            alertService.error(
                                'Please fill address completely'
                            );
                            return;
                        }
                        if (error.includes('1366')) {
                            alertService.error(
                                'Please enter the duration of working for all the positions'
                            );
                            return;
                        }
                        if (error.includes('1406')) {
                            alertService.error(
                                'Please reduce the size of the image'
                            );
                            return;
                        }
                        alertService.error(errorMesg);
                    });
            } else {
                props.setShowLineLoader(false);
            }
        }
    }



    // Handle the switching between the tabs in form
    const HandleSelect = (select) => {
        if (select) updateSelectedContactType(id, select);
        let form = {
            "phone": Phone(),
            "email": Email(),
            "address": Address(),
            "social": Social()
        }
        return (form[selectedContactType])
    }

    const hasDeletePermission = () => {
        /*
        HR- Will be able to delete profile of someone whose status is not employee
        Manager- Manager will be able to delete anyone’s profile other than self.
        Remove ‘Delete Profile’ button on self profile
        */
        let permission = false

        if (isHR) {
            if (currentUser.resource_id !== id) {
                if (isManager) {
                    permission = true
                }

                if (setProfile[0][SITE_PREFIX + tab_id]) {
                    if (!setProfile[0][SITE_PREFIX + tab_id].status || setProfile[0][SITE_PREFIX + tab_id].status.status_id.toString() !== "4") {
                        permission = true
                    }
                }
            }
        }

        return permission
    }

    return (
        <div id='profile-page' onClick={() => handleClose()}>
            <div id="form-wrapper" className={`${(isHR || tab_id === props.currentUserData?.resource_id) ? 'can-edit' : ''}`}>
                <div className='fields-section basic'>
                    <div className='profile-pic'>
                        <div className='image-wrapper'>
                            <label htmlFor='profile-image' onClick={(e) => handleImageModel(e)}>
                                <img
                                    className='profile-image-thumb thumb'
                                    alt="profileImage"
                                    src={props.profileImage[0] ? props.profileImage[0] : (formsubmit["resource"]["img_url"] === "") ? (default_image) : (formsubmit["resource"]["img_url"])}
                                    onError={() => setFormSubmit({ ...formsubmit, resource: { ...formsubmit.resource, img_url: "" } })}
                                />
                            </label>
                            {showImageModel && <Modal
                                image={formsubmit.resource && formsubmit.resource.img_url && formsubmit.resource.img_url !== "" ? formsubmit.resource.img_url : null}
                                show={showImageModel}
                                handleClose={handleClose}
                                handleOnChange={handleOnChange}
                                setImage={props.setImage}
                                tabId={tab_id}
                            />}
                        </div>
                    </div>
                    <div className='details'>
                        <div className='fields-group first-row'>
                            <div className='field-wrapper'>
                                <label className='label'>First Name<span className='red-text'>*</span></label>
                                <input value={formsubmit["resource"]["first_name"] ? formsubmit["resource"]["first_name"] : ""} placeholder='Enter first name' id="first_name" className='input' onChange={(e) => handleOnChange(e, "first_name")} />
                            </div>
                            <div className='field-wrapper'>
                                <label className='label'>Middle Name</label>
                                <input value={formsubmit["resource"]["middle_name"] ? formsubmit["resource"]["middle_name"] : ""} placeholder='Enter middle name' id="middle_name" className='input' onChange={(e) => handleOnChange(e, "middle_name")} />
                            </div>
                            <div className='field-wrapper'>
                                <label className='label'>Last Name</label>
                                <input value={formsubmit["resource"]["last_name"] ? formsubmit["resource"]["last_name"] : ""} placeholder='Enter last name' id="last_name" className='input' onChange={(e) => handleOnChange(e, "last_name")} />
                            </div>
                        </div>

                        <div className='fields-group second-row'>
                            <div className='field-wrapper dob' >
                                <label className='label'>Date of Birth</label>
                                <div id="date_of_birth">
                                    <DatePicker
                                        picker={"date"}
                                        date={formsubmit["resource"]["date_of_birth"]}
                                        align="right"
                                        allowClear={false}
                                        onChange={(e) => handleOnChange(e, "date_of_birth")}
                                        showOkCancelBtns={true}
                                    />
                                </div>
                            </div>
                            <div className='field-wrapper gender' >
                                <label className='label'>Gender</label>
                                <div id="gender">
                                    <SpeedSelect
                                        options={gender} // required
                                        selectedOption={formsubmit["resource"]["gender"].charAt(0).toUpperCase() + formsubmit["resource"]["gender"].slice(1)} // required
                                        onSelect={(e) => { handleOnChange(e, "gender") }} // required
                                        displayKey="name" // required if options is an array of objects,
                                        uniqueKey="id" // required if options is an array of objects
                                        maxHeight={100} // optional, in pixel, Default is 450px
                                        dropdownAlignment="right"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='fields-section about'>
                    <div className='col details'>
                        <div className='fields-group'>
                            <div className='field-wrapper'>
                                <label className='label'>Languages</label>
                                <div id="language">
                                    <Autocomplete
                                        options={props.languages}
                                        setOptions={props.setLanguages}
                                        selectedOption={selectedLanguages}
                                        onSelect={(e) => { handleOnChange(e, "languages") }}
                                        placeholder="Enter Language"
                                        addNonSuggestionItem={false}
                                        showAutocomplete={[showAutocompleteLanguage, setShowAutocompleteLanguage]}
                                        tabId={tab_id}
                                        handleClose={handleClose}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='fields-group'>
                            <div className='field-wrapper' >
                                <label className='label'>Skills</label>
                                <div id="skills">
                                    <SkillSelect
                                        key={tab_id}
                                        value={selectedSkills}
                                        onChange={skills => handleOnChange(skills, "skills")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fields-group'>
                            <div className='field-wrapper'>
                                <label className='label'>About</label>
                                <textarea id="about" value={formsubmit["resource"]["about_resource"] ? formsubmit["resource"]["about_resource"] : ""} className='textarea' name='about' onChange={(e) => handleOnChange(e, "about_resource")}>{formsubmit["resource"]["about_resource"]}</textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fields-section contact bg'>
                    <div className='col details'>
                        <div className='fields-group'>
                            <div className='field-wrapper contact-left'>
                                <div className='contact-title'>Contact information</div>
                                <ul>
                                    <li className={selectedContactType === "phone" ? "phone selected" : "phone"} onClick={() => HandleSelect("phone")}><i></i><span id="unique_phone"> Phone</span></li>
                                    <li className={selectedContactType === "email" ? "email selected" : "email"} onClick={() => HandleSelect("email")}><i></i><span id="unique_email">Email</span></li>
                                    <li className={selectedContactType === "address" ? "address selected" : "address"} onClick={() => HandleSelect("address")}><i></i><span id="unique_address">Address</span></li>
                                    <li className={selectedContactType === "social" ? "social selected" : "social"} onClick={() => HandleSelect("social")}><i></i><span id="unique_social">Social Networking</span></li>
                                </ul>
                            </div>
                            <div className='field-wrapper contact-right'>
                                {HandleSelect()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fields-section company bg'>
                    <div className='details'>
                        <div className='add-btn' onClick={handleAddCompany}>
                            <span>Add Company</span>
                        </div>
                        {details.company.map((sub_company, i) => {
                            const handleCrossButton = (e, i) => {
                                let company_list = details.company;

                                let form_data = props.setProfile[0][SITE_PREFIX + tab_id];
                                let check_delete = 0;

                                if (isEditMode && company_list[i] && (company_list[i].location || company_list[i].location === "" || !company_list[i].location)) { // To delete company which is there in backend
                                    company_list[i].action = "delete";
                                    check_delete = 1;
                                    editForm.work_experience ?
                                        editForm.work_experience = [...editForm.work_experience, company_list[i]]
                                        :
                                        editForm.work_experience = [...[company_list[i]]]
                                    setEditForm({ ...editForm });
                                    form_data.editForm = editForm;
                                }

                                company_list.splice(i, 1);

                                if (isEditMode && !company_list[i] && check_delete === 0) { // To delete company which is not there in backend
                                    if (editForm.work_experience && editForm.work_experience[i]) {
                                        editForm.work_experience.splice(i, 1)
                                    }
                                    setEditForm({ ...editForm });
                                    form_data.editForm = editForm;
                                }

                                setDetails({ ...details, company: [...company_list] })

                                formsubmit["work_experience"] = [...company_list];

                                form_data.formsubmit = formsubmit;
                                form_data.company = [...company_list];
                                form_data.work_experience = [...company_list];
                                form_data.profileId = profile_id;
                                props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });

                                setFormSubmit({ ...formsubmit });
                            }
                            const handleAddPosition = () => {
                                let company_list = details.company;
                                let object = {
                                    designation: "",
                                    employment_type: "",
                                    location: "",
                                    work_description: "",
                                    start_month: "",
                                    end_month: ""
                                }
                                company_list[i].tenure.push(object);
                                setDetails({ ...details, company: [...company_list] })

                            };
                            return (
                                <div className="company-inner" key={i}>
                                    <div className='close-btn' onClick={(e) => handleCrossButton(e, i)}></div>
                                    <div className='company-name'>
                                        <div className='field'>
                                            <label className='label'>Company Name</label>
                                            <input className="input" value={sub_company.company_name ? sub_company.company_name : ""} placeholder='Enter Company Name' onChange={(e) => handleOnChange(e, "company_name", i)} />
                                        </div>
                                        <button className='company-link'></button>
                                    </div>
                                    <div className='position-wrapper'>
                                        <div className='add-btn' onClick={handleAddPosition}>
                                            <span>Add Position</span>
                                        </div>
                                        {sub_company.tenure.map((position, position_i) => {
                                            const handleCrossButton = () => {
                                                let company_list = details.company;

                                                let form_data = props.setProfile[0][SITE_PREFIX + tab_id];
                                                let check_delete = 0;

                                                if (isEditMode && company_list[i] && company_list[i].tenure[position_i] && company_list[i].tenure[position_i].id) { // To delete position which is there in backend
                                                    let object = {
                                                        tenure: [{
                                                            id: company_list[i].tenure[position_i].id,
                                                            is_deleted: 1
                                                        }]
                                                    }
                                                    check_delete = 1;
                                                    if (editForm.work_experience) {
                                                        editForm.work_experience = [...editForm.work_experience, object];
                                                    } else {
                                                        editForm.work_experience = [object];
                                                    }

                                                    setEditForm({ ...editForm });
                                                    form_data.editForm = editForm;
                                                }

                                                company_list[i].tenure.splice(position_i, 1);
                                                if (isEditMode && company_list[i] && !company_list[i].tenure[position_i] && check_delete === 0) { // To delete position which is not there in backend
                                                    if (editForm.work_experience) {
                                                        editForm.work_experience[i].tenure.splice(position_i, 1);
                                                    }
                                                    setEditForm({ ...editForm });
                                                    form_data.editForm = editForm;
                                                }
                                                setDetails({ ...details, company: [...company_list] })

                                                formsubmit["work_experience"] = [...company_list];

                                                form_data.formsubmit = formsubmit;
                                                form_data.company = [...company_list];
                                                form_data.work_experience = [...company_list];
                                                form_data.profileId = profile_id;
                                                props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });

                                                setFormSubmit({ ...formsubmit });
                                            }
                                            return (

                                                <div className="position-form" key={position_i}>
                                                    {(sub_company.tenure.length > 1) ?
                                                        <div className='close-btn' onClick={handleCrossButton}></div>
                                                        : ""}
                                                    <div className='fields-group'>
                                                        <div className='field designation'>
                                                            <label className='label'>Designation</label>
                                                            <input className='input' value={position.designation ? position.designation : ""} placeholder='Enter Designation' onChange={(e) => handleOnChange(e, "designation", i, position_i)} />
                                                        </div>
                                                        <div className='field type'>
                                                            <label className='label'>Employment Type</label>
                                                            <input className='input' placeholder='Enter Employment Type' value={position.employment_type ? position.employment_type : ""} onChange={(e) => handleOnChange(e, "employment_type", i, position_i)} />
                                                        </div>
                                                        <div className='field location'>
                                                            <label className='label'>Location</label>
                                                            <input className='input' value={position.location ? position.location : ""} placeholder='Enter Location' onChange={(e) => handleOnChange(e, "location", i, position_i)} />
                                                        </div>
                                                        <div className='field date'>
                                                            <div className='field'>
                                                                <label className='label'>Start Date</label>
                                                                <DatePicker
                                                                    picker={"month"}
                                                                    placeholder={position.start_year && position.start_month ? position.start_year + "-" + position.start_month : ""}
                                                                    align="right"
                                                                    allowClear={false}
                                                                    onChange={(e) => handleOnChange(e, "position_date_start", i, position_i)}
                                                                />
                                                            </div>
                                                            <div className='field'>
                                                                <label className='label'>End Date</label>
                                                                <DatePicker
                                                                    picker={"month"}
                                                                    placeholder={position.end_year && position.end_month ? position.end_year + "-" + position.end_month : ""}
                                                                    align="right"
                                                                    allowClear={false}
                                                                    onChange={(e) => handleOnChange(e, "position_date_end", i, position_i)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='field summary'>
                                                            <label className='label'>Work Summary</label>
                                                            <textarea value={position.work_description ? position.work_description : ""} placeholder='Type here' className='textarea' onChange={(e) => handleOnChange(e, "work_description", i, position_i)}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>
                <div className='fields-section education company bg'>
                    <div className='details'>
                        <div className='add-btn' onClick={handleAddEducation}>
                            <span>Add Education</span>
                        </div>
                        {details.education.map((sub_education, i) => {
                            const handleCrossButton = () => {
                                let education_list = details.education;

                                let form_data = props.setProfile[0][SITE_PREFIX + tab_id];
                                let check_delete = 0;

                                if (editForm && !editForm.education) {
                                    editForm.education = [{}]
                                }
                                if (editForm && editForm.education && editForm.education.length - 1 < i) {
                                    let len = editForm.education.length;
                                    for (let j = 0; j < (i - (len === 0 ? -1 : len - 1)); j++) {
                                        editForm.education.push({});
                                    }
                                }

                                if (isEditMode && education_list[i] && education_list[i].id) { // To delete education which is there in backend
                                    check_delete = 1;
                                    editForm.education.push({
                                        id: education_list[i].id,
                                        is_deleted: 1
                                    })
                                    setEditForm({ ...editForm });
                                    form_data.editForm = editForm;
                                }

                                education_list.splice(i, 1);
                                if (isEditMode && !education_list[i] && check_delete === 0) { // To delete education which is not there in backend
                                    if (editForm.education) {
                                        editForm.education.splice(i, 1);
                                    }
                                    setEditForm({ ...editForm });
                                    form_data.editForm = editForm;
                                }
                                setDetails({ ...details, education: [...education_list] })

                                formsubmit["education"] = [...education_list];

                                form_data.formsubmit = formsubmit;
                                form_data.education = [...education_list];
                                form_data.profileId = profile_id;
                                props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });

                                setFormSubmit({ ...formsubmit });
                            }
                            return (
                                <div className="company-inner" key={i}>
                                    <div className='close-btn' onClick={handleCrossButton}></div>
                                    <div className='company-name'>
                                        <div className='field'>
                                            <label className='label'>School</label>
                                            <input className="input" value={sub_education.name ? sub_education.name : ""} placeholder='School Name' onChange={(e) => handleOnChange(e, "school", i)} />
                                        </div>
                                        <button className='company-link'></button>
                                    </div>
                                    <div className='fields-group'>
                                        <div className='field'>
                                            <label className='label'>Degree</label>
                                            <input className='input' value={sub_education.degree ? sub_education.degree : ""} placeholder='Degree' onChange={(e) => handleOnChange(e, "degree", i)} />
                                        </div>
                                        <div className='field'>
                                            <label className='label'>Field of Study</label>
                                            <input className='input' value={sub_education.field ? sub_education.field : ""} placeholder='Field of Study' onChange={(e) => handleOnChange(e, "field", i)} />
                                        </div>
                                        <div className='field date'>
                                            <div className='field'>
                                                <label className='label'>Start Date</label>
                                                <DatePicker
                                                    picker={"date"}
                                                    placeholder={sub_education.start_date}
                                                    align="right"
                                                    allowClear={false}
                                                    onChange={(e) => handleOnChange(e, "education_date_start", i)}
                                                />
                                            </div>
                                            <div className='field'>
                                                <label className='label'>End Date</label>
                                                <DatePicker
                                                    picker={"date"}
                                                    placeholder={sub_education.end_date}
                                                    align="right"
                                                    allowClear={false}
                                                    onChange={(e) => handleOnChange(e, "education_date_end", i)}
                                                />
                                            </div>
                                        </div>
                                        <div className='field score'>
                                            <label className='label'>Score</label>
                                            <input className='input' value={sub_education.score ? sub_education.score : ""} placeholder='Score' onChange={(e) => handleOnChange(e, "score", i)} />
                                        </div>
                                        <div className='field'>
                                            <label className='label'>Highlights</label>
                                            <textarea value={sub_education.highlights ? sub_education.highlights : ""} className='textarea' placeholder='Type here' onChange={(e) => handleOnChange(e, "highlights", i)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='fields-section company family bg'>
                    <div className='details'>
                        <div className='add-btn' onClick={handleAddMember}>
                            <span>Add Family Member</span>
                        </div>
                        <div className="family-inner">
                            {details.member && details.member.length > 0 && <div className='label-group'>
                                <div className='field'>
                                    <label className='label'>Relation</label>
                                </div>
                                <div className='field'>
                                    <label className='label'>Member</label>
                                </div>
                                <div className='field'>
                                    <label className='label'>Occupation</label>
                                </div>
                                <div className='field'>
                                    <label className='label'>Mobile</label>
                                </div>
                            </div>}
                            {details.member.map((sub_member, i) => {
                                const handleCrossButton = () => {
                                    let member_list = details.member;
                                    member_list.splice(i, 1);
                                    setDetails({ ...details, member: [...member_list] })

                                    let form_data = props.setProfile[0][SITE_PREFIX + tab_id];

                                    if (isEditMode) {
                                        if (editForm.resource) {
                                            editForm.resource["family"] = [...member_list];
                                        } else {
                                            editForm["resource"] = {
                                                "family": [...member_list]
                                            }
                                        }
                                        setEditForm({ ...editForm });
                                        form_data.editForm = editForm;
                                    }

                                    formsubmit["resource"]["family"] = [...member_list];

                                    form_data.resource = formsubmit.resource;
                                    form_data.formsubmit = formsubmit;
                                    form_data.member = [...member_list];
                                    form_data.profileId = profile_id;
                                    props.setProfile[1]({ ...props.setProfile[0], [SITE_PREFIX + tab_id]: form_data });

                                    setFormSubmit({ ...formsubmit });
                                }
                                return (
                                    <div className='field-group' key={i}>
                                        <div className='field-group-row'>
                                            <div className='field'>
                                                <input className='input' value={sub_member.relation ? sub_member.relation : ""} placeholder='Relation' onChange={(e) => handleOnChange(e, "relation", i)} />
                                            </div>
                                            <div className='field'>
                                                <input className='input' value={sub_member.member ? sub_member.member : ""} placeholder='Member Name' onChange={(e) => handleOnChange(e, "member", i)} />
                                            </div>
                                            <div className='field'>
                                                <input className='input' value={sub_member.occupation ? sub_member.occupation : ""} placeholder='Occupation' onChange={(e) => handleOnChange(e, "occupation", i)} />
                                            </div>
                                            <div className='field'>
                                                <input className='input' value={sub_member.phone ? sub_member.phone : ""} type="text" placeholder='Add Number' onChange={(e) => handleOnChange(e, "phone", i)} />
                                            </div>
                                            <label className='red-text'>{sub_member.error}</label>
                                        </div>
                                        <div className='close-btn' onClick={handleCrossButton}></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='action-btns'>
                    {isEditMode ?
                        <>
                            <button className='btn btn-with-icon btn-save' onClick={handleEdit}><i></i><span>Save Profile</span></button>
                            {hasDeletePermission() && <button className='btn btn-with-icon btn-delete' onClick={handleDelete}><i></i><span>Delete Profile</span></button>}
                        </>
                        : <button className='btn btn-with-icon btn-add' onClick={handleSubmit}><i></i><span>Add Profile</span></button>}
                </div>
            </div>
        </div>
    );
}
