import { forwardRef, useEffect, useState } from "react";
import "./SearchInput.scss";
// import SearchResults from "./SearchResults";
import { useAttendanceSearch } from "views/Attendance/context/search";

/**
 * @typedef {object} SearchInputProps
 * @property {string} value
 * @property {function} onChange
 * @property {boolean=} expand
 * @property {function=} setExpand
 * @property {number=} blurDelay
 * @property {React.RefObject<HTMLInputElement>=} ref
 */

/**
 * @type {React.FC<SearchInputProps & React.InputHTMLAttributes<HTMLInputElement>>}
 */
const SearchInput = forwardRef(
  ({ expand, setExpand, value, onChange, onClear, blurDelay = 0, showCloseBtn = false, keepExpanded = false, ...restProps }, ref) => {
    const [uncontrolledExpand, setUncontrolledExpand] = useState(false);
    const open = expand ?? uncontrolledExpand;
    const setOpen = setExpand ?? setUncontrolledExpand;

    const { toggleResultPanel, searchResults } = useAttendanceSearch()

    const handleExpand = () => {
      setOpen(true);
      const inputEl = ref.current;
      if (inputEl) inputEl.focus();
    };

    const handleClear = () => {
      setOpen(false);
      onClear && onClear();
      // toggleUserDetailPanel()
    }



    const handleInputFocus = () => {

      const input = document.querySelector('#attendance_search-input-inner');
      if (input && !input.classList.contains('focus')) {
        input.classList.add('focused')
      }
      if (searchResults?.length > 0) toggleResultPanel(true);

    }

    useEffect(() => {
      const handleOutsideClick = (e) => {
        const parent = e.target.closest('#attendance_search-input');
        if (!parent) toggleResultPanel(false)
      }
      document.addEventListener('click', handleOutsideClick)
      return () => document.removeEventListener('click', handleOutsideClick)
    }, [])

    return (

      <div
        id="attendance_search-input"
        className={`search-input ${open || keepExpanded ? "expand-search" : ""} ${value ? "has-value" : ""}`}
        onClick={() => {
          !open && !keepExpanded && handleExpand()
        }}
      >
        <input
          id='attendance_search-input-inner'
          ref={ref}
          type="text"
          placeholder="Search"
          defaultValue={value}
          onChange={(e) => onChange && onChange(e)}
          onBlur={e => {
            const input = document.querySelector('#attendance_search-input-inner');
            if (input) {
              input.classList.remove('focused')
            }
            if (open) {
              setTimeout(() => {
                e.target.value === "" && setOpen(false);
              }, blurDelay);
            }
          }}
          onFocus={handleInputFocus}
          {...restProps}
        />
        {/* <SearchResults /> */}
        {showCloseBtn && <button
          className="icon-close-search"
          onClick={handleClear}
        ></button>}
      </div>
    );
  }
);

export default SearchInput;
