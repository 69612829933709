import { createContext, useContext, useState } from "react";


const AttendanceSearchContext = createContext({
    searchResults: [],
    isSearchCleared: false,
    updateSearchResults: () => { },
    toggleIsSearchCleared: () => { },
    setOnResultClick: () => { },
    onResultClick: null,
    toggleResultPanel: () => { },
    showResultPanel: false,
    lastSearchedResult: null,
    setLastSearchedResult: () => { },
    handleLoadMore: () => { },
    setHandleLoadMore: () => { },
    isFetched: false,
    setIsFetched: () => { }
})

export const AttendanceSearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([])
    const [isSearchCleared, setIsSearchCleared] = useState(false)
    const [onResultClick, updateResultClickHandler] = useState(null)
    const [showResultPanel, setShowResultPanel] = useState(false);
    const [lastSearchedResult, updateLastSearchedResult] = useState(null);
    const [handleLoadMore, setHandleLoadMore] = useState(null);
    const [isFetched, setIsFetched] = useState(false);

    const updateSearchResults = (val) => setSearchResults(val);

    const toggleIsSearchCleared = (val) => setIsSearchCleared(val);

    const setLastSearchedResult = (val) => updateLastSearchedResult(val);

    const setOnResultClick = (val) => {
        updateResultClickHandler(val)
    }

    const toggleResultPanel = (val) => setShowResultPanel(val)

    return (
        <AttendanceSearchContext.Provider value={{ searchResults, isSearchCleared, updateSearchResults, toggleIsSearchCleared, onResultClick, setOnResultClick, showResultPanel, toggleResultPanel, setLastSearchedResult, lastSearchedResult, handleLoadMore, setHandleLoadMore, isFetched, setIsFetched }}>
            {children}
        </AttendanceSearchContext.Provider>
    )
}

export const useAttendanceSearch = () => {
    return useContext(AttendanceSearchContext)
}