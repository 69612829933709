import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PerformanceProvider } from './context';

import { withKeepAlive } from 'components/KeepAlive';
import Home from './Home';
import PerformanceConsole from './PerformanceConsole';

import { clearScrollPositions } from 'hooks/useScrollRestoration';

import { PAGE_IDS } from 'components/Navigation';

import './globalPLStyles.scss';
import './styles.scss';

const PerformanceLog = () => {

    useEffect(() => {
        return () => clearScrollPositions('performance');
    }, []);

    return (
        <div id="performance-console" className="console-wrapper">
            <PerformanceProvider>
                <div className="console-content">
                    <Switch>
                        <Route path="/app/performance/home" exact>
                            <Home />
                        </Route>
                        <Route path="/app/performance/:id">
                            <PerformanceConsole />
                        </Route>
                    </Switch>
                </div>
            </PerformanceProvider>
        </div>
    );
}

export default withKeepAlive(PerformanceLog, PAGE_IDS.PERFORMANCE)