// require('dotenv').config();

export const SITE_TITLE = 'TeamLink';
export const SITE_PREFIX = 'teamLink_';
export const SITE_VERSION = process.env.REACT_APP_VERSION;
export const API_BASE_URL = process.env.REACT_APP_API_URL; //Prod  
export const WIKI_API_BASE_URL = process.env.REACT_APP_WIKI_API_URL; //Prod  
export const WEB_SOCKET_URL = process.env.REACT_APP_SOCKET_URL; //Prod
export const ENVIRONMENT = process.env.REACT_APP_CUSTOM_NODE_ENV; //Prod
export const LOADING_ICON = 'images/icon-loading.gif';
export const SUPPORT_EMAIL = 'isupport@teamlink.com';

// export const EXTENSION_LINK = 'https://workforce-chrome-extension.s3.us-east-2.amazonaws.com/chromeextension.tar.gz';