import React, { useState, useRef, useEffect, useCallback } from 'react';

import Switch from 'components/Switch/Switch';

import { addShakeEffectToEl, formatTime, removeShakeEffectFromEl } from '../timerUtils';
import { accurateInterval, isElectronApp } from 'utils/Common';
import { resumeShift, useTimerStore, recoverLostTime } from "../store";
import { useOnline } from 'hooks/useOnline';

import ActivityTrackerSubmitErrorIcon from './ActivityTrackerSubmitErrorIcon';
import './InactivityPopup.scss';

const InactivityPopup = ({ disableTrackerOptions, inactivityPopupContent, closeInactivityPopup }) => {
    const { timerSettings, trackerDisabledTotalMins, isUserOnAdhocShift } = useTimerStore();
    const { msg } = inactivityPopupContent;
    const [selectedActivity, setSelectedActivity] = useState('');
    const [disableActivityReason, setDisableActivityReason] = useState('');
    const [selectedDisableActivityInMins, setSelectedDisableActivityInMins] = useState('');
    const [disableActivityInputMins, setDisableActivityInputMins] = useState(0);
    const [showTimeSelection, setShowTimeSelection] = useState(false);
    const intervalIDRef = useRef(null);
    const [disableOptions, setDisableOptions] = useState(inactivityPopupContent.currentLostTime >= timerSettings.max_recovery_time_allowed_seconds);
    const [currentLostTimeInSeconds, setCurrentLostTimeInSeconds] = useState(inactivityPopupContent.currentLostTime);
    const currentLostTimeInSecondsRef = useRef(null);
    currentLostTimeInSecondsRef.current = currentLostTimeInSeconds;

    const handleDisableActivityMinsSelect = ({ noOfMins = 0 } = {}) => {
        setSelectedDisableActivityInMins(oldNoOfMins => oldNoOfMins === noOfMins ? '' : noOfMins);
        disableActivityInputMins && setDisableActivityInputMins(0);
    }

    const getDifferenceInSeconds = () => {
        const enabledInTime = timerSettings.max_recovery_time_allowed_seconds - currentLostTimeInSeconds;
        return <span className={"recover-lost-time-in bold-value" + (enabledInTime <= 0 ? ' red' : '')}>{formatTime(enabledInTime < 0 ? 0 : enabledInTime)}</span>
    }

    const updateLostTime = useCallback(() => {
        console.log('updateLostTime called', currentLostTimeInSecondsRef.current)
        if (currentLostTimeInSecondsRef.current >= timerSettings.max_recovery_time_allowed_seconds) {
            setDisableOptions(true);
            intervalIDRef.current?.cancel()
        } else {
            setCurrentLostTimeInSeconds(oldCurrentLostTimeInSeconds => oldCurrentLostTimeInSeconds + 1);
        }
    }, [])

    useEffect(() => {
        let intervalID = '';
        console.log('currentLostTimeInSecondsRef.current', currentLostTimeInSecondsRef.current)
        if (currentLostTimeInSecondsRef.current < timerSettings.max_recovery_time_allowed_seconds) {
            updateLostTime()
            intervalID = accurateInterval(() => {
                updateLostTime()
            }, 1000);
        }
        intervalIDRef.current = intervalID;
        return () => {
            if (intervalID) intervalID?.cancel();
        }
    }, [timerSettings, updateLostTime])

    const handleAddMins = (id) => {
        let e = document.getElementById(id);
        let updatedValue = +e.value + 1;
        removeShakeEffectFromEl(e);
        if (updatedValue > timerSettings.max_disable_activity_tracker_allowed_mins) {
            addShakeEffectToEl(e);
            return;
        } else {
            if (id === 'disable-activity-input') {
                handleDisableActivityInputChange(updatedValue)
                return;
            }
            e.value = updatedValue;
        }
    }

    const handleSubtractMins = (id) => {
        let e = document.getElementById(id);
        removeShakeEffectFromEl(e);
        let updatedValue = +e.value - 1;
        if (updatedValue > 0) {
            if (id === 'disable-activity-input') {
                handleDisableActivityInputChange(updatedValue)
                return;
            }
            e.value = updatedValue;
        } else {
            addShakeEffectToEl(e);
        }
    }

    const handleDisableActivityInputChange = (e) => {
        selectedDisableActivityInMins && setSelectedDisableActivityInMins('');
        // setDisableActivityInputMins(() => isNaN(e) ? e?.target?.value : e)
        setDisableActivityInputMins((oldVal) => {
            if (oldVal === 0 && isNaN(e) && (+e.target.value % 10) === 0) {
                return +e.target.value / 10;
            }
            return (isNaN(e) ? +e?.target?.value : +e)
        });
    }

    const handleInputKeyDown = (e) => {
        let currentValue = isNaN(+e.key) ? "" : +e.key;
        removeShakeEffectFromEl(document.getElementById(e.target.id));
        let finalValue = e.target.value + currentValue;
        if ((e.key === 'ArrowUp' && finalValue >= timerSettings.max_disable_activity_tracker_allowed_mins) || (e.key === 'ArrowDown' && finalValue <= 1 && finalValue !== '')) {
            addShakeEffectToEl(document.getElementById(e.target.id));
        }
        if (
            ((+finalValue > timerSettings.max_disable_activity_tracker_allowed_mins) &&
                !["Backspace", "Delete"].includes(e.key) &&
                !e.key.includes("Arrow")) ||
            ["e", "E", "-", "+", "."].includes(e.key)
        ) {
            e.preventDefault();
        }
    }

    const disableSubmitButton = () => {
        if (!showTimeSelection) {
            return disableActivityReason.trim().length === 0;
        } else {
            return disableActivityReason.trim().length === 0 || (!selectedDisableActivityInMins && !disableActivityInputMins)
        }
    }

    const [disableActivityTrackerSubmitError, setDisableActivityTrackerSubmitError] = useState('');

    function handleRecoverLostTime() {
        let afkInMins = disableActivityInputMins ? parseInt(disableActivityInputMins) : selectedDisableActivityInMins;

        if (showTimeSelection) {
            const maxAllowedTime =
                timerSettings.max_disable_activity_tracker_allowed_mins;
            const totalDisabledMins = trackerDisabledTotalMins;
            const totalMins = Math.ceil(Number(afkInMins) + totalDisabledMins);

            if (totalMins > maxAllowedTime) {
                setDisableActivityTrackerSubmitError(
                    `Activity tracker: ${maxAllowedTime} min/day limit, ${Math.max(
                        maxAllowedTime - totalDisabledMins,
                        0
                    )} min left.`
                );
                return;
            }
        }

        recoverLostTime({
            activityId: selectedActivity.id,
            reason: disableActivityReason,
            disableTracker: showTimeSelection,
            afkInMins,
        });
    }

    const isOnline = useOnline();

    useEffect(() => {
        if (!isElectronApp) {
            const state = useTimerStore.getState();
            const date = new Date(state.lastClockInTime + " GMT");
            date.setDate(date.getDate() + 1);
            const setInactivityPopupCloseTimeout = () => {
                const ms = date.getTime() - new Date().getTime();
                return setTimeout(closeInactivityPopup, ms);
            };

            const sleepThreshold = 2000; // threshold in milliseconds, e.g., 2 seconds
            let lastTime = Date.now();
            let timeoutId = setInactivityPopupCloseTimeout();
            let intervalId = setInterval(() => {
                const currentTime = Date.now();
                const diff = currentTime - lastTime;
                if (diff > sleepThreshold) {
                    // console.log("The computer probably went to sleep.");
                    clearTimeout(timeoutId);
                    timeoutId = setInactivityPopupCloseTimeout();
                }

                lastTime = currentTime;
            }, 1000);

            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        }
    }, []);

    return (
        <div id='inactivity-popup-overlay'>
            <div className='inactivity-popup-wrapper timer-options-wrapper'>
                {!isOnline && (
                    <div className="no-online-overlay">
                        <p>No internet connection.</p>
                    </div>
                )}
                <div className='big-wrapper'>
                    <div className='inactivity-title-header'>
                        <div className='inactivity-title-wrapper'>
                            <div className='timer-icon'></div>
                            <p className="timer-label">{msg || 'Timer paused due to inactivity.'}</p>
                        </div>
                        <span className='icon-cross' onClick={closeInactivityPopup}></span>
                    </div>
                    <div className={"timer-section" + (disableOptions ? ' time-out' : '')}>
                        <div className="separator"></div>
                        <div className='timer-section-inner'>
                            <div className='recover-lost-time-wrapper'>
                                <div className='current-inactive-details'>
                                    <span className="timer-label">Recover current lost time</span>
                                    <span className="bold-value">{formatTime(currentLostTimeInSeconds)}</span>
                                </div>
                                <div className="recover-lost-time-in-wrapper">
                                    <span className="timer-label">Recover in</span>
                                    {getDifferenceInSeconds()}
                                </div>
                            </div>
                            <div className="activity-tracker">
                                {disableTrackerOptions.map((value) => {
                                    return (
                                        <div
                                            key={value.id}
                                            className={"timer-options capitalize" + (selectedActivity.name === value.name ? " active" : "")}
                                            onClick={() => setSelectedActivity(oldValue => oldValue.name === value.name ? '' : value)}
                                        >
                                            {value.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {selectedActivity && (
                            <div className="selected-activity-wrapper">
                                <textarea
                                    placeholder="Reason"
                                    className="reason-input"
                                    value={disableActivityReason}
                                    onChange={(e) => setDisableActivityReason(e.target.value)}
                                />
                                {!isUserOnAdhocShift && <div className='switch-wrapper'>
                                    <p className='timer-label'>Disable activity tracker</p>
                                    <Switch
                                        isOn={showTimeSelection}
                                        handleToggle={() => {
                                            setShowTimeSelection(!showTimeSelection);
                                            setDisableActivityTrackerSubmitError('');
                                        }}
                                    />
                                </div>}
                                {showTimeSelection && <div className="multiple-options-wrapper">
                                    {[5, 10, 15, 30, 45, 60].map((noOfMins) => (
                                        <div
                                            key={noOfMins}
                                            className={"timer-options" + (selectedDisableActivityInMins === noOfMins ? " active" : "")}
                                            onClick={() => handleDisableActivityMinsSelect({ noOfMins })}
                                        >
                                            {noOfMins}
                                        </div>
                                    ))}
                                    <div className="pipe"></div>
                                    <div className="mins-input">
                                        <button className="btn-arithmetic btn-minus" onClick={() => handleSubtractMins('disable-activity-input')}>
                                        </button>
                                        <input
                                            type="number"
                                            min="1"
                                            max={timerSettings.max_disable_activity_tracker_allowed_mins}
                                            id="disable-activity-input"
                                            name="disable-activity-input"
                                            className="user-input"
                                            value={Number(disableActivityInputMins).toString()}
                                            onChange={handleDisableActivityInputChange}
                                            onKeyDown={handleInputKeyDown}
                                        />
                                        <button className="btn-arithmetic btn-plus" onClick={() => handleAddMins('disable-activity-input')}>
                                        </button>
                                    </div>
                                </div>}
                                {disableActivityTrackerSubmitError && (
                                    <div className="activity-tracker-limit-error-wrapper">
                                        <ActivityTrackerSubmitErrorIcon />
                                        <p className="timer-label" style={{ fontSize: '11px' }}>{disableActivityTrackerSubmitError}</p>
                                    </div>
                                )}
                                <div
                                    className={
                                        "timer-options timer-options-large btn-submit" +
                                        (disableSubmitButton() ? " btn-submit-disabled" : "")
                                    }
                                    onClick={handleRecoverLostTime}
                                >
                                    Submit
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="timer-section">
                        <div className="separator"></div>
                        <div className='timer-section-inner'>
                            <p className="timer-label">Accept un-notified team absence.</p>
                            <div
                                className="timer-options timer-options-large"
                                onClick={() => resumeShift()}
                            >
                                Resume Shift
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InactivityPopup