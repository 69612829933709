import React, { useState, useEffect, useRef } from 'react';
import * as Constants from 'components/Constants';
import SpeedSelect from 'react-speedselect';
import ClickOutsideListner from 'components/ClickOutsideListner';
import Avatar from 'components/Avatar';
import APIService from "services/apiService";
import { alertService } from 'services/alertService';
import CreateStage from '../CreateStage';
import { getUser } from "utils/Common";
import Advanced from './Advanced';
import './styles.scss';

const CreateFunnel = (props) => {

  // Check if all mandatory fields are filled
  let isStep1Invalid = props.funnelInfo.step === 1 && !(props.funnelInfo.name && props.funnelInfo.department && props.funnelInfo.designation);
  let isStep2Invalid = props.funnelInfo.step === 2 && !props.funnelInfo.stages.every(item => item.name);

  const [jobInfo, setJobInfo] = useState({
    title: '',
    description: '',
    employment: {},
    location: {},
  })

  const userInfo = getUser(); // Get logged user info from local storage
  const stagesEndRef = useRef(null);
  let stages = props.funnelInfo.stages.map(
    (item, index, arr) => {
      return <CreateStage
        key={index}
        arr={arr}
        // myRef={stagesEndRef}
        {...(item.newStage && { myRef: stagesEndRef })}
        index={index}
        id={props.funnelInfo.id}
        funnelId={props.funnelInfo?.funnelId}
        stageId={item.id}
        name={item.name}
        description={item.description}
        title={'Stage ' + ++index}
        onChange={props.handleFunnelData}
        onDelete={props.handleFunnelData}
        onBlur={props.handleModifyFunnel}
        countResources={props.funnelInfo.mode === 'edit' ? item.countResources : 0}
        mode={props.funnelInfo.mode}
        isQuestionnaire={item.isQuestionnaire}
        is_archive={props.funnelInfo.is_archive}
        jobInfo={jobInfo}
      />
    }
  );

  // State variables for share tab
  const [loadingOrganisations, setLoadingOrganisations] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [organisationList, setOrganisationList] = useState(null);
  const [userList, setUserList] = useState(null);
  const [shareShowNewForm, setShareShowNewForm] = useState(false);
  const [shareNewFormSelectedOrganisation, setShareNewFormSelectedOrganisation] = useState(null);
  const [shareNewFormSelectedUsers, setShareNewFormSelectedUsers] = useState([]);
  const [shareNewFormSelectedAuthorizations, setShareNewFormSelectedAuthorizations] = useState(['VIEW']);
  const [shareNewOrgSearch, setShareNewOrgSearch] = useState('');
  const [shareNewUserSearch, setShareNewUserSearch] = useState('');
  const [loadingSharedUsers, setLoadingSharedUsers] = useState(false);
  const [sharedUsers, setSharedUsers] = useState(props.funnelInfo.shared);
  const [shareEditPopupUserID, setShareEditPopupUserID] = useState(null);
  const [shareEditUserInfo, setShareEditUserInfo] = useState(null);
  const [shareEditSelectedAuthorizations, setShareEditSelectedAuthorizations] = useState([]);
  const [sharingInProcess, setSharingInProcess] = useState(false);

  // Function to focus newly added stage
  const scrollToBottom = () => {
    stagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {
    scrollToBottom();
  }, [props.funnelInfo.stages]);

  useEffect(() => {
    setSharedUsers(props.funnelInfo.shared);
    return () => {
      setSharedUsers(null);
      setShareNewFormSelectedUsers([]);
      setShareNewFormSelectedAuthorizations(['VIEW']);
      setShareNewOrgSearch('');
      setShareNewUserSearch('');
    };
  }, [props.funnelInfo.shared]);

  // Share Tab Methods

  const handleShareNewBtnClick = () => {
    if (props.funnelInfo.mode === 'edit' && !props.funnelInfo.privileges.includes('SHARE')) {
      alertService.warning('You are not authorized for sharing this funnel');
      return;
    }
    setShareShowNewForm(true);
    if (!organisationList) {
      getOrganisationList();
    } else {
      // organisation list already available, just select the first organisation by default and fetch the user of that organisation
      setShareNewFormSelectedOrganisation(organisationList[0]);
      if (!userList) {
        getUserListOfOrganisation(organisationList[0].id);
      }
    }
  }

  const handleShareEditAuthSelect = (auth) => {
    const currAuths = shareEditSelectedAuthorizations;
    const newAuths = currAuths.includes(auth) ? currAuths.filter(a => a !== auth) : [...currAuths, auth];
    setShareEditSelectedAuthorizations(newAuths);
  }

  const handleShareEditBtn = (shareInfo) => {
    setShareEditUserInfo(shareInfo);
    setShareEditSelectedAuthorizations(shareInfo.privileges.split(','));
  }

  const handleShareEditDeleteBtn = (shareId) => {
    setSharingInProcess(true);
    APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/share/${shareId}`, {}, false, 'DELETE')
      .then(response => {
        if (response.status === 1) {
          let updatedSharedData = sharedUsers.filter(s => s.id !== shareId)
          setSharingInProcess(false);
          setSharedUsers(updatedSharedData);
          props.handleFunnelData(props.funnelInfo.id, 'updateShare', updatedSharedData);
          alertService.success('Access Revoked successfully');
          setShareEditPopupUserID(null);
        } else {
          alertService.warning(`Access revoke error: ${response.msg}`);
          setSharingInProcess(false);
        }
      })
      .catch(err => {
        setSharingInProcess(false);
        console.log('Error occured in Revoking share : ' + err.message);
      });
  }

  const handleShareEditSubmitBtn = () => {
    let payload = {
      recruitment_funnel_id: props.funnelInfo.funnelId,
      privileges: shareEditSelectedAuthorizations.toString(),
      user_ids: [shareEditUserInfo.user.id],
    };

    setSharingInProcess(true);
    APIService.apiRequest(Constants.API_BASE_URL + '/recruiter/share', payload, false, 'PUT')
      .then(response => {
        if (response.status === 1) {
          alertService.success('Access edited successfully');
          // update the sharedInfo in sharedUser list
          let updatedSharedData = sharedUsers.map(u => {
            if (u.user.id === payload.user_ids[0]) {
              return {
                ...u,
                privileges: payload.privileges,
              };
            }
            return u;
          })
          setSharedUsers(updatedSharedData);
          props.handleFunnelData(props.funnelInfo.id, 'updateShare', updatedSharedData);
          // reset some other variables
          setSharingInProcess(false);
          setShareEditUserInfo(null);
          setShareEditSelectedAuthorizations([]);
        } else {
          alertService.warning(`Access edit error: ${response.msg}`);
          setSharingInProcess(false);
        }
      })
      .catch(err => {
        setSharingInProcess(false);
        console.log('Error occured in Editing share config: ' + err.message);
      });
  }

  const handleShareEditCancelBtn = () => {
    setShareEditUserInfo(null);
  }

  const getOrganisationList = () => {
    let payload = null;
    setLoadingOrganisations(true);

    APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/organization`, payload, false, 'GET')
      .then(response => {
        if (response.status === 1) {
          setOrganisationList(response.output);
          setShareNewFormSelectedOrganisation(response.output[0]);
          setLoadingOrganisations(false);
          getUserListOfOrganisation(response.output[0].id);
        } else {
          setLoadingOrganisations(false);
          alertService.warning(`getOrganisationList error: ${response.msg}`);
          console.log(response);
        }
      })
      .catch(err => {
        setLoadingOrganisations(false);
        console.log('Error occured in fetching organisation list ' + err.message);
      });

  }

  const getUserListOfOrganisation = (orgId) => {
    setLoadingUsers(true);
    APIService.apiRequest(Constants.API_BASE_URL + `/user/${orgId}`, {}, false, 'GET')
      .then(response => {
        if (response.status === 1) {
          setLoadingUsers(false);
          setShareNewUserSearch('');
          // remove self from user list
          setUserList(response.output.filter(u => u.user_id !== userInfo.id));
        } else {
          alertService.warning(`getUserListOfOrganisation error: ${response.msg}`);
          setLoadingUsers(false);
        }
      })
      .catch(err => {
        setLoadingUsers(false);
        console.log('Error occured in fetching user list: ' + err.message);
      });
  }

  const handleShareOrganisationSelect = (org) => {
    setShareNewFormSelectedOrganisation(org);
    setShareNewFormSelectedUsers([]);
    // fetch the user for selected organisation
    getUserListOfOrganisation(org.id);
  }

  const handleShareUsersSelect = (user) => {
    const currSelectedUsers = shareNewFormSelectedUsers;
    let newSelectedUsers = [];
    if (user !== 'all') {
      newSelectedUsers = currSelectedUsers.some(u => u.user_id === user.user_id) ? currSelectedUsers.filter(u => u.user_id !== user.user_id) : [...currSelectedUsers, user];
    } else {
      newSelectedUsers = currSelectedUsers.length === userList.length ? [] : [...userList];
    }

    setShareNewFormSelectedUsers(newSelectedUsers)
  }

  const handleShareNewAuthSelect = (auth) => {
    const currAuths = shareNewFormSelectedAuthorizations;
    const newAuths = currAuths.includes(auth) ? currAuths.filter(a => a !== auth) : [...currAuths, auth];
    setShareNewFormSelectedAuthorizations(newAuths);
  }

  const handleShareNewFormSubmit = () => {
    if (!shareNewFormSelectedOrganisation) {
      alertService.error('Please select an Organisation');
      return;
    }
    if (shareNewFormSelectedUsers.length === 0) {
      alertService.error('Users must be selected');
      return;
    }

    let payload = {
      recruitment_funnel_id: props.funnelInfo.funnelId,
      privileges: shareNewFormSelectedAuthorizations.toString(),
      user_ids: shareNewFormSelectedUsers.map(user => user.user_id),
    };

    setSharingInProcess(true);
    APIService.apiRequest(Constants.API_BASE_URL + '/recruiter/share', payload, false, 'POST')
      .then(response => {
        if (response.status === 1) {
          alertService.success('Funnel shared successfully');

          setSharingInProcess(false);
          // reset form variables
          setShareNewFormSelectedOrganisation(null);
          setShareNewFormSelectedUsers([]);
          setShareNewFormSelectedAuthorizations(['VIEW']);
          setShareShowNewForm(false);
          // props.updateSharedInfo(props.funnelInfo.funnelId);
          // Refresh the share list
          getSharedUsersList(props.funnelInfo.funnelId);
        } else {
          alertService.warning(`funnel share error: ${response.msg}`);
          setSharingInProcess(false);
        }
      })
      .catch(err => {
        setSharingInProcess(false);
        console.log('Error occured in Sharing funnel: ' + err.message);
      });
  }

  const handleShareNewFormReset = () => {
    setShareNewFormSelectedOrganisation(organisationList[0]);
    setShareNewFormSelectedUsers([]);
    setShareNewFormSelectedAuthorizations(['VIEW']);
    setShareNewOrgSearch('');
    setShareNewUserSearch('');
  }

  const handleShareNewFormCancel = () => {
    handleShareNewFormReset();
    setShareShowNewForm(false);
  }

  const getSharedUsersList = (funnelId) => {
    let apiPayLoad = null;
    setLoadingSharedUsers(true);
    APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/share/${funnelId}`, apiPayLoad, false, 'GET')
      .then(response => {
        if (response.status === 1) {
          setSharedUsers(response.data);
          setLoadingSharedUsers(false);
          props.handleFunnelData(props.funnelInfo.id, 'updateShare', response.data);
        } else {
          alertService.warning(`getSharedUsersList error: ${response.msg}`);
          console.log('getSharedUsersList else', response);
          setLoadingSharedUsers(false);
        }
      })
      .catch(err => {
        alertService.error(`Error: ${err.msg}`);
        console.log('error', err);
        setLoadingSharedUsers(false);
      });
  }

  // Share Tab HTML

  // List shared users
  const giveConsoleContentHtmlForShareTab = () => {
    const orgSearchInput = shareNewOrgSearch.trim();
    const userSearchInput = shareNewUserSearch.trim();
    const orgList = orgSearchInput ? organisationList.filter(o => o.name.toLowerCase().includes(orgSearchInput.toLowerCase())) : organisationList;
    const localUserList = userSearchInput ? userList.filter(u => ((u.first_name).toLowerCase().includes(userSearchInput.toLowerCase()) || (u.last_name && u.last_name.toLowerCase().includes(userSearchInput.toLowerCase())))) : userList;

    // Note - In case sharedUser list has been fetched already and that list is empty, we want to restrict the user to be able to see only the invite form
    // To avoid handling this restrictions from multiple places, this has been done in render method itself
    if (!shareShowNewForm && sharedUsers && !sharedUsers.length) {
      handleShareNewBtnClick();
      return;
    }

    return (
      <div id="share-settings" className="dashboaord-tab-content">
        {(props.funnelInfo.loadingInfo || loadingSharedUsers) && <p>Loading Shared Users...</p>}

        {!props.funnelInfo.loadingInfo && !loadingSharedUsers && sharedUsers && !shareShowNewForm &&
          <>
            <div className="shared-users">
              {sharedUsers.map((shareInfo, index) => {
                // console.log('giveConsoleContentHtmlForShareTab shareInfo', shareInfo, shareEditUserInfo)
                const isEditing = shareEditUserInfo ? shareEditUserInfo.id === shareInfo.id : false;
                return (
                  <div key={shareInfo.id} className={'shared-user' + (isEditing ? ' shared-user-editing' : '')}>
                    <div className="shared-user-info">
                      <Avatar
                        imgSrc={shareInfo.user.cover_img_url}
                        alt={`${shareInfo.user.first_name}'s pic`}
                        firstName={shareInfo.user.first_name}
                        lastName={shareInfo.user.last_name}
                        height={28}
                        width={28}
                        fontSize={12}
                      />
                      <h4>{shareInfo.user.first_name + ' ' + (shareInfo.user.last_name || '')}</h4>
                    </div>
                    <div className="org-info">
                      <div className="label">Organisation : </div>
                      <div className="value">{shareInfo.user.organization}</div>
                    </div>
                    <div className="previleges">
                      <div className="label">Authorization : </div>
                      <ul>
                        {shareInfo.privileges.split(',').map((p, i) =>
                          <li key={shareInfo.id + '_' + i}><span className="item">{p[0] + p.slice(1).toLowerCase()}</span></li>
                        )}
                      </ul>
                    </div>

                    {/* {!props.funnelInfo.is_archive && ... }*/}
                    {(props.funnelInfo.mode === 'create' || props.funnelInfo.privileges.includes('SHARE')) &&
                      <span className="pop-over-btn menu-btn" onClick={() => setShareEditPopupUserID(shareInfo.user.id)}>
                        {shareEditPopupUserID === shareInfo.user.id &&
                          <ClickOutsideListner onOutsideClick={() => setShareEditPopupUserID(null)}>
                            <div className="pop-over-options-wrapper">
                              <ul className="pop-over-options">
                                <li className={sharingInProcess ? 'disabled' : ''} onClick={(e) => { e.stopPropagation(); setShareEditPopupUserID(null); handleShareEditBtn(shareInfo) }}>Edit</li>
                                <li className={sharingInProcess ? 'disabled' : ''} onClick={(e) => { e.stopPropagation(); handleShareEditDeleteBtn(shareInfo.id) }}>Revoke Access</li>
                              </ul>
                            </div>
                          </ClickOutsideListner>
                        }
                      </span>
                    }
                    {isEditing &&
                      <div className="edit-form">
                        <div className="panel-widget auths">
                          <div className="widget-header">
                            <div className="widget-title">Authorization</div>
                          </div>
                          <div className="widget-content">
                            <ul className="auth-list">
                              {['VIEW', 'EDIT', 'COMMENT', 'SHARE', 'DELETE'].map(auth => {
                                const checked = shareEditSelectedAuthorizations.includes(auth);
                                const disabled = auth === 'VIEW';
                                return (
                                  <li key={auth} className={'auth-item'}>
                                    <div className="option checkbox">
                                      <input id={`${props.funnelInfo.funnelId}-share-${auth}`} type="checkbox"
                                        checked={checked}
                                        disabled={disabled}
                                        onChange={() => handleShareEditAuthSelect(auth)} />
                                      <label htmlFor={`${props.funnelInfo.funnelId}-share-${auth}`}>
                                        {auth[0] + auth.slice(1).toLowerCase()}</label>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>

                        <div className="action-btns">
                          <button className="btn btn-small" onClick={handleShareEditSubmitBtn} disabled={sharingInProcess}><i></i><span>Update</span></button>
                          <button className="btn btn-small" onClick={handleShareEditCancelBtn} disabled={sharingInProcess}><i></i><span>Cancel</span></button>
                        </div>
                      </div>
                    }
                  </div>
                );
              })}
            </div>
          </>
        }

        {shareShowNewForm &&
          getShareInviteForm(orgList, orgSearchInput, localUserList, userSearchInput)
        }
      </div>
    );
  }

  // get Share Invite Form
  const getShareInviteForm = (orgList, orgSearchInput, localUserList, userSearchInput) => {
    // console.log('getShareInviteForm called');
    return (
      <div className="share-new-form">
        {loadingOrganisations && <p>Loading Organisations ....</p>}
        {orgList &&
          <>
            <div className="panel-widget orgs-list-container">
              <div className="widget-header">
                <div className="widget-title">Organisation</div>
                <div className="gl-search">
                  <input value={shareNewOrgSearch} onChange={e => setShareNewOrgSearch(e.target.value)} />
                </div>
              </div>
              <div className="widget-content">
                <div className="list orgs-list">
                  {orgList.map((org) => {
                    const selected = shareNewFormSelectedOrganisation ? shareNewFormSelectedOrganisation.id === org.id : false;
                    return (
                      <div key={org.id} className="option radio">
                        <input id={`${props.funnelInfo.funnelId}-org-${org.id}`} type="radio" name="share-new-org" value={org.id} checked={selected} onChange={() => handleShareOrganisationSelect(org)} />
                        <label htmlFor={`${props.funnelInfo.funnelId}-org-${org.id}`}>{org.name}</label>
                      </div>
                    )
                  })}
                  {orgSearchInput && !orgList.length && <p className="no-match-msg">No Match Found</p>}
                </div>
              </div>
            </div>

            {loadingUsers && userList === null && <p>Loading Users ....</p>}

            {userList !== null && shareNewFormSelectedOrganisation &&
              <div className={'panel-widget user-list-container' + (loadingUsers ? ' disabled' : '')}>
                <div className="widget-header">
                  <div className="widget-title">Users
                    {shareNewFormSelectedUsers.length ? <label>Selected <span>{shareNewFormSelectedUsers.length}</span></label> : null}
                  </div>
                  <div className="gl-search">
                    <input value={shareNewUserSearch} onChange={e => setShareNewUserSearch(e.target.value)} />
                  </div>
                </div>
                <div className="widget-content">
                  <div className="list user-list">
                    {localUserList.length > 0 && <div key='all' className={'option checkbox'}>
                      <input id={`${props.funnelInfo.funnelId}-user-all`} type="checkbox" name="share-new-user" checked={shareNewFormSelectedUsers.length === userList.length} value={'all'} onChange={() => handleShareUsersSelect('all')} />
                      <label htmlFor={`${props.funnelInfo.funnelId}-user-all`}>
                        <div className="user-avtar"></div>
                        All
                      </label>
                    </div>}

                    {localUserList.map((user) => {
                      const selected = shareNewFormSelectedUsers.length ? shareNewFormSelectedUsers.some(u => u.user_id === user.user_id) : false;
                      const alreadyShared = sharedUsers.some(s => s.user.id === user.user_id);
                      return (
                        <div key={user.user_id} className={'option checkbox' + (alreadyShared ? ' disabled' : '')}>
                          <input id={`${props.funnelInfo.funnelId}-user-${user.user_id}`} type="checkbox" name="share-new-user" value={user.user_id} checked={selected} onChange={() => handleShareUsersSelect(user)} />
                          <label htmlFor={`${props.funnelInfo.funnelId}-user-${user.user_id}`}>
                            <Avatar
                              imgSrc={user.cover_img_url}
                              alt={`${user.first_name}'s pic`}
                              firstName={user.first_name}
                              lastName={user.last_name}
                              height={20}
                              width={20}
                              fontSize={8}
                            />
                            <span className='user-info'>{`${user.first_name} ${user.last_name || ''}`} {alreadyShared ? '(Already shared)' : ''}</span>
                          </label>
                        </div>
                      )
                    })}
                    {userSearchInput && !localUserList.length && <p className="no-match-msg">No Match Found</p>}
                  </div>
                </div>
              </div>
            }

            {userList !== null &&
              <div className="panel-widget auths">
                <div className="widget-header">
                  <div className="widget-title">Authorization</div>
                </div>
                <div className="widget-content">
                  <ul className="auth-list">
                    {['VIEW', 'EDIT', 'COMMENT', 'SHARE', 'DELETE'].map(auth => {
                      const checked = shareNewFormSelectedAuthorizations.includes(auth);
                      const disabled = auth === 'VIEW';
                      return (
                        <li key={auth} className={'auth-item'}>
                          <div className="option checkbox">
                            <input id={`${props.funnelInfo.funnelId}-share-${auth}`} type="checkbox"
                              checked={checked}
                              disabled={disabled}
                              onChange={() => handleShareNewAuthSelect(auth)} />
                            <label htmlFor={`${props.funnelInfo.funnelId}-share-${auth}`}>{auth[0] + auth.slice(1).toLowerCase()}</label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <span></span>
              </div>
            }
          </>
        }
      </div>
    );
  }

  return (
    <div id='create-funnel-wrapper'>
      <ClickOutsideListner onOutsideClick={() => props.funnelInfo.mode === 'edit' && props.setShowSettings(false)}>
        <div className='create-funnel-dialog'>
          <div className='form-tabs'>
            <div className='form-title'>
              Funnel Settings
            </div>
            <ul className='form-tab-list'>
              <li className={props.funnelInfo.step === 1 ? 'selected' : ''}
                onClick={() => {
                  if (props.funnelInfo.mode === 'edit' && !props.funnelInfo.privileges.includes('EDIT')) {
                    alertService.warning('You are not authorized for editing this funnel');
                    return;
                  }
                  if (props.funnelInfo.mode === 'edit' && props.funnelInfo.step === 2 && isStep2Invalid) {
                    alertService.warning('Please fill all the required information');
                    return;
                  } else {
                    props.handleFunnelData(props.funnelInfo.id, 'navToFunInfo');
                  }
                }}
              >
                Basic
              </li>
              <li className={props.funnelInfo.step === 2 ? 'selected' : ''}
                onClick={() => {
                  if (props.funnelInfo.mode === 'edit' && !props.funnelInfo.privileges.includes('EDIT')) {
                    alertService.warning('You are not authorized for editing this funnel');
                    return;
                  }
                  if (props.funnelInfo.step === 1 && isStep1Invalid) {
                    alertService.warning('Please fill all the required information');
                    return;
                  } else {
                    props.handleFunnelData(props.funnelInfo.id, 'navToDefStages')
                  }
                }}
              >
                Stages
              </li>
              <li className={props.funnelInfo.step === 3 ? 'selected' : ''}
                onClick={() => {
                  // Allow navigating to share tab but cannot add/modify any provilege
                  // if (props.funnelInfo.mode === 'edit' && !props.funnelInfo.privileges.includes('SHARE')) {
                  //   alertService.warning('You are not authorized for sharing this funnel');
                  //   return;
                  // }
                  if (!props.funnelInfo.isFunnelCreated) {
                    alertService.warning('Funnel must be created before sharing')
                    return;
                  }
                  if (isStep1Invalid || isStep2Invalid) {
                    alertService.warning('Please fill all the required information');
                    return;
                  } else {
                    props.handleFunnelData(props.funnelInfo.id, 'navToShare')
                  }
                }}
              >
                Share
              </li>
              <li className={props.funnelInfo.step === 4 ? 'selected' : ''}
                onClick={() => {
                  if (!props.funnelInfo.isFunnelCreated) {
                    alertService.warning('Funnel must be created before navigating to advanced')
                    return;
                  }

                  props.handleFunnelData(props.funnelInfo.id, 'navToAdvanced')
                }}
              >
                Advanced
              </li>
            </ul>
          </div>
          <div className='form-content'>

            {/* Section 1 */}
            {props.funnelInfo.loadingInfo && props.funnelInfo.step === 1 && <p style={{ textAlign: 'center' }}>Loading Information...</p>}
            {props.funnelInfo.loadingError && props.funnelInfo.step === 1 && <p style={{ textAlign: 'center' }}>Unable to load Information</p>}

            {!props.funnelInfo.loadingInfo && !props.funnelInfo.loadingError && props.funnelInfo.step === 1 && <div className='content-setting'>
              <div className="name field-with-label">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  className={'field-control' + (props.funnelInfo.is_archive ? ' input-field-disabled' : '')}
                  name="name"
                  id="name"
                  value={props.funnelInfo.name}
                  disabled={props.funnelInfo.is_archive === 1}
                  onChange={(e) => props.handleFunnelData(props.funnelInfo.id, 'name', e.target.value)}
                  onBlur={(e) => {
                    props.funnelInfo.mode === 'edit' && props.handleModifyFunnel('name');
                    props.funnelInfo.mode === 'create' && e.target.value !== '' && props.handleTabNameChange(props.funnelInfo.id, e.target.value);
                  }}
                  maxLength="45"
                  required
                /><span className='textLength'>{`${props.funnelInfo.name.length}/45`}</span>
              </div>
              <div className="description field-with-label">
                <label htmlFor="funnelDesc">Description</label>
                <textarea
                  className={'field-control textarea' + (props.funnelInfo.is_archive ? ' input-field-disabled' : '')}
                  name="funnelDescription"
                  id='funnelDesc'
                  value={props.funnelInfo.description}
                  disabled={props.funnelInfo.is_archive}
                  onChange={(e) => props.handleFunnelData(props.funnelInfo.id, 'desc', e.target.value)}
                  onBlur={(e) => props.funnelInfo.mode === 'edit' && props.handleModifyFunnel('desc')}
                  rows="3"
                  maxLength="150"
                ></textarea>
                <span className='textLength'>{`${props.funnelInfo.description.length}/150`}</span>
              </div>

              <div className='dropdown-container'>
                <div>
                  <SpeedSelect
                    options={props.departmentsList}
                    selectedOption={props.departmentsList.length > 0 ? props.funnelInfo.department : ''}
                    onSelect={(deptValue) => props.handleFunnelData(props.funnelInfo.id, 'dept', deptValue)}
                    displayKey='name'
                    uniqueKey='id'
                    maxHeight={120}
                    hideOkCancelBtns
                    selectLabel='Select'
                    prominentLabel='Department*'
                    disable={props.funnelInfo.mode === 'edit' || props.departmentsList.length === 0}
                  />
                </div>
                <div>
                  <SpeedSelect
                    options={props.funnelInfo.department ? props.designationsList.filter(d => d.department_id === props.funnelInfo.department.id) : []}
                    selectedOption={props.designationsList.length > 0 ? props.funnelInfo.designation : ''}
                    onSelect={(desigValue) => props.handleFunnelData(props.funnelInfo.id, 'desig', desigValue)}
                    displayKey='name'
                    uniqueKey='id'
                    maxHeight={120}
                    hideOkCancelBtns
                    selectLabel='Select'
                    prominentLabel='Designation*'
                    disable={props.funnelInfo.mode === 'edit' || !props.funnelInfo.department || props.designationsList.length === 0}
                  />
                </div>
              </div>
              {props.funnelInfo.mode === 'create' && <p className='warning-msg'>Note: Department and Designation cannot be modified once funnel is created</p>}
              {props.funnelInfo.mode === 'edit' && props.funnelInfo.is_archive === 1 && <p className='warning-msg'>Note: Archived funnel cannot be modified</p>}
            </div>}

            {/* Section 2 */}
            {/* {props.funnelInfo.step === 2 && <p style={{ textAlign: 'center' }}>Loading Information ...</p>} */}
            <div className="stage-info" style={{ display: props.funnelInfo.step === 2 ? 'block' : 'none' }}>
              <div className='stage-info-header'>
                <span className='stages-count'>{props.funnelInfo.stages.length}</span>
                <button
                  className={"btn-add btn-with-icon btn-small" + (props.funnelInfo.stages.length >= 20 || props.funnelInfo.is_archive === 1 ? " btn-disabled" : "")}
                  onClick={() => {
                    // let disableBtn = props.funnelInfo.mode === 'edit' && props.funnelInfo.stages.some(stage => String(stage.id).includes('add'));
                    let disableBtn = props.funnelInfo.mode === 'edit' && props.funnelInfo.stages.some(stage => stage.name === '');
                    if (disableBtn) {
                      alertService.warning('Please fill in the stage name before adding a new stage');
                      return;
                    }
                    props.handleFunnelData(props.funnelInfo.id, 'addStage')
                  }}
                  disabled={props.funnelInfo.stages.length >= 20 || props.funnelInfo.is_archive === 1}
                  title={props.funnelInfo.stages.length >= 20 ? 'Max stage limit reached' : 'Click here to add stages'}
                >
                  <i></i>
                  <span>Add Stage</span>
                </button>
              </div>
              <div className='stage-container'>
                {stages}
                {/* <div ref={stagesEndRef} /> */}
              </div>
            </div>

            {/* Section 3 */}
            {props.funnelInfo.step === 3 && <div className='share-wrapper'>
              <div className='share-header'>
                <h3>{!shareShowNewForm ? 'Active Members' : 'Invite New Member'}</h3>
                {!shareShowNewForm && <button
                  type='button'
                  className={'btn btn-with-icon btn-add btn-small' + (props.funnelInfo.mode !== 'create' && !props.funnelInfo.privileges.includes('SHARE') ? ' btn-disabled' : '')}
                  onClick={() => handleShareNewBtnClick()}
                >
                  <i></i>
                  <span>Invite</span>
                </button>}
              </div>
              {giveConsoleContentHtmlForShareTab()}
            </div>}

            {props.funnelInfo?.funnelId && (
              <Advanced
                props={props}
                jobInfo={jobInfo}
                setJobInfo={setJobInfo}
              />
            )}

            <button
              className="close-btn-wrapper"
              title={props.funnelInfo.isFunnelCreated && props.funnelInfo.mode === 'create' ? 'Close and open the new funnel' : 'Close'}
              onClick={() => props.handleCloseBtn(props.funnelInfo.id, props.funnelInfo.isFunnelCreated, props.funnelInfo.name, props.funnelInfo.funnelId)}
            > </button>
            <div className='form-content-footer'>
              {props.funnelInfo.step > 1 && !shareShowNewForm && <button
                className={"btn-back btn-with-icon btn-small" + ((props.funnelInfo.step === 2 && props.funnelInfo.mode === 'edit' && isStep2Invalid) ? ' btn-disabled' : '')}
                disabled={props.funnelInfo.step === 2 && props.funnelInfo.mode === 'edit' && isStep2Invalid}
                onClick={() => props.handleBackBtn(props.funnelInfo.id, props.funnelInfo.step, props.funnelInfo.isFunnelCreated)}
                style={props.funnelInfo.step === 4 ? { position: 'relative', right: '60px' } : {}}
              >
                <i></i>
                <span>
                  Back
                </span>
              </button>}
              {(props.funnelInfo.step === 1 || (props.funnelInfo.step === 2 && props.funnelInfo.isFunnelCreated) || props.funnelInfo.step === 3) &&
                <button
                  // className={"btn-next btn-with-icon btn-small" + (props.funnelInfo.step <= 2 && ((props.funnelInfo.step === 1 && !isStep1Invalid) || (props.funnelInfo.step === 2 && !isStep2Invalid && (props.funnelInfo.mode === 'edit' ? props.funnelInfo.privileges && props.funnelInfo.privileges.includes('SHARE') : true))) ? '' : ' btn-disabled')}
                  className={`btn-next btn-with-icon btn-small ${(props.funnelInfo.step === 1 && isStep1Invalid) || (props.funnelInfo.step === 2 && isStep2Invalid) ? 'btn-disabled' : ''}`}
                  // disabled={!((props.funnelInfo.step === 1 && !isStep1Invalid) || (props.funnelInfo.step === 2 && !isStep2Invalid && (props.funnelInfo.mode === 'edit' ? props.funnelInfo.privileges && props.funnelInfo.privileges.includes('SHARE') : true)))}
                  disabled={(props.funnelInfo.step === 1 && isStep1Invalid) || (props.funnelInfo.step === 2 && isStep2Invalid)}
                  title={((props.funnelInfo.step === 1 && isStep1Invalid) || (props.funnelInfo.step === 2 && isStep2Invalid) ? 'Please fill all the required information' : '')}
                  onClick={() => {
                    if (!(isStep1Invalid || isStep2Invalid)) {
                      props.handleSaveBtn(props.funnelInfo.id, props.funnelInfo.step)
                      if (shareShowNewForm) setShareShowNewForm(false);
                    }
                  }}
                >
                  <i></i>
                  <span>
                    Next
                  </span>
                </button>}
              {props.funnelInfo.step === 2 && !props.funnelInfo.isFunnelCreated &&
                <button
                  className={"btn-save btn-with-icon btn-small" + ((props.funnelInfo.step === 2 && !isStep2Invalid) ? '' : ' btn-disabled')}
                  disabled={!(props.funnelInfo.step === 2 && !isStep2Invalid)}
                  title={isStep2Invalid ? 'Please fill all the required information' : 'Save the funnel'}
                  onClick={() => {
                    if (!(isStep1Invalid || isStep2Invalid)) {
                      props.handleSaveBtn(props.funnelInfo.id, props.funnelInfo.step)
                    }
                  }}
                >
                  <i></i>
                  <span>
                    Save
                  </span>
                </button>}
              {props.funnelInfo.step === 3 && shareShowNewForm && <div className='flex'>
                <button
                  className={"btn-invite btn-with-icon btn-small"}
                  onClick={handleShareNewFormSubmit}
                >
                  <i></i>
                  <span>
                    Invite
                  </span>
                </button>
                <button
                  className={"btn-reset btn-with-icon btn-small"}
                  onClick={handleShareNewFormReset}
                >
                  <i></i>
                  <span>
                    Reset
                  </span>
                </button>
                <button
                  className={"btn-cancel btn-with-icon btn-small"}
                  onClick={handleShareNewFormCancel}
                >
                  <i></i>
                  <span>
                    Cancel
                  </span>
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      </ClickOutsideListner>
    </div>
  )
}

export default CreateFunnel