import { NavLink } from "react-router-dom";
import { useTabContext } from 'components/SuperTabs/TabContext';
import { getUser } from "./Utils";

const SuperLink = ({ children, tab, isSubTab = true }) => {
    const { SITE_PREFIX, lastOpenedUrl, openTab, openSubTab, tabs, updateActiveTab } = useTabContext();

    const handleSuperLinkClick = () => {
        const user = getUser(SITE_PREFIX + 'token');
        
        const isTabOpen = !isSubTab && tabs.some(t => t.url === tab.url);
        if (isSubTab) {
            openSubTab({ tab })
        } else {
            !isTabOpen && openTab({ tab });
            updateActiveTab(tab);
            lastOpenedUrl.current[user.organization_id + '_' + tab.id] = tab;
        }
    }

    return (
        <NavLink
            to={tab.url}
            title={tab.title || ''}
            onClick={handleSuperLinkClick}
        // exact
        >
            {children}
        </NavLink>
    )
}

export default SuperLink