import { useParams, useHistory } from "react-router-dom";
import { useRecruitmentFunnelContext } from "./context";
import CreateFunnel from "./CreateFunnel";
import RecruitmentFunnelDetails from "./RecruitmentFunnelDetails";
import { alertService } from "services/alertService";
import { useTabContext } from "components/SuperTabs/TabContext";
import APIService from "services/apiService";
import * as Constants from "components/Constants";
import { useMemo } from "react";

export default function Funnel() {
  const history = useHistory();
  const { id } = useParams();
  const funnelId = useMemo(() => {
    if (id.startsWith("f-")) {
      return id.replace("f-", "");
    }
    return funnelId;
  }, [id]);
  const { activeSubTabRef, closeTab, modifyTab } = useTabContext();
  const {
    tabsInfo,
    setTabsInfo,
    designationsList,
    departmentsList,
    funnelFilter,
    setFunnelFilter,
    filterObj,
    filteredFunnelData,
    getFunnelData,
    handleFunnelFilter,
  } = useRecruitmentFunnelContext();

  // Handles new funnel data
  function handleFunnelData(id, key, value, stageIndex) {
    // console.log('handleFunnelData - id, key, value, stageIndex', id, key, value, stageIndex);
    let tabsInfoCopy = JSON.parse(JSON.stringify(tabsInfo));
    let index = tabsInfo.savedFunnelData.findIndex((f) => f.id === id);
    let isFunnelCreated = tabsInfoCopy.savedFunnelData[index].isFunnelCreated;
    if (key === "navToShare" && !isFunnelCreated) return;

    tabsInfoCopy.savedFunnelData[index].stages = tabsInfoCopy.savedFunnelData[
      index
    ].stages.map((stage) => ({ ...stage, newStage: false }));
    let stages = tabsInfoCopy.savedFunnelData[index].stages;
    let stage = tabsInfoCopy.savedFunnelData[index].stages[stageIndex];

    switch (key) {
      case "name":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          name: value,
        };
        break;
      case "desc":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          description: value,
        };
        break;
      case "dept":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          department: value,
        };
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          designation: [],
        };
        break;
      case "desig":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          designation: value,
        };
        break;
      case "stageName":
        stage.name = value;
        break;
      case "stageDesc":
        stage.description = value;
        break;
      case "isQuestionnaire":
        stage.isQuestionnaire = value;;
        break;
      case "addStage":
        tabsInfoCopy.savedFunnelData[index].stages =
          tabsInfoCopy.savedFunnelData[index].stages.map((stage) => ({
            ...stage,
            newStage: false,
          }));
        tabsInfoCopy.savedFunnelData[index].stages.push({
          name: "",
          description: "",
          newStage: true,
        });
        // mark newStage false for every stage so that auto scroll is performed only once
        // setTimeout(() => {
        //     tabsInfoCopy.savedFunnelData[index].stages = tabsInfoCopy.savedFunnelData[index].stages.map(stage => ({ ...stage, newStage: false }));
        //     setTabsInfo({ ...tabsInfoCopy, stages: [...tabsInfoCopy.savedFunnelData[index].stages] })
        // }, 1000);
        break;
      case "deleteStage":
        stages.splice(stageIndex, 1);
        break;
      case "navToFunInfo":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          step: 1,
        };
        break;
      case "navToDefStages":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          step: 2,
        };
        break;
      case "navToShare":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          step: 3,
        };
        break;
      case 'navToAdvanced':
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          step: 4,
        };
        break;
      case "updateShare":
        tabsInfoCopy.savedFunnelData[index] = {
          ...tabsInfoCopy.savedFunnelData[index],
          shared: value,
        };
        break;
      default:
        console.log("Default case triggered");
        break;
    }
    setTabsInfo(JSON.parse(JSON.stringify(tabsInfoCopy)));
    // console.log('tabsInfoCopy', tabsInfoCopy, index);
  }

  // handle save button from CreateFunnel component
  function handleSaveBtn(id, step) {
    if (step <= 0 || step > 3) return;
    // console.log("handleSaveBtn", id, step);

    let tabsInfoCopy = JSON.parse(JSON.stringify(tabsInfo));
    let index = tabsInfo.savedFunnelData.findIndex((f) => f.id === id);

    if (
      step === 2 &&
      tabsInfo.savedFunnelData[index].isFunnelCreated === false
    ) {
      let isDuplicateStage = !(
        new Set(tabsInfo.savedFunnelData[index].stages.map((s) => s.name))
          .size === tabsInfo.savedFunnelData[index].stages.length
      );
      if (isDuplicateStage) {
        alertService.warning("Stage names must be unique for each funnel");
        return;
      }
      createFunnel(id);
    } else {
      // console.log(tabsInfo.savedFunnelData[index].funnelId);
      tabsInfoCopy.savedFunnelData[index] = {
        ...tabsInfoCopy.savedFunnelData[index],
        step: ++step,
      };
    }
    setTabsInfo(tabsInfoCopy);
  }

  // handle close button from CreateFunnel component
  function handleCloseBtn(tabId, isFunnelCreated, funnelName, funnelId) {
    if (isFunnelCreated) {
      const tab = {
        id: funnelId,
        title: funnelName,
        url: `/app/recruiter/f-${funnelId}`,
      };
      modifyTab({
        oldTab: activeSubTabRef.current,
        newTab: tab,
        isSubTab: true,
      });
      history.replace(`/app/recruiter/f-${funnelId}`);
      let updatedTabsInfo = {
        ...tabsInfo,
        tabs: [...tabsInfo.tabs.filter((t) => t.id !== tabId)],
        savedFunnelData: tabsInfo.savedFunnelData.filter((f) => f.id !== tabId),
      };
      setTabsInfo(updatedTabsInfo);

    } else {
      closeTab({ tab: activeSubTabRef.current, isSubTab: true });
    }
  }

  // Handles back button from CreateFunnel component
  function handleBackBtn(id, step) {
    // console.log('handleBackBtn id, step', id, step);
    let tabsInfoCopy = JSON.parse(JSON.stringify(tabsInfo));
    let index = tabsInfo.savedFunnelData.findIndex((f) => f.id === id);

    if (step > 1) {
      tabsInfoCopy.savedFunnelData[index] = {
        ...tabsInfoCopy.savedFunnelData[index],
        step: --step,
      };
      setTabsInfo(JSON.parse(JSON.stringify(tabsInfoCopy)));
    }
  }

  // Post request for creating new funnel
  const createFunnel = async (id) => {
    let index = tabsInfo.savedFunnelData.findIndex((f) => f.id === id);
    let newFunnel = {
      name: tabsInfo.savedFunnelData[index].name,
      description: tabsInfo.savedFunnelData[index].description,
      department_id: tabsInfo.savedFunnelData[index].department.id,
      designation_id: tabsInfo.savedFunnelData[index].designation.id,
      is_active: 1,
      is_archive: 0,
      is_deleted: 0,
      stages: [
        ...tabsInfo.savedFunnelData[index].stages.map((item, index) => ({
          name: item.name,
          description: item.description,
          is_questionnaire: item.isQuestionnaire,
          order: index + 1,
        })),
      ],
    };
    // console.log(newFunnel);

    APIService.apiRequest(
      Constants.API_BASE_URL + "/recruiter/recruitment_funnel",
      newFunnel,
      false,
      "POST",
    )
      .then((response) => {
        if (response.status === 1) {
          let funnelFilterCopy = JSON.parse(JSON.stringify(funnelFilter));
          funnelFilterCopy[response.id] = filterObj;
          setFunnelFilter(funnelFilterCopy);

          let tabsInfoCopy = JSON.parse(JSON.stringify(tabsInfo));
          let index = tabsInfo.savedFunnelData.findIndex((f) => f.id === id);

          tabsInfoCopy.savedFunnelData[index] = {
            ...tabsInfoCopy.savedFunnelData[index],
            isFunnelCreated: true,
            funnelId: response.id,
          };
          tabsInfoCopy.tabs = [
            ...tabsInfoCopy.tabs,
            {
              id: String(response.id),
              name: tabsInfo.savedFunnelData[index].name,
            },
          ];

          setTabsInfo(JSON.parse(JSON.stringify(tabsInfoCopy)));

          alertService.success("Funnel created successfully");
          // getFunnelData(false);
          // console.log('createFunnel - if response', response);
          return [index, response.id, tabsInfoCopy];
        } else {
          alertService.error(`createFunnel error: ${response.msg}`);
          // getFunnelData(false);
          console.log("createFunnel - else response", response);
        }
      })
      .then((data) => data && getNewFunnelSharedInfo(data))
      .catch((err) => {
        alertService.error("Error " + err.msg);
        console.log("error", err);
      });
  };

  // Fetch share data immediately after funnel is created
  const getNewFunnelSharedInfo = ([index, funnelId, tabsInfoCopy]) => {
    let apiPayLoad = null;
    APIService.apiRequest(
      Constants.API_BASE_URL + `/recruiter/share/${funnelId}`,
      apiPayLoad,
      false,
      "GET",
    )
      .then((response) => {
        if (response.status === 1) {
          // console.log('getNewFunnelSharedInfo if', response);
          tabsInfoCopy.savedFunnelData[index] = {
            ...tabsInfoCopy.savedFunnelData[index],
            shared: response.data,
          };
          setTabsInfo(tabsInfoCopy);
          filteredFunnelData
            ? getFunnelData(true, null, false, ["pageNumber", 1])
            : getFunnelData(false, null, false, ["pageNumber", 1]);
        } else {
          alertService.warning(`getNewFunnelSharedInfo error: ${response.msg}`);
          console.log("getNewFunnelSharedInfo else", response);
        }
      })
      .catch((err) => {
        alertService.error(`Error: ${err.msg}`);
        console.log("error", err);
      });
  };

  // update tab name if funnel name is modified
  const handleTabNameChange = (funnelId, newFunnelName) => {
    let tabsInfoCopy = JSON.parse(JSON.stringify(tabsInfo));
    tabsInfoCopy.tabs = tabsInfoCopy.tabs.map((f) => {
      return f.id === String(funnelId) ? { ...f, name: newFunnelName } : f;
    });
    // console.log('handleTabNameChange trig', funnelId, newFunnelName, tabsInfoCopy)
    setTabsInfo(tabsInfoCopy);
  };

  if (id.includes("new") || id.includes("settings") || id.includes("clone")) {
    if (!tabsInfo.savedFunnelData.find((funnel) => funnel.id === funnelId)) {
      return null;
    }

    return (
      <CreateFunnel
        funnelInfo={
          tabsInfo.savedFunnelData.filter((fun) => fun.id === funnelId)[0]
        }
        handleFunnelData={handleFunnelData}
        designationsList={designationsList}
        departmentsList={departmentsList}
        handleSaveBtn={handleSaveBtn}
        handleBackBtn={handleBackBtn}
        handleCloseBtn={handleCloseBtn}
        handleTabNameChange={handleTabNameChange}
      />
    );
  }

  return (
    <RecruitmentFunnelDetails
      id={funnelId}
      designationsList={designationsList}
      departmentsList={departmentsList}
      handleTabNameChange={handleTabNameChange}
      tabInfo={tabsInfo.tabs.filter((fun) => fun.id === funnelId)[0]}
      setTabsInfo={setTabsInfo}
      funnelFilter={funnelFilter[funnelId]}
      handleFunnelFilter={handleFunnelFilter}
      history={history}
    />
  );
}
