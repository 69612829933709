import Avatar from 'components/Avatar';
import UserProfileDetailsCard from './UserProfileDetailsCard';
import './UserProfileCard.scss';
import { MouseTooltip } from 'components/ToolTip';

const UserProfileCard = ({ user, isLoggedInUser, children, selectedMemberId, enlargedTab, showCross, showTooltip = false }) => {
    const { first_name, last_name, img_url } = user;

    return (
        <div
            className={
                "avatar-name-wrapper" +
                (isLoggedInUser ? " font-bold" : "")
            }
        >
            <div className='user-info-wrapper'>
                <MouseTooltip
                    delay={500}
                    show={selectedMemberId === user.resource_id ? false : true}
                    content={<UserProfileDetailsCard user={user} />}
                    style={{ padding: '0' }}
                    asChild
                >
                    <div className={`user-info ${enlargedTab ? 'enlarged-tab' : ""} ${isLoggedInUser ? " self" : ""}`}>
                        <Avatar
                            imgSrc={img_url}
                            firstName={first_name}
                            lastName={last_name}
                            fontSize={enlargedTab ? 14 : 10}
                            height={enlargedTab ? 30 : 22}
                            width={enlargedTab ? 30 : 22}
                            borderRadius={enlargedTab || isLoggedInUser ? 2 : '50%'}
                        />
                        <span>{`${first_name} ${last_name}`}</span>
                        {showCross && <button
                            className="hide-selected-member icon"
                        ></button>}
                    </div>
                </MouseTooltip>
            </div>
            {/* <div className="icon-chevron-right"></div> */}
            {children}
        </div>
    )
}

export default UserProfileCard