// return the user data from the session storage
import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';
import { ENVIRONMENT, SITE_PREFIX } from '../components/Constants.js';

/************************************************
 * Get User Auth Token
 */
// return the token from the local storage
export const getToken = (key = 'token') => {
  return localStorage.getItem(SITE_PREFIX + key) || null;
}

/************************************************
 * Get Any Local Storage Variable Val
 */
// return the value from the local storage from given key
export const getLocalStorageVal = (key) => {
  return localStorage.getItem(SITE_PREFIX + key) || null;
}


/************************************************
 * Set Any Local Storage Variable Val
 */
// set the key and value in the local storage 
export const setLocalStorageVal = (key, value) => {
  localStorage.setItem(SITE_PREFIX + key, JSON.stringify(value))
}


/************************************************
 * Get Hashed Password
 */
export const generateHashedPassword = (password) => {
  const hashPassword = SHA512(password);
  const hasedPassword = hashPassword.toString(Base64);
  return hasedPassword;
}


/************************************************
 * Set and Get User Token
 */
// Get User Details
export const getUser = (key = 'token') => {
  // const userStr = localStorage.getItem(SITE_PREFIX + 'user');
  let userDetails = getUserDetailsFromToken(localStorage.getItem(SITE_PREFIX + key))?.identity;
  // console.log('token', token, JSON.parse(userStr));
  // if (userStr) return JSON.parse(userStr);
  // else return null;
  return userDetails ? userDetails : null;
}

// Get User Timezone
export const getMyTimeZoneFromLocal = () => {
  const myTimezone = localStorage.getItem(SITE_PREFIX + 'my_timezone');
  if (myTimezone) return JSON.parse(myTimezone);
  else return null;
}

let myTimezone = null;
function getMyTimezone() {
  if (!myTimezone) {
    myTimezone = getMyTimeZoneFromLocal();
  }
  return myTimezone;
}

export const convertTimeZone = (date = new Date(), offset) => {
  let _offset = offset ?? getMyTimezoneOffset(date);
  let newOffset = ((_offset.split(':')[0] * 60) + +_offset.split(':')[1]) * -1;
  date = new Date(date.getTime() - (newOffset * 60 * 1000));
  return new Date(date.toISOString().replace('T', ' ').split('.')[0]);
}

export function getMyTimezoneOffset2(utcDate) {
  const tz = getMyTimezone();
  if (!tz) return "00:00"; // safe fallback - didn't find timezone

  if (tz?.dst_start_date && tz?.dst_end_date) {
    // both start and end dates are in UTC
    const dstStart = convertTimeZone(new Date(tz.dst_start_date + ' GMT'), tz.utc_offset);
    const dstEnd = convertTimeZone(new Date(tz.dst_end_date + ' GMT'), tz.dst_utc_offset);

    // if utcDate is not between dstStart and dstEnd, then use tz.old_utc_offset
    if (
      utcDate.getTime() >= dstStart.getTime() &&
      utcDate.getTime() <= dstEnd.getTime()
    ) {
      return tz.dst_utc_offset;
    }
  }

  return tz.utc_offset;
}

export function getMyTimezoneOffset(date) {
  const tz = getMyTimezone();
  if (!tz) return "00:00"; // safe fallback - didn't find timezone

  const utcDate = new Date(getUTCTime(date));
  if (tz?.dst_start_date && tz?.dst_end_date) {
    // both start and end dates are in UTC
    const dstStart = new Date(tz.dst_start_date);
    const dstEnd = new Date(tz.dst_end_date);

    // if utcDate is not between dstStart and dstEnd, then use tz.old_utc_offset
    if (
      utcDate.getTime() >= dstStart.getTime() &&
      utcDate.getTime() <= dstEnd.getTime()
    ) {
      return tz.dst_utc_offset;
    }
  }

  return tz.utc_offset;
}

export function convertTimeZoneToUTC(date = new Date()) {
  const utcOffset = getMyTimezoneOffset(date);
  // Extract the sign of the UTC offset
  const utcSign = utcOffset[0] === '+' ? '-' : '+';
  // Parse the timestamp string and calculate the adjusted time
  const adjustedTime = new Date(date);
  adjustedTime.setUTCHours(adjustedTime.getUTCHours() + parseInt(utcSign + utcOffset.slice(1, 3)));
  adjustedTime.setUTCMinutes(adjustedTime.getUTCMinutes() + parseInt(utcSign + utcOffset.slice(4, 6)));

  return adjustedTime;
}

export function getUTCTime(date = new Date()) {
  return new Date(date).toISOString().replace('T', ' ').split('.')[0];
}

export function getUTCDate(date = new Date()) {
  return new Date(getUTCTime(date));
}

/**
 * @param {string | number | Date=} utc 
 * @returns {Date}
 */
export function convertUTCToTimezone(utc = new Date()) {
  if (typeof utc === 'string' && utc.endsWith('GMT')) {
    utc = utc.replace('GMT', '');
  }

  const utcDate = new Date(utc);
  const offset = getMyTimezoneOffset(utc).split(':');
  const offsetHours = parseInt(offset[0]);
  const offsetMinutes = parseInt(offset[1]);
  const offsetMilliseconds = (offsetHours * 60 + offsetMinutes) * 60 * 1000;
  return new Date(utcDate.getTime() + offsetMilliseconds);
}

/**
 * @param {string} hhmm
 * @param {string} utcOffset
 * @param {number=} daysOffset
 * @returns {[string, number]}
 */
export function convertHhmmToUtc(hhmm, utcOffset, daysOffset = 0) {
  let userTimezoneSign = utcOffset[0] === '+' ? '-' : '+'; // +05:30 -> -05:30
  let days = parseInt(daysOffset);
  let hours = parseInt(hhmm.substring(0, 2));
  let minutes = parseInt(hhmm.substring(2, 4));
  let utcHoursOffset = parseInt(utcOffset.substring(1, 3));
  let utcMinutesOffset = parseInt(utcOffset.substring(4, 6));

  // Calculate UTC time
  let utcHhmm = new Date(Date.UTC(1970, 0, 1, hours + days * 24, minutes));
  utcHhmm.setUTCMinutes(utcHhmm.getUTCMinutes() + (userTimezoneSign === '-' ? -1 : 1) * (utcMinutesOffset + utcHoursOffset * 60));

  // Extract UTC time hours and minutes and day offset
  let dayOffset = Math.floor(utcHhmm.getTime() / (24 * 60 * 60 * 1000));
  let h = utcHhmm.getUTCHours();
  let m = utcHhmm.getUTCMinutes();

  utcHhmm = ('0' + h).slice(-2) + ('0' + m).slice(-2);
  return [utcHhmm, dayOffset];
}

/**
 * @param {string} hhmm
 * @param {string} utcOffset
 * @param {number=} daysOffset
 * @returns {[string, number]}
 */
export function convertHhmmToUserTimezone(hhmm, utcOffset, daysOffset = 0) {

  let hours = parseInt((hhmm).substring(0, 2));
  let minutes = parseInt((hhmm).substring(2, 4));


  // Parse UTC offset hours and minutes
  let utcHoursOffset = parseInt(utcOffset.substring(1, 3));
  let utcMinutesOffset = parseInt(utcOffset.substring(4, 6));

  // Calculate total UTC offset in minutes
  let totalUtcOffset = (utcOffset[0] === '+' ? 1 : -1) * (utcHoursOffset * 60 + utcMinutesOffset); // 1 * (05 * 60 + 30) = 330, -1 * (05 * 60 + 30) = -330

  // Apply days offset
  let totalDaysOffset = parseInt(daysOffset);

  // Convert UTC time to local time by adding the total UTC offset
  let localTime = new Date(Date.UTC(1970, 0, 1, hours, minutes));
  localTime.setMinutes(localTime.getMinutes() + totalUtcOffset + totalDaysOffset * 24 * 60);

  // Extract local time hours and minutes
  let dayOffset = Math.floor(localTime.getTime() / (24 * 60 * 60 * 1000));
  let localHours = ('0' + localTime.getUTCHours()).slice(-2);
  let localMinutes = ('0' + localTime.getUTCMinutes()).slice(-2);

  // Format and return the local time in "HHMM" format
  return [localHours + localMinutes, dayOffset];
}

/**
 * @param {string} hhmm
 * @param {number | string | Date=} date
 */
export function getDateFromHhmm(hhmm, date = new Date()) {
  const hours = parseInt(hhmm.slice(0, -2));
  const minutes = parseInt(hhmm.slice(-2));

  const newDate = new Date(date);
  newDate.setHours(hours, minutes, 0, 0);
  return newDate;
}

// Get All Timezones
export const getTimeZonesFromLocal = () => {
  const myTimezone = localStorage.getItem(SITE_PREFIX + 'timezones');
  if (myTimezone) return JSON.parse(myTimezone);
  else return null;
}

export const getUserDetailsFromToken = (token) => {
  if (token) {
    try {
      return JSON.parse(window.atob(token.split('.')[1].replace(/-/g, "+").replace(/_/g, "/")));
    } catch (error) {
      // ignore
    }
  }
  return null;
}

// Set the token and user from the session storage
export const setUserSession = (token, refresh_token, user, uniqueid = null) => {
  localStorage.setItem(SITE_PREFIX + 'token', token);
  localStorage.setItem(SITE_PREFIX + 'rt', refresh_token || '');
  // localStorage.setItem(SITE_PREFIX + 'user', JSON.stringify(user));
  // localStorage.setItem(SITE_PREFIX + 'selected_terminal', 'Employee');
  if (uniqueid) {
    localStorage.setItem(SITE_PREFIX + 'sessionid', uniqueid);
  }
}

// Set the token and user from the session storage
export const setLoginAsUserSession = (original_token, token, user) => {
  //Take backup of orginal user token 
  // const originalUserToken = localStorage.getItem(SITE_PREFIX+'token');
  localStorage.setItem(SITE_PREFIX + 'init_token', original_token);

  //Update exsiting token and user details to local storage
  setTimeout(() => {
    localStorage.setItem(SITE_PREFIX + 'token', token);
    // localStorage.setItem(SITE_PREFIX + 'user', JSON.stringify(user));
  }, 10);
}

// Remove the token and user from the session storage
export const removeUserSession = () => {
  // var arr = []; // Array to hold the keys
  // // Iterate over localStorage and insert the keys that meet the condition into arr
  // for (let i = 0; i < localStorage.length; i++) {
  //   if (localStorage.key(i).substring(0, SITE_PREFIX.length) === SITE_PREFIX) {
  //     arr.push(localStorage.key(i));
  //   }
  // }
  // // Iterate over arr and remove the items by key
  // for (let i = 0; i < arr.length; i++) {
  //   localStorage.removeItem(arr[i]);
  // }

  removeStorage('localStorage');
  removeStorage('sessionStorage');
}

const removeStorage = (type) => {
  var arr = []; // Array to hold the keys
  // Iterate over localStorage/sessionStorage and insert the keys that meet the condition into arr
  const storage = type === 'localStorage' ? localStorage : sessionStorage;
  for (let i = 0; i < storage.length; i++) {
    if (storage.key(i).substring(0, SITE_PREFIX.length) === SITE_PREFIX) {
      arr.push(storage.key(i));
    }
  }
  // Iterate over arr and remove the items by key
  for (let i = 0; i < arr.length; i++) {
    storage.removeItem(arr[i]);
  }
}


/************************************************
 * Find Key by value  - Under object
 */

//Get Key by Value from array of objects
export const getKeyByValue = (array, value) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i].name === value) {
      return array[i];
    }
  }
}

export const findKeyValueRecursively = (arr, value, valueToFind) => {
  arr.filter(function (item) { return item.id === value })
    .map(function (item) { return item.valueToFind })
}


//Check if an object is empty
export const isEmptyObject = (obj) => {
  if (obj) {
    return Object.keys(obj).length === 0;
  }
}



/************************************************
 * Order Object with expected keys order
 */
export const orderObjectKey = (obj, keyOrder) => {
  keyOrder.forEach((k) => {
    const v = obj[k]
    delete obj[k]
    obj[k] = v
  })
  return obj;
}


/************************************************
 * Format numbers with commas
 */
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


/************************************************
 * Order Array of objects by key val
 */
export const orderArrayObjects = (arr, key, order) => {
  arr.sort(function (a, b) {
    if (order === 'asc') {
      return new Date(a[key]).getTime() - new Date(b[key]).getTime();
    } else {
      return new Date(b[key]).getTime() - new Date(a[key]).getTime();
    }
  });
}

/************************************************
 * Check if TeamLink is opened on Electron App
 */
export const isElectron = () => {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
    return true;
  }

  return false;
}

export const isElectronApp = isElectron();

// Helper function to get an element's exact position
// function getPosition(el) {
//   var xPos = 0;
//   var yPos = 0;

//   while (el) {
//     if (el.tagName.toLocaleUpperCase() === "BODY") {
//       // deal with browser quirks with body/window/document and page scroll
//       var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
//       var yScroll = el.scrollTop || document.documentElement.scrollTop;

//       xPos += (el.offsetLeft - xScroll + el.clientLeft);
//       yPos += (el.offsetTop - yScroll + el.clientTop);
//     } else {
//       // for all other non-BODY elements
//       xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
//       yPos += (el.offsetTop - el.scrollTop + el.clientTop);
//     }

//     el = el.offsetParent;
//   }
//   return {
//     x: xPos,
//     y: yPos
//   };
// }


// function scrollTo(element, to, duration) {
//   var start = element.scrollTop,
//     change = to - start,
//     currentTime = 0,
//     increment = 20;

//   var animateScroll = function () {
//     currentTime += increment;
//     var val = Math.easeInOutQuad(currentTime, start, change, duration);
//     element.scrollTop = val;
//     if (currentTime < duration) {
//       setTimeout(animateScroll, increment);
//     }
//   };
//   animateScroll();
// }

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

/**
 * Removes double quotes in string
 */
export const removeDoubleQuoteFromString = (str) => {
  return str.replace(/['"]+/g, '')
}

/**
 * Add https to url string if http or https is not present
 * @param {String} url
 * @returns {String} url
 */
export const addHttpsToURLString = (url) => {
  let result = typeof url === 'string' ? url.trim() : url;
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    result = "https://" + url;
  }

  return result;
}

export const addW3ToURLString = (url) => {
  let result = url
  if (!/^(?:www)/.test(url)) {
    result = "www." + url;
  }

  return result;
}

export const formatURL = (url) => {
  url = url.trim();
  try {
    if (!url.startsWith('http')) {
      url = 'https://' + url;
    }

    const parsedUrl = new URL(url);
    if (!parsedUrl.hostname.startsWith('www')) {
      parsedUrl.hostname = 'www.' + parsedUrl.hostname;
    }

    return parsedUrl.toString();
  } catch (error) {
    return url;
  }
}

/**
 * Checks whether the value is empty or not
 * @param {*} value 
 * @returns {Boolean}
 */
export const isEmpty = (value) => {
  if (value === null) {
    return true
  }

  if (typeof value === 'undefined') {
    return true
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return true
  }

  if (typeof value === 'object') {
    if (Array.isArray(value) && value.length === 0) {
      return true
    } else {
      return Object.keys(value).length === 0
    }
  }

  return false
}

/**
 * Format date to MMM DD, YYYY
 * @param {Date} date 
 * @returns {String} formatted date
 */
export const formatDate = (date) => {
  return new Intl.DateTimeFormat('en', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date)
}

/**
 * Check if value is string or not
 * @param {*} value 
 * @returns {Boolean}
 */
export const isString = (value) => {
  return typeof value === 'string' || value instanceof String;
}

export const isNumber = (value) => {
  return typeof value === 'number' && Number.isFinite(value);
}

/**
 * Debounce function
 * @param {Function} func
 * @param {Number} wait
 * @returns {Function} executedFunction
 */
export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * debounce(func, [wait=0], [options={}])
 *
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0] The number of milliseconds to delay.
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=false] Specify invoking on the leading edge of the timeout.
 * @param {cancelObj} [options.cancelObj='canceled'] Specify the error object to be rejected.
 * @returns {Function} Returns the new debounced function.
 */
export const debounceAsync = (
  func,
  wait = 0,
  { leading = false, cancelObj = "canceled" } = {},
) => {
  let timerId, latestResolve, shouldCancel;

  const invokeAtLeading = (args, resolve, reject) => {
    func(...args)
      .then(resolve)
      .catch(reject);
    shouldCancel = false;
  };

  const invokeAtTrailing = (args, resolve, reject) => {
    if (shouldCancel && resolve !== latestResolve) {
      reject(cancelObj);
    } else {
      func(...args)
        .then(resolve)
        .catch(reject);
      shouldCancel = false;
      clearTimeout(timerId);
      timerId = latestResolve = null;
    }
  };

  return function (...args) {
    if (!latestResolve) {
      return new Promise((resolve, reject) => {
        latestResolve = resolve;
        if (leading) {
          invokeAtLeading(args, resolve, reject);
        } else {
          timerId = setTimeout(
            () => invokeAtTrailing(args, resolve, reject),
            wait,
          );
        }
      });
    }

    shouldCancel = true;
    return new Promise((resolve, reject) => {
      latestResolve = resolve;
      timerId = setTimeout(() => invokeAtTrailing(args, resolve, reject), wait);
    });
  };
};

export function isBlankText(text) {
  return !/[a-zA-Z]+/.test(text)
}

export function isBlankHTML(text) {
  return !/[a-zA-Z]+/.test(text.replace(/<[^>]*>?/gm, ''))
}

export function removeHTMLTags(str) {
  return str.replace(/<[^>]*>/g, '');
}

export function accurateInterval(fn, time) {
  var cancel, nextAt, timeout, wrapper;
  nextAt = new Date().getTime() + time;
  timeout = null;
  wrapper = function () {
    nextAt += time;
    timeout = setTimeout(wrapper, nextAt - new Date().getTime());
    return fn();
  };
  cancel = function () {
    return clearTimeout(timeout);
  };
  timeout = setTimeout(wrapper, nextAt - new Date().getTime());
  return {
    cancel: cancel
  };
};

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Reorder a provided `list`
 * Returns a new array and does not modify the original array
 * @param {Array} array 
 * @param {number} from 
 * @param {number} to 
 */
export function reorder(list, startIndex, finishIndex) {
  if (startIndex === -1 || finishIndex === -1) {
    return list
  }

  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(finishIndex, 0, removed)

  return result
}

export function isDev() {
  return ENVIRONMENT === 'development';
}

export async function measureInternetSpeed({
  file_url = 'https://teamlink-assets.s3.us-east-2.amazonaws.com/asc_background_m.jpeg',
  timeoutInSeconds = 15,
  controller = new AbortController(),
} = {}) {
  if (!file_url) throw new Error('measureInternetSpeed - file_url is required');
  const signal = controller.signal;
  // const apiStartTime = new Date().getTime();

  // Set up a timeout to abort the request if it takes too long
  const apiTimeoutId = setTimeout(() => {
    controller.abort();
  }, timeoutInSeconds * 1000);

  try {
    const fetchOptions = {
      method: "GET",
      signal,
      cache: "reload",
    };

    const fileRequest = new Request(file_url, fetchOptions);
    const response = await fetch(fileRequest);

    if (!response.ok) {
      throw new Error('Network request failed');
    }
    const downloadStartTime = new Date().getTime();
    const blob = await response.blob();
    const downloadEndTime = new Date().getTime();

    // Clear the timeout if the request is successful and resource is downloaded on time
    clearTimeout(apiTimeoutId);

    // const apiEndTime = new Date().getTime();
    const duration = (downloadEndTime - downloadStartTime) / 1000; // Convert to seconds
    const fileSizeInBytes = blob.size;
    const speedMBps = ((fileSizeInBytes / duration) / 1000000).toFixed(2); // Convert to MBps

    return speedMBps;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('measureInternetSpeed - Request timed out');
    } else {
      console.error('measureInternetSpeed - Error fetching data:', error);
    }
    return null;
  }
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function checkPrivilege(privileges, access) {
  const accessArray = Array.isArray(access) ? access : [access];

  return Object.values(privileges).some((rolePrivileges) =>
    accessArray.some((item) => rolePrivileges.includes(item))
  );
}

export function isEmail(email) {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

export function isURL(value) {
  return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w.-]*)*\/?$/.test(value);
}