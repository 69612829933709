import React, { useState, useEffect } from "react";
import './styles.scss';

const backgroundColor = [
    '#986f0b', '#7a7574', '#986f0b', '#a4262c', '#8764b8', '#038759',
    '#881798', '#4a154a', '#8764b8', '#7a7574', '#881798', '#038759',
    '#8764b8', '#038759', '#a4262c', '#5a003a', '#986f0b', '#ca5010',
    '#038759', '#038387', '#7a7574', '#c239b3', '#881798', '#8764b8',
    '#986f0b', '#7a7574'
];

const key = backgroundColor.map((_, i) => String.fromCharCode(i + 65));

const colorsList = [];
for (let i = 0; i < key.length; i++) {
    let obj = { key: key[i], fontColor: '#FFF', backgroundColor: backgroundColor[i] }
    colorsList.push(obj);
}

export default function Avatar({
    imgSrc,
    firstName,
    lastName,
    fontSize = '14',
    alt,
    height = '30',
    width = '30',
    borderRadius = '50%'
}) {

    const [src, setSrc] = useState(imgSrc && imgSrc !== 'None' ? imgSrc : '');
    const initials = ((firstName ? firstName.charAt(0) : '') + (lastName ? lastName.charAt(0) : '')).toUpperCase();

    const [color, setColor] = useState({ fontColor: '#000', backgroundColor: '#FFF' });

    useEffect(() => {
        setSrc(imgSrc && imgSrc !== 'None' ? imgSrc : '');
        let [colour] = colorsList.filter(c => c.key === initials.charAt(0));
        colour = !colour ? { key: '', fontColor: '#FFF', backgroundColor: '#c239b3' } : colour;
        setColor({ fontColor: colour.fontColor, backgroundColor: colour.backgroundColor });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initials, imgSrc]);

    return (
        <>
            {
                src ? <img
                    src={src}
                    alt={alt ? alt : `${firstName}'s profile pic`}
                    onError={() => setSrc('')}
                    style={{ height: height, width: width, borderRadius: borderRadius }}
                    className='avatar-img user-icon'
                /> : <div
                    className='initial user-icon'
                    style={{
                        height: height,
                        width: width,
                        minWidth: width,
                        fontSize: fontSize,
                        color: color.fontColor,
                        backgroundColor: color.backgroundColor,
                        borderRadius: borderRadius
                    }}
                >
                    {initials}
                </div>
            }
        </>
    );
}