export const PAGE_IDS = Object.freeze({
  ATTENDANCE: 1,
  BRANDING_KIT: 2,
  PERFORMANCE: 3,
  ORGANIZATION: 4,
  PROFILE: 5,
  RECRUITER: 6,
  WIKI: 7,
});

export const ATTENDANCE_PAGE = Object.freeze({
  title: "Attendance",
  url: "/app/attendance",
  privilege: "ATTENDANCE",
  id: PAGE_IDS.ATTENDANCE,
  showInMobile: true,
});

const pages = [
  ATTENDANCE_PAGE,
  {
    title: "Branding Kit",
    url: "/app/signgen",
    privilege: "EMPLOYEE",
    id: PAGE_IDS.BRANDING_KIT,
  },
  {
    title: "Performance",
    url: "/app/performance/home",
    privilege: "PERFORMANCE_MANAGEMENT",
    containsSubTabs: true,
    id: PAGE_IDS.PERFORMANCE,
  },
  {
    title: "Organization",
    url: "/app/organization",
    privilege: "ORGANIZATION",
    id: PAGE_IDS.ORGANIZATION,
  },
  {
    title: "Profile",
    url: "/app/profile/home",
    privilege: ["PROFILE_MANAGEMENT_EMPLOYEE", "PROFILE_MANAGEMENT_HR"],
    showAddButton: ['MANAGER', 'PROFILE_MANAGEMENT_HR'],
    containsSubTabs: true,
    id: PAGE_IDS.PROFILE,
  },
  {
    title: "Recruiter",
    url: "/app/recruiter/f-home",
    privilege: "RECRUITER",
    showAddButton: true,
    containsSubTabs: true,
    id: PAGE_IDS.RECRUITER,
  },
  {
    title: 'Wiki',
    url: '/app/wiki/home',
    privilege: 'WIKI',
    showAddButton: true,
    containsSubTabs: true,
    id: PAGE_IDS.WIKI,
  }
]

export const SITE_PAGES = Object.freeze(pages);
