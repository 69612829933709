import React, { useContext, useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';

//npm components and tools
import SuperPager from 'super-pager';
//services
import APIService from 'services/apiService';
import { alertService } from 'services/alertService';

//context
import { TimeWorkerContext } from 'Main.js';
import { useAttendanceFilters } from 'views/Attendance/context';
import { useTeamMemberFilter } from 'views/Attendance/context/teamMemberFilterContext';
import { connectSocket, useSocket } from 'socket';
import { useGlobalContext } from 'contexts/GlobalContext';

//constant
import { AL_BASE_URL, BREAK_SUB_OPTIONS, INACTIVITY_SUB_OPTIONS } from '../../constants';
import * as Constants from 'components/Constants';

//utils
import { convertTimeZone, getFormattedTimeAndDiff, getAwayTime, isNotNone, isNone, getShiftDetails } from '../../utils';
import { format, formatTimeInSeconds, isDate, isWithinInterval, subDays } from 'utils/date';
import { convertHhmmToUserTimezone, convertUTCToTimezone, getDateFromHhmm, getMyTimezoneOffset, getUser, isElectronApp } from 'utils/Common';
import clsx from 'utils/clsx';

//hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";

//components
import TeamMemberAttendanceTable from './TeamMemberAttendanceTable';
import TeamMemberCard from './TeamMemberCard';
import HeaderSubMenu from './HeaderSubMenu'
import BreakDetails from './BreakDetails';
import ShiftTotalHeader from './ShiftTotalHeader';
import UserProfileCard from './UserProfileCard';
// import BreakPopup from './BreakPopup';
import { MouseTooltip } from 'components/ToolTip';
import InactivityDetails from './InactivityDetails';
import ClockTimeDetails from './ClockTimeDetails';
import ShiftLog, { SkeletonShiftLog } from './ShiftLog';
import GroupsPopup, { GROUPS_DEFAULT_OPT } from './GroupsPopup';
import GroupPopup from './GroupPopup';
import ShiftButton from './ShiftButton';
import ShiftDetailsModal from './ShiftDetailsModal';

//styles
import './AttendanceTable.scss';
import { useAttendanceSearch } from 'views/Attendance/context/search';

const SORT_BY_STATE_LOCAL_KEY = Constants.SITE_PREFIX + 'attendanceSortBy';

let ac = new AbortController();


const getIconMargin = () => {
  const userTable = document.querySelector('.users-table');
  const userTableWidth = userTable?.clientWidth;
  const teamTableWidth = document.querySelector('.team-member-table')?.clientWidth;
  if (userTable) {
    const firstColWidth = userTable.querySelector('.office-floor-header')?.clientWidth;
    return `${15 + firstColWidth - (userTableWidth - teamTableWidth)}px`;
  } else return '15px';
}

const AttendanceTable = ({ showLineLoader, setShowLineLoader, showAnalyticsFor, toggleAnalytics, officeFloor, setOfficeFloor }) => {
  const socket = useSocket();
  const { hasTimerAccess, isAppInActive } = useGlobalContext();
  const { date: selectedDate, search, department, attendance, status, departmentOptions, isFilterApplied, handleDateChange, groups } = useAttendanceFilters();

  const { updateSearchResults, setOnResultClick, setLastSearchedResult, lastSearchedResult, setIsFetched } = useAttendanceSearch();


  const attendanceLoadTimeStampRef = useRef(new Date());
  const officeFloorRef = useRef(null);
  const officeFloorAbortControllerRef = useRef(new AbortController());
  const selectedDateRef = useRef(selectedDate);
  const prevSelectedDateRef = useRef(selectedDate);
  const sortByRef = useRef(null);
  const loadingFirstPageDataRef = useRef(false);
  const loggedInUserRef = useRef({ resource_id: getUser().resource.id });

  const customSortFnRef = useRef(null);
  const retryAttemptRef = useRef(0);
  const retryAttemptTimeoutRef = useRef(null);

  const searchRef = useRef(null);
  const departmentRef = useRef(null);
  const attendanceRef = useRef(null);
  const statusRef = useRef(null);
  const bufferLoadedTimeRef = useRef(null);

  const tableHeight = window.innerHeight - (36 + 60 + 2.5 + 15); // 36 is the height of header, 60 is attendance filter and 15 is the padding bottom
  const tableEmptyRowsCount = Math.round((tableHeight / 32)); // 32 is the height of each row
  const [rowHeight] = useState(tableHeight / tableEmptyRowsCount)
  let PAGE_SIZE = parseInt((tableHeight / 32) * 1.2);
  let BUFFER_SIZE = Math.ceil(PAGE_SIZE * 0.1);
  const timeWorkerInstance = useContext(TimeWorkerContext);

  const [loadingData, setLoadingData] = useState(false);
  const loadingDataRef = useRef(loadingData);
  const [loadingFirstPageData, setLoadingFirstPageData] = useState(false);
  officeFloorRef.current = officeFloor;
  const [userShiftDetails, setUserShiftDetails] = useState({});
  const [prevSelectedDate, setPrevSelectedDate] = useState(selectedDate);
  const isTimerStartedRef = useRef(false);
  const [sortBy, setSortBy] = useLocalStorage(SORT_BY_STATE_LOCAL_KEY, { column: 'office_floor', order: 'ascending', update: false });
  const [showBreaksMenu, setShowBreaksMenu] = useState(false);
  const [showInactivityMenu, setShowInactivityMenu] = useState(false);
  const [activeGroup, setActiveGroup] = useState(GROUPS_DEFAULT_OPT);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [expandedCard, setExpandedCard] = useState('');
  const [showUserDetails, setShowUserDetails] = useState('');

  const updateLoadingData = (val) => {
    setLoadingData(val);
    loadingDataRef.current = val;
  }

  const formattedSelectedDate = format(prevSelectedDate);

  const calculateTotalTime = useCallback((val1, val2) => {
    const isVal1NotNone = isNotNone(val1);
    const isVal2NotNone = isNotNone(val2);

    if (!isVal1NotNone && !isVal2NotNone) {
      return 'None';
    } else if (!isVal1NotNone && isVal2NotNone) {
      return val2;
    } else if (isVal1NotNone && !isVal2NotNone) {
      return val1;
    } else {
      return val1 + val2;
    }
  }, []);

  const mergeArrayOfObjects = useCallback((arr1, arr2) => {
    const merged = [].concat(arr1);
    for (let i = 0; i < arr2.length; i++) {
      const obj = arr2[i];
      const index = merged.findIndex((item) => item.resource_id === obj.resource_id);
      if (index === -1) {
        merged.push(obj);
      } else {
        merged.splice(index, 1);
        merged.push(arr2[i]);
      }
    }
    return merged;
  }, []);

  const updateIsBufferFlag = useCallback((data, allDataFetched = null, addShiftKey = false) => {
    let isAllDataFetched = allDataFetched ? allDataFetched : data.length === officeFloorRef.current?.[formattedSelectedDate]?.meta?.total_results;
    return data.map((candidate, index) => {
      // Do not update buffer flag to true if all data is fetched
      if (addShiftKey) {
        const { shift } = getShiftDetails(candidate);
        candidate.shift = shift;
      }

      if (!isAllDataFetched && (data.length - index <= BUFFER_SIZE)) {
        return { ...candidate, isBuffer: true }
      } else {
        return { ...candidate, isBuffer: false }
      };
    })
  }, [BUFFER_SIZE, formattedSelectedDate]);

  const getOfficeFloorData = useCallback(({ controller = ac, startingPoint = 0, showLoader = true, pageSize = PAGE_SIZE, resourceId = null } = {}) => {
    let abortController = controller;
    if (abortController.signal.aborted) {
      ac = new AbortController();
      abortController = ac;
      officeFloorAbortControllerRef.current = ac;
    }
    let date = format(selectedDate);
    let url = new URL(AL_BASE_URL + '/home_page/');
    const dataExists = officeFloor?.hasOwnProperty(date);
    const isInitialLoad = startingPoint === 0;
    url.searchParams.append('page_size', pageSize);
    url.searchParams.append('start_date', date);
    url.searchParams.append('end_date', date);

    if (search) {
      url.searchParams.append('search_string', search);
      setIsFetched(false);
    }

    let filtersQuery = [];
    if (department.length && departmentOptions.length !== department.length) {
      filtersQuery.push(`department:${department.map(dept => dept.id)}`);
    }
    if (attendance.length) {
      filtersQuery.push(`attendance:${attendance}`);
    }
    if (status.length && status.length !== 3) {
      filtersQuery.push(`status:${status}`);
    }
    if (activeGroup?.id) {
      filtersQuery.push(`group_id:${activeGroup.id}`);
    }
    if (filtersQuery.length > 0) {
      url.searchParams.append('filters', filtersQuery.join(';'));
    }
    if (resourceId) {
      url.searchParams.append('filters', `resource_ids:${[resourceId]}`);
    }
    // if(filters && filterValue[0] !== 'reset') url.searchParams.append('filters', filters);
    url.searchParams.append('sort_key', sortBy.column.replace('-', '_'));
    url.searchParams.append('sort_desc', sortBy.order === 'ascending' ? 0 : 1);
    // if (searchRef.current && filterValue[0] !== 'reset') url.searchParams.append('search_string', searchRef.current);

    // if (pageNumber !== 1) {
    // url.searchParams.append('starting_point', startingPoint);
    // } else {
    //   url.searchParams.append('page_number', pageNumber);
    // }

    // TODO: Need to work on starting point
    url.searchParams.append('starting_point', isInitialLoad ? startingPoint : startingPoint - BUFFER_SIZE);
    // url.searchParams.append('starting_point', startingPoint !== null ? startingPoint : dataLength || 0);
    // if (pageNumber !== 1) url.searchParams.append('starting_point', 1);
    url = url.toString();
    // if (filters && filterValue[0] !== 'reset') url += `&filters=${filters}`;
    // return;
    // To avoid flickering while sorting using total hours we use loadingFirstPageDataRef
    if (startingPoint === 0) {
      loadingFirstPageDataRef.current = true;
      setLoadingFirstPageData(true);
    }
    updateLoadingData(true);
    setShowLineLoader(true);
    if (dataExists && isInitialLoad) {
      setPrevSelectedDate(selectedDate)
      prevSelectedDateRef.current = selectedDate;
    }
    APIService.apiRequest(url, null, false, 'GET', abortController)
      .then(response => {
        setShowSkeleton(false);
        if (response.status === 1) {
          if (retryAttemptRef.current > 0) {
            retryAttemptRef.current = 0;
          }
          if (!hasTimerAccess) connectSocket();
          if (!dataExists || !isInitialLoad) {
            setPrevSelectedDate(selectedDate);
            prevSelectedDateRef.current = selectedDate
          }
          if (isInitialLoad || pageSize === BUFFER_SIZE) bufferLoadedTimeRef.current = Date.now();

          const tzOffset = getMyTimezoneOffset(date);
          response.output[date].data = response.output[date].data.map((emp) => {
            let special_shift_time = emp.special_shift_time;
            if (Array.isArray(special_shift_time)) {
              special_shift_time = special_shift_time.map((shift) => {
                const [start_time, start_time_offset] = convertHhmmToUserTimezone(
                  shift.special_shift_start_time.time,
                  tzOffset,
                  shift.special_shift_start_time.time_offset
                );

                const [end_time, end_time_offset] = convertHhmmToUserTimezone(
                  shift.special_shift_end_time.time,
                  tzOffset,
                  shift.special_shift_end_time.time_offset
                );

                return {
                  special_shift_start_time: {
                    time: start_time,
                    time_offset: start_time_offset,
                  },
                  special_shift_end_time: {
                    time: end_time,
                    time_offset: end_time_offset,
                  },
                };
              });
            }

            const [approved_start_time, approved_start_time_offset] = emp.approved_start_time.time
              ? convertHhmmToUserTimezone(
                emp.approved_start_time.time,
                tzOffset,
                emp.approved_start_time.time_offset
              ) : [null, null];
            const [approved_end_time, approved_end_time_offset] = emp.approved_end_time.time
              ? convertHhmmToUserTimezone(
                emp.approved_end_time.time,
                tzOffset,
                emp.approved_end_time.time_offset
              ) : [null, null];

            return {
              ...emp,
              special_shift_time,
              approved_start_time,
              approved_start_time_offset,
              approved_end_time,
              approved_end_time_offset,
              expected_back_at: isNotNone(emp.expected_back_at) ? convertUTCToTimezone(emp.expected_back_at) : emp.expected_back_at,
              clock_in_time: isNotNone(emp.clock_in_time) ? convertUTCToTimezone(emp.clock_in_time) : emp.clock_in_time,
              clock_out_time: isNotNone(emp.clock_out_time) ? convertUTCToTimezone(emp.clock_out_time) : emp.clock_out_time,
            };
          });

          setOfficeFloor(oldOfficeFloor => {
            let oldOfficeFloorCopy = structuredClone(oldOfficeFloor);
            // if (!isFilterApplied && oldOfficeFloorCopy && oldOfficeFloorCopy[date]) {
            if (resourceId) {
              let result = customSortFnRef.current(mergeArrayOfObjects(oldOfficeFloorCopy[date].data, response.output[date].data));
              oldOfficeFloorCopy[date].data = updateIsBufferFlag(result, null, true)
              return oldOfficeFloorCopy;
            }

            if (!isInitialLoad) {
              // oldOfficeFloorCopy[date].data = [...oldOfficeFloorCopy[date].data, ...response.output[date].data];
              let result = mergeArrayOfObjects(oldOfficeFloorCopy[date].data, response.output[date].data);
              oldOfficeFloorCopy[date].data = updateIsBufferFlag(result, null, true)
              // if (result.length < response.output[date].meta.total_results) {
              //   oldOfficeFloorCopy[date].data = customSort(result)
              // } else {
              //   oldOfficeFloorCopy[date].data = result;
              // }
            } else {
              if (oldOfficeFloorCopy === null) oldOfficeFloorCopy = {};
              oldOfficeFloorCopy[date] = response.output[date]
              oldOfficeFloorCopy[date].isFilteredData = isFilterApplied;
              const isAllDataFetched = response.output[date].data.length === response.output[date].meta.total_results;
              if (isAllDataFetched) {
                oldOfficeFloorCopy[date].data = oldOfficeFloorCopy[date].data.map(item => {
                  const { shift } = getShiftDetails(item);
                  return { ...item, shift }
                })
              } else {
                oldOfficeFloorCopy[date].data = updateIsBufferFlag(oldOfficeFloorCopy[date].data, null, true)
              }
            }

            return oldOfficeFloorCopy;
          })
          // update search results if search is present in filters
          if (search) {
            if (isInitialLoad) {
              updateSearchResults(response.output[date].data);
            }
          }
          else updateSearchResults([]);
          // update fetching status of search results
          setIsFetched(true);

          const formattedToday = format(convertTimeZone());
          const yesterday = new Date(formattedToday);
          yesterday.setDate(yesterday.getDate() - 1);
          const formattedYesterday = format(yesterday);

          if (!isAppInActive) {
            if (!isTimerStartedRef.current && (formattedToday === date || formattedYesterday === date)) {
              isTimerStartedRef.current = true;
              timeWorkerInstance.postMessage({
                command: 'worker:updateTotalHoursStatus',
                updateTotalHours: true,
              });
            } else if (formattedToday !== date && formattedYesterday !== date) {
              isTimerStartedRef.current = false;
              timeWorkerInstance.postMessage({
                command: 'worker:updateTotalHoursStatus',
                updateTotalHours: false,
              });
            }
          }

          // if (!loadPinnedData) {  // Do not connect socket while loading inital pinned data
          // if (!socketInstanceRef.current) {
          //   const socket = io(Constants.WEB_SOCKET_URL, {
          //     auth: { token: getToken() },
          //     transports: ['websocket'],
          //   });

          //   socket.on("connect_error", (e) => {
          //     console.log(`connect_error due to ${e.message}`, e);
          //     socket.auth.token = getToken();
          //     // socket.connect();
          //   });

          //   socket.on('attendance', (msg) => updateStatus(msg));
          //   setSocketInstance(socket);
          //   socketInstanceRef.current = socket;
          // } else 
          // }
          // if (!loadPinnedData && socketInstanceRef.current && !socketInstanceRef.current.connected) {
          //   console.log('isSocketConnected', socketInstanceRef.current, socketInstanceRef.current?.connected, loadPinnedData);
          //   socketInstanceRef.current.auth.token = getToken();
          //   socketInstanceRef.current.connect();
          // }

          if (startingPoint === 0) {
            loadingFirstPageDataRef.current = false;
            setLoadingFirstPageData(false);
          }
        } else {
          if (!(response instanceof DOMException && response.name === 'AbortError')) {
            if (navigator.onLine && response?.message === 'Failed to fetch' && retryAttemptRef.current < 3) {
              retryAttemptRef.current += 1;
              retryAttemptTimeoutRef.current = setTimeout(() => {
                getOfficeFloorData({ controller, startingPoint, showLoader, pageSize });
                retryAttemptTimeoutRef.current = null;
              }, 1000);
              return;
            }
            alertService.error(response.msg || `Error occured while fetching office floor data`);
            if (date !== format(prevSelectedDateRef.current)) {
              handleDateChange(() => prevSelectedDateRef.current);
            }
            if (startingPoint === 0) {
              loadingFirstPageDataRef.current = false;
              setLoadingFirstPageData(false);
            }
          }
        }
        if (!abortController?.signal?.aborted || response.msg === 'Failed to fetch') {
          setShowLineLoader(false);
          updateLoadingData(false);
        }
      })
      .catch(err => {
        alertService.error(`Error: ${err.message}`);
        console.log('getOfficeFloorData - error', err);
        setShowSkeleton(false);
        if (startingPoint === 0) {
          loadingFirstPageDataRef.current = false;
          setLoadingFirstPageData(false);
        }
        if (!abortController?.signal?.aborted || err.message === 'Failed to fetch') {
          setShowLineLoader(false);
          updateLoadingData(false);
        }
      })
  }, [PAGE_SIZE, selectedDate, officeFloor, search, department, departmentOptions.length, attendance, status, sortBy.column, sortBy.order, BUFFER_SIZE, setShowLineLoader, hasTimerAccess, isAppInActive, mergeArrayOfObjects, updateIsBufferFlag, isFilterApplied, timeWorkerInstance, handleDateChange, activeGroup?.id]);

  const customSort = useCallback((data, skipBuffer = false) => {
    let sortedData = structuredClone(data);
    const officeDataCopy = officeFloorRef.current;
    let sortByColumn = sortByRef.current.column;
    let isAscending = sortByRef.current.order === 'ascending';

    if (sortByRef.current.column === 'office_floor') sortByColumn = 'first_name';
    if (sortByRef.current.column === 'shift_total') sortByColumn = 'total_time';
    if (sortByRef.current.column.includes('clock')) sortByColumn += '_time';
    sortByColumn = sortByColumn.replaceAll('-', '_');

    sortedData.sort((a, b) => {
      const aName = (a.first_name + a.last_name + a.resource_id).replaceAll(" ", "").toLocaleLowerCase();
      const bName = (b.first_name + b.last_name + b.resource_id).replaceAll(" ", "").toLocaleLowerCase();
      if (sortByColumn === 'total_break_time') {
        a['total_break_time'] = calculateTotalTime(a.punctual_break_time, a.late_break_time);
        b['total_break_time'] = calculateTotalTime(b.punctual_break_time, b.late_break_time);
      }
      if (a[sortByColumn] === b[sortByColumn] && sortByColumn !== 'first_name') {
        if (isAscending) {
          return aName > bName ? 1 : -1;
        } else {
          return aName < bName ? 1 : -1;
        }
      }
      if ((a[sortByColumn] === 'None' && isAscending) || (b[sortByColumn] === 'None' && !isAscending)) return -1;
      if ((a[sortByColumn] === 'None' && !isAscending) || (b[sortByColumn] === 'None' && isAscending)) return 1;
      if (sortByColumn === 'first_name') {
        const isASelf = a.resource_id === loggedInUserRef.current?.resource_id;
        const isBSelf = b.resource_id === loggedInUserRef.current?.resource_id;
        if (isASelf) {
          return isAscending ? -1 : 1;
        }
        if (isBSelf) {
          return isAscending ? 1 : -1;
        }
        return isAscending ? aName > bName ? 1 : -1 : aName < bName ? 1 : -1;
      }

      return isAscending ? a[sortByColumn] > b[sortByColumn] ? 1 : -1 : a[sortByColumn] < b[sortByColumn] ? 1 : -1;
    })

    const index = officeDataCopy?.[formattedSelectedDate]?.data?.findIndex(item => item.isBuffer);
    // Do not update buffer if buffer is not present which means all data is fetched
    if (!skipBuffer && index !== undefined && index !== -1) {
      let bufferLength = officeDataCopy?.[formattedSelectedDate]?.data?.filter(item => item.isBuffer).length;
      let shouldUpdateBuffer = false;
      for (let i = 0; i < bufferLength; i++) {
        if (sortedData?.[index + i]?.resource_id && officeDataCopy?.[formattedSelectedDate]?.data?.[index + i]?.resource_id !== sortedData[index + i].resource_id) {
          shouldUpdateBuffer = true;
          break;
        }
      }
      if (shouldUpdateBuffer && !loadingData) {
        // Check if the buffer can be called again after a 1-minute cooldown. This is to avoid calling buffer again and again
        const canCallBuffer = bufferLoadedTimeRef.current !== null && bufferLoadedTimeRef.current - Date.now() < -60000;
        console.log('call buffer update', new Date(), sortedData.map(e => `${e.first_name} ${e.total_time}`), officeDataCopy?.[formattedSelectedDate]?.data?.map(e => `${e.first_name} ${e.total_time}`));
        if (canCallBuffer) {
          console.log('call buffer update with buffer', new Date(), sortedData.map(e => `${e.first_name} ${e.total_time}`), officeDataCopy?.[formattedSelectedDate]?.data?.map(e => `${e.first_name} ${e.total_time}`));
          getOfficeFloorData({ startingPoint: index + 1, showLoader: false, pageSize: BUFFER_SIZE })
        }
      }
    }

    return skipBuffer ? sortedData : updateIsBufferFlag(sortedData);
  }, [BUFFER_SIZE, getOfficeFloorData, calculateTotalTime, formattedSelectedDate, updateIsBufferFlag, loadingData]);

  useEffect(() => {
    customSortFnRef.current = customSort;
  }, [customSort]);

  const updateStatus = useCallback(message => {
    const data = message.data;
    const resId = +data.resource_id;
    const action = data.action;

    // let currentDate = format(convertTimeZone());
    const date = data.date;

    if (officeFloorRef?.current?.[date] && format(prevSelectedDateRef.current) === date) {
      // if (data.clock_in_time !== 'None' && formatDates(selectedDateRef.current, 'yyyy-mm-dd') === formatDates(new Date(data.clock_in_time), 'yyyy-mm-dd')) {
      let officeFloorCopy = structuredClone(officeFloorRef.current);
      // Update user info on status change
      let { first_name, middle_name, last_name, resource_id, attendance, department, status, clock_in_time, clock_out_time, special_shift_time, is_adhoc_shift } = data;
      let isClockedIn = isNotNone(clock_in_time);
      let isSearchMatching = searchRef.current ? ((first_name + middle_name + last_name).trim().toLocaleLowerCase().includes(searchRef.current.toLocaleLowerCase()) || searchRef.current === String(resource_id)) : true;

      let isSameDept = departmentRef.current.length ? departmentRef.current.some(dept => dept.name === department) : true

      let includesOnLeave = attendanceRef.current.includes('on_leave');
      let includesOnSpecialShift = attendanceRef.current.includes('special_shift');
      let includesOnAdhocShift = attendanceRef.current.includes('adhoc_shift');

      let includesEarly = attendanceRef.current.includes('early');
      let includesLate = attendanceRef.current.includes('late');
      let includesOnTime = attendanceRef.current.includes('on_time');

      // let includesNotStarted = statusRef.current.includes('not_started');
      let includesOnline = statusRef.current.includes('online');
      let includesAway = statusRef.current.includes('on_break') && isClockedIn;
      // let includesAway = statusRef.current.includes('on_break');
      let includesClockedOut = statusRef.current.includes('clocked_out');

      let isLeaveMatching = includesOnLeave ? attendance.includes('leave') : true;
      let isSpecialShiftMatching = includesOnSpecialShift ? isNotNone(special_shift_time) : true;
      let isAdhocShiftMatching = includesOnAdhocShift ? isNotNone(is_adhoc_shift) : true;
      let includesPunctuality = includesEarly || includesLate || includesOnTime;
      let isWorkingStatusMatching = includesPunctuality ? attendanceRef.current.includes(attendance) : true;
      let isPresenceMatching = (includesOnline || includesAway || includesClockedOut) ? isNotNone(clock_out_time) ? includesClockedOut : status === 'On' ? includesOnline : includesAway : true;

      let shiftStatusMatching =
        (includesOnLeave && isLeaveMatching) ||
        (includesOnSpecialShift && isSpecialShiftMatching) ||
        (includesOnAdhocShift && isAdhocShiftMatching) ||
        (includesPunctuality && isWorkingStatusMatching);

      if (
        !includesOnLeave &&
        !includesOnSpecialShift &&
        !includesOnAdhocShift &&
        !includesPunctuality
      ) {
        shiftStatusMatching =
          isLeaveMatching &&
          isSpecialShiftMatching &&
          isAdhocShiftMatching &&
          isWorkingStatusMatching;
      }

      // const expected_back_at = isDate(data.expected_back_at) ? format(convertTimeZone(new Date(data.expected_back_at)), 'YYYY-MM-DD HH:mm:ss') : data.expected_back_at;
      // const clockInDateTime = data.clock_in_time !== 'None' ? format(convertTimeZone(new Date(data.clock_in_time)), 'YYYY-MM-DD HH:mm:ss') : data.clock_in_time;
      // const clockOutDateTime = data.clock_out_time !== 'None' ? format(convertTimeZone(new Date(data.clock_out_time)), 'YYYY-MM-DD HH:mm:ss') : data.clock_out_time;

      const presentInGroup = activeGroup?.default ? true : activeGroup.resource_ids.includes(resId)

      // check if filter (dept, on leave, working, special shift, etc.), search string and page number matches the result
      if (isSearchMatching && isSameDept && isPresenceMatching && shiftStatusMatching && presentInGroup) {

        if (loadingDataRef.current && resId === loggedInUserRef.current.resource_id && action !== "User checked in") {
          getOfficeFloorData({ controller: officeFloorAbortControllerRef.current, startingPoint: 0, showLoader: false, resourceId: resId });
        }

        const { shift } = getShiftDetails(data);
        const tzOffset = getMyTimezoneOffset(date);
        if (Array.isArray(special_shift_time)) {
          special_shift_time = special_shift_time.map(shift => {
            const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.special_shift_start_time.time, tzOffset, shift.special_shift_start_time.time_offset);
            const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.special_shift_end_time.time, tzOffset, shift.special_shift_end_time.time_offset);
            return {
              special_shift_start_time: {
                time: start_time,
                time_offset: start_time_offset,
              },
              special_shift_end_time: {
                time: end_time,
                time_offset: end_time_offset,
              }
            }
          });
        }

        const [approved_start_time, approved_start_time_offset] =
          data.approved_start_time.time
            ? convertHhmmToUserTimezone(data.approved_start_time.time, tzOffset, data.approved_start_time.time_offset)
            : [null, null];
        const [approved_end_time, approved_end_time_offset] =
          data.approved_end_time.time
            ? convertHhmmToUserTimezone(data.approved_end_time.time, tzOffset, data.approved_end_time.time_offset)
            : [null, null];

        const newData = {
          ...data,
          shift,
          special_shift_time,
          approved_start_time,
          approved_start_time_offset,
          approved_end_time,
          approved_end_time_offset,
          expected_back_at: isNotNone(data.expected_back_at) ? convertUTCToTimezone(data.expected_back_at) : data.expected_back_at,
          clock_in_time: isNotNone(data.clock_in_time) ? convertUTCToTimezone(data.clock_in_time) : data.clock_in_time,
          clock_out_time: isNotNone(data.clock_out_time) ? convertUTCToTimezone(data.clock_out_time) : data.clock_out_time,
        }

        // If data matches with filters and already loaded then udpate the data
        if (officeFloorCopy?.[date]?.data?.some(cand => +cand.resource_id === +resId)) {
          officeFloorCopy[date].data = customSort(officeFloorCopy[date]?.data.map(cand => +cand.resource_id === +resId ? newData : cand))
        } else {
          // If data matches with filters and not loaded already then add the user and remove last user after sorting
          // remove only if last page is not loaded

          // TODO: handle page size, meta here and sorting (once sorting is implemented)
          if (officeFloorCopy?.[date]?.meta?.total_results === officeFloorCopy?.[date]?.data?.length) {
            officeFloorCopy[date].meta.total_results += 1;
          }
          officeFloorCopy[date].data = customSort([...officeFloorCopy?.[date]?.data, newData], true);
          if (officeFloorCopy?.[date]?.meta?.total_results !== officeFloorCopy?.[date]?.data?.length) {
            officeFloorCopy[date].data.pop();
          }
          const totalResults = officeFloorCopy[date].meta.total_results;
          const dataLength = officeFloorCopy[date].data.length;
          officeFloorCopy[date].data = updateIsBufferFlag(officeFloorCopy[date].data, totalResults === dataLength);
          // updateMeta('add');
        }
      } else {
        if (officeFloorCopy[date].data.some(cand => +cand.resource_id === +resId)) {
          // TODO: handle page content and meta since we are removing a candidate
          // No need to fetch last entry if we are using starting point
          // Make sure to fetch only last entry of that page by updating page number and page size
          officeFloorCopy[date].data = officeFloorCopy[date].data.filter(cand => +cand.resource_id !== +resId);
          officeFloorCopy[date].meta.total_results -= 1;
          // let shouldUpdate = updateMeta('subtract');
          // shouldUpdate && getHomePageData(['fetchLastEntry', homePageMetaRef.current.page_number * PAGE_SIZE], false);
        } else {
          // Do nothing
        }
      }

      setOfficeFloor(() => officeFloorCopy);
    }
  }, [customSort, updateIsBufferFlag, activeGroup, getOfficeFloorData]);

  // useEffect(() => {

  //   if (ac.signal.aborted) {
  //     ac = new AbortController();
  //   }
  //   getOfficeFloorData({ controller: ac, loadPinnedData: true });

  //   return () => {
  //     // socketInstanceRef.current && socketInstanceRef.current.disconnect();
  //     ac.abort();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!isAppInActive) socket.on("attendance", updateStatus);
    return () => {
      if (!isAppInActive) socket.off("attendance", updateStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, isAppInActive]);

  useEffect(() => {
    const showLoader = selectedDate === selectedDateRef.current;
    selectedDateRef.current = selectedDate;
    searchRef.current = search;
    departmentRef.current = department;
    attendanceRef.current = attendance
    statusRef.current = status;
    sortByRef.current = sortBy;

    if (ac.signal.aborted) {
      ac = new AbortController();
      officeFloorAbortControllerRef.current = ac;
    }
    // const onOnlineCallback = () => {
    //   if (isAppInActive) return;
    //   if (ac.signal.aborted) {
    //     ac = new AbortController();
    //   }
    //   retryAttemptTimeoutRef.current = setTimeout(() => {
    //     getOfficeFloorData({ controller: ac });
    //     retryAttemptTimeoutRef.current = null;
    //   }, 1000);
    // }
    // window.addEventListener('online', onOnlineCallback);
    getOfficeFloorData({ controller: ac, showLoader });

    // TODO: check and add this if user does not have timer privilege
    // if (isElectronApp) window.ipcRender.receive('refreshAttendanceData', getOfficeFloorData);

    return () => {
      // if (isElectronApp) window.ipcRender.removeAllListeners('refreshAttendanceData', getOfficeFloorData);
      // window.removeEventListener('online', onOnlineCallback);
      ac.abort();
      // if (retryAttemptTimeoutRef.current) clearTimeout(retryAttemptTimeoutRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, search, department, attendance, status, sortBy, activeGroup?.id]);

  useEffect(() => {
    const callback = () => {
      // if (window.navigator.onLine) {
      //   alertService.info(`[SOCKET] ${error.message}`);
      //   socketInstance.auth.token = getToken();
      //   socketInstance.connect();
      // }
      if (attendanceLoadTimeStampRef.current.getTime() + 5000 < new Date().getTime()) {
        getOfficeFloorData();
      }
    }

    socket.on("connect", callback);
    return () => socket.off("connect", callback);
  }, [getOfficeFloorData]);

  useEffect(() => {
    if (!hasTimerAccess) {
      if (isElectronApp) window.ipcRender.receive('refreshAttendanceData', getOfficeFloorData);
      attendanceLoadTimeStampRef.current = new Date();
    }

    return () => {
      if (!hasTimerAccess && isElectronApp) window.ipcRender.removeAllListeners('refreshAttendanceData', getOfficeFloorData);
    }
  }, [hasTimerAccess, getOfficeFloorData]);

  useEffect(() => {

    function stopTotalHoursUpdate() {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTotalHoursStatus',
        updateTotalHours: false,
      });
      isTimerStartedRef.current = false;
      console.log('inside stopTotalHoursUpdate');
    }

    if (isElectronApp) {
      window.ipcRender.receive('stopInterval', () => {
        stopTotalHoursUpdate();
      })
    }

    function updateTotalHours(e) {
      const now = convertTimeZone();
      const todayDate = format(now, 'YYYY-MM-DD');
      const yesterdayDate = format(subDays(now, 1), 'YYYY-MM-DD')
      const selectedDate = format(selectedDateRef.current, 'YYYY-MM-DD');
      if (
        e.data.command === "updateTotalHours" &&
        [todayDate, yesterdayDate].includes(selectedDate)
      ) {
        setOfficeFloor((officeFloor) => {
          // const currentDate = format(convertTimeZone());
          const formattedSelectedDate = format(selectedDateRef.current);
          let officeFloorCopy = structuredClone(officeFloor);
          if (officeFloorCopy?.hasOwnProperty(formattedSelectedDate)) {
            officeFloorCopy[formattedSelectedDate].data = officeFloorCopy[
              formattedSelectedDate
            ].data.map((user) => {
              const isUserOnline = user.status === "On";
              const isUserClockedIn = isNotNone(user.clock_in_time);
              const isUserClockedOut = isNotNone(user.clock_out_time);
              if (
                isUserOnline &&
                isNotNone(user.total_time) &&
                isUserClockedIn &&
                !isUserClockedOut
              ) {
                const total_time =
                  isElectronApp &&
                    user?.resource_id === loggedInUserRef.current?.resource_id
                    ? e.data.time || user.total_time
                    : user.total_time + 1;

                if (user.shift === "Standard") {
                  const startTime = getDateFromHhmm(user.approved_start_time, user.date);
                  startTime.setDate(startTime.getDate() + user.approved_start_time_offset);

                  const endTime = getDateFromHhmm(user.approved_end_time, user.date);
                  endTime.setDate(endTime.getDate() + user.approved_end_time_offset);
                  if (isWithinInterval(now, { start: startTime, end: endTime })) {
                    user.overlap_seconds += total_time - user.total_time; // 2000 + (2111 - 2110) = 2001
                  }
                }

                return { ...user, total_time };
              }

              if (
                !isUserOnline &&
                isUserClockedIn &&
                !isUserClockedOut
              ) {
                const now = convertTimeZone();
                const isUserInactive = user.expected_back_at === "None";
                const isUserLate = isDate(user.expected_back_at)
                  ? now.getTime() > convertUTCToTimezone(user.expected_back_at).getTime()
                  : false;
                const inactivity_time =
                  isUserInactive && isNotNone(user.inactivity_time)
                    ? user.inactivity_time + 1
                    : user.inactivity_time;
                const late_break_time =
                  isUserLate &&
                    !isUserInactive &&
                    isNotNone(user.late_break_time)
                    ? user.late_break_time + 1
                    : user.late_break_time;
                const punctual_break_time =
                  !isUserLate &&
                    !isUserInactive &&
                    isNotNone(user.punctual_break_time)
                    ? user.punctual_break_time + 1
                    : user.punctual_break_time;

                return {
                  ...user,
                  late_break_time: late_break_time,
                  punctual_break_time: punctual_break_time,
                  inactivity_time: inactivity_time,
                };
              }

              return user;
            });
            if (sortByRef.current.update && !loadingFirstPageDataRef.current) {
              officeFloorCopy[formattedSelectedDate].data = customSortFnRef.current(
                officeFloorCopy[formattedSelectedDate].data
              );
            }
            // return sortByRef.current.column === 'shift_total' && !loadingFirstPageDataRef.current ? customSort(homePageDataCopy, sortByRef.current.column, sortByRef.current.order) : homePageDataCopy;
          }
          return officeFloorCopy;

          // let officeFloorCopy = officeFloor.map(cand => cand.status === 'On' && cand.total_time !== 'None' && cand.clock_in_time !== 'None' && cand.clock_out_time === 'None' ? { ...cand, total_time: cand.resource_id === isElectronApp && loggedInUser.resource_id ? e.data.time || cand.total_time : cand.total_time + 1 } : cand);
        })
      }
    }

    timeWorkerInstance.addEventListener("message", updateTotalHours, false);

    return () => {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTotalHoursStatus',
        updateTotalHours: false,
      });
      isTimerStartedRef.current = false;
      timeWorkerInstance.removeEventListener("message", updateTotalHours, false)
    }

  }, [timeWorkerInstance]);

  const handleSort = (colName, order = 'descending') => {
    const isSortableColumn = ['shift_total', 'punctual_break_time', 'late_break_time', 'inactivity_time', 'total_break_time'].includes(colName);
    const newSortBy = {
      column: colName,
      order: sortBy.column === colName ? (sortBy.order === 'ascending' ? 'descending' : 'ascending') : order,
      update: isSortableColumn, // This is used for updating values dynamically
    };
    // TODO: This (zIndex change) is a temp work around for fixing tooltip issue. Need to remove once the tooltip issue is fixed
    const elem = document.querySelector('.attendance__filters');
    if (elem) elem.style.zIndex = 99999;
    setSortBy(newSortBy);
    setTimeout(() => {
      if (elem) elem.style.zIndex = 9;
    }, 0);
  };

  const clearSort = () => {
    setSortBy({ column: 'office_floor', order: 'ascending', update: false });
  }

  const toggleBreaksMenu = () => {
    setShowBreaksMenu(!showBreaksMenu);
  }

  const toggleInactivityMenu = () => {
    setShowInactivityMenu(!showInactivityMenu);
  }

  const isBreakSortingApplied = () => {
    return BREAK_SUB_OPTIONS.some(item => item.value === sortBy.column);
  }

  const isInactivitySortingApplied = () => {
    return INACTIVITY_SUB_OPTIONS.some(item => item.value === sortBy.column);
  }

  const [handleBreakHeaderClick, handleBreakHeaderDoubleClick] = useClickPreventionOnDoubleClick(toggleBreaksMenu, clearSort);
  const [handleInactivityHeaderClick, handleInactivityHeaderDoubleClick] = useClickPreventionOnDoubleClick(toggleInactivityMenu, clearSort);
  const [handleSortClick, handleSortDoubleClick] = useClickPreventionOnDoubleClick(handleSort, clearSort);

  const getShiftHeader = () => {
    const isSortingApplied = sortBy.column === 'shift';
    return (
      <div className="header">
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              isSortingApplied
                ? ' chevron clock-in-out-header' +
                (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down')
                : ''
            }
            onClick={() => handleSortClick('shift', 'ascending')}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            Shift
          </span>
        </MouseTooltip>
      </div>
    );
  };

  const getBreaksHeader = () => {
    const isBreakSorting = isBreakSortingApplied();
    return <div className='sub-menu-wrapper header'>
      <MouseTooltip
        asChild
        delay={500}
        show={isBreakSorting && !showBreaksMenu}
        content={'Double click to remove sorting'}
        style={{ lineHeight: "17px" }}
      >
        <span
          className={'breaks-header' +
            (isBreakSorting
              ? " chevron" +
              (sortBy.order === "ascending"
                ? " chevron-up"
                : " chevron-down")
              : "")
          }
          onClick={handleBreakHeaderClick}
          onDoubleClick={() => {
            if (!showBreaksMenu && isBreakSorting) {
              handleBreakHeaderDoubleClick()
            }
          }}
        >
          Breaks
        </span>
      </MouseTooltip>
      {showBreaksMenu &&
        <HeaderSubMenu
          showMenu={showBreaksMenu}
          setShowMenu={setShowBreaksMenu}
          sortBy={sortBy}
          menuOptions={BREAK_SUB_OPTIONS}
          onOptionClick={handleSort}
        />
      }
    </div>
  }

  const getInactivityHeader = () => {
    const isInactivitySorting = isInactivitySortingApplied()
    return <div className='sub-menu-wrapper header'>
      <MouseTooltip
        asChild
        delay={500}
        show={isInactivitySorting && !showInactivityMenu}
        content={'Double click to remove sorting'}
        style={{ lineHeight: "17px" }}
      >
        <span
          className={'inactivity-header' +
            (isInactivitySorting
              ? " chevron" +
              (sortBy.order === "ascending"
                ? " chevron-up"
                : " chevron-down")
              : "")
          }
          onClick={handleInactivityHeaderClick}
          onDoubleClick={() => {
            if (!showInactivityMenu && isInactivitySorting) {
              handleInactivityHeaderDoubleClick()
            }
          }}
        >
          Inactivity
        </span>
      </MouseTooltip>
      {showInactivityMenu &&
        <HeaderSubMenu
          showMenu={showInactivityMenu}
          setShowMenu={setShowInactivityMenu}
          sortBy={sortBy}
          menuOptions={INACTIVITY_SUB_OPTIONS}
          onOptionClick={handleSort}
        />
      }
    </div>
  }

  const getClockInOutHeader = (key, val) => {
    const isSortingApplied = sortBy.column === key;
    return (
      <div className="header">
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              isSortingApplied
                ? ' chevron clock-in-out-header' +
                (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down')
                : ''
            }
            onClick={() => handleSortClick(key)}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            {val}
          </span>
        </MouseTooltip>
      </div>
    );
  };

  const getShiftTotalHeader = () => {
    const isSortingApplied = sortBy.column === 'shift_total';
    return (
      <div className='header'>
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              `shift-total ${isSortingApplied ? ' chevron' + (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down') : ''}`
            }
            onClick={() => handleSort('shift_total')}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            Total Hrs
          </span>
        </MouseTooltip>
        <span className='overlap-title'>Scheduled Hrs</span>
      </div>
    );
  };

  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const { toggleTeamMemberFilters } = useTeamMemberFilter();
  const [activePopupResourceId, setActivePopupResourceId] = useState(null);

  function handleMemberSelection(resourceId, employee) {

    if (!loadingData) {
      if (!resourceId) {
        setSelectedMemberId(null);
        toggleTeamMemberFilters(false);
        return;
      } else if (!selectedMemberId) {
        const usersTableElem = document.querySelector('.users-table');
        if (usersTableElem && usersTableElem.scrollTop > 0) {
          setTimeout(() => {
            usersTableElem.scrollTo({
              top: Math.max(usersTableElem.scrollTop - (2 * parseInt(rowHeight)), 0),
              behavior: "smooth",
            });
          }, 300);
        }
      }
      setSelectedMemberId(selectedMemberId === resourceId ? null : resourceId);
      toggleTeamMemberFilters(selectedMemberId !== resourceId);
      const extraDetails = { activeGroup, setActivePopupResourceId, removeResourceFromGroup, toggleAnalytics, showAnalyticsFor, prevSelectedDate, formattedSelectedDate }
      setLastSearchedResult(employee ? { ...employee, ...extraDetails } : null)
    }
  }

  useEffect(() => {
    setOnResultClick(() => handleMemberSelection);
    // setHandleLoadMore(() => () => {
    //   getOfficeFloorData({
    //     startingPoint: officeFloor[formattedSelectedDate]?.data?.length,
    //   })
    // });
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setShowLineLoader(false), []);

  const usersTableRef = useRef(null);
  const attendanceTableScrollTopRef = useRef(0);
  useLayoutEffect(() => {
    if (selectedMemberId != null) {
      const el = usersTableRef.current;
      if (el) {
        el.scrollTop = attendanceTableScrollTopRef.current
      }
    }
  }, [selectedMemberId])

  const getSkeleton = () => {
    let result = [];
    for (let i = 0; i < (tableEmptyRowsCount - 1); i++) {
      result.push(
        <TeamMemberSkeletonRow selectedMemberId={selectedMemberId} key={`skeleton_row_${i}`} />
      );
    }

    return result;
  }

  const removeResourceFromGroup = (id) => {
    setOfficeFloor(officeFloor => {
      let officeFloorCopy = structuredClone(officeFloor);
      officeFloorCopy[formattedSelectedDate].data = officeFloorCopy[formattedSelectedDate].data.filter(cand => +cand.resource_id !== +id);
      officeFloorCopy[formattedSelectedDate].meta.total_results -= 1;
      return officeFloorCopy;
    })
  }

  const getOfficeFloorContent = (obj, isPinnedData = false) => {
    if (loadingData && !obj) return null;
    const formattedSelectedDate = format(prevSelectedDate);

    if (obj?.[formattedSelectedDate]?.data?.length > 0) {
      const { data, meta } = obj[formattedSelectedDate];

      let result = data.map((emp, index) => {
        if (emp?.isBuffer) return null;

        const isClockedIn = isNotNone(emp.clock_in_time);
        const isClockedOut = isNotNone(emp.clock_out_time);
        const isOnLeave = emp.attendance.includes("leave");
        const isOnline = emp.status === "On";
        const hasTakenBreak = isNotNone(emp?.break_count);
        const awayTime =
          !isOnline && isClockedIn && hasTakenBreak && isNotNone(emp.expected_back_at)
            ? getAwayTime(emp.expected_back_at)
            : null;

        let expected_back_at =
          emp.expected_back_at === "None"
            ? ""
            : getFormattedTimeAndDiff(emp.expected_back_at, prevSelectedDate);
        let formattedExpectedBackAt = "",
          isOverdue = false;
        if (expected_back_at) {
          let d = convertTimeZone();
          let expectedTimePlus5Mins = new Date(emp.expected_back_at).getTime();
          formattedExpectedBackAt =
            expected_back_at.time +
            (expected_back_at.diff ? ` (${expected_back_at.diff})` : "");
          isOverdue = d > expectedTimePlus5Mins;
        }

        let status = "",
          tooltip = "";
        if (isOnline) {
          // if (emp?.special_shift_activity_tracked === 0) {
          //   status = "online-working-afk";
          //   tooltip = "Working AFK";
          // } else {
          // }
          status = "online";
          tooltip = "Online";
        } else {
          if (isClockedOut) {
            status = "sign-off";
            tooltip = "Signed off";
          } else {
            // we add leave class for both leave and not clocked in
            if (isOnLeave) {
              status = isClockedIn ? "away" : "leave";
              tooltip = isClockedIn
                ? `${isOverdue ? "Was expected back" : "Back"} at ${!emp.expected_back_at || emp.expected_back_at === "None"
                  ? "unknown"
                  : formattedExpectedBackAt
                }`
                : "On Leave";
            } else {
              status = isClockedIn ? "away" : "sign-off";
              tooltip = isClockedIn
                ? `${isOverdue ? "Was expected back" : "Back"} at ${!emp.expected_back_at || emp.expected_back_at === "None"
                  ? "unknown"
                  : formattedExpectedBackAt
                }`
                : "Not started";
            }
          }
        }
        const isDarkMode = document.body.dataset.theme === "dark";
        const isLoggedInUser = loggedInUserRef.current?.resource_id === emp.resource_id;

        const calculatedMargin = getIconMargin();

        return (
          <tr key={`${index}-${emp.resource_id}`} className={clsx({ active: activePopupResourceId === emp.resource_id || showAnalyticsFor === emp.resource_id })}>
            <td
              className={clsx("name-container", {
                selected: selectedMemberId === emp.resource_id,
                "not-selected":
                  selectedMemberId && selectedMemberId !== emp.resource_id,
                disabled: !selectedMemberId && loadingData,
                active: activePopupResourceId === emp.resource_id || showAnalyticsFor === emp.resource_id,
              })}
              onClick={() => {
                if (selectedMemberId === emp.resource_id) return;
                handleMemberSelection(emp.resource_id, emp)
              }}
            >

              <MouseTooltip
                asChild
                delay={500}
                content={tooltip}
                style={{
                  lineHeight: "17px",
                  color:
                    isOverdue && isClockedIn && !isClockedOut
                      ? "#F00"
                      : isDarkMode
                        ? "#B1B2B3"
                        : "#000",
                }}
              >
                <div className={`status-container ${status}`}></div>
              </MouseTooltip>
              <UserProfileCard
                resourceId={emp.resource_id}
                isLoggedInUser={isLoggedInUser}
                user={emp}
                selectedMemberId={selectedMemberId}
              >
                <div className="icon-wrapper" {...selectedMemberId ? { style: { right: calculatedMargin } } : {}}>
                  <MouseTooltip
                    show={!selectedMemberId || (selectedMemberId && selectedMemberId !== emp.resource_id)}
                    asChild
                    delay={500}
                    content="Analytics"
                    style={{ lineHeight: "17px" }}
                  >
                    <div
                      className="icon icon-anlytics"
                      id={`user-analytics-${emp.resource_id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showAnalyticsFor === emp.resource_id) return;
                        toggleAnalytics(e, emp.resource_id);
                        if (selectedMemberId) handleMemberSelection(emp.resource_id, emp)
                      }}
                    ></div>
                  </MouseTooltip>
                  {/* <div className='icon icon-smiley'></div> */}
                  {groups.length > 0 && (
                    <GroupPopup
                      empId={emp.resource_id}
                      activeGroup={activeGroup}
                      setActivePopupResourceId={setActivePopupResourceId}
                      removeResourceFromGroup={(empId) => {
                        setActivePopupResourceId(null);
                        setActiveGroup(prev => ({
                          ...prev,
                          resource_ids: prev.resource_ids.filter(id => id !== empId)
                        }))
                        setOfficeFloor(officeFloor => {
                          let officeFloorCopy = structuredClone(officeFloor);
                          officeFloorCopy[formattedSelectedDate].data = officeFloorCopy[formattedSelectedDate].data.filter(cand => +cand.resource_id !== +empId);
                          officeFloorCopy[formattedSelectedDate].meta.total_results -= 1;
                          return officeFloorCopy;
                        })
                      }}
                    />
                  )}
                </div>
              </UserProfileCard>
              {/* </Wrapper> */}
            </td>
            {/* {!selectedMemberId && (
              <> */}
            <td className="capitalize">
              <ShiftButton
                data={emp}
                date={formattedSelectedDate}
                selectedShiftMemberId={selectedShiftMemberId}
                onClick={(e) => {
                  e.stopPropagation();
                  if (selectedShiftMemberId === emp.resource_id) return;
                  setSelectedShiftMemberId(emp.resource_id);
                }}
                disabled={loadingData}
              />
            </td>
            <td>
              <ClockTimeDetails data={emp} date={prevSelectedDate} />
            </td>
            <td>
              <div className="user-details">
                <BreakDetails data={emp} isLoggedInUser={isLoggedInUser} />
                <div
                  className={
                    "bg-gray away-time" + (awayTime < 0 ? " red" : "")
                  }
                >
                  {awayTime !== null && !isClockedOut
                    ? formatTimeInSeconds(Math.abs(awayTime))
                    : ""}
                </div>
              </div>
              {/* </div> */}
            </td>
            <td>
              {isNotNone(emp.inactivity_count) && (
                <InactivityDetails data={emp} />
              )}
            </td>
            <td>
              <ShiftLog log={emp} />
            </td>
            {/* </>
            )} */}
          </tr>
        );
      });

      if (Math.ceil(meta.num_pages) <= 1) {
        const emptyRowsCount = (tableEmptyRowsCount - 1) - meta.total_results;
        if (emptyRowsCount > 0) {
          for (let i = 0; i < emptyRowsCount; i++) {
            result.push(
              <TeamMemberSkeletonRow
                key={`skeleton-row-${i}`}
                selectedMemberId={selectedMemberId}
                handleMemberSelection={handleMemberSelection}
              />
            );
          }
        }
      }


      return [
        // <TeamMemberSkeletonRow selectedMemberId={selectedMemberId} className='spare-skeleton-row collapse' key={'skeleton-1'} />,
        // <TeamMemberSkeletonRow selectedMemberId={selectedMemberId} className='spare-skeleton-row collapse' key={'skeleton-2'} />,
        <SpareRow hide={!selectedMemberId} key={'spare-row'} />,
        ...result];

    } else {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            No data to display
          </td>
        </tr>
      );
    }
  };

  const { width } = useWindowDimensions()

  useEffect(() => {
    const skeletonRows = document.querySelectorAll('.spare-skeleton-row');
    if (selectedMemberId && lastSearchedResult) {
      [...skeletonRows]?.forEach((row) => row.classList.remove('collapse'))

    } else {
      [...skeletonRows]?.forEach((row) => row.classList.add('collapse'))
    }

  }, [selectedMemberId, lastSearchedResult])
  // const isDarkMode = document.body.dataset.theme === "dark";
  // const stylingOptions = isDarkMode ? TOOLTIP_STYLING_OPTIONS_DARK : TOOLTIP_STYLING_OPTIONS_LIGHT;

  const [selectedShiftMemberId, setSelectedShiftMemberId] = useState(null);

  return (
    <div
      className={`attendance-table-wrapper ${selectedMemberId ? 'side-by-side-table' : ''}`}
      style={{ "--table-row-height": `${rowHeight}px` }}
    >
      {width > 640 ? (
        <>
          <div className="users-table" ref={usersTableRef} onScroll={e => (attendanceTableScrollTopRef.current = e.target.scrollTop)}>
            <table id="all-users-table" className="attendance-table">
              <thead>
                <tr>

                  <th
                    className={
                      'office-floor-header' + (selectedMemberId ? ' team-member-header' : '')
                    }
                  >
                    {/* office floor header */}
                    <div className="office-floor-header-inner">
                      <GroupsPopup
                        sortBy={sortBy}
                        handleSort={handleSort}
                        activeGroup={activeGroup}
                        onGroupSelect={(group) => setActiveGroup(group)}
                      />
                    </div>
                  </th>
                  {/* {!selectedMemberId && ( */}
                  <>
                    <th style={{ width: '100px' }}>
                      {getShiftHeader()}
                    </th>
                    <th style={{ width: '190px' }}>
                      <div className="clock-info-wrapper">
                        {getClockInOutHeader('clock_in', 'Clock-In')}
                        {getClockInOutHeader('clock_out', 'Clock-Out')}
                      </div>
                    </th>
                    <th
                      style={{ width: '274px' }}
                      className={`${showBreaksMenu || showInactivityMenu ? 'open-menu' : ''}`}
                    >
                      <div className="break-header">
                        <div className="break-info">
                          {getBreaksHeader()}
                        </div>
                        <div className="away-time">
                          <span>Away</span>
                        </div>
                      </div>
                    </th>
                    <th
                      style={{ width: '125px' }}
                      className={`header ${showBreaksMenu || showInactivityMenu ? 'open-menu' : ''
                        }`}
                    >
                      {getInactivityHeader()}
                    </th>
                    <th style={{ width: '206px', minWidth: '125px' }} className='users-table__total-shift-header'>
                      <ShiftTotalHeader>
                        {getShiftTotalHeader()}
                      </ShiftTotalHeader>
                    </th>
                  </>
                  {/* )} */}
                </tr>
              </thead>
              <tbody>
                {(!officeFloor || (loadingFirstPageData && showSkeleton)) && getSkeleton()}
                {(officeFloor && (loadingFirstPageData ? !showSkeleton : true)) && (
                  <SuperPager
                    type="infiniteScroll"
                    isTable={true}
                    dataLength={
                      loadingData ? 0 : officeFloor[formattedSelectedDate]?.data?.length || null
                    }
                    loadMore={() =>
                      getOfficeFloorData({
                        startingPoint: officeFloor[formattedSelectedDate]?.data?.length,
                      })
                    }
                    hasMore={
                      officeFloor[formattedSelectedDate]?.data.length <
                      officeFloor[formattedSelectedDate]?.meta.total_results
                    }
                    wrapper={true}
                    children={getOfficeFloorContent(officeFloor)}
                  />
                )}
              </tbody>
            </table>
            {selectedShiftMemberId && <ShiftDetailsModal
              resourceId={selectedShiftMemberId}
              date={formattedSelectedDate}
              shiftData={officeFloor[formattedSelectedDate]?.data.find(emp => emp.resource_id === selectedShiftMemberId)}
              toggle={() => setSelectedShiftMemberId(null)}
            />}
          </div>
          {/* {selectedMemberId && ( */}
          <TeamMemberAttendanceTable
            isLoggedInUser={loggedInUserRef.current?.resource_id === selectedMemberId}
            resourceId={selectedMemberId}
            showLineLoader={showLineLoader}
            setShowLineLoader={setShowLineLoader}
            isTimerStarted={isTimerStartedRef.current}
            selectedDate={selectedDate}
            userShiftDetails={userShiftDetails}
            setUserShiftDetails={setUserShiftDetails}
          />
          {/* )} */}
        </>
      ) : (
        <div className="team-members">
          {officeFloor ? (
            officeFloor[formattedSelectedDate]?.data?.length > 0 ? <SuperPager
              type="infiniteScroll"
              dataLength={officeFloor[formattedSelectedDate]?.data?.length || null}
              loadMore={() =>
                getOfficeFloorData({
                  startingPoint: officeFloor[formattedSelectedDate]?.data?.length,
                })
              }
              hasMore={
                officeFloor[formattedSelectedDate]?.data.length <
                officeFloor[formattedSelectedDate]?.meta.total_results
              }
              wrapper={true}
              children={officeFloor?.[format(prevSelectedDate)]?.data?.map((emp, index) => {
                return (
                  <TeamMemberCard
                    key={`${index}-${emp.resource_id}`}
                    emp={emp}
                    loggedInUser={loggedInUserRef.current}
                    prevSelectedDate={prevSelectedDate}
                    expandedCard={expandedCard}
                    setExpandedCard={setExpandedCard}
                    showUserDetails={showUserDetails}
                    setShowUserDetails={setShowUserDetails}
                  />
                );
              })}
            /> : <div className='no-data'>
              <span>No Data Available</span>
              <span>Check Filters</span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

function TeamMemberSkeletonRow({ selectedMemberId, handleMemberSelection, className = '' }) {
  return (
    <tr className={className}>
      <td className={`name-container empty-cell ${selectedMemberId ? 'not-selected' : ''}`}
        onClick={() => selectedMemberId && handleMemberSelection(selectedMemberId)}
      ></td>
      {/* {!selectedMemberId && (
        <> */}
      <td className="capitalize"></td>
      <td>
        <div className="clock-info-wrapper">
          <div className="bg-gray clock-info"></div>
          <div className="bg-gray clock-info"></div>
        </div>
      </td>
      <td>
        <div className="user-details">
          <div className="user-details break-details"></div>
          <div className="bg-gray away-time"></div>
        </div>
      </td>
      <td></td>
      <td>
        <SkeletonShiftLog />
      </td>
      {/* </>
      )} */}
    </tr>
  );
}
function SpareRow({ hide }) {
  return (
    <tr className={`spare-skeleton-row ${hide ? 'collapse' : ""}`}>
      <td
        // rowSpan={2}
        colSpan={6}
      // onClick={() => selectedMemberId && handleMemberSelection(selectedMemberId)}
      ></td>
    </tr>
  );
}

export default AttendanceTable